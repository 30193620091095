import moment from "moment";
import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as utils from "../../Utils/comman";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllCities } from "../../reducers/allCitySlice";
import Multiselect from "multiselect-react-dropdown";
import { AddCircle } from "iconsax-react";
import CreatableSelect from "react-select/creatable";

function Add(props) {
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    customer: "",
    travelHead: [],
    purpose: "",
    remarks: "",
  });
  const [type, setType] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [pruposeList, setPerposeList] = useState([]);
  const [travelHead, setTravelHead] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [validationMessage, setValidationMessage] = useState({});
  const staticPurposeData = [
    { id: "Sales", name: "Sales" },
    { id: "Technical", name: "Technical" },
    { id: "Back Office", name: "Back Office" },
    { id: "R&D", name: "R&D" },
    { id: "QC", name: "QC" },
    { id: "Operation", name: "Operation" },
    { id: "Marketing", name: "Marketing" },
    { id: "PR", name: "PR" },
    { id: "Other", name: "Other" },
  ];
  const Allcities = useSelector((state) => state.allcity.Allcities);
  const dispatch = useDispatch();
  const [sendToTravelDesk, setSendToTravelDesk] = useState("");
  const [isTravelHeadValid, setIsTravelHeadValid] = useState(true);
  const [rows, setRows] = useState([
    {
      id: 1,
      startDate: formData.startDate,
      endDate: formData.endDate,
      destination: "",
      nameOfDestination: "",
      state: "",
      district: "",
      city: "",
      remarks: "",
    },
  ]);

  useEffect(() => {
    getState();
    getPurposelist();
    getTravel();
    dispatch(fetchAllCities());
  }, []);

  const getPurposelist = () => {
    utils
      .callAPI("get", `/state/list`)
      .then((res) => {
        if (Array.isArray(res)) {
          setStates(res);
        } else {
          setStates([]);
        }
      })
      .catch((err) => {
        // console.error("Failed to fetch states:", err);
        setStates([]);
      });
  };

  const getTravel = () => {
    utils
      .callAPI("get", `/travel-head/list`)
      .then((res) => {
        if (Array.isArray(res)) {
          const travelHead = res.find((head) => head.head_id === "1");
          if (travelHead) {
            const filteredSubheads = travelHead.subheads.filter(
              (subhead) =>
                !["Auto", "Car", "Bike"].includes(subhead.sub_head_name)
            );
            setTravelHead(filteredSubheads);
          } else {
            setTravelHead([]);
          }
        } else {
          setTravelHead([]);
        }
      })
      .catch((err) => {
        // console.error("Failed to fetch travel heads:", err);
      });
  };

  const getState = () => {
    utils
      .callAPI("get", `/state/list`)
      .then((res) => {
        if (Array.isArray(res)) {
          setStates(res);
        } else {
          setStates([]);
        }
      })
      .catch((err) => {
        // console.error("Failed to fetch states:", err);
        setStates([]);
      });
  };

  const getCitiesForRow = (rowIndex, districtId) => {
    utils
      .callAPI("get", `/city/by-district-list?districtId=${districtId}`)
      .then((res) => {
        if (Array.isArray(res)) {
          const updatedRows = [...rows];
          updatedRows[rowIndex].cities = res;
          updatedRows[rowIndex].city = "";
          setRows(updatedRows);
        } else {
          const updatedRows = [...rows];
          updatedRows[rowIndex].cities = [];
          updatedRows[rowIndex].city = "";
          setRows(updatedRows);
        }
      })
      .catch((err) => {
        // const updatedRows = [...rows];
        // updatedRows[rowIndex].cities = [];
        // updatedRows[rowIndex].city = "";
        // setRows(updatedRows);
      });
  };

  const getDisByState = (rowIndex, stateId) => {
    utils
      .callAPI("get", `/district/list?stateId=${stateId}`)
      .then((res) => {
        if (Array.isArray(res)) {
          const updatedRows = [...rows];
          updatedRows[rowIndex].dis = res;
          updatedRows[rowIndex].district = "";
          setRows(updatedRows);
        } else {
          const updatedRows = [...rows];
          updatedRows[rowIndex].dis = [];
          updatedRows[rowIndex].district = "";
          setRows(updatedRows);
        }
      })
      .catch((err) => {
        // const updatedRows = [...rows];
        // updatedRows[rowIndex].cities = [];
        // updatedRows[rowIndex].city = "";
        // setRows(updatedRows);
      });
  };

  const handlePurposeMultiSelect = (selectedList, selectedItem) => {
    const managerIds = selectedList.map((item) => item.cat).join(",");
    setFormData({
      ...formData,
      travelHead: managerIds,
    });
  };

  const handlePurposeRemove = (selectedList, removedItem) => {
    const removedId = String(removedItem.cat);

    const updatedReportingManager = formData.travelHead
      .split(",")
      .filter((id) => id !== removedId);

    setFormData({
      ...formData,
      travelHead: updatedReportingManager.join(","),
    });
  };

  // const handleCityMultiSelect = (selectedList, selectedItem) => {
  //   const managerIds = selectedList.map((item) => item.cat).join(",");
  //   setFormData({
  //     ...formData,
  //     multi_city: managerIds,
  //   });
  // };

  // const handleCityRemove = (selectedList, removedItem) => {
  //   const removedId = String(removedItem.cat);

  //   const updatedReportingManager = formData.multi_city
  //     .split(",")
  //     .filter((id) => id !== removedId);

  //   setFormData({
  //     ...formData,
  //     multi_city: updatedReportingManager.join(","),
  //   });
  // };

  const handleStartDateChange = (date) => {
    setFormData({ ...formData, startDate: date });
  };

  const handleEndDateChange = (date) => {
    setFormData({ ...formData, endDate: date });
    setRows([
      {
        id: 1,
        startDate: null,
        endDate: null,
        destination: "",
        nameOfDestination: "",
        state: "",
        city: "",
        remarks: "",
      },
    ]);
    setValidationMessage({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      if (!formData.travelHead) {
        setIsTravelHeadValid(false);
      } else {
        setValidated(false);
        setIsTravelHeadValid(true);
        setIsSubmit(true);
      }
    }
  };

  useEffect(() => {
    if (isSubmit) {
      const transformRowsToTravelPlan = (rows) => {
        return rows.map((row) => ({
          sdate: moment(row.startDate).format("YYYY-MM-DD"),
          edate: moment(row.endDate).format("YYYY-MM-DD"),
          destination: row.destination,
          name_of_destination: row.nameOfDestination,
          state: row.state,
          city: row.city.value,
          district: row.district,
          remark: row.remarks,
        }));
      };
      const travel_plan = transformRowsToTravelPlan(rows);
      const bodyFormData = new FormData();
      if (editId) {
        bodyFormData.append("id", editId);
      }

      bodyFormData.append(
        "departureDate",
        moment(formData.startDate).format("YYYY-MM-DD")
      );
      bodyFormData.append(
        "returnDate",
        moment(formData.endDate).format("YYYY-MM-DD")
      );
      bodyFormData.append(
        "purpose_of",
        formData.purpose == "Other" ? formData.remarks : formData.purpose
      );
      bodyFormData.append("travel_mode", formData.travelHead);

      bodyFormData.append("send_to_desk", sendToTravelDesk);
      bodyFormData.append("travel_plan", JSON.stringify(travel_plan));
      // bodyFormData.append("state", formData.multi_state);
      // bodyFormData.append("city", formData.multi_city);

      utils
        .callAPI("post", "/travel/request", bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setFormData({
              startDate: null,
              endDate: null,
              destination: null,
              budget: null,
              reason: null,
            });
            setRows([
              {
                id: 1,
                startDate: null,
                endDate: null,
                destination: "",
                nameOfDestination: "",
                state: "",
                city: "",
                remarks: "",
              },
            ]);

            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
          }
          else{
            setIsSubmit(false);

          }
          setIsSubmit(false);
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      setEditId(props.rowData.id);
      setIsEdit(true);
  
      const purposeExists = staticPurposeData.some(
        (purpose) => purpose.name === props.rowData.purpose_of
      );
  
      setFormData({
        startDate: props.rowData.departureDate,
        endDate: props.rowData.returnDate,
        type: props.rowData.type,
        purpose: purposeExists ? props.rowData.purpose_of : "Other",
        remarks: !purposeExists ? props.rowData.purpose_of : "",
        travelHead: props.rowData.travel_mode,
      });
  
      const sortedTravelPlan = [...props.rowData.travelPlan].sort(
        (a, b) => new Date(a.start_date) - new Date(b.start_date)
      );
  
      const fetchCitiesAndSetRows = async () => {
        const initialRows = [];
        for (const [index, item] of sortedTravelPlan.entries()) {
          const row = {
            id: item.id,
            startDate: item.start_date,
            endDate: item.end_date,
            destination: item.destination,
            nameOfDestination: item.name_of_destination,
            state: item.state,
            district: item.district, // Add district here
            city: item.city,
            remarks: item.remark,
          };
  
          if (row.state) {
            // Fetch the districts based on the state
            const districts = await utils.callAPI(
              "get",
              `/district/list?stateId=${row.state}`
            );
            row.dis = districts || [];
  
            // Fetch the cities based on the district (if district exists)
            if (row.district) {
              const cities = await utils.callAPI(
                "get",
                `/city/by-district-list?districtId=${row.district}`
              );
              row.cities = cities.map(city => ({
                label: city.city_name,
                value: city.city_code,
              })) || [];
  
              row.city = row.cities.find(
                city => city.value === item.city
              ) || { label: item.city_name, value: item.city };
            } else {
              row.cities = [];
            }
          } else {
            row.dis = [];
            row.cities = [];
          }
  
          initialRows.push(row);
        }
  
        setRows(initialRows);
      };
  
      fetchCitiesAndSetRows();
      setSendToTravelDesk(props.rowData.send_to_desk);
      setType(props.rowData.type);
    } else {
      setFormData({
        startDate: null,
        endDate: null,
        destination: null,
        budget: null,
        reason: null,
      });
      setIsEdit(false);
    }
  }, [props.rowData]);
  

  const handleRadioChange = (e) => {
    setSendToTravelDesk(e.target.value);
  };

  const handleAddRow = () => {
    const lastRow = rows[rows.length - 1];
    const newRow = {
      id: rows.length + 1,
      startDate: "",
      endDate: "",
      destination: "",
      nameOfDestination: "",
      state: "",
      city: "",
      remarks: "",
    };
    setRows([...rows, newRow]);
  };

  const handleTableInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    if (field === "state") {
      getDisByState(index, value);
    }

    if (field === "district") {
      getCitiesForRow(index, value);
    }
    setRows(updatedRows);
  };

  const handleDateChange = (index, field, date) => {
    const updatedRows = [...rows];

    if (field === "endDate" && date < updatedRows[index].startDate) {
      setValidationMessage((prev) => ({
        ...prev,
        [index]: "End date cannot be earlier",
      }));
      return;
    }

    setValidationMessage((prev) => ({
      ...prev,
      [index]: "",
    }));

    updatedRows[index][field] = date;
    setRows(updatedRows);
  };

  const handleCityChange = (index, selectedOption) => {
    const newRows = [...rows];
    newRows[index].city = selectedOption;
    setRows(newRows);
  };

  const handleCreateCity = (index, inputValue) => {
    const newRows = [...rows];
    newRows[index].cities = [
      ...newRows[index].cities,
      { value: inputValue, label: inputValue },
    ];
    newRows[index].city = { value: inputValue, label: inputValue };
    setRows(newRows);
    const bodyFormData = new FormData();
    const selectedState = newRows[index].state;
    const selectedDistrict = newRows[index].district;

    bodyFormData.append("state", selectedState);
    bodyFormData.append("district", selectedDistrict);
    bodyFormData.append("city_name", inputValue);

    utils
      .callAPI("post", "/travel/add-city", bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          const updatedRows = [...newRows];
          updatedRows[index].city = { value: res.city_code, label: inputValue };
          setRows(updatedRows);
        }
      })
      .catch((err) => {});
  };

  return (
    <div className="section-body">
      <RB.Card>
        <RB.Card.Header>
          <h4>Add</h4>
          <div className="card-header-action">
            <Link className="btn btn-underline me-2" onClick={handleBackClick}>
              Back
            </Link>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <DatePicker
                      selected={formData.startDate}
                      onChange={handleStartDateChange}
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Start Date"
                      minDate={
                        new Date(new Date().setDate(new Date().getDate() - 10))
                      }
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              {formData.startDate && (
                <RB.Col md={6}>
                  <div className="mb-3">
                    <RB.FloatingLabel className="floating-label-fancy">
                      <DatePicker
                        selected={formData.endDate}
                        onChange={handleEndDateChange}
                        dateFormat="dd-MM-yyyy"
                        className="form-control travelDesk-datePicker"
                        placeholderText="End Date"
                        minDate={formData.startDate}
                        required
                      />
                    </RB.FloatingLabel>
                  </div>
                </RB.Col>
              )}

              {formData.endDate && (
                <RB.Col md={6}>
                  <div className="mb-3">
                    <RB.FloatingLabel className="floating-label-fancy">
                      <RB.Form.Select
                        aria-label="Purpose"
                        name="purpose"
                        value={formData.purpose}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Select Purpose</option>
                        {staticPurposeData.map((purpose) => (
                          <option key={purpose.id} value={purpose.name}>
                            {purpose.name}
                          </option>
                        ))}
                      </RB.Form.Select>
                      <RB.Form.Label>Purpose</RB.Form.Label>
                    </RB.FloatingLabel>
                  </div>
                </RB.Col>
              )}

              {formData.purpose === "Other" && (
                <RB.Col md={6}>
                  <div className="mb-3">
                    <RB.FloatingLabel className="floating-label-fancy">
                      <RB.Form.Control
                        type="text"
                        placeholder="Remarks"
                        name="remarks"
                        value={formData.remarks}
                        onChange={handleInputChange}
                        required
                      />
                      <RB.Form.Label>Remarks</RB.Form.Label>
                    </RB.FloatingLabel>
                  </div>
                </RB.Col>
              )}

              {formData.purpose && (
                <RB.Col md={6}>
                  <div className={`mb-3 ${formData?.travelHead ? "" : "mt-3"}`}>
                    <Multiselect
                      name="it_setup"
                      displayValue="key"
                      id="css_custom"
                      onKeyPressFn={function noRefCheck() {}}
                      onRemove={handlePurposeRemove}
                      className={`${
                        formData?.travelHead?.length !== 0
                          ? "emp_multiselect"
                          : ""
                      } ${
                        !isTravelHeadValid ? "border-bottom border-danger" : ""
                      }`}
                      onSearch={function noRefCheck() {}}
                      onSelect={handlePurposeMultiSelect}
                      options={travelHead.map((it) => ({
                        cat: it.sub_head_id,
                        key: it.sub_head_name,
                      }))}
                      selectedValues={
                        isEdit
                          ? travelHead
                              .filter((plan) =>
                                props?.rowData?.travel_mode
                                  .split(",")
                                  .includes(plan.sub_head_id.toString())
                              )
                              .map((plan) => ({
                                cat: plan.sub_head_id,
                                key: plan.sub_head_name,
                              }))
                          : ""
                      }
                      placeholder="Select Mode Of Travel.."
                      customCloseIcon={
                        <>
                          <i class="material-icons-outlined md14 ms-1">
                            cancel
                          </i>
                        </>
                      }
                      style={{
                        chips: {
                          background: "#FFDE1B",
                          color: "#000000",
                          borderRadius: "1rem",
                        },
                        multiselectContainer: {
                          zIndex: 1,
                        },
                        searchBox: {
                          border: "none",
                          borderBottom: "1px solid #dee2e6",
                          borderRadius: "0px",
                        },
                      }}
                    />
                  </div>
                </RB.Col>

                // <RB.Col md={6}>
                //   <div className="mb-3">
                //     <RB.FloatingLabel className="floating-label-fancy">
                //       <RB.Form.Select
                //         aria-label="Travel Head"
                //         name="travelHead"
                //         value={formData.travelHead}
                //         onChange={handleInputChange}
                //         required
                //       >
                //         <option value="">Select Travel Head</option>
                //         {travelHead.map((head) => (
                //           <option
                //             key={head.sub_head_id}
                //             value={head.sub_head_id}
                //           >
                //             {head.sub_head_name}
                //           </option>
                //         ))}
                //       </RB.Form.Select>
                //       <RB.Form.Label>Travel Head</RB.Form.Label>
                //     </RB.FloatingLabel>
                //   </div>
                // </RB.Col>
              )}

              {formData.travelHead && (
                <>
                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.Form.Label>Send to Travel Desk?</RB.Form.Label>
                      <div className="d-flex">
                        <RB.Form.Check
                          inline
                          type="radio"
                          id="sendToTravelDeskYes"
                          name="sendToTravelDesk"
                          value="yes"
                          label="Yes"
                          checked={sendToTravelDesk === "yes"}
                          onChange={handleRadioChange}
                          required
                        />
                        <RB.Form.Check
                          inline
                          type="radio"
                          id="sendToTravelDeskNo"
                          name="sendToTravelDesk"
                          value="no"
                          label="No"
                          checked={sendToTravelDesk === "no"}
                          onChange={handleRadioChange}
                          required
                        />
                      </div>
                    </div>
                  </RB.Col>
                </>
              )}

              {/* {sendToTravelDesk && (
                <RB.Col md={6}>
                  <div className="mb-3">
                    <RB.FloatingLabel className="floating-label-fancy">
                      <RB.Form.Select
                        aria-label="Type"
                        name="type"
                        value={type}
                        onChange={handleTypeChange}
                        required
                      >
                        <option value="">Select Type</option>
                        <option value="area_visit">Area Wise</option>
                        <option value="distributor_wise">
                          Distributor Wise
                        </option>
                      </RB.Form.Select>
                      <RB.Form.Label>Type</RB.Form.Label>
                    </RB.FloatingLabel>
                  </div>
                </RB.Col>
              )} */}

              {/* {type === "area_visit" && (
                <>
                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        label="State"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Select
                          aria-label="State"
                          name="state"
                          value={formData.state}
                          onChange={handleInputChange}
                        >
                          <option value="">Select State</option>
                          {states?.map((state) => (
                            <option
                              key={state?.state_code}
                              value={state?.state_code}
                            >
                              {state?.state_name}
                            </option>
                          ))}
                        </RB.Form.Select>
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                  {formData?.state && (
                    <RB.Col md={6}>
                      <div className="mb-3">
                        <RB.FloatingLabel
                          label="City"
                          className="floating-label-fancy"
                        >
                          <RB.Form.Select
                            aria-label="City"
                            name="city"
                            value={formData?.city}
                            onChange={handleInputChange}
                          >
                            <option value="">Select City</option>
                            {cities?.map((city) => (
                              <option key={city?.id} value={city?.id}>
                                {city?.city_name}
                              </option>
                            ))}
                          </RB.Form.Select>
                        </RB.FloatingLabel>
                      </div>
                    </RB.Col>
                  )}
                </>
              )} */}

              {/* {type === "distributor_wise" && (
                <RB.Col md={6}>
                  <div className="mb-3">
                    <RB.FloatingLabel className="floating-label-fancy">
                      <RB.Form.Control
                        type="text"
                        placeholder="Customer"
                        name="customer"
                        value={formData.customer}
                        onChange={handleInputChange}
                        required={type === "distributor_wise"}
                      />
                      <RB.Form.Label>Customer</RB.Form.Label>
                    </RB.FloatingLabel>
                  </div>
                </RB.Col>
              )} */}
            </RB.Row>

            {formData.endDate && (
              <div className="mt-2" style={{ overflowX: "auto" }}>
                <RB.Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Destination</th>
                      <th>Name of Destination</th>
                      <th>State</th>
                      <th>District</th>
                      <th>City</th>
                      <th>Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td>{row.id}</td>
                        <td>
                          <div style={{ width: "160px", margin: "-13px" }}>
                            <DatePicker
                              selected={row.startDate}
                              onChange={(date) =>
                                handleDateChange(index, "startDate", date)
                              }
                              placeholderText="Start date"
                              dateFormat="dd-MM-yyyy"
                              className="form-control"
                              minDate={formData.startDate}
                              maxDate={formData.endDate}
                              required
                              popperProps={{ strategy: "fixed" }}
                            />
                          </div>
                        </td>
                        <td>
                          <div style={{ width: "160px", margin: "-13px" }}>
                            <DatePicker
                              selected={row.endDate}
                              onChange={(date) =>
                                handleDateChange(index, "endDate", date)
                              }
                              placeholderText="End date"
                              dateFormat="dd-MM-yyyy"
                              className="form-control"
                              minDate={formData.startDate}
                              maxDate={formData.endDate}
                              required
                              popperProps={{
                                strategy: "fixed",
                                modifiers: [
                                  {
                                    name: "preventOverflow",
                                    options: {
                                      padding: 8,
                                    },
                                  },
                                ],
                              }}
                              popperClassName="custom-datepicker-popper" 
                            />
                            {validationMessage[index] && (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {validationMessage[index]}
                              </span>
                            )}
                          </div>
                        </td>
                        <td>
                          <RB.Form.Select
                            aria-label="Type"
                            name="destination"
                            value={row.destination}
                            onChange={(e) =>
                              handleTableInputChange(
                                index,
                                "destination",
                                e.target.value
                              )
                            }
                            required
                            style={{ width: "170px", margin: "-13px" }}
                          >
                            <option value="">Select Type</option>
                            <option value="area_visit">Area Wise</option>
                            <option value="distributor_wise">
                              Distributor Wise
                            </option>
                            <option value="other">Other</option>
                          </RB.Form.Select>
                          {/* <RB.Form.Label>Type</RB.Form.Label> */}
                        </td>
                        <td>
                          <RB.Form.Control
                            style={{ width: "150px", margin: "-13px" }}
                            type="text"
                            value={row.nameOfDestination}
                            onChange={(e) =>
                              handleTableInputChange(
                                index,
                                "nameOfDestination",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <RB.Form.Select
                            aria-label="State"
                            style={{ width: "170px", margin: "-13px" }}
                            name="state"
                            value={row.state}
                            onChange={(e) =>
                              handleTableInputChange(
                                index,
                                "state",
                                e.target.value
                              )
                            }
                            required
                          >
                            <option value="">Select State</option>
                            {states?.map((state) => (
                              <option
                                key={state?.state_code}
                                value={state?.state_code}
                              >
                                {state?.state_name}
                              </option>
                            ))}
                          </RB.Form.Select>
                        </td>
                        <td>
                          <RB.Form.Select
                            // aria-label="City"
                            style={{ width: "190px", margin: "-13px" }}
                            name="district"
                            value={row.district}
                            onChange={(e) =>
                              handleTableInputChange(
                                index,
                                "district",
                                e.target.value
                              )
                            }
                            required
                          >
                            <option value="">Select District</option>
                            {(row.dis || []).map((dist) => (
                              <option
                                key={dist.district_code}
                                value={dist.district_code}
                              >
                                {dist.district_name}
                              </option>
                            ))}
                          </RB.Form.Select>
                        </td>
                        <td>
                          {/* <RB.Form.Select
                            aria-label="City"
                            style={{ width: "170px", margin: "-13px" }}
                            name="city"
                            value={row.city}
                            onChange={(e) =>
                              handleTableInputChange(
                                index,
                                "city",
                                e.target.value
                              )
                            }
                            required
                          >
                            <option value="">Select City</option>
                            {(row.cities || []).map((city) => (
                              <option
                                key={city.city_code}
                                value={city.city_code}
                              >
                                {city.city_name}
                              </option>
                            ))}
                          </RB.Form.Select> */}
                          <div style={{ width: "200px" }}>
                            <CreatableSelect
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              isClearable
                              value={row.city}
                              onChange={(selectedOption) =>
                                handleCityChange(index, selectedOption)
                              }
                              onCreateOption={(inputValue) =>
                                handleCreateCity(index, inputValue)
                              }
                              options={
                                row?.cities?.map((city) => ({
                                  label: city.city_name,
                                  value: city.city_code,
                                })) || []
                              }
                              placeholder="Type or select a city"
                            />
                          </div>
                        </td>

                        <td>
                          <RB.Form.Control
                            type="text"
                            style={{ width: "170px", margin: "-13px" }}
                            value={row.remarks}
                            onChange={(e) =>
                              handleTableInputChange(
                                index,
                                "remarks",
                                e.target.value
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </RB.Table>

                <RB.Button
                  variant="primary"
                  className="p-2 mb-1"
                  onClick={handleAddRow}
                  // disabled={canAddRow()}
                >
                  <AddCircle />
                </RB.Button>
              </div>
            )}
            <RB.Row className="justify-content-end mt-3">
              <RB.Col className="d-flex justify-content-end">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
