
import { createSlice } from '@reduxjs/toolkit';

const updateExpenseSlice = createSlice({
  name: 'user',
  initialState: {
    updateExp: {}, 
  },
  reducers: {
    setExp: (state, action) => {
      state.updateExp = action.payload;
    },
    clearExp: (state) => {
      state.updateExp = {};
    },
  },
});

export const { setExp, clearExp } = updateExpenseSlice.actions;
export default updateExpenseSlice.reducer;
