// LoaderOverlay.js
import React from "react";
import "./loader.css";
import { useLoader } from "./Loader";
import { Bars, InfinitySpin, RotatingLines } from "react-loader-spinner";
// import { color } from "html2canvas/dist/types/css/types/color";

function LoaderOverlay() {
  const { loading } = useLoader();
  return loading ? (
    <div className="loader-overlay">
      <div className="loader">
        {/* <RotatingLines height="80" width="80" color="#f1d100"></RotatingLines> */}
        <RotatingLines height="80" width="80" color="yellow" strokeColor="yellow"></RotatingLines>
      </div>
    </div>
  ) : null;
}

export default LoaderOverlay;
