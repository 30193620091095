import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";
import { DocumentText1 } from "iconsax-react";

function DocumentUpload({ setDocUpload, editDocument }) {
  const [categories, setCategories] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [errors, setErrors] = useState({});
  const [existingDocs, setExistingDocs] = useState({});

  useEffect(() => {
    getCategoryList();
    if (editDocument) {
      const docs = {};
      editDocument.forEach((doc) => {
        docs[doc.document_categories_id] = doc;
      });
      setExistingDocs(docs);
    }
  }, [editDocument]);

  const getCategoryList = () => {
    utils
      .callAPI("get", `/DocumentCategories/list`)
      .then((res) => {
        if (res && res.result) {
          setCategories(res.result);
        } else {
          console.error("Error fetching categories");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const allowedFileTypes = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const handleFileChange = (e, id) => {
    const file = e.target.files[0];
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        setErrors((prev) => ({ ...prev, [id]: "Invalid file type" }));
      } else {
        setErrors((prev) => ({ ...prev, [id]: null }));
        setUploadedFiles((prev) => ({ ...prev, [id]: file }));
      }
    }
  };

  useEffect(() => {
    const docArray = Object.entries(uploadedFiles).map(([id, file]) => ({
      doc_id: id,
      file: file,
    }));
    setDocUpload(docArray);
  }, [uploadedFiles, setDocUpload]);


  return (
    <>
      <h6 className="mt-2">Employee Documents</h6>
      <hr className="hr-lg mt-1" />
      <RB.Container>
        <RB.Row>
          {categories.slice().reverse().map((category) => (
            <RB.Col md={6} key={category.id} className="mb-3">
              <RB.Form.Group>
                <RB.Form.Label>{category.categories_name}</RB.Form.Label>
                <div className="d-flex align-items-center">
                  <RB.Form.Control
                    type="file"
                    accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/*,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) => handleFileChange(e, category.id)}
                    required={!existingDocs[category.id] && category.isRequired === "true"}
                  />
                  {existingDocs[category.id] && (
                    <a
                      href={existingDocs[category.id].documentUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="ms-2"
                    >
                     <DocumentText1/>
                    </a>
                  )}
                </div>
                {errors[category.id] && (
                  <RB.Form.Text className="text-danger">
                    {errors[category.id]}
                  </RB.Form.Text>
                )}
              </RB.Form.Group>
            </RB.Col>
          ))}
        </RB.Row>
      </RB.Container>
    </>
  );
}

export default DocumentUpload;
