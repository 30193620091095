import React, { useCallback, useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";
import { Tree } from "primereact/tree";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";

function AssignPermission({
  setAssignPermission,
  setCurrentRole,
  currentRole,
}) {
  const [permission, setPermission] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState(null);
  const [currentUserPermission, setCurrentUserPermission] = useState([]);

  useEffect(() => {
    getPermission();
  }, []);

  const getPermission = () => {
    utils
      .callAPI("get", `/permission/module-permission`)
      .then((res) => {
        if (typeof res !== "undefined") {
          const formattedData = transformPermissions(res);
          setPermission(formattedData);
          getCurrentUserPermission();

        } else {
          setPermission([]);
        }
      })
      .catch((err) => {
        setPermission([]);
      });
  };

  const transformPermissions = (permissions) => {
    return permissions.map((module) => ({
      key: module.module,
      label: module.module,
      children: module.permissions.map((perm) => ({
        key: perm.id.toString(),
        label: perm.name,
      })),
    }));
  };
  const getCurrentUserPermission = () => {
    utils
      .callAPI("get", `role/permissions?role_id=${currentRole?.id}&user_id=`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setCurrentUserPermission(res?.permissions);
        } else {
        }
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    if (currentUserPermission.length > 0 && permission.length > 0) {
      const selectedKeyData = {};
      permission.forEach((module) => {
        const selectedChildren = module.children.filter((child) =>
          currentUserPermission.some(
            (permission) => permission.permission_id === child.key
          )
        );

        if (selectedChildren.length > 0) {
          if (selectedChildren.length === module.children.length) {
            selectedKeyData[module.key] = { checked: true };
          } else {
            selectedKeyData[module.key] = { partialChecked: true };
          }

          selectedChildren.forEach((child) => {
            selectedKeyData[child.key] = { checked: true };
          });
        }
      });

      setSelectedKeys(selectedKeyData);
    }
  }, [currentUserPermission, permission]);
  

  const handleBack = () => {
    setAssignPermission(false);
  };

  const handlePermission = () => {
    // console.log(selectedKeys)
    // return
    const selectedPermissionIds = Object.keys(selectedKeys)
      .filter((key) => !isNaN(parseInt(key, 10)))
      .map((key) => parseInt(key, 10));

    var bodyFormData = new FormData();

    bodyFormData.append("role_id", currentRole.id);
    bodyFormData.append("permission_id", JSON.stringify(selectedPermissionIds));
    bodyFormData.append("description", "");

    utils
      .callAPI("post", `/role/assign/permission`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          // setAssignPermission(false);
        }
      })
      .catch((err) => {});
  };

  return (
    <RB.Card className="p-4">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <h5 className="d-inline-block">{currentRole.roleName}</h5>
        </div>
        <RB.Button className="btn btn-underline me-2" onClick={handleBack}>
          Back
        </RB.Button>
      </div>
      <RB.Card.Body>
        <Tree
          className="w-full md:w-30rem"
          value={permission}
          selectionMode="checkbox"
          selectionKeys={selectedKeys}
          onSelectionChange={(e) => setSelectedKeys(e.value)}
        />
        <div class="d-flex justify-content-center mt-3">
          <div></div>
          <RB.Button variant="primary" onClick={handlePermission}>
            Update
          </RB.Button>
        </div>
      </RB.Card.Body>
    </RB.Card>
  );
}

export default AssignPermission;
