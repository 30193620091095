import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import * as XLSX from "xlsx";
import CustomPagination from "../../Utils/CustomPagination";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import excelSvg from "../../assets/images/excel.svg";
import { debounce } from "lodash";
import { AddCircle, DocumentDownload } from "iconsax-react";

function TransactionDetail(props) {
  const { rowData } = props;
  const [loading, setLoading] = useState(true);

  const [couponCode, setCouponCode] = useState([]);

  const [activeTab, setActiveTab] = useState("Initiate");

  const [csvData, setCsvData] = useState([]);
  const [page, setPage] = useState(1);
  const [filterDate, setFilterDate] = useState("");
  const [selectable, setSelectable] = useState(false);
  const [filterCoupon, setFilterCoupon] = useState("");
  const [filterProduct, setFilterProduct] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startError, setStartError] = useState(false);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [active, setActive] = useState(false);
  const csvLink = createRef();
  const [selectedRows, setSelectedRows] = React.useState(false);
  const [download, setDownload] = useState(false);

  const debouncedGetCouponList = useCallback(
    debounce(() => {
      getCouponList();
    }, 500),
    []
  );

  const handleChange = ({ selectedRows }) => {
    if (activeTab === "all") {
      setSelectedRows(selectedRows.map((row) => row.coupon_batch));
    } else {
      setSelectedRows(selectedRows.map((row) => row.id));
    }
  };
  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const baseColumns = [
    {
      name: "Sr.No",
      selector: (row, index) => (page - 1) * countPerPage + index + 1,
      sortable: false,
      maxWidth: "80px", // Adjust the maximum width as needed
      minWidth: "60px",
    },
    {
      name: <div>Transaction Date</div>,
      selector: (row) =>
        moment(row.transction_date).format("DD-MM-yyyy HH:mm:ss"),
      maxWidth: "150px", // Adjust the maximum width as needed
      minWidth: "150px",
    },
    { name: "Account No", selector: (row) => row.account_no },
    { name: "IFSC Code", selector: (row) => row.ifsc_code },
    {
      name: <div>Account Holder Name</div>,
      selector: (row) => row.account_holder_name,
    },
    {
      name: <div>Amount</div>,
      selector: (row) => parseFloat(row.amount).toFixed(2),
    },
  ];

  const columnsByTab = {
    Initiate: [
      ...baseColumns,
      { name: "Unique Id", selector: (row) => row.unique_id },
    ],
    Pending: [
      ...baseColumns,
      { name: "Response", selector: (row) => row.response },
      //  { name: "Message", selector: (row) => row.message },
    ],
    Succeed: [
      ...baseColumns,
      { name: "Unique Id", selector: (row) => row?.unique_id },
      { name: "UTR Number", selector: (row) => row?.utr_no },
      { name: "Response", selector: (row) => row?.response },
    ],
    Failed: [
      ...baseColumns,
      { name: "Unique Id", selector: (row) => row?.unique_id },
      { name: "UTR Number", selector: (row) => row?.utr_no },
      // { name: "NEFT Status", selector: (row) => row?.neftStatus },
      // { name: "Response", selector: (row) => row?.response },
      //  { name: "Message", selector: (row) => row?.message },
    ],
  };

  const columns = useMemo(() => columnsByTab[activeTab], [activeTab]);

  const handleStartDate = (date) => {
    setStartDate(date);
    setStartError(false);
  };

  useEffect(() => {
    setCouponCode([]);
    getCouponList();
  }, [page, activeTab]);

  const getCouponList = () => {
    setLoading(true);
    let endpoint = "";
    //URL-{{base_url}}/wallet/transaction-list?client_id
    endpoint = `/wallet/transaction-list?client_id=${
      rowData.id ? rowData.id : rowData.clientId
      //rowData.id ? rowData.id : rowData.clientId ? rowData.clientId : ""
    }&search=${activeTab}`;

    utils
      .callAPI(
        "get",
        `${endpoint}&sdate=${
          startDate ? moment(startDate).format("YYYY-MM-DD") : ""
        }&edate=${
          endDate ? moment(endDate).format("YYYY-MM-DD") : ""
        }&page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        setLoading(false);
        if (typeof res !== "undefined") {
          setCouponCode(res);
        } else {
          setCouponCode([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setCouponCode([]);
      });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setPage(1); // Reset page number when switching tabs
  };

  const handleDownloadBatch = (row) => {
    setCsvData([]);

    utils
      .callAPI("get", `Coupon/export?coupon_batch=${row.coupon_batch}`)
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          setCsvData(res);
          setActive(true);
        } else {
          setCsvData([]);
        }
      })
      .catch((err) => {
        setCsvData([]);
      });
  };

  function handleDownload() {
    const commonColumns = [
      "Transaction Date",
      "Account No",
      "IFSC Code",
      "Account Holder Name",
      "Amount",
    ];
    const tabSpecificColumns = {
      Initiate: ["Unique Id"],
      Pending: ["Response"],
      Succeed: ["Unique Id", "UTR Number", "Response"],
      Failed: ["Unique Id", "UTR Number"],
    };
    const csvColumns = [...commonColumns, ...tabSpecificColumns[activeTab]];
    utils
      .callAPI(
        "get",
        `/wallet/transaction-export?status=${activeTab}&client_id=${
          rowData.id ? rowData.id : rowData.clientId
        }&sdate=${
          startDate ? moment(startDate).format("YYYY-MM-DD") : ""
        }&edate=${endDate ? moment(endDate).format("YYYY-MM-DD") : ""}`
      )
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          const formattedData = res.map((item) => {
            return csvColumns.map((col) => {
              switch (col) {
                case "Transaction Date":
                  return item.transction_date || "";
                case "Account No":
                  return item.account_no || "";
                case "IFSC Code":
                  return item.ifsc_code || "";
                case "Account Holder Name":
                  return item.account_holder_name || "";
                case "Amount":
                  return item.amount || "";
                case "Unique Id":
                  return item.unique_id || "";
                case "Response":
                  return item.response || "";
                case "UTR Number":
                  return item.utr_no || "";
                default:
                  return "";
              }
            });
          });

          const csvData = [csvColumns, ...formattedData];
          // setCsvData(csvData);
          // setActive(true);
          generateCSV(csvData); 

        } else {
          setCsvData([]);
        }
      })
      .catch((err) => {
        setCsvData([]);
      });
  }

  
  function generateCSV(filteredData) {
    const ws = XLSX.utils.json_to_sheet(filteredData, {
      header: Object.keys(filteredData[0]),
      skipHeader: true
    });
    
    const wb = XLSX.utils.book_new();
    
    XLSX.utils.book_append_sheet(wb, ws, "Employees");
    
    const csvOutput = XLSX.utils.sheet_to_csv(ws);
    
    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });
    
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${activeTab}_transactions.csv`;
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleDate = (date) => {
    setFilterDate(date);
  };

  const handleSearch = () => {
    getCouponList();
  };

  const handleResetDate = () => {
    setStartDate(null);
    setEndDate(null);

    debouncedGetCouponList();
  };

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [csvData]);

  const handleMultipleDelete = () => {
    setSelectable(!selectable);
  };

  const handleEndDate = (date) => {
    if (!startDate) {
      setStartError(true);
      return;
    }
    setEndDate(date);
  };

  const handleDeleteSelectedRows = () => {
    if (selectedRows.length === 0) {
      return;
    }

    const formattedIds = selectedRows.join(",");

    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete them!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("post", `/Coupon/bulk-delete?id=${formattedIds}`)
          .then((res) => {
            if (typeof res !== "undefined") {
              // Remove deleted rows from the table
              //  const updatedCouponCode = couponCode.filter(
              //    (row) => !idsToDelete.includes(row.id)
              //  );
              //  setCouponCode(updatedCouponCode);
              // Clear selected rows
              setSelectedRows([]);

              // Optionally, refresh the table data
              getCouponList();
            }
          })
          .catch((err) => {});
      }
    });
  };

  const handleFilter = () => {
    getCouponList();
  };

  const handleBackClick = useCallback((row) => async () => {
    const propdata = {
      mode: "view",
      rowData: row,
    };
    props.onChange(propdata);
  });
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <div className="section-body">
      <RB.Card className="card shadow">
        <RB.Card.Header className="border-0">
          <h4>{rowData ? rowData.name : "List"}</h4>
          <div className="card-header-action">
            <Link
              className="btn btn-underline"
              onClick={handleBackClick(rowData)}
            >
              Back
            </Link>
          </div>
        </RB.Card.Header>
        <div className="col-8 px-4 pt-3">
          {activeTab !== "scanned" ? (
            <div className="row align-items-center mt-1 mb-3" text-center>
              <div className="col-md-9">
                <div className="row g-3 align-items-center">
                  <div className="col-md">
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDate}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="dd-MM-yyyy"
                      className={`form-control range-datepicker-input ${
                        startError ? "date-border-danger" : ""
                      }`}
                      placeholderText="From Date"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearch();
                        }
                      }}
                      required
                    />
                  </div>

                  <div className="col-md">
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDate}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate} // Ensures end date cannot be before start date
                      dateFormat="dd-MM-yyyy"
                      className={`form-control range-datepicker-input ${
                        startError ? "date-border-danger" : ""
                      }`}
                      placeholderText="To Date"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearch();
                        }
                      }}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <RB.Button
                  variant="primary"
                  className=" btn-md"
                  onClick={handleFilter}
                  disabled={!endDate}
                >
                  Find
                </RB.Button>
                <Link
                  className="ms-2 btn-underline"
                  //variant="secondary"
                  onClick={handleResetDate}
                >
                  Reset
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <RB.Card.Header>
          <RB.Row>
            <RB.Col md={12}>
              <RB.Nav variant="tabs" defaultActiveKey="link-0">
                <RB.Nav.Item>
                  <RB.Nav.Link
                    eventKey="link-0"
                    onClick={() => handleTabClick("Initiate")}
                  >
                    Initiate
                  </RB.Nav.Link>
                </RB.Nav.Item>
                <RB.Nav.Item>
                  <RB.Nav.Link
                    eventKey="link-1"
                    onClick={() => handleTabClick("Pending")}
                  >
                    Pending
                  </RB.Nav.Link>
                </RB.Nav.Item>
                <RB.Nav.Item>
                  <RB.Nav.Link
                    eventKey="link-2"
                    onClick={() => handleTabClick("Succeed")}
                  >
                    Succeed
                  </RB.Nav.Link>
                </RB.Nav.Item>
                <RB.Nav.Item>
                  <RB.Nav.Link
                    eventKey="link-3"
                    onClick={() => handleTabClick("Failed")}
                  >
                    Failed
                  </RB.Nav.Link>
                </RB.Nav.Item>
              </RB.Nav>
            </RB.Col>

            <div className="card-header-action">
              <div className="floating-btns-holder-artisan">
                <a
                  href
                  className="btn btn-primary btn-circle me-2"
                  variant="primary"
                  onClick={() => handleDownload()}
                >
                  <DocumentDownload />
                </a>
                <CSVLink data={csvData} ref={csvLink} />

                <a
                  href
                  className="btn btn-dark btn-circle"
                  variant="dark"
                  onClick={handleAddClick}
                >
                  <AddCircle />
                </a>
              </div>

              {/* <CSVLink data={csvData} ref={csvLink} target="_blank" /> */}
            </div>
          </RB.Row>
        </RB.Card.Header>
        <RB.Card.Body>
          {/* <div className="deleteOptions">
            {selectable ? (
              <>
                <RB.Button
                  className=" btn-md me-2"
                  variant="light"
                  onClick={handleMultipleDelete}
                >
                  Cancel
                </RB.Button>
                {selectedRows.length > 0 && (
                  <RB.Button
                    className="btn-md"
                    variant="danger"
                    onClick={handleDeleteSelectedRows}
                  >
                    Delete Selected
                  </RB.Button>
                )}
              </>
            ) : (
              <RB.Button
                className=" btn-md"
                variant="light"
                onClick={handleMultipleDelete}
              >
                Delete Multiple
              </RB.Button>
            )}
          </div> */}
          <DataTable
            columns={columns}
            className="table-stripped-columns"
            data={couponCode.result}
            highlightOnHover
            responsive
            pagination
            paginationServer
            selectableRows={selectable}
            paginationTotalRows={couponCode.total}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            paginationComponent={() => (
              <CustomPagination
                currentPage={page}
                totalRows={couponCode.total}
                rowsPerPage={countPerPage}
                onPageChange={handlePageChange}
              />
            )}
            striped
            progressPending={loading}
            onChangePage={(page) => setPage(page)}
            onSelectedRowsChange={handleChange}
            persistTableHead
            customStyles={{
              head: {
                style: {
                  fontSize: "0.875rem", // Adjust the font size as needed
                  fontWeight: "bold", // Adjust the font weight as needed
                },
              },

              // rows: {
              //   style: {
              //     backgroundColor: "#f7f7f7", // Default background color for all rows
              //   },
              // },
            }}
            // customRowStyles={[
            //   {
            //     when: (row, index) => index % 2 !== 0, // Apply style to odd rows
            //     style: {
            //       backgroundColor: "#ffffff", // Background color for odd rows
            //     },
            //   },
            // ]}
            // subHeader
            // subHeaderComponent={subHeaderComponent}
          />
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default TransactionDetail;
