import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../Utils/comman";

// Async thunk to fetch travel-local expense data
export const fetchTravelLocalExpense = createAsyncThunk(
  "expense/fetchTravelLocalExpense",
  async ({ openAccordionId, localID }, thunkAPI) => {
    try {
      //   const scheduleId = openAccordionId ? openAccordionId : "";
      //   const expMasterId = !openAccordionId ? 25 : "";

      const response = await utils.callAPI(
        "get",
        `/expense/travel-local-expense?schedule_id=${openAccordionId ? openAccordionId:''}&exp_master_id=${localID}`
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const initialState = {
  expenseData: null,
  isLoading: false,
  isError: false,
  errorMessage: "",
};

const expenseSlice = createSlice({
  name: "expense",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTravelLocalExpense.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchTravelLocalExpense.fulfilled, (state, action) => {
        state.expenseData = action.payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchTravelLocalExpense.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload || "Something went wrong";
      });
  },
});

export default expenseSlice.reducer;
