import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../Utils/comman";

export const fetchDistricts = createAsyncThunk(
  "location/fetchDistricts",
  async (stateId, thunkAPI) => {
    try {
      const response = await utils.callAPI("get", `/district/list?stateId=${stateId ? stateId:''}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  districts: [],
  isLoading: false,
  isError: false,
};

const districtSlice = createSlice({
  name: "district",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDistricts.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchDistricts.fulfilled, (state, action) => {
        state.districts = Array.isArray(action.payload) ? action.payload : [];
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchDistricts.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default districtSlice.reducer;
