import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Add(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [formData, setFormData] = useState({
    employelist: id || "",
    year: new Date().getFullYear(),
    leaveBalances: [],
  });

  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [userList, setUserList] = useState([]);
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [editLeaveTypeId, setEditLeaveTypeId] = useState(null);

  useEffect(() => {
    getUserList();
    getLeaveTypeList();
  }, []);

  const getUserList = () => {
    utils
      .callAPI("get", `/users/users-list`)
      .then((res) => {
        setUserList(res?.result || []);
      })
      .catch(() => {
        setUserList([]);
      });
  };

  const getLeaveTypeList = () => {
    utils
      .callAPI("get", `/leave/leave-type/list`)
      .then((res) => {
        if (res) {
          // Filter to include only active leave types
          const activeLeaveTypes = res.filter(leaveType => leaveType.isActive === "1");
  
          setLeaveTypeList(activeLeaveTypes);
  
          if (props.rowData) {
            const leaveTypeId = props.rowData.leave_type_id;
            setEditLeaveTypeId(leaveTypeId);
            setIsEdit(true);
            setFormData({
              employelist: props.rowData.user_id || "",
              year: props.rowData.year || new Date().getFullYear(),
              leaveBalances: [
                {
                  leaveTypeId: leaveTypeId,
                  leaveBalance: props.rowData.leave_balance || "",
                },
              ],
            });
          } else {
            setIsEdit(false);
            setFormData({
              ...formData,
              leaveBalances: activeLeaveTypes.map((leaveType) => ({
                leaveTypeId: leaveType.id,
                leaveBalance: "0",
              })),
            });
          }
        } else {
          setLeaveTypeList([]);
        }
      })
      .catch(() => {
        setLeaveTypeList([]);
      });
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLeaveBalanceChange = (index, value) => {
    const updatedLeaveBalances = formData.leaveBalances.map((item, i) =>
      i === index
        ? { ...item, leaveBalance: value.replace(/[^0-9]/g, "") }
        : item
    );
    setFormData({
      ...formData,
      leaveBalances: updatedLeaveBalances,
    });
  };

  const handleBackClick = () => {
    props.onChange({ mode: "list" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      const bodyFormData = new FormData();
      if (props.rowData && Object.keys(props.rowData).length > 0) {
        bodyFormData.append("id", props.rowData.id);
      }

      bodyFormData.append("user_id", formData.employelist);
      bodyFormData.append("year", formData.year);
      bodyFormData.append(
        "leave_balance",
        JSON.stringify(
          formData.leaveBalances.map((item) => ({
            leave_type_id: item.leaveTypeId,
            leave_balance: item.leaveBalance,
          }))
        )
      );

      utils
        .callAPI("post", `/leave/leave-balance/save`, bodyFormData)
        .then(() => {
          props.onChange({ mode: "list" });
          setIsSubmit(false);
          if (id) {
            navigate(".", { replace: true });
          }
        })
        .catch(() => {});
    }
  }, [isSubmit]);

  return (
    <div className="section-body">
      <RB.Card className="card shadow">
        <RB.Card.Header>
          <h4>{isEdit ? "Edit" : "Add"}</h4>
          <div className="card-header-action">
            <Link className="btn btn-underline" onClick={handleBackClick}>
              Back
            </Link>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formEmployeeList"
                    label="Employee"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Select
                      name="employelist"
                      value={formData.employelist}
                      onChange={handleInputChange}
                      required
                      disabled={id ? true : false}
                    >
                      <option value="">Select Employee...</option>
                      {userList.map((employee) => (
                        <option key={employee.id} value={employee.id}>
                          {`${employee.firstName} ${employee.lastName}`}
                        </option>
                      ))}
                    </RB.Form.Select>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formYear"
                    label="Year"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="number"
                      placeholder="Enter Year"
                      name="year"
                      value={formData.year}
                      onChange={handleInputChange}
                      required
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please provide a year.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>
            {leaveTypeList.map((leaveType) =>
              leaveType.id === editLeaveTypeId || !isEdit ? (
                <RB.Row key={leaveType.id}>
                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        controlId={`formLeaveType-${leaveType.id}`}
                        label="Leave Type"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="text"
                          placeholder="Leave Type"
                          value={leaveType.leave_name}
                          readOnly
                        />
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        controlId={`formLeaveBalance-${leaveType.id}`}
                        label="Leave Balance"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="text"
                          placeholder="Enter Leave Balance"
                          value={
                            formData.leaveBalances.find(
                              (balance) => balance.leaveTypeId === leaveType.id
                            )?.leaveBalance || ""
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value)) {
                              handleLeaveBalanceChange(
                                formData.leaveBalances.findIndex(
                                  (balance) =>
                                    balance.leaveTypeId === leaveType.id
                                ),
                                value
                              );
                            }
                          }}
                          required
                        />

                        <RB.Form.Control.Feedback type="invalid">
                          Please provide a numeric value for the leave balance.
                        </RB.Form.Control.Feedback>
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                </RB.Row>
              ) : null
            )}
            <RB.Row className="justify-content-start mt-3">
              <RB.Col className="col-auto">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
