import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../Utils/comman";

export const fetchStates = createAsyncThunk(
  "location/fetchStates",
  async (_, thunkAPI) => {
    try {
      const response = await utils.callAPI("get", "/state/list");
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  states: [],
  isLoading: false,
  isError: false,
};

const stateSlice = createSlice({
  name: "state",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStates.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.states = Array.isArray(action.payload) ? action.payload : [];
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchStates.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default stateSlice.reducer;
