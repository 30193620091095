import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Edit } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { AddCircle, Edit2, Trash } from "iconsax-react";
import CustomPagination from "../../Utils/CustomPagination";

function List(props) {
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [data, setData] = useState({});
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };
  const columns = [
    {
      name: "Employee",
      selector: (row) => row.username,
    },
    {
      name: "Leave Type",
      selector: (row) => row.leave_name,
    },
    {
      name: "Year",
      selector: (row) => row.year,
    },
    {
      name: "Leave Balance",
      selector: (row) => row.leave_balance,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          {utils.hasPermission(
            primaryMenuRes,
            "Leave Balance",
            "leave_balance.edit"
          ) && (
            <RB.Button
              className="btn-flat"
              onClick={handelEdit(row)}
            >
              <Edit2 />
            </RB.Button>
          )}
          &nbsp;
          {/* {utils.hasPermission(
            primaryMenuRes,
            "Leave Balance",
            "leave_balance.delete"
          ) && (
            <RB.Button
              className="btn-flat"
              onClick={showDelete(row)}
            >
              <Trash />
            </RB.Button>
          )} */}
        </>
      ),

      omit:
        !utils.hasPermission(
          primaryMenuRes,
          "Leave Balance",
          "leave_balance.edit"
        ) &&
        !utils.hasPermission(
          primaryMenuRes,
          "Leave Balance",
          "leave_balance.delete"
        ),
    },
  ];

  useEffect(() => {
    getLeaveBalanceList();
  }, [page]);

  const getLeaveBalanceList = () => {
    utils
      .callAPI(
        "get",
        `/leave/leave-balance/list?page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setData(res);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        setData([]);
      });
  };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    // Swal({
    //     title: 'Are you sure?',
    //     text: 'Once deleted, you will not be able to recover this imaginary file!',
    //     icon: 'warning',
    //     buttons: ["No", "Yes"],
    //     dangerMode: true,
    //   })
    //   .then((willDelete) => {
    //     if (willDelete) {

    //     }
    //   });

    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/leave/leave-balance/delete?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              getLeaveBalanceList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  
  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
      <div className="floating-btns-holder-artisan">
            {utils.hasPermission(
              primaryMenuRes,
             "Leave Balance",
              "leave_balance.create"
            ) && (
              <a
                href
                className="btn btn-dark btn-circle"
                variant="dark"
                onClick={handleAddClick}
              >
                <AddCircle />
              </a>
            )}
          </div>
        <RB.Card.Header>
          <h4></h4>
          <div className="card-header-action">
            
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          {utils.hasPermission(
            primaryMenuRes,
            "Leave Balance",
            "leave_balance.view"
          ) && (
            <DataTable
              columns={columns}
              data={data.result}
              highlightOnHover
              responsive
              pagination
              striped
              paginationServer
              paginationTotalRows={data.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              paginationComponent={() => (
                <CustomPagination
                  currentPage={page}
                  totalRows={data.total}
                  rowsPerPage={countPerPage}
                  onPageChange={handlePageChange}
                />
              )}
              onChangePage={(page) => setPage(page)}
              persistTableHead
              // subHeader
              customStyles={{
                head: {
                  style: {
                    fontSize: "0.875rem", // Adjust the font size as needed
                    fontWeight: "bold", // Adjust the font weight as needed
                  },
                },
              }}
              // subHeaderComponent={subHeaderComponent}
            />
          )}
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
