import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../Layout";
import ItSetup1 from "../Components/ItSetup/ItSetup";
function ItSetup() {
  const [comp, setComp] = useState("list");
  const [rowData, setRowData] = useState();
  const location = useLocation();

  useEffect(() => {
    setComp("list");
  }, [location]);

  function handleChange(newValue) {
    setComp(newValue.mode);
    setRowData(newValue.rowData);
  }
  return <ItSetup1 />;
}

export default ItSetup;
