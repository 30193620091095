import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../Utils/comman";

export const fetchTravelPermissionList = createAsyncThunk(
  "travelPermission/fetchTravelPermissionList",
  async (_, thunkAPI) => {
    try {
      const response = await utils.callAPI("get", "/travel-permission/list");
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  travelPermissionList: [],
  isLoading: false,
  isError: false,
};

const travelPermissionSlice = createSlice({
  name: "travelPermission",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTravelPermissionList.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchTravelPermissionList.fulfilled, (state, action) => {
        state.travelPermissionList = action.payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchTravelPermissionList.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default travelPermissionSlice.reducer;
