import React, { useState } from "react";
import Layout from "../Layout";
import Add from "../Components/Tds/Add";
import View from "../Components/Tds/View";
import List from "../Components/Tds/List";

function Tds() {
  const [comp, setComp] = useState("list");
  const [rowData, setRowData] = useState();

  function handleChange(newValue) {
    setComp(newValue.mode);
    setRowData(newValue.rowData);
  }

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Tax & TDS</h1>
          </div>
          {comp === "list" ? (
            <List comp={comp} onChange={handleChange} rowData={rowData} />
          ) : comp === "view" ? ( // Check if comp is "scan"
            <View
              comp={comp}
              rowData={rowData}
              onChange={handleChange}
              setRowData={setRowData}
            />
          ) : (
            <Add comp={comp} rowData={rowData} onChange={handleChange} />
          )}
        </section>
      </div>
    </Layout>
  );
}

export default Tds;
