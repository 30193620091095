import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import * as utils from "../../Utils/comman";
import * as RB from "react-bootstrap";
import { useSelector } from "react-redux";

function List() {
  const [docList, setDocList] = useState({});
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  useEffect(() => {
    getannouncement();
  }, []);

  const getannouncement = () => {
    utils
      .callAPI("get", `/employee/documents`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setDocList(res);
        } else {
          setDocList({});
        }
      })
      .catch(() => {
        setDocList({});
      });
  };

  const extractDocName = (url) => {
    const parts = url.split("/");
    const fileName = parts[parts.length - 1];

    if (fileName.includes("probation_period")) {
      return "Confirmation Letter";
    } else if (fileName.includes("relievingletter")) {
      return "Relieving Letter";
    } else if (fileName.includes("appointmentletter")) {
      return "Appointment Letter";
    }

    const name = fileName.split("_")[0].split(".")[0];
    return capitalizeFirstLetter(name.replace(/_/g, " "));
  };

  const capitalizeFirstLetter = (string) => {
    return string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Documents</h1>
          </div>
          <div className="section-body">
            <RB.Card>
              <RB.Card.Body>
                {utils.hasPermission(
                  primaryMenuRes,
                  "Documents",
                  "documents.view"
                ) && (
                  <>
                    <RB.Row>
                      {Object.keys(docList).length > 0 ? (
                        <>
                          {Object.keys(docList).map((key) => (
                            <RB.Col key={key} className="col-md-4 col-lg-3">
                              <div
                                className="mb-4 rounded border p-3 text-center"
                                style={{ width: "180px" }}
                              >
                                <div className="product-doc-upload1">
                                  <a
                                    href={docList[key].path}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-decoration-none text-dark"
                                  >
                                    <i className="material-icons-outlined md-64">
                                      picture_as_pdf
                                    </i>
                                    <div className="text-center">
                                      {docList[key].cat_name
                                        ? docList[key].cat_name
                                        : extractDocName(docList[key].path)}
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </RB.Col>
                          ))}
                        </>
                      ) : (
                        <div className="text-center">
                          <h6>No Documents Found</h6>
                        </div>
                      )}
                    </RB.Row>
                  </>
                )}
              </RB.Card.Body>
            </RB.Card>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default List;
