import React, { useState, useEffect } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../Utils/comman";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../Components/Logo";
import flowerImg from "../assets/images/flower.svg";

function ResetPassword() {
	const [validated, setValidated] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [error, setError] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const navigate = useNavigate();
	const { token } = useParams();
	let year = new Date().getFullYear();
	const [values, setValues] = useState({
		newPassword: "",
		confirmPassword: "",
	});

	const handleTextChange = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.currentTarget;

		if (form.checkValidity() === false) {
			e.stopPropagation();
			setValidated(true);
		} else if (values.newPassword !== values.confirmPassword) {
			setError("Passwords do not match");
		} else {
			setError("");
			setIsSubmit(true);
		}
	};

	useEffect(() => {
		if (isSubmit) {
			setIsSubmit(false);

			const bodyFormData = new FormData();
			bodyFormData.append("token", token);
			bodyFormData.append("new_password", values.confirmPassword);

			utils.callAPI("post", "/forgot/reset-password", bodyFormData).then((res) => {
				if (res !== undefined) {
					navigate("/");
				} else {
					setError("Failed to reset password");
				}
			});
		}
	}, [isSubmit]);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	return (
		<>
			<section className="login-wrap bg-black min-vh-100 d-flex justify-content-center">
				<RB.Container className="pt-5 d-flex flex-column">
					<RB.Row className="justify-content-center justify-content-lg-center h-100 ">
						<RB.Col
							md={10}
							lg={6}
							xl={7}
							className="order-2 order-lg-1 align-self-end text-center d-none">
							{/* <div className="logo-block d-none d-lg-flex align-items-center mb-4">
								<Logo />
							</div> */}
							<h4 className="text-white mb-5">
								Reset Password
								{/* 	A Futuristic Brand Conceived And Nurtured With The Experience Of 120
								Years.{" "}
								A FUTURISTIC BRAND CONCEIVED AND NURTURED WITH THE EXPERIENCE OF
                120 YEARS. */}
							</h4>
							<p className="text-md mt-3">
								{/*	Vura Bau-Chemie LLP is a global brand that manufactures adhesives,
								sealants, waterproofing, repairs, maintenance & building products which
								are formulated with world-class German polymers & technology. It has
								developed a comprehensive product range based on international standards
								to facilitate the global construction chemical manufacturer and building
								materials market.
								 The brains behind the product development have a
                combined experience of more than 120 years in the same industry.
                With this abundance of experience, the company is ready to build
                better! */}
							</p>
							<div className="login-img">
								<RB.Image src={flowerImg} alt="Vura login" className="img-fluid" />
							</div>
						</RB.Col>
						<RB.Col
							md={10}
							lg={6}
							xl={5}
							className="order-1 order-lg-2 mb-3 mb-md-0 d-flex justify-content-center align-items-center">
							{/* <div className="logo-block d-flex d-lg-none my-4 justify-content-center">
								<Logo />
							</div> */}
							<RB.Card className="login-card bg-black rounded-4 shadow-lg border-0 w-100">
								<RB.CardHeader className="border-0">
									<div className="logo-block">
										{/* <Logo /> */}
										<h4 className="text-white">Reset Password</h4>
									</div>
									{/* <h2 className="text-dark mb-3 text-2xl ">
										Welcome,
										<br />
										Please Login Here
									</h2> */}
								</RB.CardHeader>
								<RB.CardBody>
									<RB.Form
										onSubmit={handleSubmit}
										noValidate
										validated={validated}
										className="form-dark">
										<div className="mb-3 position-relative">
											<RB.FloatingLabel
												controlId="floatingNewPassword"
												label="New Password"
												className="floating-label-fancy">
												<RB.Form.Control
													type={showPassword ? "text" : "password"}
													placeholder="New Password"
													name="newPassword"
													onChange={handleTextChange}
													required
												/>
												<i
													className="material-icons-outlined position-absolute end-0 translate-middle-y icon-password-eye"
													style={{
														cursor: "pointer",
														top: "50%",
														transform: "translateY(-50%)",
													}}
													onClick={togglePasswordVisibility}>
													{showPassword ? "visibility_off" : "visibility"}
												</i>
												<RB.Form.Control.Feedback type="invalid">
													New password is required.
												</RB.Form.Control.Feedback>
											</RB.FloatingLabel>
										</div>
										<div className="mb-3 position-relative">
											<RB.FloatingLabel
												controlId="floatingConfirmPassword"
												label="Confirm Password"
												className="floating-label-fancy">
												<RB.Form.Control
													type={showConfirmPassword ? "text" : "password"}
													placeholder="Confirm Password"
													name="confirmPassword"
													onChange={handleTextChange}
													required
													isInvalid={!!error}
												/>
												<i
													className="material-icons-outlined position-absolute end-0 translate-middle-y icon-password-eye"
													style={{
														cursor: "pointer",
														top: "50%",
														transform: "translateY(-50%)",
													}}
													onClick={toggleConfirmPasswordVisibility}>
													{showConfirmPassword ? "visibility_off" : "visibility"}
												</i>
												<RB.Form.Control.Feedback type="invalid">
													{error || "Confirm password is required."}
												</RB.Form.Control.Feedback>
											</RB.FloatingLabel>
										</div>
										<div className="d-grid gap-2">
											<RB.Button type="submit" variant="primary">
												Reset Password
											</RB.Button>
										</div>
									</RB.Form>
								</RB.CardBody>
							</RB.Card>
						</RB.Col>
					</RB.Row>
				</RB.Container>
			</section>
			<section className="section">
				<RB.Container mt={5}>
					<RB.Row className="justify-content-md-center">
						<RB.Col xs={12} sm={8} md={6} lg={6} xl={4}>
							<div className="login-brand">
								<RB.Image
									src=""
									className="shadow-light rounded-circle"
									width={100}></RB.Image>
							</div>
							<RB.Card className="">
								<RB.CardHeader>
									<h4>Reset Password</h4>
								</RB.CardHeader>
								<RB.CardBody>
									<RB.Form onSubmit={handleSubmit} noValidate validated={validated}>
										<div className="mb-3 position-relative">
											<RB.FloatingLabel
												controlId="floatingNewPassword"
												label="New Password"
												className="floating-label-fancy">
												<RB.Form.Control
													type={showPassword ? "text" : "password"}
													placeholder="New Password"
													name="newPassword"
													onChange={handleTextChange}
													required
												/>
												<i
													className="material-icons-outlined position-absolute end-0 translate-middle-y icon-password-eye"
													style={{
														cursor: "pointer",
														top: "50%",
														transform: "translateY(-50%)",
													}}
													onClick={togglePasswordVisibility}>
													{showPassword ? "visibility_off" : "visibility"}
												</i>
												<RB.Form.Control.Feedback type="invalid">
													New password is required.
												</RB.Form.Control.Feedback>
											</RB.FloatingLabel>
										</div>
										<div className="mb-3 position-relative">
											<RB.FloatingLabel
												controlId="floatingConfirmPassword"
												label="Confirm Password"
												className="floating-label-fancy">
												<RB.Form.Control
													type={showConfirmPassword ? "text" : "password"}
													placeholder="Confirm Password"
													name="confirmPassword"
													onChange={handleTextChange}
													required
													isInvalid={!!error}
												/>
												<i
													className="material-icons-outlined position-absolute end-0 translate-middle-y icon-password-eye"
													style={{
														cursor: "pointer",
														top: "50%",
														transform: "translateY(-50%)",
													}}
													onClick={toggleConfirmPasswordVisibility}>
													{showConfirmPassword ? "visibility_off" : "visibility"}
												</i>
												<RB.Form.Control.Feedback type="invalid">
													{error || "Confirm password is required."}
												</RB.Form.Control.Feedback>
											</RB.FloatingLabel>
										</div>
										<div className="d-grid gap-2">
											<RB.Button type="submit" variant="primary">
												Reset Password
											</RB.Button>
										</div>
									</RB.Form>
								</RB.CardBody>
							</RB.Card>
							<div className="simple-footer">
								Copyright &copy; {process.env.REACT_APP_TITLE} {year}
							</div>
						</RB.Col>
					</RB.Row>
				</RB.Container>
			</section>
		</>
	);
}

export default ResetPassword;
