import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Layout from "../../Layout";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Cancel,
  Delete,
  Done,
  ExpandMore,
  MoreHoriz,
  MoreVert,
  PendingActions,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { CSVLink, CSVDownload } from "react-csv";
import { event } from "jquery";
import DatePicker from "react-datepicker";
import FilterComponent from "../FilterComponent";
import { ArrowDown, DocumentDownload, Eye, Timer1 } from "iconsax-react";
import ReasonModal from "../ReasonModal";
import * as XLSX from "xlsx";
import CustomPagination from "../../Utils/CustomPagination";

function Leaves() {
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [data, setData] = useState([]);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const handleClose = () => {
    setShow(false);
    setFormData({
      reason: "",
    });
  };
  const handleShow = () => setShow(true);
  const [currentStatus, setCurrentStatus] = useState("");
  const [crrentdata, setCrrentdata] = useState({});
  const [isSubmit, SetIsSbmit] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [active, setActive] = useState(false);
  const csvLink = createRef();
  const [formData, setFormData] = useState({
    reason: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModal(true);
    },
    []
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const columns = [
    {
      name: "Id",
      selector: (row) => row.emp_code,
    },
    {
      name: "Name",
      selector: (row) => row.employee_name,
    },
    {
      name: "Start Date",
      selector: (row) => moment(row.start_date).format("DD-MM-YYYY"),
    },
    {
      name: "End  Date",
      selector: (row) => moment(row.end_date).format("DD-MM-YYYY"),
    },
    {
      name: "Leave Day",
      selector: (row) => (row.leave_day == "full" ? "Full Day" : "Custome"),
    },
    {
      name: (
        <div>
          Leave Type <br />
        </div>
      ),
      selector: (row) => row.leave_name,
    },
    {
      name: "Reason",
      selector: (row) => row.reason,
      cell: (row) => {
        const wordCount = row?.reason?.split(" ").length;
        console.log(wordCount);
        return wordCount > 5 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("Reason", row.reason)}
          >
            <Eye />
          </RB.Button>
        ) : (
          <span>{row.reason}</span>
        );
      },
    },
    {
      name: "Status ",
      selector: (row) => getStatusName(row.status),
    },
    {
      name: "Action By ",
      selector: (row) => row.auth_name,
    },
    {
      name: "Team Log",
      center: true,
      selector: (row) =>
        row.logData && row.logData.length > 0 ? (
          <RB.OverlayTrigger
            trigger="click"
            rootClose
            key="bottom"
            placement="bottom"
            overlay={
              <RB.Popover id={`popover-positioned-bottom`}>
                <RB.Popover.Body className="p-0 overflow-auto popover-scroll ">
                  <div className="list-group ">
                    {row.logData.map((log, index) => (
                      <div className="list-group-item " key={index}>
                        {log.reporting_manager_name}
                      </div>
                    ))}
                  </div>
                </RB.Popover.Body>
              </RB.Popover>
            }
          >
            <Link className="btn btn-flat">
              <Timer1 />
            </Link>
          </RB.OverlayTrigger>
        ) : (
          <></>
        ),
    },

    // {
    //   name: "Action",
    //   center: true,
    //   cell: (row) => (
    //     <>
    //       {row.status !== "1" && row.status !== "3" && (
    //         <RB.OverlayTrigger
    //           trigger="click"
    //           rootClose
    //           key="bottom"
    //           placement="bottom"
    //           overlay={
    //             <RB.Popover id={`popover-positioned-bottom`}>
    //               <RB.Popover.Body className="p-0">
    //                 <div className="list-group">
    //                   {row.status === "0" && (
    //                     <>
    //                       <Link
    //                         className="list-group-item list-group-item-action"
    //                         onClick={(e) => {
    //                           e.preventDefault();
    //                           handleLeave(row, "2")();
    //                         }}
    //                       >
    //                         <Done /> Approve
    //                       </Link>
    //                       {/* <li className="mb-2">
    //                         <RB.Button
    //                           title="Approve"
    //                           variant="outline-success"
    //                           size="sm"
    //                         ></RB.Button>
    //                       </li> */}
    //                       <Link
    //                         className="list-group-item list-group-item-action"
    //                         onClick={(e) => {
    //                           e.preventDefault();
    //                           handleLeave(row, "1")();
    //                         }}
    //                       >
    //                         <Cancel /> Reject
    //                       </Link>
    //                     </>
    //                   )}

    //                   {(row.status === "0" || row.status === "2") && (
    //                     <Link
    //                       className="list-group-item list-group-item-action"
    //                       onClick={(e) => {
    //                         e.preventDefault();
    //                         handleLeave(row, "3")();
    //                       }}
    //                     >
    //                       <Cancel /> Cancel
    //                     </Link>
    //                   )}
    //                 </div>
    //               </RB.Popover.Body>
    //             </RB.Popover>
    //           }
    //         >
    //           <RB.Button
    //             className="btn-circle"
    //             variant="outline-secondary"
    //             size="sm"
    //           >
    //             <MoreHoriz />
    //           </RB.Button>
    //         </RB.OverlayTrigger>
    //       )}
    //     </>
    //   ),
    // },
  ];

  const getStatusName = (status) => {
    switch (status) {
      case "0":
        return "Pending";
      case "1":
        return "Reject";
      case "2":
        return "Approved";
      case "3":
        return "Cancel";
      default:
        return "Unknown";
    }
  };

  useEffect(() => {
    getAllLeaveList();
  }, [page, filterText, startDate]);

  const getAllLeaveList = () => {
    const formattedDate = moment(startDate).format("MMM-yyyy").toLowerCase();

    utils
      .callAPI(
        "get",
        `/leave/leave-request/leave-list-hr?month=${formattedDate}&search=${filterText}&page=${page}&per_page=${countPerPage}`
      )
      // .callAPI("get", `/leave/leave-request/employee-list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setData(res);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        setData([]);
      });
  };

  const handleLeave = useCallback((row, status) => async () => {
    if (status === "1" || status === "3") {
      setCurrentStatus(status);
      setCrrentdata(row);
      handleShow();
      return;
    } else {
      SetIsSbmit(true);
      setCurrentStatus(status);
      setCrrentdata(row);
      // leave();
    }
  });

  const handleSbmit = () => {
    SetIsSbmit(true);
    setShow(false);
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      bodyFormData.append("leave_id", crrentdata.id);
      bodyFormData.append("status", currentStatus);
      bodyFormData.append("auth_reason", formData.reason);

      utils
        .callAPI("post", `/leave/leave-request/approval`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            getAllLeaveList();
            setCurrentStatus("");
            setCrrentdata({});
            SetIsSbmit(false);
            setFormData({
              reason: "",
            });
          }
        })
        .catch((err) => {});
    }
  }, [isSubmit]);

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [csvData]);

  function handleDownload() {
    setCsvData([]);
    const formattedDate = moment(startDate).format("MMM-yyyy").toLowerCase();
    utils
      .callAPI("get", `/leave/report?month_year=${formattedDate}`)
      .then((res) => {
        if (typeof res !== "undefined") {
          // console.log(res);
          // setCsvData(res);
          // setActive(true);
          generateCSV(res);
        } else {
          setCsvData([]);
          // done(false);
        }
      })
      .catch((err) => {
        setCsvData([]);
        // done(false);
      });
  }

  function generateCSV(filteredData) {
    const ws = XLSX.utils.json_to_sheet(filteredData, {
      header: Object.keys(filteredData[0]),
      skipHeader: true,
    });

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Employees");

    const csvOutput = XLSX.utils.sheet_to_csv(ws);

    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Leaves_List.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  
  return (
    <>
      <Layout>
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>Leaves</h1>
            </div>
            <div className="section-body">
              <RB.Card className="card shadow ">
                <div className="floating-btns-holder-artisan">
                  <a
                    href
                    className={`${data?.result?.length == 0 ? 'disabled':''} btn btn-primary btn-circle me-2`}
                    variant="primary"
                    onClick={() => handleDownload()}
                  >
                    <DocumentDownload />
                  </a>
                </div>
                <RB.Card.Header className="border-0">
                  <h4>Employee Leave List</h4>
                  <div className="card-header-action"></div>
                  <div className="me-2 my-md-0 my-2">
                    <DatePicker
                      dateFormat="MMMM-yyyy"
                      showMonthYearPicker
                      selected={startDate}
                      className="form-control range-datepicker-input  "
                      maxDate={new Date()}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                </RB.Card.Header>
                <RB.Card.Body>
                  {utils.hasPermission(
                    primaryMenuRes,
                    "Leaves",
                    "leaves.employeeLeaves"
                  ) && (
                    <DataTable
                      columns={columns}
                      data={data.result}
                      highlightOnHover
                      responsive
                      pagination
                      striped
                      paginationServer
                      paginationPerPage={countPerPage}
                      paginationTotalRows={data.total}
                      paginationComponentOptions={{
                        noRowsPerPage: true,
                      }}
                      paginationComponent={() => (
                        <CustomPagination
                          currentPage={page}
                          totalRows={data.total}
                          rowsPerPage={countPerPage}
                          onPageChange={handlePageChange}
                        />
                      )}
                      onChangePage={(page) => setPage(page)}
                      persistTableHead
                      customStyles={{
                        head: {
                          style: {
                            fontSize: "0.875rem", // Adjust the font size as needed
                            fontWeight: "bold", // Adjust the font weight as needed
                          },
                        },
                      }}
                      subHeader
                      subHeaderComponent={subHeaderComponent}
                    />
                  )}

                  {/* )} */}
                </RB.Card.Body>
              </RB.Card>
            </div>
          </section>
        </div>
      </Layout>
      <RB.Modal show={show} onHide={handleClose} backdrop="static">
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Reason</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          <RB.Row>
            <RB.Col md={12}>
              <div className="">
                <RB.FloatingLabel
                  label="Reason"
                  className="floating-label-fancy"
                >
                  <RB.Form.Control
                    as="textarea"
                    placeholder="Enter Reason"
                    name="reason"
                    value={formData.reason}
                    onChange={handleInputChange}
                    required
                    maxLength={500}
                    // className="textarea-lg"
                  />
                </RB.FloatingLabel>
              </div>
            </RB.Col>
          </RB.Row>
        </RB.Modal.Body>
        <RB.Modal.Footer>
          <RB.Button variant="secondary" onClick={handleClose}>
            Close
          </RB.Button>
          <RB.Button variant="primary" onClick={handleSbmit}>
            Save
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>
      <ReasonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        text={modalText}
      />
    </>
  );
}

export default Leaves;
