import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../Utils/comman";

export const fetchCities = createAsyncThunk(
  "location/fetchCities",
  async (districtId, thunkAPI) => {
    try {
      const response = await utils.callAPI("get", `/city/by-district-list?districtId=${districtId ? districtId:''}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  cities: [],
  isLoading: false,
  isError: false,
};

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCities.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        console.log('all city data',action.payload)
        state.cities = Array.isArray(action.payload) ? action.payload : [];
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchCities.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default citySlice.reducer;
