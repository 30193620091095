import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";

function SalaryGenerate(props) {
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const [empStatistics, setEmpStatistics] = useState({
    total_employee: 0,
    payroll_processed: 0,
  });
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    return `${months[currentMonth]}-${currentYear}`;
  });
  const [isSubmit, setIsSubmit] = useState(false);

  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isSalaryGenerated, setIsSalaryGenerated] = useState(false);
  const [isPrevious, setIsPrevios] = useState(false);
  const [isGenerateSalary, setIsgenerateSalary] = useState(false);
  const [monthStatus, setMonthStats] = useState([]);
  const [checkboxState, setCheckboxState] = useState([]);

  const today = new Date();
  const currentMonthName = months[today.getMonth()];
  const currentYearValue = today.getFullYear();
  const currentMonthFormatted = `${currentMonthName}-${currentYearValue}`;
  const [finalEmpSalary, setFinalEmpSalary] = useState([]);
  const startOfMonthFormatted = new Date(
    currentYearValue,
    today.getMonth(),
    1
  ).toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
  });
  const endOfMonthFormatted = new Date(
    currentYearValue,
    today.getMonth() + 1,
    0
  ).toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
  });
  const daysInMonth = new Date(
    currentYearValue,
    today.getMonth() + 1,
    0
  ).getDate();
  const [salaryGeneratedEmployee, setsalaryGeneratedEmployee] = useState({});
  const generateMonthCards = () => {
    const cards = [];
    const monthsToShow = 6;

    let startMonthIndex = currentMonth;
    let startYear = currentYear;

    if (startMonthIndex < monthsToShow - 1) {
      startMonthIndex = 12 - (monthsToShow - 1 - startMonthIndex);
      startYear--;
    } else {
      startMonthIndex = startMonthIndex - (monthsToShow - 1);
    }

    for (let i = 0; i < monthsToShow; i++) {
      let monthIndex = (startMonthIndex + i) % 12;
      let year = startYear;
      if (startMonthIndex + i >= 12) {
        year++;
      }

      const monthName = months[monthIndex];
      const formattedMonth = `${monthName}-${year}`;

      const startDate = new Date(year, monthIndex, 1).toLocaleDateString(
        "en-US",
        {
          month: "short",
          day: "2-digit",
        }
      );
      const endDate = new Date(year, monthIndex + 1, 0).toLocaleDateString(
        "en-US",
        {
          month: "short",
          day: "2-digit",
        }
      );

      const monthStatusInfo = monthStatus?.find(
        (status) => status.month === formattedMonth
      );
      cards.push(
        <RB.Col
          key={formattedMonth}
          className="col-12 col-sm-6 col-md-4 col-lg-2 p-1"
          onClick={() =>
            handleMonthYear(formattedMonth, monthIndex, monthStatusInfo)
          }
        >
          <div
            className={`card mb-0 ${
              formattedMonth === selectedMonth ? "current-month" : "bg-white"
            }  monthYear-card`}
          >
            <div className="card-body text-center">
              <div
                className={`mb-2 ${
                  i === monthsToShow - 1 && monthIndex === currentMonth
                    ? "font-weight-bold"
                    : ""
                }`}
              >
                {formattedMonth}
              </div>
              <span>
                {startDate}-{endDate}
              </span>
            </div>

            {/* <div
              className={`card-footer rounded-bottom p-2 bg-transparent border-top ${
                formattedMonth === selectedMonth ? "salary-current-month" : ""
              }`}
            >
              <>
                <div
                  className={`d-flex justify-content-center align-items-center ${
                    monthStatusInfo &&
                    monthStatusInfo.salary_status === "pending"
                      ? "text-warning"
                      : "text-success"
                  }`}
                >
                  <i className="material-icons-outlined p-1">
                    {monthStatusInfo
                      ? monthStatusInfo.salary_status === "pending"
                        ? "error_outline"
                        : "check_circle"
                      : ""}
                  </i>
                  <span className="p-1">
                    {monthStatusInfo
                      ? monthStatusInfo.salary_status === "pending"
                        ? "Pending"
                        : "Completed"
                      : ""}
                  </span>
                </div>
              </>
            </div> */}
          </div>
        </RB.Col>
      );
    }

    return cards;
  };

  const handleMonthYear = useCallback(
    (formattedMonth, monthIndex, monthStatusInfo) => {
      setsalaryGeneratedEmployee({});
      setCheckboxState([]);
      setSelectAllChecked(false);

      setSelectedMonth(formattedMonth);
      if (monthStatusInfo.salary_status == "completed") {
        setIsgenerateSalary(true);
      } else {
        setIsgenerateSalary(false);
      }
      if (monthStatusInfo) {
        setEmpStatistics({
          total_employee: monthStatusInfo.total_employee,
          payroll_processed: monthStatusInfo.payroll_processed,
        });
      }
    },
    []
  );

  const handlePrevMonth = () => {
    // {console.log(new Date().getFullYear() - 1  , currentYear ,
    //   currentMonth ,new Date().getMonth())}
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
    const firstMonthIndex = (currentMonth - 6 + 12) % 12;
    const firstMonthYear = currentYear - (currentMonth < 6 ? 1 : 0);

    const prevMonth = (currentMonth - 6 + 12) % 12;
    const monthYear = currentYear - (currentMonth < 6 ? 1 : 0);
    const prevMonthYear = `${months[prevMonth]}-${monthYear}`;
    getMonthsStatus(prevMonthYear);

    if (
      new Date().getFullYear() - 1 === firstMonthYear &&
      firstMonthIndex === new Date().getMonth() + 1
    ) {
      setIsPrevios(true);
      return;
    } else {
      setIsPrevios(false);
    }
  };

  const handleNextMonth = () => {
    setIsPrevios(false);
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const generateSalary = () => {
    // setIsSubmit(true);

    setIsSalaryGenerated(true);
    console.log(checkboxState);
  };

  useEffect(() => {
    if (isSalaryGenerated) {
      utils
        .callAPI("get", `/salary/payslip/pay-list?month_year=${selectedMonth}`)
        .then((res) => {
          if (typeof res !== "undefined") {
            // console.log(res);
            setsalaryGeneratedEmployee(res);
            const newInputValues = res.reduce((acc, employee) => {
              const allowances = JSON.parse(employee.allowance);
              const deductions = JSON.parse(employee.deduction);

              const actualVariable =
                allowances.find(
                  (allowance) => allowance.name === "Actual Variable Amount"
                )?.amt || "";
              const schemeIncentive =
                allowances.find(
                  (allowance) => allowance.name === "Scheme Incentive"
                )?.amt || "";
              const esic =
                deductions.find((deduction) => deduction.name === "ESIC")
                  ?.damt || "";
              const advanceLoan =
                deductions.find(
                  (deduction) => deduction.name === "Advance Loan"
                )?.damt || "";

              acc[employee.employeeId] = {
                actual_variable: actualVariable,
                scheme_incentive: schemeIncentive,
                esic: esic,
                advance_loan: advanceLoan,
              };

              return acc;
            }, {});

            setInputValues(newInputValues);
            setIsSalaryGenerated(false);
          } else {
            setIsSalaryGenerated(false);
          }
        })
        .catch((err) => {
          setIsSalaryGenerated(false);
        });
    }
  }, [isSalaryGenerated]);
  useEffect(() => {
    getMonthsStatus();
  }, []);
  const getMonthsStatus = (monthYear) => {
    let apiUrl = "/salary/payslip/statistics";

    if (monthYear) {
      apiUrl += `?month_year=${monthYear}`;
    }

    utils
      .callAPI("get", apiUrl)
      .then((res) => {
        if (typeof res !== "undefined") {
          if (monthYear) {
            setMonthStats((prevMonthStatus) => {
              const updatedMonthStatus = [...prevMonthStatus, ...res];
              return updatedMonthStatus;
            });
            return;
          }
          setMonthStats(res);

          const currentMonthEmpData = res.find(
            (item) => item.month == `${months[currentMonth]}-${currentYear}`
          );
          // console.log(currentMonthEmpData);
          if (currentMonthEmpData) {
            setEmpStatistics({
              payroll_processed: currentMonthEmpData.payroll_processed,
              total_employee: currentMonthEmpData.total_employee,
            });
            if (currentMonthEmpData.salary_status == "completed") {
              setIsgenerateSalary(true);
            } else {
              setIsgenerateSalary(false);
            }
          }
        } else {
          setMonthStats([]);
        }
      })
      .catch((err) => {
        setMonthStats([]);
      });
  };

  // useEffect(() => {
  //   if (prevClick) {
  //     const firstMonthIndex = (currentMonth - 5 + 12) % 12;
  //     const firstMonthYear = currentYear - (currentMonth < 5 ? 1 : 0);
  //     setPrevClick(false);
  //   }
  // }, [prevClick]);

  // const handleCheckboxChange = (e) => {
  //   const { name, checked } = e.target;
  //   setCheckboxState((prevState) => ({
  //     ...prevState,
  //     [name]: checked,
  //   }));
  // };

  const handlePresentDaysChange = (index, event) => {
    const enteredValue = event.target.value.trim();
    const updatedSalaryData = [...salaryGeneratedEmployee];

    if (
      enteredValue === "" ||
      (!isNaN(enteredValue) &&
        enteredValue >= 0 &&
        enteredValue <= updatedSalaryData[index].month_days)
    ) {
      updatedSalaryData[index].present_days =
        enteredValue === "" ? enteredValue : parseInt(enteredValue, 10);
      setsalaryGeneratedEmployee(updatedSalaryData);
    }
  };

  const handleInputChange = (e, employeeId) => {
    const { name, value } = e.target;

    setInputValues((prevValues) => ({
      ...prevValues,
      [employeeId]: {
        ...prevValues[employeeId],
        [name]: value,
      },
    }));
  };

  const handleCheckboxChange = (event, employeeId) => {
    const { checked } = event.target;
    setCheckboxState((prevState) => ({
      ...prevState,
      [employeeId]: checked,
    }));

    setSelectedEmployeeIds((prevIds) => {
      if (checked) {
        return [...prevIds, employeeId];
      } else {
        return prevIds.filter((id) => id !== employeeId);
      }
    });
    if (!checked) {
      setSelectAllChecked(false);
    }
  };

  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    setSelectAllChecked(checked);

    const updatedCheckboxState = salaryGeneratedEmployee.reduce(
      (acc, employee) => {
        acc[employee.employeeId] = checked;
        return acc;
      },
      {}
    );

    setCheckboxState(updatedCheckboxState);

    if (checked) {
      const allEmployeeIds = salaryGeneratedEmployee.map(
        (employee) => employee.employeeId
      );
      setSelectedEmployeeIds(allEmployeeIds);
    } else {
      setSelectedEmployeeIds([]);
    }
  };

  const handleLeaveCountChange = (e, employeeId, leaveTypeId) => {
    const value = e.target.value;
    setsalaryGeneratedEmployee((prevEmployees) =>
      prevEmployees.map((employee) =>
        employee.employeeId === employeeId
          ? {
              ...employee,
              leave_count: JSON.stringify(
                JSON.parse(employee.leave_count).map((leave) =>
                  leave.leave_type_id === leaveTypeId
                    ? { ...leave, days: value }
                    : leave
                )
              ),
            }
          : employee
      )
    );
  };

  const handleSave = () => {
    const selectedEmployees = salaryGeneratedEmployee.filter((employee) =>
      selectedEmployeeIds.includes(employee.employeeId)
    );

    const updatedEmployees = selectedEmployees.map((employee) => {
      const employeeId = employee.employeeId;
      const employeeValues = inputValues[employeeId] || {};

      let updatedAllowance = JSON.parse(employee.allowance);
      let updatedDeduction = JSON.parse(employee.deduction);

      updatedAllowance = updatedAllowance.map((allowance) => {
        if (
          allowance.name === "Actual Variable Amount" &&
          employeeValues.actual_variable
        ) {
          allowance.amt = employeeValues.actual_variable;
          allowance.total = parseFloat(employeeValues.actual_variable);
        }
        if (
          allowance.name === "Scheme Incentive" &&
          employeeValues.scheme_incentive
        ) {
          allowance.amt = employeeValues.scheme_incentive;
          allowance.total = parseFloat(employeeValues.scheme_incentive);
        }
        return allowance;
      });

      updatedDeduction = updatedDeduction.map((deduction) => {
        if (deduction.name === "ESIC" && employeeValues.esic) {
          deduction.damt = employeeValues.esic;
          deduction.total = parseFloat(employeeValues.esic);
        }
        if (deduction.name === "Advance Loan" && employeeValues.advance_loan) {
          deduction.damt = employeeValues.advance_loan;
          deduction.total = parseFloat(employeeValues.advance_loan);
        }
        return deduction;
      });

      return {
        ...employee,
        allowance: JSON.stringify(updatedAllowance),
        deduction: JSON.stringify(updatedDeduction),
      };
    });

    // setFinalEmpSalary(updatedEmployees)
    // setIsSubmit(true)

    utils
      .callAPI("post", `/salary/payslip/create`, updatedEmployees)
      .then((res) => {
        if (typeof res !== "undefined") {
          setEmpStatistics({
            total_employee: res.total_employee,
            payroll_processed: res.payroll_processed,
          });
          getMonthsStatus();
          // setsalaryGeneratedEmployee([])
          setsalaryGeneratedEmployee({});
          setCheckboxState([]);
          setSelectAllChecked(false);
          setIsSubmit(false);
        } else {
          setIsSubmit(false);
        }
      })
      .catch((err) => {
        setIsSubmit(false);
      });
  };

  return (
    <>
      <RB.Card className="">
        <RB.Card.Body>
          <RB.Card className="shadow card border-0 justify-content-center mb-2">
            <RB.Card.Body className="p-0 text-center">
              <RB.Row className="align-items-center">
                <div className="col-auto">
                  <button
                    className={`btn btn-sm btn-circle btn-secondary text-center d-flex justify-content-center align-items-center ${
                      isPrevious ? "disabled" : ""
                    }`}
                    onClick={handlePrevMonth}
                    disabled={isPrevious}
                  >
                    <i className="material-icons-outlined">navigate_before</i>
                  </button>
                </div>
                <RB.Col className="col">
                  <div className="row justify-content-center">
                    {generateMonthCards()}
                  </div>
                </RB.Col>
                <div className="col-auto">
                  <button
                    className={`btn btn-sm btn-circle btn-secondary text-center d-flex justify-content-center align-items-center ${
                      currentYear === new Date().getFullYear() &&
                      currentMonth === new Date().getMonth()
                        ? "disabled"
                        : ""
                    }`}
                    onClick={handleNextMonth}
                    disabled={
                      currentYear === new Date().getFullYear() &&
                      currentMonth === new Date().getMonth()
                    }
                  >
                    <i className="material-icons-outlined">navigate_next</i>
                  </button>
                </div>
              </RB.Row>
            </RB.Card.Body>
          </RB.Card>
          <div className="d-flex align-items-start text-center mb-0">
            <h5>{currentMonthFormatted}</h5>
            <p className="ms-2 mb-0">
              ({startOfMonthFormatted} - {endOfMonthFormatted}, {daysInMonth}{" "}
              days)
            </p>
          </div>
          <hr />

          <RB.Row className="mb-3 g-3">
            <RB.Col md={4}>
              <div className="d-flex border rounded-2">
                <div className="flex-shrink-0 me-3 bg-light d-flex p-3 justify-content-center align-items-center">
                  <i class="material-icons-outlined">groups</i>
                </div>
                <div className="flex-grow-1 p-2">
                  <p className="mb-0">Total Employee</p>
                  <h4 className="fw-bold">{empStatistics.total_employee}</h4>
                </div>
              </div>
            </RB.Col>
            <RB.Col md={4}>
              <div className="d-flex border rounded-2">
                <div className="flex-shrink-0 me-3 bg-light d-flex p-3 justify-content-center align-items-center">
                  <i class="material-icons-outlined">calendar_month</i>
                </div>
                <div className="flex-grow-1 p-2">
                  <p className="mb-0">Calendar days</p>
                  <h4 className="fw-bold">{daysInMonth}</h4>
                </div>
              </div>
            </RB.Col>
            <RB.Col md={4}>
              <div className="d-flex border rounded-2">
                <div className="flex-shrink-0 me-3 bg-light d-flex p-3 justify-content-center align-items-center">
                  <i class="material-icons-outlined">payments</i>
                </div>
                <div className="flex-grow-1 p-2">
                  <p className="mb-0">Payroll Processed</p>
                  <h4 className="fw-bold">
                    {`${empStatistics.payroll_processed} / ${empStatistics.total_employee}`}{" "}
                    employee
                  </h4>
                </div>
              </div>
            </RB.Col>

            {/* <RB.Col md={4} className="p-3">
                          {" "}

                        </RB.Col>
                        <RB.Col md={4} className="p-3">
                          {" "}
                          <div>Calendar days</div>
                          <div className="fw-bold">{daysInMonth}</div>
                        </RB.Col>
                        <RB.Col md={4} className="p-3">
                          {" "}
                          <div>Payroll Processed</div>
                          <div className="fw-bold">
                            {`${empStatistics.payroll_processed} / ${empStatistics.total_employee}`}{" "}
                            employee
                          </div>
                        </RB.Col> */}
          </RB.Row>

          {/* <RB.Row className="mb-3">
            {[
              "actualVariableAmount",
              "schemeIncentive",
              "esic",
              "tds",
              "advanceLoan",
            ].map((checkboxKey, index) => (
              <RB.Col key={index} xs={6} md={3} lg={2} className="mb-2">
                <RB.Form.Check
                  type="checkbox"
                  id={`checkbox-${index}`}
                  label={
                    checkboxKey === "actualVariableAmount"
                      ? "Actual Variable Amount"
                      : checkboxKey === "schemeIncentive"
                      ? "Scheme Incentive"
                      : checkboxKey === "esic"
                      ? "ESIC"
                      : checkboxKey === "tds"
                      ? "TDS"
                      : checkboxKey === "advanceLoan"
                      ? "Advance Loan"
                      : ""
                  }
                  checked={checkboxState[checkboxKey]}
                  onChange={handleCheckboxChange}
                  name={checkboxKey} // Added name prop
                />
              </RB.Col>
            ))}
          </RB.Row> */}

          {utils.hasPermission(
            primaryMenuRes,
            "Generate Salary",
            "salary.generate"
          ) && (
            <RB.Button
              className="btn btn-primary mt-0"
              onClick={generateSalary}
              // disabled={isGenerateSalary}
            >
              Generate Salary
            </RB.Button>
          )}
          {salaryGeneratedEmployee.length > 0 ? (
            <RB.Row>
              <RB.Col className="col-12">
                <div className="card border mt-2">
                  <div className="card-body">
                    <h6>{selectedMonth} Salary</h6>

                    <RB.Table
                      striped
                      bordered
                      hover
                      responsive
                      className="custom-table"
                    >
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAllChecked}
                              onChange={handleSelectAllChange}
                            />
                          </th>
                          {/* <th>Month-Year</th> */}
                          <th>Employee ID</th>
                          <th>Employee Name</th>
                          <th>CTC</th>
                          <th>Month Days</th>
                          <th>Leave Count</th>
                          <th>Present Days</th>
                          <th>Actual Variable Amount</th>
                          <th className="w-75">Scheme Incentive</th>
                          <th className="table-inputs">ESIC</th>
                          <th className="table-inputs">Advance Loan</th>
                        </tr>
                      </thead>
                      <tbody>
                        {salaryGeneratedEmployee.map((employee, index) => (
                          <tr key={employee.employeeId}>
                            <td>
                              <input
                                type="checkbox"
                                name={`checkbox-${employee.employeeId}`}
                                checked={
                                  checkboxState[employee.employeeId] || false
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(e, employee.employeeId)
                                }
                              />
                            </td>

                            <td>{employee.employee_code}</td>
                            <td>{employee.employee_name}</td>
                            <td>{employee.ctc}</td>
                            <td>{employee.month_days}</td>
                            <td>
                              {JSON.parse(employee?.leave_count)?.length !==
                              0 ? (
                                <div className="leave-count-container">
                                  {JSON.parse(employee?.leave_count)?.map(
                                    (leave) => (
                                      <div
                                        key={leave.leave_type_id}
                                        className="leave-input-wrapper"
                                      >
                                        <span className="leave-label">
                                          {leave.name}:
                                        </span>
                                        <input
                                          type="text"
                                          value={leave.days}
                                          onChange={(e) =>
                                            handleLeaveCountChange(
                                              e,
                                              employee.employeeId,
                                              leave.leave_type_id
                                            )
                                          }
                                          className="leave-input"
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              ) : (
                                <span>-</span>
                              )}
                            </td>

                            <td>
                              <input
                                type="text"
                                value={employee.present_days}
                                onChange={(e) =>
                                  handlePresentDaysChange(index, e)
                                }
                                className="w-75"
                              />
                            </td>
                            {/* <td>
                              <input
                                type="text"
                                // value={employee.present_days}
                                // onChange={(e) =>
                                //   handlePresentDaysChange(index, e)
                                // }
                                className="w-75"
                              />
                            </td> */}
                            <td>
                              <input
                                type="text"
                                name="actual_variable"
                                value={
                                  inputValues[employee.employeeId]
                                    ?.actual_variable || ""
                                }
                                onChange={(e) =>
                                  handleInputChange(e, employee.employeeId)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="scheme_incentive"
                                value={
                                  inputValues[employee.employeeId]
                                    ?.scheme_incentive || ""
                                }
                                onChange={(e) =>
                                  handleInputChange(e, employee.employeeId)
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                name="esic"
                                value={
                                  inputValues[employee.employeeId]?.esic || ""
                                }
                                onChange={(e) =>
                                  handleInputChange(e, employee.employeeId)
                                }
                              />
                            </td>

                            <td>
                              <input
                                type="text"
                                name="advance_loan"
                                value={
                                  inputValues[employee.employeeId]
                                    ?.advance_loan || ""
                                }
                                onChange={(e) =>
                                  handleInputChange(e, employee.employeeId)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </RB.Table>

                    <div class="d-flex justify-content-between mt-3">
                      <div></div>
                      <RB.Button
                        className="btn btn-primary"
                        onClick={handleSave}
                        disabled={
                          !Object.keys(checkboxState).filter(
                            (key) => checkboxState[key]
                          ).length > 0
                        }
                      >
                        Save
                      </RB.Button>
                    </div>
                  </div>
                </div>
              </RB.Col>
            </RB.Row>
          ) : (
            ""
          )}
        </RB.Card.Body>
      </RB.Card>
    </>
  );
}

export default SalaryGenerate;
