import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";
import Swal from "sweetalert2";
import { EditIcon } from "@mui/icons-material/Edit";
import CustomPagination from "../../Utils/CustomPagination";
import {
  BorderStyle,
  Delete,
  Download,
  Replay,
  StarBorderPurple500,
  Visibility,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import excelSvg from "../../assets/images/excel.svg";
import { debounce } from "lodash";
import { AddCircle, ArrowDown, DocumentDownload, Eye, Refresh2, Trash } from "iconsax-react";
import * as XLSX from "xlsx";

function List(props) {
  const [loading, setLoading] = useState(true);
  const [couponCode, setCouponCode] = useState([]);
  const [available, setAvailable] = useState(false);
  const [all, setAll] = useState(false);
  const [activeTab, setActiveTab] = useState("all");
  const [removed, setRemoved] = useState(false);
  const [scanned, setScanned] = useState(false);
  const [scannedCoupons, setScannedCoupons] = useState([]);
  const [removedCoupons, setRemovedCoupons] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [page, setPage] = useState(1);
  const [filterDate, setFilterDate] = useState("");
  const [selectable, setSelectable] = useState(false);
  const [filterCoupon, setFilterCoupon] = useState("");
  const [filterProduct, setFilterProduct] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startError, setStartError] = useState(false);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [active, setActive] = useState(false);
  const csvLink = createRef();
  const [selectedRows, setSelectedRows] = React.useState(false);

  const [dateFilter, setDateFilter] = useState(false);

  const debouncedGetCouponList = useCallback(
    debounce(() => {
      getCouponList();
    }, 500), // Adjust the delay time as needed (e.g., 500 milliseconds)
    [] // Empty dependency array to ensure the function is created only once
  );

  const handleChange = ({ selectedRows }) => {
    if (activeTab === "all") {
      setSelectedRows(selectedRows.map((row) => row.coupon_batch));
    } else {
      setSelectedRows(selectedRows.map((row) => row.id));
    }
  };
  const handleAddClick = (e) => {
    console.log("clicked add button");
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  // const handleStatusChange = (row) => (e) => {
  //   const isChecked = e.target.checked;
  //   const statusValue = isChecked ? 1 : 0;

  //   // Check if the coupon has already been activated

  //   utils
  //     .callAPI(
  //       "get",
  //       `/Coupon/nonscannable-status?id=${row.id}&coupon_status=${statusValue}`
  //     )
  //     .then((res) => {
  //       if (typeof res !== "undefined") {
  //         getCouponList();
  //       }
  //     })
  //     .catch((err) => {
  //       // Handle error
  //     });
  // };

  const handleStatusChange = (row) => (e) => {
    const isChecked = e.target.checked;
    const statusValue = isChecked ? 1 : 0;

    // Check if the coupon has already been activated
    Swal.fire({
      title: "Are you sure?",
      text: `You want to set the status to Active`,
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI(
            "get",
            `/Coupon/nonscannable-status?id=${row.id}&coupon_status=${statusValue}`
          )
          .then((res) => {
            if (typeof res !== "undefined") {
              getCouponList();
            }
          })
          .catch((err) => {
            // Handle error
          });
      } else if (result.isDenied) {
        // If user denies the change, revert the checkbox
        e.target.checked = !isChecked;
      }
    });
  };

  const columns = [
    // {scannedCode?  {
    //   name: "Sr.No",
    //   selector: (row, index) => (page - 1) * countPerPage + index + 1,
    //   sortable: false,
    // },:""}
    {
      name: "Sr.No",
      selector: (row, index) => (page - 1) * countPerPage + index + 1,
      sortable: false,
      maxWidth: "80px", // Adjust the maximum width as needed
      minWidth: "60px",
    },
    {
      name: activeTab === "scanned" ? "Scanned Date" : "Date Created",
      selector: (row) =>
        activeTab === "scanned"
          ? moment(row.scanned_date).format("DD-MM-yyyy HH:mm:ss")
          : moment(row.start_date).format("DD-MM-yyyy"),
      maxWidth: "200px", // Adjust the maximum width as needed
      minWidth: "160px",
      style: {
        //  backgroundColor: "#f7f7f7",

        width: "200px",
        minWidth: "160px",
      },
    },
    {
      name: "Expiry Date",
      selector: (row) => moment(row.end_date).format("DD-MM-yyyy"),
      style: {
        StarBorderPurple500,
      },
    },

    {
      name: "Created By",
      selector: (row) => row.created_by_name,
      maxWidth: "200px", // Adjust the maximum width as needed
      minWidth: "150px",
      style: {
        width: "200px",
        minWidth: "150px",
        //    backgroundColor: "#f7f7f7",
      },
    },

    {
      name: "Created For",
      selector: (row) => "Artisan",
      maxWidth: "200px", // Adjust the maximum width as needed
      minWidth: "150px",
      style: {
        width: "200px",
        minWidth: "150px",

        // backgroundColor: "#f7f7f7",
      },
    },

    {
      name: (
        <div>
          Coupon
          <br /> Generated Qty
        </div>
      ),
      selector: (row) => row.coupon_qty,
      maxWidth: "200px", // Adjust the maximum width as needed
      minWidth: "150px",
      style: {
        width: "200px",
        minWidth: "150px",
        //   backgroundColor: "#f7f7f7",
      },
      ...(activeTab !== "all" && { omit: true }),
    },

    {
      name: "Coupon code",
      selector: (row) => row.coupon_code,
      maxWidth: "200px", // Adjust the maximum width as needed
      minWidth: "180px",
      style: {
        //   backgroundColor: "#f7f7f7",

        width: "200px",
        minWidth: "180px",
      },
    },
    // {
    //   name: "Coupon Name",
    //   selector: (row) => row.coupon_name,
    // },

    {
      name: "Product Name",
      selector: (row) => row.product_name,
      maxWidth: "200px", // Adjust the maximum width as needed
      minWidth: "150px",
      style: {
        width: "200px",
        minWidth: "150px",
        //   backgroundColor: "#f7f7f7",
      },
    },

    {
      name: "Coupon value",
      selector: (row) => row.price,

      style: {
        //  backgroundColor: "#f7f7f7",
      },
      ...(activeTab !== "all" && { omit: true }),
    },

    {
      name: "Total Coupon Value",
      selector: (row) => row.price_sum,
      maxWidth: "200px", // Adjust the maximum width as needed
      minWidth: "150px",
      style: {
        width: "200px",
        minWidth: "150px",
        // backgroundColor: "#f7f7f7",
      },
      ...(activeTab !== "all" && { omit: true }),
    },

    // {
    //   name: "Remark",
    //   selector: (row) => row.remark,
    //   maxWidth: "200px", // Adjust the maximum width as needed
    //   minWidth: "150px",
    //   style: {
    //     width: "200px",
    //     minWidth: "150px",
    //     //  backgroundColor: "#f7f7f7",
    //   },
    //   ...(activeTab !== "all" &&
    //     activeTab !== "Non Scannable" && { omit: true }), // Include the column for "All" and "Non Scannable" tabs
    // },
    {
      name: "Scanned By",
      cell: (row) => (
        <div>
          {row.client_name} {" / "} {row.mobileNo && <br /> && <>&nbsp;</>}
          {row.mobileNo}
        </div>
      ),
      // selector: (row) => {
      //   return row.client_name + (row.mobileNo ? " / " + row.mobileNo : "");
      // },
      maxWidth: "220px",
      minWidth: "220px",
      style: {
        //  backgroundColor: "#f7f7f7",
        width: "220px",
        minWidth: "220px",
      },
    },

    {
      name: "Coupon Status",

      selector: (row) =>
        activeTab === "available"
          ? "Available"
          : activeTab === "scanned"
          ? "Scanned"
          : activeTab === "removed"
          ? "Removed"
          : row.coupon_type,

      style: {
        //  backgroundColor: "#f7f7f7",
      },
      ...(activeTab === "Non Scannable" && { omit: true }),
    },

    {
      name: "Coupon Type",

      selector: (row) => row.couponType,

      style: {
        //  backgroundColor: "#f7f7f7",
      },
    },

    {
      name: "Status",
      cell: (row) => (
        <>
          <RB.Form.Check // prettier-ignore
            type="switch"
            id={`custom-switch-${row.id}`}
            label={row.coupon_status === "1" ? "Active" : "Inactive"}
            disabled={row.coupon_status === "1"}
            onChange={handleStatusChange(row)}
            checked={row.coupon_status === "1"}
          />
        </>
      ),
      ...(activeTab !== "Non Scannable" && { omit: true }),
      center: true,
    },

    {
      name: "View",
      cell: (row) => (
        <>
          <RB.Button className="btn-flat" onClick={handleView(row)}>
            <Eye />
          </RB.Button>
        </>
      ),
      center: true,

      style: {
        //  backgroundColor: "#f7f7f7",
      },
    },

    {
      name: "Active",
      cell: (row) => (
        <>
          <RB.Button
           className="btn-flat"
            onClick={handleActive(row)}
          >
            <Refresh2 />
          </RB.Button>
        </>
      ),
      ...(activeTab !== "scanned" && { omit: true }),
      center: true,

      style: {
        //   backgroundColor: "#f7f7f7",
      },
    },

    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          <RB.Button
           className="btn-flat"
            onClick={() => handleDownloadBatch(row)}
          >
            <DocumentDownload />
          </RB.Button>{" "}
          &nbsp;
          <RB.Button
           className="btn-flat"
            onClick={showDelete(row)}
          >
            <Trash />
          </RB.Button>
        </>
      ),
    },
  ];

  if (activeTab !== "scanned") {
    const scannedByColumnIndex = columns.findIndex(
      (column) => column.name === "Scanned By"
    );
    if (scannedByColumnIndex !== -1) {
      columns.splice(scannedByColumnIndex, 1);
    }

    const viewColumnIndex = columns.findIndex(
      (column) => column.name === "View"
    );
    if (viewColumnIndex !== -1) {
      columns.splice(viewColumnIndex, 1);
    }

    const reopenColumnIndex = columns.findIndex(
      (column) => column.name === "Reopen"
    );
    if (reopenColumnIndex !== -1) {
      columns.splice(reopenColumnIndex, 1);
    }
  }

  if (activeTab === "scanned" || activeTab === "removed") {
    const actionColumnIndex = columns.findIndex(
      (column) => column.name === "Action"
    );
    if (actionColumnIndex !== -1) {
      columns.splice(actionColumnIndex, 1);
    }
  }

  const handleStartDate = (date) => {
    setStartDate(date);
    setStartError(false);
  };

  const handleView = useCallback((row) => async () => {
    const propdata = {
      mode: "view",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleActive = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Active this",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, Active it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("post", `/Coupon/reopen?coupon_id=${row.couponId}`)
          .then((res) => {
            if (typeof res !== "undefined") {
              getCouponList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  useEffect(() => {
    setCouponCode([]);
    getCouponList();
  }, [page, activeTab]);

  const getCouponList = () => {
    setLoading(true);
    let endpoint = "";

    if (activeTab === "all") {
      endpoint = "/Coupon/list?";
    } else if (
      activeTab === "available" ||
      activeTab === "scanned" ||
      activeTab === "removed"
    ) {
      endpoint = `/Coupon/listByCouponType?available=${
        activeTab === "available"
      }&scanned=${activeTab === "scanned"}&removed=${activeTab === "removed"}&`;
    } else if (activeTab === "Non Scannable") {
      endpoint = "/Coupon/nonscannable-list?";
    }

    utils
      .callAPI(
        "get",
        `${endpoint}from_date=${
          startDate ? moment(startDate).format("YYYY-MM-DD") : ""
        }&to_date=${
          endDate ? moment(endDate).format("YYYY-MM-DD") : ""
        }&start_date=${
          filterDate ? moment(filterDate).format("YYYY-MM-DD") : ""
        }&coupon_code=${filterCoupon ? filterCoupon : ""}&product_name=${
          filterProduct ? filterProduct : ""
        }&page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        setLoading(false);
        if (typeof res !== "undefined") {
          console.log("Coupon data", res);
          setCouponCode(res);
        } else {
          setCouponCode([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setCouponCode([]);
      });
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setPage(1); // Reset page number when switching tabs
  };

  // useEffect(() => {
  //   getScannedCoupon();
  // }, [scanned, available, page]);

  // const getScannedCoupon = () => {
  //   utils
  //     .callAPI(
  //       "get",
  //       `/Coupon/listByCouponType?available=${available}&scanned=${scanned}from_date=${
  //         startDate ? moment(startDate).format("YYYY-MM-DD") : ""
  //       }&to_date=${
  //         endDate ? moment(endDate).format("YYYY-MM-DD") : ""
  //       }&start_date=${
  //         filterDate ? moment(filterDate).format("YYYY-MM-DD") : ""
  //       }&coupon_code=${filterCoupon ? filterCoupon : ""}&product_name=${
  //         filterProduct ? filterProduct : ""
  //       }&page=${page}&per_page=${countPerPage}`
  //     )
  //     .then((res) => {
  //       if (typeof res !== "undefined") {
  //         console.log("Coupon data", res);
  //         setCouponCode(res);
  //       } else {
  //         setCouponCode([]);
  //       }
  //     })
  //     .catch((err) => {
  //       setCouponCode([]);
  //     });
  // };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    console.log(row.id);
    Swal.fire({
      title: "Delete Coupon?",
      text: "Once deleted, your data will be permanently removed and cannot be recovered. Are you sure you want to delete?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let apiEndpoint = "";
        if (activeTab !== "all") {
          apiEndpoint = `/Coupon/delete?id=${row.id}`;
        } else {
          apiEndpoint = `/Coupon/batch-delete?batch_id=${row.coupon_batch}`;
        }

        utils
          .callAPI("get", apiEndpoint)
          .then((res) => {
            if (typeof res !== "undefined") {
              getCouponList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleDownloadBatch = (row) => {
    console.log("Downloading Batch", row.coupon_batch);
    setCsvData([]);

    utils
      .callAPI("get", `Coupon/export?coupon_batch=${row.coupon_batch}`)
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          // setCsvData(res);
          // setActive(true);
          generateCSV(res);

        } else {
          setCsvData([]);
        }
      })
      .catch((err) => {
        setCsvData([]);
      });
  };

  
  function generateCSV(filteredData) {
    const ws = XLSX.utils.json_to_sheet(filteredData, {
      header: Object.keys(filteredData[0]),
      skipHeader: true,
    });

    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, "Employees");

    const csvOutput = XLSX.utils.sheet_to_csv(ws);

    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "coupon_detail.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  function handleDownload() {
    console.log("Downloading");
    setCsvData([]);

    utils
      .callAPI(
        "get",
        `Coupon/export?start_date=${
          filterDate ? moment(filterDate).format("YYYY-MM-DD") : ""
        }&coupon_code=${filterCoupon}&product_name=${filterProduct}`
      )
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          // setCsvData(res);
          // setActive(true);
          generateCouponCSV(res);

        } else {
          setCsvData([]);
        }
      })
      .catch((err) => {
        setCsvData([]);
      });
  }

  
  function generateCouponCSV(filteredData) {
    const ws = XLSX.utils.json_to_sheet(filteredData, {
      header: Object.keys(filteredData[0]),
      skipHeader: true
    });
    
    const wb = XLSX.utils.book_new();
    
    XLSX.utils.book_append_sheet(wb, ws, "Employees");
    
    const csvOutput = XLSX.utils.sheet_to_csv(ws);
    
    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });
    
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Coupons.csv";
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  
  const handleDate = (date) => {
    setFilterDate(date);
  };

  const handleSearch = () => {
    getCouponList();
  };

  const handleReset = () => {
    console.log("handle reset...", activeTab);
    setFilterDate("");
    setFilterCoupon("");
    setFilterProduct("");
    debouncedGetCouponList();
  };

  const handleResetDate = () => {
    console.log("handle reset...", activeTab);
    setStartDate(null);
    setEndDate(null);
    setDateFilter(false);
    debouncedGetCouponList();
  };

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [csvData]);

  const handleMultipleDelete = () => {
    setSelectable(!selectable);
  };

  const handleEndDateChange = (date) => {
    if (!startDate) {
      setStartError(true);
      return;
    }
    setEndDate(date);
  };

  const handleDeleteSelectedRows = () => {
    if (selectedRows.length === 0) {
      return;
    }
    console.log(selectedRows);
    const formattedIds = selectedRows.join(",");

    Swal.fire({
      title: "Delete Coupons?",
      text: "Once deleted, your data will be permanently removed and cannot be recovered. Are you sure you want to delete?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete them!",
    }).then((result) => {
      if (result.isConfirmed) {
        const apiEndpoint =
          activeTab !== "all"
            ? `/Coupon/bulk-delete?id=${formattedIds}`
            : `/Coupon/bulk-batch-delete?batch_id=${formattedIds}`;

        utils
          .callAPI("post", apiEndpoint)
          .then((res) => {
            if (typeof res !== "undefined") {
              // Remove deleted rows from the table
              //  const updatedCouponCode = couponCode.filter(
              //    (row) => !idsToDelete.includes(row.id)
              //  );
              //  setCouponCode(updatedCouponCode);
              // Clear selected rows
              setSelectedRows([]);

              // Optionally, refresh the table data
              getCouponList();
            }
          })
          .catch((err) => {});
      }
    });
  };

  const handleFilter = () => {
    getCouponList();
  };

  // const handleAllClick = () => {
  //   setAvailable(false);
  //   setScanned(false);
  //   setAll(true);
  // };

  // const handleAvailableClick = () => {
  //   setAvailable(true);
  //   setScanned(false);
  //   setAll(false);
  // };

  // const handleScannedClick = () => {
  //   setAvailable(false);
  //   setScanned(true);
  //   setAll(false);
  // };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <div className="section-body">
      <RB.Card className="card shadow">
      <div className="floating-btns-holder-artisan">
          <a
            href
            className="btn btn-primary btn-circle me-2"
            variant="primary"
            onClick={() => handleDownload()}

          >
            <DocumentDownload />
          </a>
          {/* <CSVLink data={csvData} ref={csvLink} target="_blank" /> */}
          <a
            href
            className="btn btn-dark btn-circle"
            variant="dark"
            onClick={handleAddClick}
          >
            <AddCircle />
          </a>
        </div>
        <div className="col-12 px-4 pt-3">
          {activeTab !== "scanned" ? (
            <div className="row align-items-center mt-1 mb-3" text-center>
              <div className="col-md-9">
                <div className="row g-3 align-items-center mb-3 mb-md-0">
                  <div className="col-md">
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDate}
                      selectsStart
                      // startDate={startDate}
                      // endDate={endDate}
                      dateFormat="dd-MM-yyyy"
                      className={`form-control range-datepicker-input ${
                        startError ? "date-border-danger" : ""
                      }`}
                      placeholderText="Select Start Date"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearch();
                        }
                      }}
                      // maxDate={new Date()}
                      required
                    />
                  </div>
                  <div className="col-12 col-sm-auto">To</div>
                  <div className="col-md">
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      selectsEnd
                      // startDate={startDate}
                      // endDate={endDate}
                      minDate={startDate}
                      // maxDate={new Date()}
                      dateFormat="dd-MM-yyyy"
                      className="form-control range-datepicker-input"
                      placeholderText="Select End Date"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearch();
                        }
                      }}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <RB.Button
                  variant="primary"
                  className=" btn-md"
                  onClick={handleFilter}
                  disabled={!startDate || !endDate}
                >
                  Find
                </RB.Button>
                <Link
                  className="ms-2 text-underline"
                  //variant="secondary"
                  onClick={handleResetDate}
                >
                  Reset
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <RB.Card.Header>
          <RB.Row>
            <RB.Col md={12}>
              <RB.Nav variant="tabs" defaultActiveKey="link-0">
                <RB.Nav.Item>
                  <RB.Nav.Link
                    eventKey="link-0"
                    onClick={() => handleTabClick("all")}
                  >
                    All
                  </RB.Nav.Link>
                </RB.Nav.Item>
                <RB.Nav.Item>
                  <RB.Nav.Link
                    eventKey="link-1"
                    onClick={() => handleTabClick("available")}
                  >
                    Available
                  </RB.Nav.Link>
                </RB.Nav.Item>
                <RB.Nav.Item>
                  <RB.Nav.Link
                    eventKey="link-2"
                    onClick={() => handleTabClick("scanned")}
                  >
                    Scanned
                  </RB.Nav.Link>
                </RB.Nav.Item>
                <RB.Nav.Item>
                  <RB.Nav.Link
                    eventKey="link-3"
                    onClick={() => handleTabClick("removed")}
                  >
                    Removed
                  </RB.Nav.Link>
                </RB.Nav.Item>

                <RB.Nav.Item>
                  <RB.Nav.Link
                    eventKey="link-4"
                    onClick={() => handleTabClick("Non Scannable")}
                  >
                    Non Scannable
                  </RB.Nav.Link>
                </RB.Nav.Item>
              </RB.Nav>
            </RB.Col>

            <div className="card-header-action">
              {/* <div className="floating-btns-holder">
                <a
                  href
                  className="floating-btn"
                  variant="primary"
                  onClick={() => handleDownload()}
                >
                  <img src={excelSvg} />
                </a>
                <CSVLink data={csvData} ref={csvLink} />

                <a
                  href
                  className="floating-btn btn-dark"
                  variant="dark"
                  onClick={handleAddClick}
                >
                  <span className="material-icons-outlined">add</span>
                </a>
              </div> */}

              {/* <CSVLink data={csvData} ref={csvLink} target="_blank" /> */}
            </div>
          </RB.Row>
        </RB.Card.Header>
        <RB.Card.Body>
          <div className="row mb-4">
            <div className="col-md-9">
              <div className="row g-3 mb-3 mb-md-0">
                {activeTab !== "scanned" ? (
                  <div className="col-md">
                    {/* <input
                type="text"
                className="form-control"
                placeholder="Date"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
              /> */}

                    <DatePicker
                      onChange={handleDate}
                      value={filterDate}
                      selected={filterDate ? new Date(filterDate) : null}
                      selectsEnd
                      dateFormat="dd-MM-yyyy"
                      className="form-control range-datepicker-input-artisan"
                      placeholderText="Date"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearch();
                        }
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Enter Code"
                    value={filterCoupon}
                    onChange={(e) => setFilterCoupon(e.target.value)}
                    maxLength={32}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                </div>

                <div className="col-md">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Product Name"
                    value={filterProduct}
                    onChange={(e) => setFilterProduct(e.target.value)}
                    maxLength={32}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <RB.Button
                className=" btn-md"
                variant="primary"
                onClick={handleSearch}
                disabled={!filterProduct && !filterDate && !filterCoupon}
              >
                Find
              </RB.Button>
              <Link
                className="ms-2 text-underline"
                // variant="secondary"
                onClick={handleReset}
              >
                Reset
              </Link>
            </div>
          </div>

          <div className="deleteOptions">
            {selectable ? (
              <>
                <RB.Button
                  className=" btn-md me-2"
                  variant="light"
                  onClick={handleMultipleDelete}
                >
                  Cancel
                </RB.Button>
                {selectedRows.length > 0 && (
                  <RB.Button
                    className="btn-md"
                    variant="danger"
                    onClick={handleDeleteSelectedRows}
                  >
                    Delete Selected
                  </RB.Button>
                )}
              </>
            ) : (
              <RB.Button
                className=" btn-md"
                variant="light"
                onClick={handleMultipleDelete}
              >
                Delete Multiple
              </RB.Button>
            )}
          </div>
          <DataTable
            columns={columns}
            className="table-stripped-columns"
            data={couponCode.result}
            highlightOnHover
            responsive
            pagination
            paginationServer
            selectableRows={selectable}
            paginationTotalRows={couponCode.total}
            paginationPerPage={countPerPage}
            paginationComponentOptions={{
              noRowsPerPage: true,
            }}
            paginationComponent={() => (
              <CustomPagination
                currentPage={page}
                totalRows={couponCode.total}
                rowsPerPage={countPerPage}
                onPageChange={handlePageChange}
              />
            )}
            striped
            progressPending={loading}
            onChangePage={(page) => setPage(page)}
            onSelectedRowsChange={handleChange}
            persistTableHead
            customStyles={{
              head: {
                style: {
                  fontSize: "0.875rem", // Adjust the font size as needed
                  fontWeight: "bold", // Adjust the font weight as needed
                },
              },

              // rows: {
              //   style: {
              //     backgroundColor: "#f7f7f7", // Default background color for all rows
              //   },
              // },
            }}
            // customRowStyles={[
            //   {
            //     when: (row, index) => index % 2 !== 0, // Apply style to odd rows
            //     style: {
            //       backgroundColor: "#ffffff", // Background color for odd rows
            //     },
            //   },
            // ]}
            // subHeader
            // subHeaderComponent={subHeaderComponent}
          />
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
