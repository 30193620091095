import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../Utils/comman";

export const fetchExpensePlans = createAsyncThunk(
  "expense/fetchExpensePlans",
  async ({ planId }, thunkAPI) => {
    try {
      const response = await utils.callAPI("get", `/expense/travel-local-expense?schedule_id=${planId}&exp_master_id=0`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);


const initialState = {
    expensePlans: [],
    isLoading: false,
    isError: false,
  };
  
  const expenseSlice = createSlice({
    name: "expense",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchExpensePlans.pending, (state) => {
          state.isLoading = true;
          state.isError = false;
        })
        .addCase(fetchExpensePlans.fulfilled, (state, action) => {
          console.log('all expense plan data', action.payload);
          state.expensePlans = Array.isArray(action.payload) ? action.payload : [];
          state.isLoading = false;
          state.isError = false;
        })
        .addCase(fetchExpensePlans.rejected, (state) => {
          state.isLoading = false;
          state.isError = true;
        });
    },
  });
  
  export default expenseSlice.reducer;
