import React, { useEffect, useState } from 'react'
import List from '../Components/SalaryHistory/List';
import View from '../Components/SalaryHistory/View';
import Layout from '../Layout';
import { useLocation } from 'react-router-dom';

function SalaryHistoy() {

    const [comp, setComp] = useState("list");
    const [rowData, setRowData] = useState();
    const location = useLocation();

    useEffect(() => {
      setComp("list")
    }, [location]);
    
    function handleChange(newValue) {
      setComp(newValue.mode);
      setRowData(newValue.rowData);
    }
    return (
      <Layout>
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>Salary History</h1>
            </div>
  
            {comp === "list" ? (
              <List
                comp={comp}
                onChange={handleChange}
                rowData={rowData}
              />
            ) : (
              <View comp={comp} rowData={rowData} onChange={handleChange} />
            )}
          </section>
        </div>
      </Layout>
    );
}

export default SalaryHistoy