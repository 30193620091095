import moment from "moment";
import React, { useState, useEffect } from "react";
import { Form, Row, Col, FloatingLabel, Button, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as utils from "../../../Utils/comman";
import * as RB from "react-bootstrap";
import { setExpId } from "../../../reducers/expIdSlice";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { fetchTravelExpenses } from "../../../reducers/travelExpensesSlice";
import { fetchExpensePlans } from "../../../reducers/expenseSlice";
import { clearExp } from "../../../reducers/updateExpenseSlice";

function HotelExpense({
  head,
  userPermissions,
  openAccordionId,
  currentTravelScedule,
}) {
  const [hasPermission, setHasPermission] = useState(false);
  const [formData, setFormData] = useState({
    city: "",
    checkInDate: null,
    checkOutDate: null,
    hotelName: "",
    amount: "",
    description: "",
    document: [],
  });
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [amountError, setAmountError] = useState("");
  const dispatch = useDispatch();
  const expId = useSelector((state) => state.expId);
  const [isDocDragging, setIsDocDragging] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isValidImage, setisValidImage] = useState(false);
  const [isValidDoc, setisValidDoc] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [error, setError] = useState(false);
  const { expenses } = useSelector((state) => state.expenses);
  const updateExp = useSelector((state) => state.updateExp.updateExp);

  useEffect(() => {
    if (Object.keys(updateExp).length > 0) {
      console.log(updateExp);
      setFormData({
        city: updateExp.city,
        checkInDate: updateExp.dateFrom,
        checkOutDate: updateExp.dateTo,
        hotelName: updateExp.hotelName,
        amount: updateExp.amount,
        description: updateExp.description,
        document: [],
      });
    }
  }, [updateExp]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      console.log(formData);
      e.stopPropagation();
      setValidated(true);
    } else {
      if (Object.keys(updateExp).length !== 0) {
        if (
          Array.isArray(updateExp.expense_image) &&
          updateExp.expense_image.length !== 0
        ) {
          setIsImage(false);
        } else {
          setIsImage(true);
          return;
        }
      } else {
        if (formData.document.length < 1) {
          setIsImage(true);
          return;
        } else {
          setIsImage(false);
        }
      }
      setIsSubmit(true);
    }
  };

  const page = 0;
  const countPerPage = 0;
  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (Object.keys(updateExp).length > 0) {
        bodyFormData.append("id", updateExp.id);
      }
      bodyFormData.append("expense_type", "travel");
      bodyFormData.append("planId", openAccordionId);
      bodyFormData.append("headId", head.head_id);
      bodyFormData.append("subHeadId", 0);
      bodyFormData.append("classId", 0);
      bodyFormData.append(
        "dateFrom",
        moment(formData.checkInDate).format("YYYY-MM-DD")
      );
      bodyFormData.append(
        "dateTo",
        moment(formData.checkOutDate).format("YYYY-MM-DD")
      );
      bodyFormData.append("stationFrom", "");
      bodyFormData.append("stationTo", "");
      bodyFormData.append("hotelName", formData.hotelName);
      bodyFormData.append("tollAmount", formData.amount || "0.00");
      bodyFormData.append("amount", formData.amount || "0.00");
      bodyFormData.append("expId", expId || "");
      bodyFormData.append("description", formData.description || "");
      bodyFormData.append("city", formData.city || "");
      bodyFormData.append("nature_of_expense", "");
      formData.document.forEach((doc, index) => {
        bodyFormData.append(`expense_images[]`, doc);
      });

      utils
        .callAPI("post", `/expense/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            dispatch(setExpId(res.expId));
            dispatch(clearExp());
            setIsSubmit(false);
            setFormData({
              city: "",
              checkInDate: null,
              checkOutDate: null,
              hotelName: "",
              amount: "",
              description: "",
              document: [],
            });
            setValidated(false);
            setAmountError("");
            dispatch(fetchTravelExpenses({ page, countPerPage }));
            dispatch(
              fetchExpensePlans({ expenseId: expId, planId: openAccordionId })
            );
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  useEffect(() => {
    const permission = userPermissions.find(
      (permission) =>
        permission.head_id === head.head_id && permission.sub_head_id === "0"
    );
    setHasPermission(!!permission);
  }, [head, userPermissions]);

  if (!hasPermission) {
    return <div>No permissions to add Hotel Expense.</div>;
  }

  // const handleDateChange = (date, field) => {
  //   setFormData({ ...formData, [field]: date });
  // };

  const handleDateChange = (date, field) => {
    setFormData({ ...formData, [field]: date });

    // const selectedDate = moment(date);

    // const travelEntitlementRanges = expenses?.result
    //   .flatMap((expense) => expense.expList)
    //   .filter((expenseItem) => expenseItem.headId === "2")
    //   .map((expenseItem) => ({
    //     dateFrom: moment(expenseItem.dateFrom),
    //   }));

    // const isDateInRange = travelEntitlementRanges.some((range) =>
    //   selectedDate.isSame(range.dateFrom, "day")
    // );

    // if (isDateInRange) {
    //   Swal.fire({
    //     icon: "error",
    //     title: "Oops...",
    //     text: "This date is already used for Local Conveyance.Cannot add hotel expense on this date.",
    //   });
    //   setError(true);
    // } else {
    //   setError(false);
    // }
  };

  const handleAmountChange = (e) => {
    const enteredAmount = e.target.value;

    const permissionValue = parseFloat(
      userPermissions.find(
        (permission) =>
          permission.head_id === head.head_id && permission.sub_head_id === "0"
      )?.value || 0
    );

    const amount = parseFloat(enteredAmount);

    if (amount > permissionValue) {
      setAmountError(`Amount cannot exceed ₹${permissionValue}`);
      setFormData({
        ...formData,
        amount: permissionValue.toString(),
      });
    } else {
      setAmountError("");
      setFormData({
        ...formData,
        amount: enteredAmount,
      });
    }
  };

  const handleDragOver = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(true);
    } else {
      setIsDocDragging(true);
    }
  };

  const handleDragEnter = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(true);
    } else {
      setIsDocDragging(true);
    }
  };

  const handleDragLeave = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(false);
    } else {
      setIsDocDragging(false);
    }
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    setIsDraggingOver(false);
    setIsDocDragging(false);

    const files = Array.from(e.dataTransfer.files);
    const validExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ];
    const isValidFileType = files.every((file) =>
      validExtensions.includes(file.type)
    );

    if (!isValidFileType) {
      setisValidDoc(true);
      return;
    }

    setisValidDoc(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      document: [...prevFormData.document, ...files],
    }));
  };

  const handleDocumentChange = (e) => {
    const files = Array.from(e.target.files);
    const validExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ];
    const isValidFileType = files.every((file) =>
      validExtensions.includes(file.type)
    );

    if (!isValidFileType) {
      setisValidDoc(true);
      return;
    }

    setisValidDoc(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      document: [...prevFormData.document, ...files],
    }));
  };

  const handleDeleteDocument = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      document: prevFormData.document.filter((_, i) => i !== index),
    }));
  };

  const getNextDay = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1); // Add one day
    return date;
  };
  
  return (
    <Card>
      <Card.Body>
        <h5>{head.head_name}</h5>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <FloatingLabel
                controlId="formCity"
                label="City"
                className="floating-label-fancy"
              >
                <Form.Control
                  type="text"
                  placeholder="City"
                  value={formData.city}
                  onChange={(e) =>
                    setFormData({ ...formData, city: e.target.value })
                  }
                  required
                />
              </FloatingLabel>
            </Col>
            <Col md={6} className="mb-3 mt-3">
              <FloatingLabel
                controlId="formCheckInDate"
                label="Check In Date"
                className={`floating-label-fancy ${
                  formData.checkInDate ? "dateSelected" : "dateempty"
                }`}
              >
                <DatePicker
                  selected={formData.checkInDate}
                  onChange={(date) => handleDateChange(date, "checkInDate")}
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  showMonthDropdown
                  showYearDropdown
                  required
                  dropdownMode="select"
                  minDate={currentTravelScedule?.start_date}
                  maxDate={currentTravelScedule?.end_date}
                />
              </FloatingLabel>
            </Col>
            <Col md={6} className="mb-3 mt-3">
              <FloatingLabel
                controlId="formCheckOutDate"
                label="Check Out Date"
                className={`floating-label-fancy ${
                  formData.checkOutDate ? "dateSelected" : "dateempty"
                }`}
              >
                <DatePicker
                  selected={formData.checkOutDate}
                  onChange={(date) => handleDateChange(date, "checkOutDate")}
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  showMonthDropdown
                  showYearDropdown
                  required
                  dropdownMode="select"
                  minDate={formData.checkInDate}
                  maxDate={currentTravelScedule?.end_date ? getNextDay(currentTravelScedule.end_date) : null}
                />
              </FloatingLabel>
            </Col>
            <Col md={6} className="mb-3">
              <FloatingLabel
                controlId="formHotelName"
                label="Hotel Name"
                className="floating-label-fancy"
              >
                <Form.Control
                  type="text"
                  placeholder="Hotel Name"
                  required
                  value={formData.hotelName}
                  onChange={(e) =>
                    setFormData({ ...formData, hotelName: e.target.value })
                  }
                />
              </FloatingLabel>
            </Col>
            <Col md={6} className="mb-3">
              <FloatingLabel
                controlId="formAmount"
                label="Amount"
                className="floating-label-fancy"
              >
                <RB.Form.Control
                  type="text"
                  placeholder="Amount"
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9.]/g, ""))
                  }
                  value={formData.amount}
                  onChange={handleAmountChange}
                  required
                />
                {amountError && (
                  <div className="invalid-feedback d-block">{amountError}</div>
                )}
              </FloatingLabel>
            </Col>
            <Col md={6} className="mb-3">
              <FloatingLabel
                controlId="formDescription"
                label="Description"
                className="floating-label-fancy"
              >
                <Form.Control
                  as="textarea"
                  type="text"
                  placeholder="Description"
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="text-start">
              <h6 className="mt-2">
                Total: ₹ {formData.amount ? formData.amount : 0}
              </h6>
            </Col>
            {/* <Col className="text-end">
              <Button variant="success" type="submit">
                Add to List
              </Button>
            </Col> */}
          </Row>
          <RB.Row>
            <RB.Col md={6}>
              <RB.Card className="mb-0">
                <RB.CardBody className="p-2">
                  <h6 className="mb-2">Upload Bills</h6>
                  <hr />
                  <div
                    className={`border p-4 text-center d-flex flex-column align-items-center ${
                      isDocDragging ? "bg-info" : ""
                    }`}
                    onDragOver={(e) => handleDragOver(e, "doc")}
                    onDragEnter={(e) => handleDragEnter(e, "doc")}
                    onDragLeave={(e) => handleDragLeave(e, "doc")}
                    onDrop={(e) => handleDrop(e, "doc")}
                  >
                    <div className="avatar avatar-circle avatar-lg bg-light text-dark">
                      <i className="material-icons-outlined">cloud_upload</i>
                    </div>
                    <p className="mb-0">Drag & Drop your bills here</p>
                    <small className="mb-2">or</small>
                    <div>
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png,.pdf"
                        multiple
                        style={{ display: "none" }}
                        onChange={handleDocumentChange}
                        id="docInput"
                      />
                      <RB.Button
                        variant="primary"
                        className="me-2"
                        onClick={() =>
                          document.getElementById("docInput").click()
                        }
                      >
                        <i className="las la-file-upload me-1"></i>Browse Bills
                      </RB.Button>
                    </div>
                  </div>
                  <span className={`${isValidDoc ? "text-danger" : ""}`}>
                    <small>
                      *Note: Only jpg,jpeg,png and pdf Files are allowed
                    </small>
                  </span>
                  <RB.Row className="mt-3 g-3"></RB.Row>
                </RB.CardBody>
              </RB.Card>
              {isImage && (
                <div className="text-danger">
                  Please upload at least one bill document.
                </div>
              )}
            </RB.Col>
            <RB.Col md={6}>
              <RB.Row>
                {formData?.document?.map((doc, index) => (
                  <RB.Col md={4} key={index}>
                    <div className="position-relative me-2 mb-4 rounded border p-3 text-center">
                      <div className="position-relative">
                        {doc.type === "application/pdf" ? (
                          <a
                            href={URL.createObjectURL(doc)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-decoration-none text-dark "
                          >
                            <div className="product-image-upload-trael">
                              <i className="material-icons-outlined md-64 mt-4">
                                picture_as_pdf
                              </i>
                            </div>
                          </a>
                        ) : (
                          <div className="product-image-upload-trael">
                            <img
                              src={URL.createObjectURL(doc)}
                              alt={`Image ${index}`}
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          </div>
                        )}
                        {Array.isArray(updateExp.expense_image) &&
                          updateExp.expense_image.length !== 0 && (
                            <>
                              {updateExp?.expense_image?.map(
                                (expImage, index) => (
                                  <RB.Col
                                    md={4}
                                    key={`update-exp-image-${index}`}
                                  >
                                    <div className="position-relative me-2 mb-4 rounded border p-3 text-center">
                                      <div className="position-relative">
                                        {expImage.expense_image.endsWith(
                                          ".pdf"
                                        ) ? (
                                          <a
                                            href={expImage.expensePath}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-decoration-none text-dark"
                                          >
                                            <div className="product-image-upload-trael">
                                              <i className="material-icons-outlined md-64 mt-4">
                                                picture_as_pdf
                                              </i>
                                            </div>
                                          </a>
                                        ) : (
                                          <div className="product-image-upload-trael">
                                            <img
                                              src={expImage.expensePath}
                                              alt={`Image ${index}`}
                                              style={{
                                                maxWidth: "100%",
                                                maxHeight: "100%",
                                              }}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </RB.Col>
                                )
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  </RB.Col>
                ))}
              </RB.Row>
            </RB.Col>
          </RB.Row>
          <RB.Row>
            <RB.Col className="text-end">
              <RB.Button variant="success" type="submit" disabled={error}>
                Add to List
              </RB.Button>
            </RB.Col>
          </RB.Row>
        </Form>
      </Card.Body>
    </Card>
  );
}

export default HotelExpense;
