import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useDispatch, useSelector } from "react-redux";
import { fetchTravelHeadList } from "../../reducers/travelHeadSlice";
import { fetchTravelPermissionList } from "../../reducers/travelPermissionSlice";
import moment from "moment";
import { Eye, Trash } from "iconsax-react";
import { setExpId } from "../../reducers/expIdSlice";
import { fetchTravelExpenses } from "../../reducers/travelExpensesSlice";
import ReasonModal from "../ReasonModal";
import CustomPagination from "../../Utils/CustomPagination";

function List({ setcompo, setID, setShowAdd }) {
  // const [expenses, setExpenses] = useState([]);
  const { expenses } = useSelector((state) => state.expenses);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModal(true);
    },
    []
  );

  useEffect(() => {
    getExpId();
  }, []);

  const getExpId = () => {
    const currentDate = new Date().toISOString().split("T")[0];
    const url = `/expense/get-expenseid?date=${currentDate}`;

    utils
      .callAPI("get", url)
      .then((res) => {
        if (typeof res !== "undefined") {
          dispatch(setExpId(res.expId));
        } else {
        }
      })
      .catch((err) => {});
  };

  const columns = [
    {
      name: "Created Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
    },
    {
      name: "Expense Id",
      selector: (row) => row?.expId,
    },
    {
      name: "Senior Status",
      selector: (row) => row.seniorStatus,
    },
    {
      name: <div>Hr Status</div>,
      selector: (row) => {
        if (row.hr_submit === null || row.hr_submit === undefined) {
          return "";
        } else if (row.hr_submit === "0") {
          return "Pending";
        } else if (row.hr_submit === "1") {
          return "Approved";
        } else {
          return "";
        }
      },
    },
    {
      name: <div>Hr Remark</div>,
      selector: (row) => row.hr_remark,
      cell: (row) => {
        const wordCount = row?.hr_remark?.split(" ").length;
        return wordCount > 3 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("HR Remark", row.hr_remark)}
          >
            <Eye />
          </RB.Button>
        ) : (
          <span>{row.hr_remark}</span>
        );
      },
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          {/* {utils.hasPermission(primaryMenuRes, "User", "user.edit") && ( */}
          <RB.Button className="btn btn-flat ms-1" onClick={handleView(row)}>
            <Eye />
          </RB.Button>
          {/* {row.seniorStatus !== "Approve" || row.accountStatus !== "Approve" ? (
            <RB.Button
              className="btn btn-flat ms-1"
              onClick={handleDelete(row)}
            >
              <Delete />
            </RB.Button>
          ) : null} */}
          {/* )} */}
        </>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchTravelHeadList());
    dispatch(fetchTravelPermissionList());
  }, []);

  useEffect(() => {
    dispatch(fetchTravelExpenses({ page, countPerPage }));
  }, [page, filterText]);

  // const getTravelExpenses = () => {
  //   utils
  //     .callAPI("get", `expense/list?page=${page}&per_page=${countPerPage}`)
  //     .then((res) => {
  //       if (typeof res !== "undefined") {
  //         setExpenses(res);
  //       } else {
  //         setExpenses([]);
  //       }
  //     })
  //     .catch((err) => {
  //       setExpenses([]);
  //     });
  // };

  const handleView = useCallback((row) => async () => {
    setcompo("allView");
    setID(row?.id);
    setShowAdd(true);
  });
  const handleDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/expense/delete-expense-master?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              // getTravelExpenses();
              dispatch(fetchTravelExpenses({ page, countPerPage }));
            }
          })
          .catch((err) => {});
      }
    });
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <DataTable
        columns={columns}
        data={expenses.result}
        highlightOnHover
        responsive
        pagination
        paginationServer
        paginationTotalRows={expenses.total}
        paginationPerPage={countPerPage}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        paginationComponent={() => (
          <CustomPagination
            currentPage={page}
            totalRows={expenses.total}
            rowsPerPage={countPerPage}
            onPageChange={handlePageChange}
          />
        )}
        striped
        onChangePage={(page) => setPage(page)}
        persistTableHead
        customStyles={{
          head: {
            style: {
              fontSize: "0.875rem", // Adjust the font size as needed
              fontWeight: "bold", // Adjust the font weight as needed
            },
          },
        }}
        bHeaderComponent={subHeaderComponent}
      />

      <ReasonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        text={modalText}
      />
    </>
  );
}

export default List;
