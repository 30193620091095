import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../Utils/comman";
import { fetchDistricts } from "./districtSlice";

export const fetchAllDistricts = createAsyncThunk(
  "location/fetchAllDistricts",
  async (stateId, thunkAPI) => {
    try {
      const response = await utils.callAPI("get", `/district/list?stateId=${''}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  Alldistricts: [],
  isLoading: false,
  isError: false,
};

const AlldistrictSlice = createSlice({
  name: "Alldistrict",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllDistricts.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchAllDistricts.fulfilled, (state, action) => {
        state.Alldistricts = Array.isArray(action.payload) ? action.payload : [];
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchAllDistricts.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default AlldistrictSlice.reducer;
