import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import { Link } from "react-router-dom";

function Add(props) {
  const [isEdit, setisEdit] = useState(false);
  const [formData, setFormData] = useState({
    department: "",
    refBy: "",
    name: "",
    email: "",
    currentCTC: "",
    expectedCTC: "",
    zone: "",
    monthlySaleValue: "",
    yearlyTarget: "",
    expectedDOJ: "",
    document: null,
  });
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [zones, setZones] = useState([]);
  const [isDocDragging, setIsDocDragging] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isValidImage, setisValidImage] = useState(false);
  const [isValidDoc, setisValidDoc] = useState(false);
  const [isImage, setIsImage] = useState(false);

  useEffect(() => {
    getDepartmentList();
    getZoneList();
  }, []);

  const getDepartmentList = () => {
    utils
      .callAPI("get", `/department/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setDepartments([
            { id: "", department_name: "Select an option..." },
            ...res.result,
          ]);
        } else {
          setDepartments([]);
        }
      })
      .catch((err) => {
        setDepartments([]);
      });
  };

  const getZoneList = () => {
    utils
      .callAPI("get", `/leave/holiday/group-list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setZones([{ id: "", group_name: "Select Zone..." }, ...res.result]);
        } else {
          setZones([]);
        }
      })
      .catch((err) => {
        setZones([]);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (props.rowData && Object.keys(props.rowData).length > 0) {
        bodyFormData.append("id", props.rowData.id);
        bodyFormData.append("interested", props.rowData.interested);
        bodyFormData.append("remarks", props.rowData.remarks);
      }
      bodyFormData.append("department_id", formData.department);
      bodyFormData.append("ref_by", formData.refBy);
      bodyFormData.append("name", formData.name);
      bodyFormData.append("email", formData.email);
      bodyFormData.append("current_ctc", formData.currentCTC);
      bodyFormData.append("expected_ctc", formData.expectedCTC);
      bodyFormData.append("zone", formData.zone);
      bodyFormData.append("monthly_sales_value", formData.monthlySaleValue);
      bodyFormData.append("yearly_target", formData.yearlyTarget);
      bodyFormData.append("expected_date_of_joining", moment(formData.expectedDOJ).format("YYYY-MM-DD"));
      bodyFormData.append("document", formData.document);

      utils
        .callAPI("post", `/department/save_manpower`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
            setIsSubmit(false);
          }
        })
        .catch((err) => {});

      setIsSubmit(false);
    }
  }, [isSubmit]);

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      setisEdit(true);
      setFormData({
        department: props.rowData.department_id || "",
        refBy: props.rowData.ref_by || "",
        name: props.rowData.name || "",
        email: props.rowData.email || "",
        currentCTC: props.rowData.current_ctc || "",
        expectedCTC: props.rowData.expected_ctc || "",
        zone: props.rowData.zone || "",
        monthlySaleValue: props.rowData.monthly_sales_value || "",
        yearlyTarget: props.rowData.yearly_target || "",
        expectedDOJ: props.rowData.expected_date_of_joining || "",
      });
    } else {
      setisEdit(false);
    }
  }, [props.rowData]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDocDragging(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDocDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDocDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDocDragging(false);

    const file = e.dataTransfer.files[0];
    const validExtensions = ["application/pdf"];

    if (!validExtensions.includes(file.type)) {
      setisValidDoc(true);
      return;
    }

    setisValidDoc(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      document: file,
    }));
  };

  const handleDocumentChange = (e) => {
    const file = e.target.files[0];
    const validExtensions = ["application/pdf"];

    if (!validExtensions.includes(file.type)) {
      setisValidDoc(true);
      return;
    }

    setisValidDoc(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      document: file,
    }));
  };

  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
        <RB.Card.Header>
          <h4>{isEdit ? "Edit" : "Add"}</h4>

          <div className="card-header-action">
            <Link
              className="btn btn-underline"
              onClick={handleBackClick}
            >
              Back
            </Link>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formDepartment"
                    label="Department"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="department"
                      value={formData.department}
                      onChange={handleInputChange}
                      required
                    >
                      {departments.map((dept) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.department_name}
                        </option>
                      ))}
                    </RB.Form.Control>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formRefBy"
                    label="Ref. By"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Ref. By"
                      name="refBy"
                      value={formData.refBy}
                      onChange={handleInputChange}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formName"
                    label="Name"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formEmail"
                    label="Email"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formCurrentCTC"
                    label="Current CTC"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Current CTC"
                      name="currentCTC"
                      value={formData.currentCTC}
                      onChange={handleInputChange}
                      required
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formExpectedCTC"
                    label="Expected CTC"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Expected CTC"
                      name="expectedCTC"
                      value={formData.expectedCTC}
                      onChange={handleInputChange}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formZone"
                    label="Zone"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="zone"
                      value={formData.zone}
                      onChange={handleInputChange}
                      required
                    >
                      {zones.map((zone) => (
                        <option key={zone.id} value={zone.id}>
                          {zone.group_name}
                        </option>
                      ))}
                    </RB.Form.Control>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formMonthlySaleValue"
                    label="Monthly Sale Value"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Monthly Sale Value"
                      name="monthlySaleValue"
                      value={formData.monthlySaleValue}
                      onChange={handleInputChange}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formYearlyTarget"
                    label="Yearly Target"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Yearly Target"
                      name="yearlyTarget"
                      value={formData.yearlyTarget}
                      onChange={handleInputChange}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                      }}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3 mt-3">
                  <RB.FloatingLabel
                    controlId="formExpectedDOJ"
                    //   label="Expected Date of Joining"
                    className={`floating-label-fancy ${
                      formData.expectedDOJ ? "dateSelected" : "dateempty"
                    }`}
                  >
                    <DatePicker
                      selected={formData.expectedDOJ}
                      onChange={(date) =>
                        setFormData({ ...formData, expectedDOJ: date })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Expected Date of Joining"
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <RB.Card className="mb-0">
                  <RB.CardBody className="p-2">
                    <h6 className="mb-2">Upload Document</h6>
                    <hr />
                    <div
                      className={`border p-4 text-center d-flex flex-column align-items-center ${
                        isDocDragging ? "bg-info" : ""
                      }`}
                      onDragOver={(e) => handleDragOver(e, "doc")}
                      onDragEnter={(e) => handleDragEnter(e, "doc")}
                      onDragLeave={(e) => handleDragLeave(e, "doc")}
                      onDrop={(e) => handleDrop(e, "doc")}
                    >
                      <div className="avatar avatar-circle avatar-lg bg-light text-dark">
                        <i className="material-icons-outlined">cloud_upload</i>
                      </div>
                      <p className="mb-0">Drag & Drop your document here</p>
                      <small className="mb-2">or</small>
                      <div>
                        <input
                          type="file"
                          accept=".pdf"
                          style={{ display: "none" }}
                          onChange={handleDocumentChange}
                          id="docInput"
                        />
                        <RB.Button
                          variant="primary"
                          className="me-2"
                          onClick={() =>
                            document.getElementById("docInput").click()
                          }
                        >
                          <i className="las la-file-upload me-1"></i>Browse
                          Documents
                        </RB.Button>
                      </div>
                    </div>
                    <span className={`${isValidDoc ? "text-danger" : ""}`}>
                      <small>*Note: Only pdf Files are allowed</small>
                    </span>
                    <RB.Row className="mt-3 g-3"></RB.Row>
                  </RB.CardBody>
                </RB.Card>
                {isImage && (
                  <div className="text-danger">Please upload document.</div>
                )}
              </RB.Col>
              <RB.Col md={6}>
                <RB.Row>
                  {formData.document ? (
                    <>
                      <RB.Col md={4}>
                        <div className="position-relative me-2 mb-4 rounded border p-3 text-center">
                          <div className="position-relative">
                            <a
                              href={URL.createObjectURL(formData.document)}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-decoration-none text-dark "
                            >
                              <div className="product-image-upload-trael">
                                <i className="material-icons-outlined md-64 mt-4">
                                  picture_as_pdf
                                </i>
                              </div>
                            </a>
                          </div>
                        </div>
                      </RB.Col>
                    </>
                  ) : (
                    ""
                  )}
                </RB.Row>
              </RB.Col>
            </RB.Row>

            <RB.Row className="align-items-center mt-3">
              <RB.Col className="text-end">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
            <RB.Row></RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
