import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    employeeSalary: []
};

const empSalaryDataSlice = createSlice({
    name: "empSalary",
    initialState,
    reducers: {
        setEmpSalary:(state, action) => {
            state.employeeSalary = action.payload;
        },
    }
})

export const { setEmpSalary } = empSalaryDataSlice.actions;
export default empSalaryDataSlice.reducer;