import React, { useEffect, useState } from "react";
import PrimaryNavbar from "./PrimaryNavbar";
import * as RB from "react-bootstrap";
import Logo from "../Components/Logo";
import { isMobile } from "react-device-detect";

function Sidebar() {
  const [menuClass, setmenuClass] = useState(
    "main-sidebar sidebar-style-2 offcanvas-lg offcanvas-start"
  );

  useEffect(() => {
    if (isMobile)
      setmenuClass("main-sidebar sidebar-style-2 offcanvas offcanvas-start");
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg sidebar-navbar"></nav>
      <div id="main-sidebar" className={menuClass}>
        <button
          type="button"
          className="btn-close mb-3 pb-3 mx-auto  d-inline-block d-lg-none"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>

        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            <Logo />
          </div>
          <div className="sidebar-brand sidebar-brand-sm">
            <a href="index.html">VURA</a>
          </div>
          <PrimaryNavbar />
        </aside>
      </div>
    </>
  );
}
export default Sidebar;
