import React from "react";
import * as RB from "react-bootstrap";

function ReasonModal({ show, onHide, title, text }) {
  return (
    <RB.Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
    //   centered
    >
      <RB.Modal.Header closeButton>
        <RB.Modal.Title>{title}</RB.Modal.Title>
      </RB.Modal.Header>
      <RB.Modal.Body>{text}</RB.Modal.Body>
    </RB.Modal>
  );
}

export default ReasonModal;
