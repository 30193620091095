import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import * as utils from "../../../Utils/comman";
import { setExpId } from "../../../reducers/expIdSlice";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { fetchTravelExpenses } from "../../../reducers/travelExpensesSlice";
import { clearExp } from "../../../reducers/updateExpenseSlice";

function LocalConveyance({ head, userPermissions, setShowAdd }) {
  const [selectedSubhead, setSelectedSubhead] = useState(null);
  const [formData, setFormData] = useState({
    subhead: "",
    amount: "",
    description: "",
    date: null,
    document: [],
  });
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [amountError, setAmountError] = useState("");
  const dispatch = useDispatch();
  const expId = useSelector((state) => state.expId);
  const [isDocDragging, setIsDocDragging] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isValidImage, setisValidImage] = useState(false);
  const [isValidDoc, setisValidDoc] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const { expenses } = useSelector((state) => state.expenses);
  // const [selectedDate, setSelectedDate] = useState('');
  const [error, setError] = useState(false);
  const dates = useSelector((state) => state.dates.dates);
  const updateExp = useSelector((state) => state.updateExp.updateExp);

  useEffect(() => {
    if (Object.keys(updateExp).length > 0 ) {
      console.log(updateExp);
      setFormData({
        subhead:updateExp.subHeadId,
        date: updateExp.dateFrom,
        amount: updateExp.amount,
        description: updateExp.description,
        document: [],
      });
    }
  }, [updateExp]);
  const handleSubheadSelect = (event) => {
    const subheadId = event.target.value;
    const subhead = head.subheads.find((sub) => sub.sub_head_id === subheadId);
    
    const permission = userPermissions.find(
      (perm) => perm.head_id === head.head_id && perm.sub_head_id === subheadId
    );
    const amount = permission ? permission.value : "";
    setSelectedSubhead(subhead);
    setFormData({ ...formData, subhead: subheadId, amount });
    setAmountError("");
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      // if (formData.document.length < 1) {
      //   setIsImage(true);
      //   return;
      // } else {
      //   setIsImage(false);
      // }
      setIsSubmit(true);
    }
  };

  const page = 0;
  const countPerPage = 0;
  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (Object.keys(updateExp).length > 0 ) {
        bodyFormData.append("id", updateExp.id);
      }
      bodyFormData.append("expense_type", "local");
      bodyFormData.append("planId", "");
      bodyFormData.append("headId", head.head_id);
      bodyFormData.append("subHeadId", formData.subhead);
      bodyFormData.append("classId", 0);
      bodyFormData.append(
        "dateFrom",
        moment(formData.date).format("YYYY-MM-DD")
      );
      bodyFormData.append("dateTo", "");
      bodyFormData.append("stationFrom", "");
      bodyFormData.append("stationTo", "");
      bodyFormData.append("hotelName", "");
      bodyFormData.append("tollAmount", formData.amount || "0.00");
      bodyFormData.append("amount", formData.amount || "0.00");
      bodyFormData.append("expId", expId || "");
      bodyFormData.append("description", formData.description || "");
      bodyFormData.append("city", "");
      bodyFormData.append("nature_of_expense", "");
      formData.document.forEach((doc, index) => {
        bodyFormData.append(`expense_images[]`, doc);
      });
      utils
        .callAPI("post", `/expense/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            dispatch(clearExp());
            dispatch(setExpId(res.expId));
            setIsSubmit(false);
            setFormData({
              subhead: "",
              amount: "",
              description: "",
              date: null,
              document: [],
            });
            setValidated(false);
            setAmountError("");
            dispatch(fetchTravelExpenses({ page, countPerPage }));
            setShowAdd(false)
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  const filteredSubheads = head.subheads.filter((subhead) =>
    userPermissions.some(
      (permission) =>
        permission.head_id === head.head_id &&
        permission.sub_head_id === subhead.sub_head_id
    )
  );



  const handleAmountChange = (e) => {
    const enteredAmount = parseFloat(e.target.value);
    const selectedSubheadId = formData.subhead;
    const permission = userPermissions.find(
      (perm) =>
        perm.head_id === head.head_id && perm.sub_head_id === selectedSubheadId
    );

    if (permission && enteredAmount > parseFloat(permission.value)) {
      setAmountError(`Amount cannot exceed ₹${permission.value}`);
      setFormData({
        ...formData,
        amount: permission.value.toString(),
      });
    } else {
      setAmountError("");
      setFormData({ ...formData, amount: e.target.value });
    }
  };

  const handleDragOver = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(true);
    } else {
      setIsDocDragging(true);
    }
  };

  const handleDragEnter = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(true);
    } else {
      setIsDocDragging(true);
    }
  };

  const handleDragLeave = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(false);
    } else {
      setIsDocDragging(false);
    }
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    setIsDraggingOver(false);
    setIsDocDragging(false);

    const files = Array.from(e.dataTransfer.files);
    const validExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ];
    const isValidFileType = files.every((file) =>
      validExtensions.includes(file.type)
    );

    if (!isValidFileType) {
      setisValidDoc(true);
      return;
    }

    setisValidDoc(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      document: [...prevFormData.document, ...files],
    }));
  };

  const handleDocumentChange = (e) => {
    const files = Array.from(e.target.files);
    const validExtensions = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "application/pdf",
    ];
    const isValidFileType = files.every((file) =>
      validExtensions.includes(file.type)
    );

    if (!isValidFileType) {
      setisValidDoc(true);
      return;
    }

    setisValidDoc(false);
    setFormData((prevFormData) => ({
      ...prevFormData,
      document: [...prevFormData.document, ...files],
    }));
  };

  const handleDeleteDocument = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      document: prevFormData.document.filter((_, i) => i !== index),
    }));
  };

  const handleDateChange = (date) => {
    const selectedDate = moment(date);

    const isDateValid = dates.every((d) => {
      const departureDate = moment(d.departureDate);
      const returnDate = moment(d.returnDate);
      return !selectedDate.isBetween(departureDate, returnDate, "day", "[]");
    });

    if (isDateValid) {
      setFormData({ ...formData, date: date });

      setError(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "As a travel request is added on this date, you cannot add local conveyance on this date.",
      });

      setError(true);
    }
  };

  // const handleDateChange = (date) => {
  //   setFormData({ ...formData, date });

  //   const selectedDate = moment(date);
  //   console.log(dates);
  //   const isDateValid = dates.some((d) =>
  //     selectedDate.isSame(moment(d), "day")
  //   );

  //   if (isDateValid) {
  //     console.log("The selected date is valid.");
  //   } else {
  //     console.log("The selected date is not within the allowed dates.");
  //   }

  // const travelEntitlementRanges = expenses?.result
  //   .flatMap(expense => expense.expList)
  //   .filter(expenseItem => expenseItem.headId === "1")
  //   .map(expenseItem => ({
  //     dateFrom: moment(expenseItem.dateFrom),
  //     dateTo: moment(expenseItem.dateTo),
  //   }));

  // const isDateInRange = travelEntitlementRanges.some(range =>
  //   selectedDate.isBetween(range.dateFrom, range.dateTo, null, '[]')
  // );

  // if (isDateInRange) {
  //   Swal.fire({
  //     icon: "error",
  //     title: "Oops...",
  //     text: "This date is already added for Travel Entertainment!",
  //   });
  //   setError(true);
  // } else {
  //   setError(false);
  // }
  // };

  return (
    <RB.Card>
      <RB.Card.Body>
        {/* <h5>{head.head_name}</h5> */}
        <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
          <RB.Row>
            <RB.Col md={6} className="mb-3">
              <RB.FloatingLabel
                controlId="formSubhead"
                label="Type"
                className="floating-label-fancy"
              >
                <RB.Form.Select
                  value={formData.subhead}
                  onChange={handleSubheadSelect}
                  required
                >
                  <option value="">Select Type</option>
                  {filteredSubheads.map((subhead) => (
                    <option
                      key={subhead.sub_head_id}
                      value={subhead.sub_head_id}
                    >
                      {subhead.sub_head_name}
                    </option>
                  ))}
                </RB.Form.Select>
              </RB.FloatingLabel>
            </RB.Col>
            <RB.Col md={6} className="mb-3">
              <RB.FloatingLabel
                controlId="formAmount"
                label="Amount"
                className="floating-label-fancy"
              >
                <RB.Form.Control
                  type="text"
                  placeholder="Amount"
                  value={formData.amount}
                  onChange={handleAmountChange}
                  onInput={(e) =>
                    (e.target.value = e.target.value.replace(/[^0-9.]/g, ""))
                  }
                  required
                />
                {amountError && (
                  <div className="invalid-feedback d-block">{amountError}</div>
                )}
              </RB.FloatingLabel>
            </RB.Col>
            <RB.Col md={6} className="mb-3 mt-4">
              <RB.FloatingLabel
                label="Date"
                className={`floating-label-fancy ${
                  formData.date ? "dateSelected" : "dateempty"
                }`}
              >
                <DatePicker
                  selected={formData.date}
                  onChange={handleDateChange}
                  dateFormat="dd-MM-yyyy"
                  className="form-control"
                  showMonthDropdown
                  showYearDropdown
                  required
                  dropdownMode="select"
                  maxDate={new Date()}
                />
                {/* {error && <p className="text-danger">{error}</p>} */}
              </RB.FloatingLabel>
            </RB.Col>
            <RB.Col md={6} className="mt-2">
              <RB.FloatingLabel
                controlId="formDescription"
                label="Description"
                className="floating-label-fancy"
              >
                <RB.Form.Control
                  as="textarea"
                  type="textarea"
                  placeholder="Description"
                  value={formData.description}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                />
              </RB.FloatingLabel>
            </RB.Col>
          </RB.Row>
          <RB.Row className="mt-2">
            <RB.Col className="text-start">
              <h6 className="mt-2">
                Total: ₹ {formData.amount ? formData.amount : 0}
              </h6>
            </RB.Col>
          </RB.Row>
          <RB.Row>
            <RB.Col md={6}>
              <RB.Card className="mb-0">
                <RB.CardBody className="p-2">
                  <h6 className="mb-2">Upload Bills</h6>
                  <hr />
                  <div
                    className={`border p-4 text-center d-flex flex-column align-items-center ${
                      isDocDragging ? "bg-info" : ""
                    }`}
                    onDragOver={(e) => handleDragOver(e, "doc")}
                    onDragEnter={(e) => handleDragEnter(e, "doc")}
                    onDragLeave={(e) => handleDragLeave(e, "doc")}
                    onDrop={(e) => handleDrop(e, "doc")}
                  >
                    <div className="avatar avatar-circle avatar-lg bg-light text-dark">
                      <i className="material-icons-outlined">cloud_upload</i>
                    </div>
                    <p className="mb-0">Drag & Drop your bills here</p>
                    <small className="mb-2">or</small>
                    <div>
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png,.pdf"
                        multiple
                        style={{ display: "none" }}
                        onChange={handleDocumentChange}
                        id="docInput"
                      />
                      <RB.Button
                        variant="primary"
                        className="me-2"
                        onClick={() =>
                          document.getElementById("docInput").click()
                        }
                      >
                        <i className="las la-file-upload me-1"></i>Browse Bills
                      </RB.Button>
                    </div>
                  </div>
                  <span className={`${isValidDoc ? "text-danger" : ""}`}>
                    <small>
                      *Note: Only jpg,jpeg,png and pdf Files are allowed
                    </small>
                  </span>
                  <RB.Row className="mt-3 g-3"></RB.Row>
                </RB.CardBody>
              </RB.Card>
              {isImage && (
                <div className="text-danger">
                  Please upload at least one bill document.
                </div>
              )}
            </RB.Col>
            <RB.Col md={6}>
              <RB.Row>
                {formData?.document?.map((doc, index) => (
                  <RB.Col md={4} key={index}>
                    <div className="position-relative me-2 mb-4 rounded border p-3 text-center">
                      <div className="position-relative">
                        {doc.type === "application/pdf" ? (
                          <a
                            href={URL.createObjectURL(doc)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-decoration-none text-dark "
                          >
                            <div className="product-image-upload-trael">
                              <i className="material-icons-outlined md-64 mt-4">
                                picture_as_pdf
                              </i>
                            </div>
                          </a>
                        ) : (
                          <div className="product-image-upload-trael">
                            <img
                              src={URL.createObjectURL(doc)}
                              alt={`Image ${index}`}
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <RB.Button
                          variant="danger"
                          className="btn btn-sm btn-circle position-absolute top-100 start-50 translate-middle"
                          onClick={() => handleDeleteDocument(index)}
                        >
                          <i className="material-icons-outlined">delete</i>
                        </RB.Button> */}
                  </RB.Col>
                ))}
                {Array.isArray(updateExp.expense_image) &&
                  updateExp.expense_image.length !== 0 && (
                    <>
                      {updateExp?.expense_image?.map((expImage, index) => (
                        <RB.Col md={4} key={`update-exp-image-${index}`}>
                          <div className="position-relative me-2 mb-4 rounded border p-3 text-center">
                            <div className="position-relative">
                              {expImage.expense_image.endsWith(".pdf") ? (
                                <a
                                  href={expImage.expensePath}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-decoration-none text-dark"
                                >
                                  <div className="product-image-upload-trael">
                                    <i className="material-icons-outlined md-64 mt-4">
                                      picture_as_pdf
                                    </i>
                                  </div>
                                </a>
                              ) : (
                                <div className="product-image-upload-trael">
                                  <img
                                    src={expImage.expensePath}
                                    alt={`Image ${index}`}
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </RB.Col>
                      ))}
                    </>
                  )}
              </RB.Row>
            </RB.Col>
          </RB.Row>

          <RB.Row>
            <RB.Col className="text-end">
              <RB.Button variant="success" type="submit" disabled={error}>
                Add To List
              </RB.Button>
            </RB.Col>
          </RB.Row>
        </RB.Form>
      </RB.Card.Body>
    </RB.Card>
  );
}

export default LocalConveyance;
