import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { ArrowDown, Eye } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import * as utils from "../../../Utils/comman";
import CustomPagination  from "../../../Utils/CustomPagination";

function TeamExpenseList(props) {
  const [expenses, setExpenses] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const location = useLocation();

  const userRole = userProfile?.user_role[0]?.roleName;


  const columns = [
    {
      name: "Employee Code",
      selector: (row) => row.emp_code,
    },
    {
      name: "Employee Name",
      selector: (row) => row.emp_name,
    },
    {
      name: "Approved Amount",
      selector: (row) => parseFloat(row.total_amt).toFixed(2),
      omit: location.pathname === "/expenses/team-expense",
    },
    {
      name: "Total Amount",
      selector: (row) => parseFloat(row.expense_amt).toFixed(2),
      omit: location.pathname !== "/expenses/all-expense",
    },
    {
      name: "Status",
      selector: (row) =>
        parseFloat(row.hr_submit) == 2 ? "Approved" : "Pending",
      omit: location.pathname !== "/expenses/all-expense",
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          <RB.Button className="btn btn-flat ms-1" onClick={handleView(row)}>
            <Eye />
          </RB.Button>
        </>
      ),
      omit: location.pathname === "/expenses/accountant-expense",
    },
  ];

  const getEmpList = () => {
    utils
      .callAPI("get", "/expense/emp-list")
      .then((res) => {
        if (res) {
          setExpenses(res.result);
        } else {
          setExpenses([]);
        }
      })
      .catch(() => {
        setExpenses([]);
      });
  };

  useEffect(() => {
    // if (userRole) {
      getEmpList();
    // }
  }, [page]);

  const handleView = useCallback((row) => async () => {
    const propdata = {
      mode: "view",
      rowData: row,
    };
    props.onChange(propdata);
    props.setemp(row);
  });

  const filteredItems = useMemo(() => {
    return expenses.filter((item) => {
      const statusText = item.hr_submit === 2 ? "Approved" : "Pending";
      const searchValue = filterText.toLowerCase();

      return (
        item.emp_code.toLowerCase().includes(searchValue) ||
        item.emp_name.toLowerCase().includes(searchValue) ||
        statusText.toLowerCase().includes(searchValue)
      );
    });
  }, [expenses, filterText]);

  const paginatedExpenses = useMemo(() => {
    if (!Array.isArray(filteredItems)) {
      return [];
    }

    const startIndex = (page - 1) * countPerPage;
    const endIndex = startIndex + countPerPage;

    return filteredItems?.slice(startIndex, endIndex);
  }, [filteredItems, page, countPerPage]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <>
      <DataTable
        columns={columns}
        data={paginatedExpenses}
        highlightOnHover
        responsive
        pagination
        paginationServer
        paginationTotalRows={filteredItems.length}
        paginationPerPage={countPerPage}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        paginationComponent={() => (
          <CustomPagination
            currentPage={page}
            totalRows={filteredItems?.length}
            rowsPerPage={countPerPage}
            onPageChange={handlePageChange}
          />
        )}
        onChangePage={(page) => setPage(page)}
        customStyles={{
          head: {
            style: {
              fontSize: "0.875rem",
              fontWeight: "bold",
            },
          },
        }}
      />
    </>
  );
}

export default TeamExpenseList;
