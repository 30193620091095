import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Check, Close, Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CustomPagination from "../../Utils/CustomPagination";

import {
  AddCircle,
  Document,
  DocumentCode,
  DocumentText,
  Edit2,
  Eye,
  Ticket,
  Trash,
} from "iconsax-react";
import ReasonModal from "../ReasonModal";

function List(props) {
  const [travelList, settravelList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [hideAction, setHideAction] = useState(false);
  const location = useLocation();
  const [status, setstatus] = useState("");
  const [show, setShow] = useState(false);
  const [selectedTravelRequest, setSelectedTravelRequest] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const [selectedStatus, setSelectedStatus] = useState("");
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  console.log(userProfile);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModal(true);
    },
    []
  );

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const columns = [
    {
      name: (
        <div>
          Request Id <br />
        </div>
      ),
      selector: (row) => row.requestId,
    },
    // {
    //   name: (
    //     <div>
    //       Employee Id <br />
    //     </div>
    //   ),
    //   selector: (row) => row.employeeId,
    // },
    // {
    //   name: (
    //     <div>
    //       Destination <br />
    //     </div>
    //   ),
    //   selector: (row) => row.destination,
    // },
    {
      name: (
        <div>
          Start Date <br />
        </div>
      ),
      selector: (row) => moment(row.departureDate).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          End Date <br />
        </div>
      ),
      selector: (row) => moment(row.returnDate).format("DD-MM-YYYY"),
    },
    // {
    //   name: <div>Type</div>,
    //   selector: (row) =>
    //     row.type == "area_visit" ? "Area Wise" : "Distributor Wise",
    // },
    // {
    //   name: <div>State</div>,
    //   selector: (row) => row.state,
    // },
    // {
    //   name: <div>City</div>,
    //   selector: (row) => row.city,
    // },
    {
      name: <div>Approve By</div>,
      selector: (row) => row.approval_name,
    },
    {
      name: <div>Reject Reason</div>,
      selector: (row) => row.reason,
      cell: (row) => {
        const wordCount = row?.reason?.split(" ").length;
        console.log(wordCount);
        return wordCount > 5 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("Reason", row.reason)}
          >
            <Eye />
          </RB.Button>
        ) : (
          <span>{row.reason}</span>
        );
      },
    },
    // {
    //   name: (
    //     <div>
    //       Approval By Hr <br />
    //     </div>
    //   ),
    //   selector: (row) => row.hr_name,
    // },
    // {
    //   name: (
    //     <div>
    //       Manager Approval <br />
    //     </div>
    //   ),
    //   selector: (row) => row.approval_status_manager,
    // },
    {
      name: (
        <div>
          Status <br />
        </div>
      ),
      selector: (row) => row.approvalStatus,
    },
    {
      name: (
        <div>
          Action <br />
        </div>
      ),
      cell: (row) => (
        <>
          {utils.hasPermission(
            primaryMenuRes,
            "Your Travel Request",
            "travel_request.edit"
          ) && (
            <>
              {["Pending", "Desk Reject"].includes(row.approvalStatus) && (
                <RB.Button className="btn btn-flat" onClick={handelEdit(row)}>
                  <Edit2 />
                </RB.Button>
              )}
            </>
          )}
          &nbsp;
          {utils.hasPermission(
            primaryMenuRes,
            "Your Travel Request",
            "travel_request.delete"
          ) && (
            <>
              {" "}
              {row.approvalStatus == "Pending" && (
                <RB.Button
                  className="btn btn-flat ms-1"
                  onClick={showDelete(row)}
                >
                  <Trash />
                </RB.Button>
              )}
            </>
          )}
          {row.travelTicket.length !== 0 && (
            <>
              <RB.Button
                className="btn btn-flat ms-1"
                onClick={showTicket(row)}
              >
                <Ticket />
              </RB.Button>
            </>
          )}
          <>
            {/* {row.approvalStatus == "Approved" && (
              <>
                <RB.Button
                  variant="outline-primary"
                  className="btn-circle me-1"
                  size="sm"
                  onClick={handleDocUpload(row)}
                >
                  <Document />
                </RB.Button>
              </>
            )} */}
            {/* {row?.travelDocuments && row?.travelDocuments?.length !== 0 && (
              <RB.Button
                variant="outline-info"
                className="btn-circle"
                size="sm"
                onClick={handleView(row)}
              >
                <Eye />
              </RB.Button>
            )} */}
          </>
        </>
      ),
      omit:
        !utils.hasPermission(
          primaryMenuRes,
          "Your Travel Request",
          "travel_request.edit"
        ) &&
        !utils.hasPermission(
          primaryMenuRes,
          "Your Travel Request",
          "travel_request.delete"
        ),
    },
  ];

  const showTicket = useCallback(
    (row) => () => {
      setSelectedTravelRequest(row);
      setShow(true);
    },
    []
  );

  useEffect(() => {
    getTravelList();
  }, [page, location.pathname]);

  const getTravelList = () => {
    utils
      .callAPI(
        "get",
        `/travel/listForUser?page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          settravelList(res);
          setHideAction(res?.result?.some((item) => item.status !== "0"));
        } else {
          settravelList([]);
        }
      })
      .catch((err) => {
        settravelList([]);
      });
  };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/travel/delete?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              getTravelList();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const handleDocUpload = useCallback((row) => async () => {
    const propdata = {
      mode: "travel",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const handleView = useCallback((row) => async () => {
    const propdata = {
      mode: "view",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const statusOptions = ["", "Pending", "Approved", "Rejected"];

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const filteredData = useMemo(() => {
    return (
      travelList.result?.filter((item) => {
        const filterTextLower = filterText.toLowerCase();
        return (
          (!selectedStatus || item.approvalStatus === selectedStatus) &&
          (item.requestId.toLowerCase().includes(filterTextLower) ||
            item.employeeId.toLowerCase().includes(filterTextLower) ||
            item.destination.toLowerCase().includes(filterTextLower))
        );
      }) || []
    );
  }, [filterText, selectedStatus, travelList]);

  const handleDownload = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(
        new Blob([blob], { type: "image/jpeg" })
      );

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "Ticket";
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }, 10000);
    } catch (error) {
      console.error("Failed to download image:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <div className="section-body">
        <RB.Card className="card shadow ">
          <div className="floating-btns-holder-artisan">
            {utils.hasPermission(
              primaryMenuRes,
              "Your Travel Request",
              "travel_request.create"
            ) && (
              <a
                href
                className="btn btn-dark btn-circle"
                variant="dark"
                onClick={handleAddClick}
              >
                <AddCircle />
              </a>
            )}
          </div>

          <RB.Card.Header>
            <h4></h4>
            <div className="card-header-action"></div>
          </RB.Card.Header>
          <RB.Card.Body>
            <RB.Row>
              <RB.Col md={4}>
                <RB.Form.Select
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  {statusOptions.map((status, index) => (
                    <option key={index} value={status}>
                      {status || "Select Status"}
                    </option>
                  ))}
                </RB.Form.Select>
              </RB.Col>
            </RB.Row>
            {utils.hasPermission(
              primaryMenuRes,
              "Your Travel Request",
              "travel_request.view"
            ) && (
              <>
                <DataTable
                  columns={columns}
                  data={filteredData}
                  highlightOnHover
                  responsive
                  striped
                  pagination
                  paginationServer
                  paginationTotalRows={travelList.total}
                  paginationPerPage={countPerPage}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  paginationComponent={() => (
                    <CustomPagination
                      currentPage={page}
                      totalRows={travelList.total}
                      rowsPerPage={countPerPage}
                      onPageChange={handlePageChange}
                    />
                  )}
                  onChangePage={(page) => setPage(page)}
                  persistTableHead
                  customStyles={{
                    head: {
                      style: {
                        fontSize: "0.875rem", // Adjust the font size as needed
                        fontWeight: "bold", // Adjust the font weight as needed
                      },
                    },
                  }}
                  // subHeader
                  // subHeaderComponent={subHeaderComponent}
                />
              </>
            )}
          </RB.Card.Body>
        </RB.Card>
      </div>
      <RB.Modal show={show} onHide={handleClose} size="lg">
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Travel Tickets</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          <RB.Row className="justify-content-center">
            {selectedTravelRequest?.travelTicket?.map((ticket) => {
              const fileExtension = ticket.ticket_image
                .split(".")
                .pop()
                .toLowerCase();
              const isImage = ["jpg", "jpeg", "png", "gif"].includes(
                fileExtension
              );
              const isPDF = fileExtension === "pdf";
              const isDoc = ["doc", "docx", "document"].includes(fileExtension);

              return (
                <RB.Col
                  key={ticket.id}
                  md={4}
                  className="d-flex justify-content-center ticket-col"
                >
                  <div className="position-relative ticket-wrapper">
                    <p>{ticket.headName}</p>

                    {isImage && (
                      <>
                        <a
                          href={ticket.ticketPath}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={ticket.ticketPath}
                            alt="ticket"
                            className="ticket-image"
                          />
                        </a>

                        <RB.Button
                          variant="primary"
                          className="btn btn-sm btn-circle download-btn"
                          onClick={() => handleDownload(ticket.ticketPath)}
                        >
                          <i className="material-icons-outlined">
                            file_download
                          </i>
                        </RB.Button>
                      </>
                    )}

                    {isPDF && (
                      <a
                        href={ticket.ticketPath}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="material-icons-outlined md-128">
                          picture_as_pdf
                        </i>
                      </a>
                    )}

                    {isDoc && (
                      <a
                        href={ticket.ticketPath}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DocumentText size={70} />
                      </a>
                    )}
                  </div>
                </RB.Col>
              );
            })}
          </RB.Row>
        </RB.Modal.Body>
        <RB.Modal.Footer>
          <RB.Button variant="secondary" onClick={handleClose}>
            Close
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>
      <ReasonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        text={modalText}
      />
    </>
  );
}

export default List;
