import * as RB from "react-bootstrap";
import React, { useEffect, useState } from "react";
import * as utils from "../../Utils/comman";
import { useLoader } from "../../Loader";
import { useSelector } from "react-redux";

function List() {
  const [entitlements, setEntitlements] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [empList, setEmpList] = useState([]);
  const [isHeadLoaded, setIsHeadLoaded] = useState(false);
  const [modifiedEmpList, setModifiedEmpList] = useState([]);
  const [editData, setEditData] = useState([]);
  const [newEditData, setNewEditData] = useState([]);
  const { startLoader, stopLoader } = useLoader();

  const [reCallApi, setReCallApi] = useState(false);

  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  useEffect(() => {
    getTraelEditData();
    getTravelAllowance();
  }, []);

  useEffect(() => {
    if (isHeadLoaded) {
      getEmployeeList();
    }
  }, [isHeadLoaded, filterText, editData]);

  const getTraelEditData = () => {
    startLoader();

    utils
      .callAPI("get", `/travel-permission/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setEditData(res);
          setReCallApi(true);
        } else {
          setEditData([]);
          stopLoader();
        }
      })
      .catch((err) => {
        setEditData([]);
        stopLoader();
      });
  };

  useEffect(() => {
    if (reCallApi) {
      getTravelAllowance();
    }
  }, [reCallApi]);

  const getTravelAllowance = () => {
    startLoader();
    utils
      .callAPI("get", `/travel-head/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          const updatedEntitlements = res.map((head) => ({
            ...head,
            subheads: head.subheads.map((subhead) => {
              const newSubhead = {
                ...subhead,
                class: subhead.class.map((cls) => ({
                  ...cls,
                  ischecked: false,
                })),
              };
              switch (subhead.sub_head_name) {
                case "Flight":
                  newSubhead.class = newSubhead.class.map((cls) =>
                    cls.sub_head_name === "Economy Class"
                      ? { ...cls, ischecked: false }
                      : cls
                  );
                  break;
                case "Train":
                  const checkedClasses = [
                    "Executive Chair Car, Sleeper",
                    "III Tier",
                    "II Tier",
                  ];
                  newSubhead.class = newSubhead.class.map((cls) =>
                    checkedClasses.includes(cls.sub_head_name)
                      ? { ...cls, ischecked: false }
                      : cls
                  );
                  break;
                case "Car":
                case "Bike":
                  newSubhead.value = "";
                  break;
                case "Daily":
                case "Monthly":
                  newSubhead.value = "";
                  break;
                case "AC Bus":
                case "Non AC Bus":
                case "Taxi":
                case "Auto":
                  newSubhead.ischecked = false;
                  break;
                default:
                  break;
              }
              return newSubhead;
            }),
          }));

          const updatedHeads = updatedEntitlements.map((head) => {
            switch (head.head_name) {
              case "Misc. Monthly Limit":
              case "Hotel Expense":
              case "Food Expense":
                head.value = "";
                break;
              default:
                break;
            }
            return head;
          });

          setEntitlements(updatedHeads);
          setIsHeadLoaded(true);
        } else {
          setEntitlements([]);
          stopLoader();
        }
      })
      .catch((err) => {
        setEntitlements([]);
        stopLoader();
      });
  };

  const getEmployeeList = () => {
    startLoader();
    utils
      .callAPI(
        "get",
        `/employee/list?search=${filterText}&page=0&per_page=0&departmentId=&designationId=`
      )
      .then((res) => {
        if (typeof res !== "undefined" && res?.result.length !== 0) {
          console.log("first");
          const updatedEmpList = res.result.map((emp) => {
            const matchingEditData = editData.find(
              (editItem) => editItem.user_id === emp.employeeId
            );

            const mergedEntitlements = matchingEditData
              ? mergeEntitlements(entitlements, matchingEditData.permissions)
              : JSON.parse(JSON.stringify(entitlements));

            return {
              employeeId: emp.employeeId,
              name: `${emp.firstName} ${
                emp.middleName ? emp.middleName + " " : ""
              }${emp.lastName}`,
              entitlements: mergedEntitlements,
            };
          });
          console.log(updatedEmpList);
          setEmpList(updatedEmpList);

          const modifiedEmployees = updatedEmpList.filter((emp) => {
            const matchingEditData = editData.find(
              (editItem) => editItem.user_id === emp.employeeId
            );
            return !!matchingEditData;
          });
          setIsHeadLoaded(false);
          setReCallApi(false);
          stopLoader();
        } else {
          setEmpList([]);
          stopLoader();
        }
      })
      .catch((err) => {
        setEmpList([]);
        stopLoader();
      });
  };

  const mergeEntitlements = (entitlements, permissions) => {
    const updatedEntitlements = JSON.parse(JSON.stringify(entitlements));

    permissions.forEach((permission) => {
      const headIndex = updatedEntitlements.findIndex(
        (ent) => ent.head_id === permission.head_id
      );

      if (headIndex !== -1) {
        const head = updatedEntitlements[headIndex];

        if (permission.sub_head_id !== "0") {
          const subheadIndex = head.subheads.findIndex(
            (sub) => sub.sub_head_id === permission.sub_head_id
          );

          if (subheadIndex !== -1) {
            const subhead = head.subheads[subheadIndex];

            if (permission.classId && permission.classId !== "0") {
              const classIndex = subhead.class.findIndex(
                (cls) => cls.class_id === permission.classId
              );

              if (classIndex !== -1) {
                subhead.class[classIndex].ischecked = true;
              }
            } else if (permission.classId === "0" && permission.value === "") {
              subhead.ischecked = true;
            }

            if (permission.value !== "") {
              subhead.value = permission.value;
            }
          }
        } else if (permission.value !== "") {
          head.value = permission.value;
        }
      }
    });

    return updatedEntitlements;
  };

  // checkbox on chnage
  const handleCheckboxChange = (
    categoryIndex,
    subcategoryIndex,
    optionIndex,
    userIndex
  ) => {
    const newEmpList = [...empList];
    newEmpList[userIndex].entitlements[categoryIndex].subheads[
      subcategoryIndex
    ].class[optionIndex].ischecked =
      !newEmpList[userIndex].entitlements[categoryIndex].subheads[
        subcategoryIndex
      ].class[optionIndex].ischecked;

    const newModifiedEmpList = [...modifiedEmpList];
    newModifiedEmpList[userIndex] = newEmpList[userIndex];

    const editEmp = newModifiedEmpList.filter(
      (item) => item !== null && item !== undefined
    );

    const newPermission = {
      head_id: newEmpList[userIndex].entitlements[categoryIndex].head_id,
      sub_head_id:
        newEmpList[userIndex].entitlements[categoryIndex].subheads[
          subcategoryIndex
        ].sub_head_id,
      classId:
        newEmpList[userIndex].entitlements[categoryIndex].subheads[
          subcategoryIndex
        ].class[optionIndex].class_id,
      value: "",
      action: "add", // Default action is "add"
    };

    const editEmpData = editData.find(
      (a) => a.user_id == editEmp[0].employeeId
    );

    if (editEmpData) {
      const exists = editEmpData.permissions.some(
        (perm) => perm.classId === newPermission.classId
      );

      if (exists) {
        newPermission.action = "remove";
      }

      setNewEditData((prevState) => {
        const employeeIndex = prevState.findIndex(
          (emp) => emp.user_id === editEmp[0].employeeId
        );

        if (employeeIndex !== -1) {
          const updatedPermissions = [...prevState];
          const existingPermissionIndex = updatedPermissions[
            employeeIndex
          ].permissions.findIndex(
            (perm) => perm.classId === newPermission.classId
          );

          if (existingPermissionIndex !== -1) {
            updatedPermissions[employeeIndex].permissions[
              existingPermissionIndex
            ] = newPermission;
          } else {
            updatedPermissions[employeeIndex].permissions.push(newPermission);
          }

          return updatedPermissions;
        } else {
          return [
            ...prevState,
            { user_id: editEmp[0].employeeId, permissions: [newPermission] },
          ];
        }
      });
    } else {
      setModifiedEmpList(newModifiedEmpList);
    }

    setEmpList(newEmpList);
  };

  const handleSubHeadeCheckboxChange = (
    categoryIndex,
    subcategoryIndex,
    optionIndex,
    userIndex
  ) => {
    const newEmpList = [...empList];
    newEmpList[userIndex].entitlements[categoryIndex].subheads[
      subcategoryIndex
    ].ischecked =
      !newEmpList[userIndex].entitlements[categoryIndex].subheads[
        subcategoryIndex
      ].ischecked;

    const newModifiedEmpList = [...modifiedEmpList];
    newModifiedEmpList[userIndex] = newEmpList[userIndex];

    const editEmp = newModifiedEmpList.filter(
      (item) => item !== null && item !== undefined
    );

    const editEmpData = editData.find(
      (a) => a.user_id == editEmp[0].employeeId
    );

    const newPermission = {
      head_id: newEmpList[userIndex].entitlements[categoryIndex].head_id,
      sub_head_id:
        newEmpList[userIndex].entitlements[categoryIndex].subheads[
          subcategoryIndex
        ].sub_head_id,
      classId: 0, // Using 0 for classId for this scenario
      value: "",
      action: "add", // Default action is "add"
    };

    if (editEmpData) {
      const exists = editEmpData.permissions.some(
        (perm) =>
          perm.head_id === newPermission.head_id &&
          perm.sub_head_id === newPermission.sub_head_id
      );

      if (exists) {
        newPermission.action = "remove";
      }

      setNewEditData((prevState) => {
        const employeeIndex = prevState.findIndex(
          (emp) => emp.user_id === editEmp[0].employeeId
        );

        if (employeeIndex !== -1) {
          const updatedPermissions = [...prevState];
          const existingPermissionIndex = updatedPermissions[
            employeeIndex
          ].permissions.findIndex(
            (perm) =>
              perm.head_id === newPermission.head_id &&
              perm.sub_head_id === newPermission.sub_head_id &&
              perm.classId === newPermission.classId
          );

          if (existingPermissionIndex !== -1) {
            updatedPermissions[employeeIndex].permissions[
              existingPermissionIndex
            ] = newPermission;
          } else {
            updatedPermissions[employeeIndex].permissions.push(newPermission);
          }

          return updatedPermissions;
        } else {
          return [
            ...prevState,
            { user_id: editEmp[0].employeeId, permissions: [newPermission] },
          ];
        }
      });
    } else {
      setModifiedEmpList(newModifiedEmpList);
    }

    setEmpList(newEmpList);
  };

  // input on chnage
  const handleInputChange = (categoryIndex, optionIndex, value, userIndex) => {
    const newEmpList = [...empList];
    newEmpList[userIndex].entitlements[categoryIndex].value = value;

    const newModifiedEmpList = [...modifiedEmpList];
    newModifiedEmpList[userIndex] = newEmpList[userIndex];

    const editEmp = newModifiedEmpList.filter(
      (item) => item !== null && item !== undefined
    );

    const editEmpData = editData.find(
      (a) => a.user_id === editEmp[0].employeeId
    );

    let action = "update";
    if (value === "0" || value === "") {
      action = "remove";
    }

    const newPermission = {
      head_id: newEmpList[userIndex].entitlements[categoryIndex].head_id,
      sub_head_id: 0,
      classId: 0,
      value: value,
      action: action,
    };

    if (!editEmpData) {
      action = "add";
    } else {
      const existingPermission = editEmpData.permissions.find(
        (perm) => perm.head_id === newPermission.head_id
      );
      if (!existingPermission) {
        action = "add";
      }
    }

    newPermission.action = action;

    if (editEmpData) {
      setNewEditData((prevState) => {
        const employeeIndex = prevState.findIndex(
          (emp) => emp.user_id === editEmp[0].employeeId
        );

        if (employeeIndex !== -1) {
          const updatedPermissions = [...prevState];
          const permissionIndex = updatedPermissions[
            employeeIndex
          ].permissions.findIndex(
            (perm) => perm.head_id === newPermission.head_id
          );

          if (permissionIndex !== -1) {
            updatedPermissions[employeeIndex].permissions[permissionIndex] =
              newPermission;
          } else {
            updatedPermissions[employeeIndex].permissions.push(newPermission);
          }

          return updatedPermissions;
        } else {
          return [
            ...prevState,
            { user_id: editEmp[0].employeeId, permissions: [newPermission] },
          ];
        }
      });
    } else {
      setModifiedEmpList(newModifiedEmpList);
    }

    setEmpList(newEmpList);
  };

  const handleSubcategoryInputChange = (
    categoryIndex,
    subcategoryIndex,
    value,
    userIndex
  ) => {
    const newEmpList = [...empList];
    newEmpList[userIndex].entitlements[categoryIndex].subheads[
      subcategoryIndex
    ].value = value;

    const newModifiedEmpList = [...modifiedEmpList];
    newModifiedEmpList[userIndex] = newEmpList[userIndex];
    const editEmp = newModifiedEmpList.filter(
      (item) => item !== null && item !== undefined
    );

    const editEmpData = editData.find(
      (a) => a.user_id == editEmp[0].employeeId
    );

    let action = "update";
    if (value == "0" || value == "") {
      action = "remove";
    }

    const newPermission = {
      head_id: newEmpList[userIndex].entitlements[categoryIndex].head_id,
      sub_head_id:
        newEmpList[userIndex].entitlements[categoryIndex].subheads[
          subcategoryIndex
        ].sub_head_id,
      classId: 0,
      value: value,
      action: action,
    };

    if (!editEmpData) {
      action = "add";
    } else {
      const existingPermission = editEmpData.permissions.find(
        (perm) =>
          perm.head_id === newPermission.head_id &&
          perm.sub_head_id === newPermission.sub_head_id
      );
      if (!existingPermission) {
        action = "add";
      }
    }

    newPermission.action = action;

    if (editEmpData) {
      setNewEditData((prevState) => {
        const employeeIndex = prevState.findIndex(
          (emp) => emp.user_id === editEmp[0].employeeId
        );

        if (employeeIndex !== -1) {
          const updatedPermissions = [...prevState];
          const permissionIndex = updatedPermissions[
            employeeIndex
          ].permissions.findIndex(
            (perm) =>
              perm.head_id === newPermission.head_id &&
              perm.sub_head_id === newPermission.sub_head_id
          );

          if (permissionIndex !== -1) {
            updatedPermissions[employeeIndex].permissions[permissionIndex] =
              newPermission;
          } else {
            updatedPermissions[employeeIndex].permissions.push(newPermission);
          }

          return updatedPermissions;
        } else {
          return [
            ...prevState,
            { user_id: editEmp[0].employeeId, permissions: [newPermission] },
          ];
        }
      });
    } else {
      setModifiedEmpList(newModifiedEmpList);
    }
    setEmpList(newEmpList);
  };

  const createSaveArray = (modifiedEmpList) => {
    const result = [];

    modifiedEmpList.forEach((user) => {
      const userObj = {
        user_id: user.employeeId,
        permissions: [],
      };

      const userEditData = getEditPermissions(0);

      user.entitlements.forEach((entitlement) => {
        entitlement.subheads.forEach((subhead) => {
          if (subhead.class && subhead.class.length > 0) {
            subhead.class.forEach((classItem) => {
              const editPermission = getEditPermission(
                userEditData,
                entitlement.head_id,
                subhead.sub_head_id,
                classItem.class_id
              );

              if (editPermission) {
                if (classItem.ischecked.toString() !== editPermission.action) {
                  userObj.permissions.push({
                    head_id: entitlement.head_id,
                    sub_head_id: subhead.sub_head_id,
                    classId: classItem.class_id,
                    value: subhead.value || "",
                    action: classItem.ischecked ? "add" : "remove",
                  });
                }
              } else if (classItem.ischecked) {
                userObj.permissions.push({
                  head_id: entitlement.head_id,
                  sub_head_id: subhead.sub_head_id,
                  classId: classItem.class_id,
                  value: subhead.value || "",
                  action: "add",
                });
              }
            });
          } else {
            const editPermission = getEditPermission(
              userEditData,
              entitlement.head_id,
              subhead.sub_head_id
            );

            const hasValueChanged = subhead.value !== editPermission?.value;
            const hasCheckChanged =
              subhead.ischecked !== Boolean(editPermission);

            if (editPermission) {
              if (hasCheckChanged || hasValueChanged) {
                userObj.permissions.push({
                  head_id: entitlement.head_id,
                  sub_head_id: subhead.sub_head_id,
                  value: subhead.value || "",
                  action: subhead.ischecked ? "add" : "remove",
                  classId: 0,
                });
              }
            } else if (subhead.ischecked || subhead.value) {
              userObj.permissions.push({
                head_id: entitlement.head_id,
                sub_head_id: subhead.sub_head_id,
                value: subhead.value || "",
                action: "add",
                classId: 0,
              });
            }
          }
        });

        const editPermission = getEditPermission(
          userEditData,
          entitlement.head_id
        );

        if (editPermission) {
          if (entitlement.value !== editPermission.value) {
            userObj.permissions.push({
              head_id: entitlement.head_id,
              value: entitlement.value,
              action: entitlement.value ? "update" : "remove",
              sub_head_id: 0,
              classId: 0,
            });
          }
        } else if (entitlement.value) {
          userObj.permissions.push({
            head_id: entitlement.head_id,
            value: entitlement.value,
            action: "add",
            sub_head_id: 0,
            classId: 0,
          });
        }
      });

      // Only add the userObj to result if it has permissions
      if (userObj.permissions.length > 0) {
        result.push(userObj);
      }
    });

    return result;
  };

  const getEditPermissions = (userId) => {
    const userEditData = editData.find(
      (editItem) => editItem.user_id === userId
    );
    return userEditData ? userEditData.permissions : [];
  };

  const getEditPermission = (
    userEditData,
    headId,
    subHeadId = 0,
    classId = 0
  ) => {
    return userEditData.find(
      (perm) =>
        perm.head_id === headId &&
        perm.sub_head_id === subHeadId &&
        perm.classId === classId
    );
  };

  const saveModifiedData = () => {
    let filteredArr = modifiedEmpList.filter(
      (item) => item !== null && item !== undefined
    );

    const saveArray = createSaveArray(filteredArr);
    const mergedData = [...newEditData, ...saveArray];
    console.log(mergedData);
    if (mergedData.length == 0) {
      return;
    }
    startLoader();
    var bodyFormData = new FormData();
    bodyFormData.append("data", JSON.stringify(mergedData));
    utils
      .callAPI("post", `/travel-permission/save`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          setModifiedEmpList([]);
          setNewEditData([]);
          getTraelEditData();
          getTravelAllowance();
          stopLoader();
        } else {
          stopLoader();
        }
      })
      .catch((err) => {
        stopLoader();
      });
  };

  return (
    <div className="section-body">
      <RB.Card className="card shadow">
        <RB.Card.Header>
          <h4></h4>
          {utils.hasPermission(
            primaryMenuRes,
            "Allownce Master",
            "travel_allownce.create"
          ) && (
            <div className="card-header-action">
              <RB.Button onClick={saveModifiedData}>Update Allownce</RB.Button>
            </div>
          )}
        </RB.Card.Header>
        <RB.Card.Body>
          {utils.hasPermission(
            primaryMenuRes,
            "Allownce Master",
            "travel_allownce.view"
          ) && (
            <RB.Row className="align-items-center">
              <RB.Col className="col-12">
                <div style={{ overflowX: "auto" }}>
                  <RB.Table striped bordered>
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>User Name</th>
                        {empList[0]?.entitlements?.map(
                          (entitlement, categoryIndex) => (
                            <React.Fragment key={categoryIndex}>
                              <th
                                colSpan={
                                  entitlement.subheads.length > 0
                                    ? entitlement.subheads.reduce(
                                        (acc, subhead) =>
                                          acc + (subhead.class.length || 1),
                                        0
                                      )
                                    : 1
                                }
                              >
                                {entitlement.head_name}
                              </th>
                            </React.Fragment>
                          )
                        )}
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <RB.Form.Control
                            type="text"
                            placeholder="Search..."
                            value={filterText}
                            onChange={(e) => {
                              setFilterText(e.target.value);
                              setIsHeadLoaded(true);
                            }}
                            style={{ width: "150px" }}
                          />
                        </td>
                        {empList[0]?.entitlements?.map(
                          (entitlement, categoryIndex) => (
                            <React.Fragment key={categoryIndex}>
                              {entitlement.subheads.length > 0 ? (
                                entitlement.subheads.map(
                                  (subhead, subheadIndex) => (
                                    <React.Fragment
                                      key={`${categoryIndex}-${subheadIndex}`}
                                    >
                                      {subhead.class.length !== 0 ? (
                                        subhead.class.map((cls, index) => (
                                          <th
                                            key={`${categoryIndex}-${subheadIndex}-${index}`}
                                          >
                                            {subhead.sub_head_name} (
                                            {cls.sub_head_name})
                                          </th>
                                        ))
                                      ) : (
                                        <th key={subheadIndex}>
                                          {["Car", "Bike"].includes(
                                            subhead.sub_head_name
                                          )
                                            ? `${subhead.sub_head_name}/km`
                                            : subhead.sub_head_name}
                                        </th>
                                      )}
                                    </React.Fragment>
                                  )
                                )
                              ) : (
                                <th key={categoryIndex}></th>
                              )}
                            </React.Fragment>
                          )
                        )}
                      </tr>
                    </thead>
                    {empList.length !== 0 ? (
                      <>
                        <tbody>
                          {empList.map((emp, userIndex) => (
                            <tr key={emp.employeeId}>
                              <td>{userIndex + 1}</td>
                              <td>{emp.name}</td>
                              {emp.entitlements.map(
                                (entitlement, categoryIndex) => (
                                  <React.Fragment key={categoryIndex}>
                                    {entitlement.subheads.length > 0 ? (
                                      entitlement.subheads.map(
                                        (subhead, subheadIndex) => (
                                          <React.Fragment
                                            key={`${categoryIndex}-${subheadIndex}`}
                                          >
                                            {subhead.class.length !== 0 ? (
                                              subhead.class.map(
                                                (cls, index) => (
                                                  <td
                                                    key={`${categoryIndex}-${subheadIndex}-${index}`}
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      checked={cls.ischecked}
                                                      onChange={() =>
                                                        handleCheckboxChange(
                                                          categoryIndex,
                                                          subheadIndex,
                                                          index,
                                                          userIndex
                                                        )
                                                      }
                                                    />
                                                  </td>
                                                )
                                              )
                                            ) : (
                                              <td key={subheadIndex}>
                                                {subhead.sub_head_name ===
                                                  "Car" ||
                                                subhead.sub_head_name ===
                                                  "Bike" ||
                                                subhead.sub_head_name ===
                                                  "Daily" ||
                                                subhead.sub_head_name ===
                                                  "Monthly" ? (
                                                  <input
                                                    type="text"
                                                    value={subhead.value}
                                                    onChange={(e) => {
                                                      const value =
                                                        e.target.value;
                                                      if (/^\d*$/.test(value)) {
                                                        handleSubcategoryInputChange(
                                                          categoryIndex,
                                                          subheadIndex,
                                                          value,
                                                          userIndex
                                                        );
                                                      }
                                                    }}
                                                    style={{ width: "70px" }}
                                                  />
                                                ) : (
                                                  <>
                                                    <input
                                                      type="checkbox"
                                                      checked={
                                                        subhead.ischecked
                                                      }
                                                      onChange={(e) =>
                                                        handleSubHeadeCheckboxChange(
                                                          categoryIndex,
                                                          subheadIndex,
                                                          e.target.value,
                                                          userIndex
                                                        )
                                                      }
                                                    />
                                                  </>
                                                )}
                                              </td>
                                            )}
                                          </React.Fragment>
                                        )
                                      )
                                    ) : (
                                      <td key={categoryIndex}>
                                        {entitlement.head_name ===
                                          "Misc. Monthly Limit" ||
                                        entitlement.head_name ===
                                          "Hotel Expense" ||
                                        entitlement.head_name ===
                                          "Food Expense" ? (
                                          <input
                                            type="text"
                                            value={entitlement.value}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              if (/^\d*$/.test(value)) {
                                                handleInputChange(
                                                  categoryIndex,
                                                  0,
                                                  value,
                                                  userIndex
                                                );
                                              }
                                            }}
                                            style={{ width: "70px" }}
                                          />
                                        ) : null}
                                      </td>
                                    )}
                                  </React.Fragment>
                                )
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </>
                    ) : (
                      <>
                        <tbody>
                          <tr>
                            <td colSpan={2} className="text-center">
                              No employee found.
                            </td>
                          </tr>
                        </tbody>
                      </>
                    )}
                  </RB.Table>
                </div>
              </RB.Col>
            </RB.Row>
          )}
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
