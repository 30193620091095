import React, { useState, useEffect } from 'react';
import Header from './Components/Header';
import Sidebar from './Components/Sidebar';
const Layout = ({ children }) => {
    return (
        <>
            <div id="app">
                <div className='main-wrapper'>
                    <Header />
                    <Sidebar />
                    {children}
                </div>
            </div>
        </>
    );
}
export default Layout