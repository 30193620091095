import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, Visibility } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import { ArrowDown, ArrowDown2, DocumentDownload, Eye } from "iconsax-react";
import DatePicker from "react-datepicker";
import * as XLSX from "xlsx";
import CustomPagination from '../../Utils/CustomPagination'


function Transactions(props) {
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterReset, setFilterReset] = useState(false);
  const [empList, setEmpList] = useState([]);
  const getFormattedMonthYear = (date) => {
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${month}-${year}`;
  };
  const [mobileFilter, setMobileFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedStatus, setselectedStatus] = useState("success");
  const [amt, setAmt] = useState(0);
  const [isFinding, setIsFinding] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const columns = [
    {
      name: "Mobile No.",
      selector: (row) => row.mobileNo,
      width: "110px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY HH:mm:ss"),
    },

    {
      name: "Amount",
      selector: (row) => (row?.debit ? Number(row.debit).toFixed(2) : "0.00"),
      right: true,
    },
  ];

  useEffect(() => {
    getTransactionHistory();
  }, [page]);

  const getTransactionHistory = () => {
    utils
      .callAPI(
        "get",
        `Client/transaction?page=${page}&per_page=${countPerPage}&date=${
          selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : ""
        }&status=${selectedStatus}&mobile=${mobileFilter}&name=${nameFilter}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setTransactionHistory(res);
          setAmt(res?.amt_total);
          setIsFinding(false);
          setIsReset(false);
        } else {
          setTransactionHistory([]);
          setIsFinding(false);
          setIsReset(false);
        }
      })
      .catch((err) => {
        setTransactionHistory([]);
        setIsFinding(false);
        setIsReset(false);
      });
  };


  function handleDownload() {
    utils
      .callAPI(
        "get",
        `/Client/transaction-download?date=${
          selectedDate ? moment(selectedDate).format("YYYY-MM-DD") : ""
        }&status=${selectedStatus}&mobile=${mobileFilter}&name=${nameFilter}`
      )
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          generateCSV(res);
        } else {
          setCsvData([]);
        }
      })
      .catch((err) => {
        setCsvData([]);
      });
  }
  function generateCSV(filteredData) {
    const formattedData = filteredData.map((row, index) => {
      if (index === 0) {
        return row;
      }

      const amount = parseFloat(row[3].replace(/,/g, ""));

      return [row[0], row[1], row[2], amount];
    });

    const ws = XLSX.utils.aoa_to_sheet(formattedData);

    const amountColumnIndex = 3;
    const numRows = formattedData.length;

    for (let rowIndex = 1; rowIndex < numRows; rowIndex++) {
      const cellAddress = XLSX.utils.encode_cell({
        c: amountColumnIndex,
        r: rowIndex,
      });

      if (ws[cellAddress]) {
        ws[cellAddress].v = formattedData[rowIndex][amountColumnIndex];
        ws[cellAddress].z = "0.00";
      }
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions");

    const excelFile = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    const blob = new Blob([s2ab(excelFile)], {
      type: "application/octet-stream",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${moment(selectedDate).format(
      "DD-MM-YYYY"
    )}_transactions.xlsx`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  const handleFindClick = () => {
    setIsFinding(true);
  };

  const handleResetClick = () => {
    setIsFinding(false);
    setMobileFilter("");
    setNameFilter("");
    setSelectedDate(new Date());
    setselectedStatus("success");
    // getTransactionHistory();
    setIsReset(true);
  };

  useEffect(() => {
    if (isFinding) {
      getTransactionHistory();
    }
    if (isReset) {
      getTransactionHistory();
    }
  }, [isFinding, isReset]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="section-body">
      {utils.hasPermission(primaryMenuRes, "Transactions", "transactions") && (
        <RB.Card className="card">
          <div className="floating-btns-holder-artisan">
            <a
              href
              className={`btn btn-primary btn-circle me-2 ${
                transactionHistory?.result?.length === 0 ? "disabled" : ""
              }`}
              variant="primary"
              onClick={() => handleDownload()}
            >
              <DocumentDownload />
            </a>
          </div>
          <RB.Card.Body>
            <RB.Row className="justify-content-between">
              <RB.Col className="d-flex col-6">
                <RB.Col className="col-4 me-2">
                  <RB.Form.Control
                    type="text"
                    placeholder="Enter Name"
                    aria-label="Default select example"
                    value={nameFilter}
                    onChange={(e) => {
                      setNameFilter(e.target.value);
                    }}
                  ></RB.Form.Control>
                </RB.Col>
                <RB.Col className="col-4 me-2">
                  <RB.Form.Control
                    type="text"
                    placeholder="Enter MobileNo"
                    aria-label="Default select example"
                    value={mobileFilter}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        setMobileFilter(value);
                      }
                    }}
                  ></RB.Form.Control>
                </RB.Col>
                <RB.Col className="col-4 me-2">
                  <RB.Form.Select
                    aria-label="Default select example"
                    value={selectedStatus}
                    onChange={(e) => {
                      setselectedStatus(e.target.value);
                    }}
                  >
                    <option value="">All</option>
                    {/* <option value="processing">Processing</option> */}
                    <option value="success">Success</option>
                    <option value="fail">Fail</option>
                  </RB.Form.Select>
                </RB.Col>

                <RB.Col className="col-5">
                  <DatePicker
                    selected={selectedDate}
                    onChange={(date) => {
                      setSelectedDate(date);
                    }}
                    placeholderText="Select Date"
                    className="form-control"
                    maxDate={new Date()}
                    //   isClearable
                    dateFormat="dd-MM-yyyy"
                  />
                </RB.Col>
              </RB.Col>

              <RB.Col className="col-auto">
                <RB.Button variant="primary" onClick={handleFindClick}>
                  Find
                </RB.Button>
                <RB.Button
                  variant="secondary"
                  onClick={handleResetClick}
                  className="ms-2"
                >
                  Reset
                </RB.Button>
              </RB.Col>
            </RB.Row>

            <DataTable
              columns={columns}
              data={transactionHistory.result}
              highlightOnHover
              responsive
              pagination
              striped
              paginationServer
              paginationTotalRows={transactionHistory.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              paginationComponent={() => (
                <CustomPagination
                  currentPage={page}
                  totalRows={transactionHistory.total}
                  rowsPerPage={countPerPage}
                  onPageChange={handlePageChange}
                />
              )}
              // paginationComponent={({ paginationProps }) => (
              //   <CustomPagination
              //     amtTotal={amt}
              //     paginationProps={paginationProps}
              //   />
              // )}
              onChangePage={(page) => setPage(page)}
              persistTableHead
              customStyles={{
                head: {
                  style: {
                    fontSize: "0.875rem",
                    fontWeight: "bold",
                  },
                },
              }}
            />
            {amt ? (
              <>
                <RB.Row>
                  <RB.Col className="d-flex justify-content-between align-items-center">
                    <h5>
                      <strong>Total Amount: </strong>
                      {amt.toFixed(2)}
                    </h5>
                  </RB.Col>
                </RB.Row>
              </>
            ) : (
              ""
            )}
          </RB.Card.Body>
        </RB.Card>
      )}
    </div>
  );
}

export default Transactions;
