import { AddCircle, Delete } from "@mui/icons-material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as utils from "../../Utils/comman";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setEmpSalary } from "../../reducers/employeeSalarySlice";

function Add(props) {
  const dispatch = useDispatch();
  const [SalaryTableData, settableData] = useState([]);
  const empSalary = useSelector((state) => state.empSalary.employeeSalary);
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [allowance, setallowance] = useState([]);
  const [deduction, setdeduction] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [istype, setIsType] = useState(false);
  const [isSalayaFullUpdate, setIsSalaryFullUpdate] = useState(false);
  const [formData, setFormData] = useState({
    employeeId: id ? id : "",
    ctc: "",
    payment_frequency: "",
    payment_mode: "",
    effective_date: null,
    bonus: "",
    deduction_reason: "",
    isActive: "",
    allowance: [{ aid: "", amt: "", type: "per" }],
    deduction: [{ did: "", damt: "", type: "per" }],
    variable_per: "",
  });
  const [ctcAmt, setCtcAmt] = useState("");
  const [isEditCtc, setIsEditCtc] = useState(false);
  const [selectedOption, setSelectedOption] = useState("percentage");
  const [salaryView, setSalaryView] = useState([
    {
      label: "CTC to Offer",
      textbox1: "",
      txt1Disable: true,
      textbox2: "",
      txt2Disable: false,
    },
    {
      label: "Variable Pay",
      textbox1: "10",
      txt1Disable: false,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "Balance",
      textbox1: "",
      txt1Disable: true,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "Basic Salary",
      textbox1: "40",
      txt1Disable: false,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "Basic/Month",
      textbox1: "",
      txt1Disable: true,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "Mediclaim",
      textbox1: "0",
      txt1Disable: false,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "Prof Tax",
      textbox1: "0",
      txt1Disable: true,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "PF",
      textbox1: "12",
      txt1Disable: false,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "HRA",
      textbox1: "50",
      txt1Disable: false,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "Gratuity",
      textbox1: "4.81",
      txt1Disable: false,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "P.F",
      textbox1: "12",
      txt1Disable: false,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "Total",
      textbox1: "",
      txt1Disable: true,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "Special Allowances",
      textbox1: "",
      txt1Disable: true,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "Actual Variable Amount",
      textbox1: "0",
      txt1Disable: false,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "Scheme Incentive",
      textbox1: "0",
      txt1Disable: false,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "ESIC",
      textbox1: "0",
      txt1Disable: false,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "TDS",
      textbox1: "0",
      txt1Disable: false,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "Advance Loan",
      textbox1: "0",
      txt1Disable: false,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "Annual Package",
      textbox1: "",
      txt1Disable: true,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "VIP (Monthly Amount)",
      textbox1: "",
      txt1Disable: true,
      textbox2: "",
      txt2Disable: true,
    },
    {
      label: "SIP Amount",
      textbox1: "30",
      txt1Disable: false,
      textbox2: "",
      txt2Disable: true,
    },
  ]);

  const [basicSalary, setBasicSalary] = useState("");
  const [CurrentBalance, setCurrentBalance] = useState("");
  const [ctc, setCtc] = useState(false);
  const status = [
    { id: "", name: "Select an option..." },
    { id: "1", name: "Active" },
    { id: "2", name: "Inactive" },
  ];

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  useEffect(() => {
    getallowance();
    getdeduction();
  }, []);

  const getallowance = () => {
    utils
      .callAPI("get", `/salary/allowance/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setallowance(res.result);
        } else {
          setallowance([]);
        }
      })
      .catch((err) => {
        setallowance([]);
      });
  };
  const getdeduction = () => {
    utils
      .callAPI("get", `/salary/deduction/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setdeduction(res.result);
        } else {
          setdeduction([]);
        }
      })
      .catch((err) => {
        setdeduction([]);
      });
  };

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      setIsEdit(true);
      const {
        id,
        employeeId,
        variable_per,
        ctc,
        allowance,
        deduction,
        gross_salary,
        net_salary,
        payment_frequency,
        payment_mode,
        effective_date,
        bonus,
        deduction_reason,
        isActive,
        basic_per,
        sip_per,
      } = props.rowData[0];

      const parsedAllowance = JSON.parse(allowance);
      const parsedDeduction = JSON.parse(deduction);
      setFormData({
        // employeeId,
        ctc,
        allowance: parsedAllowance,
        deduction: parsedDeduction,
        variable_per,
        gross_salary,
        net_salary,
        payment_frequency,
        payment_mode,
        effective_date: new Date(effective_date),
        bonus,
        deduction_reason,
        isActive,
      });
      console.log(salaryView);
      console.log(parsedAllowance);
      let basicSalaryAllowance;
      const updatedSalaryView = salaryView.map((item) => {
        switch (item.label) {
          case "Variable Pay":
            item.textbox1 = variable_per;
            break;
          case "Basic Salary":
            basicSalaryAllowance = parsedAllowance.find(
              (allowance) => allowance.name === "Basic Salary"
            );
            if (basicSalaryAllowance) {
              if (basicSalaryAllowance.type === "fix") {
                item.textbox1 = "0"; // Set the textbox1 value to "0" for fixed type
                item.textbox2 = basicSalaryAllowance.amt; // Set the amt directly
                item.txt1Disable = true;
                item.txt2Disable = false;
                setSelectedOption("fixed");
              } else {
                setIsType(false);
                item.textbox1 = basic_per; // Set the percentage
                item.txt1Disable = false;
                item.txt2Disable = true;
                setSelectedOption("percentage");
              }
            }
            break;
          case "SIP Amount":
            item.textbox1 = sip_per;
            break;
          default:
            // Find the corresponding item in parsedAllowance or parsedDeduction
            const allowanceItem = parsedAllowance.find(
              (allowance) => allowance.name === item.label
            );
            const deductionItem = parsedDeduction.find(
              (deduction) => deduction.name === item.label
            );

            if (allowanceItem) {
              item.textbox1 = allowanceItem.amt;
            } else if (deductionItem) {
              item.textbox1 = deductionItem.damt;
            }
            break;
        }

        // if (item.label === "Basic Salary" && basicSalaryAllowance && basicSalaryAllowance.type === "fix") {
        //   item.textbox1 = "0";
        // }
        return item;
      });
      const basicSalaryItem = updatedSalaryView.find(
        (item) => item.label === "Basic Salary"
      );
      if (basicSalaryItem) {
        const basicSalaryAllowance = parsedAllowance.find(
          (allowance) => allowance.name === "Basic Salary"
        );
        if (basicSalaryAllowance && basicSalaryAllowance.type === "fix") {
          basicSalaryItem.textbox1 = "0";
        }
      }
      // Set updated salaryView state
      setSalaryView(updatedSalaryView);
      if (ctc) {
        // const value = parseFloat(ctc);
        // handleCTCChange({ target: { value } });
        setIsSalaryFullUpdate(true);
      }
    } else {
      setIsEdit(false);
    }
  }, [props.rowData]);

  useEffect(() => {
    if (isSalayaFullUpdate) {
      if (isEdit) {
        const value = parseFloat(props?.rowData[0].ctc);
        handleCTCChange({ target: { value } });
      }
    }
  }, [isSalayaFullUpdate]);

  const handleBasicSalaryChange = (e) => {
    const { value, name } = e.target;
    const updatedSalaryView = [...salaryView];

    const basicSalaryItem = updatedSalaryView.find(
      (item) => item.label === name
    );

    if (selectedOption === "fixed") {
      basicSalaryItem.textbox2 = value;
      basicSalaryItem.textbox1 = "0";
    } else {
      basicSalaryItem.textbox1 = value;
    }
    handleCTCChange({ target: { value: ctcAmt } });
  };

  const handleCTCChange = (e) => {
    const { value, name } = e.target;
    const ctcAmount = parseFloat(value);
    setCtcAmt(ctcAmount);
    if (value === "" || !isNaN(ctcAmount)) {
      const variablePayPercentage =
        parseFloat(
          salaryView.find((item) => item.label === "Variable Pay").textbox1
        ) / 100;
      const variablePayAmount = ctcAmount * variablePayPercentage;
      const balance = ctcAmount - variablePayAmount;
      const basicYearPercentage =
        selectedOption === "fixed"
          ? parseFloat(
              salaryView.find((item) => item.label === "Basic Salary").textbox2
            )
          : parseFloat(
              salaryView.find((item) => item.label === "Basic Salary").textbox1
            ) / 100;
      const pfPercentage =
        parseFloat(salaryView.find((item) => item.label === "PF").textbox1) /
        100;
      const hraPercentage =
        parseFloat(salaryView.find((item) => item.label === "HRA").textbox1) /
        100;
      const gratuityPercentage =
        parseFloat(
          salaryView.find((item) => item.label === "Gratuity").textbox1
        ) / 100;
      const p_fPercentage =
        parseFloat(salaryView.find((item) => item.label === "P.F").textbox1) /
        100;
      const sipPercentage =
        parseFloat(
          salaryView.find((item) => item.label === "SIP Amount").textbox1
        ) / 100;
      const actualVariableAmount = parseFloat(
        salaryView.find((item) => item.label === "Actual Variable Amount")
          .textbox1
      );
      const schemeIncentive = parseFloat(
        salaryView.find((item) => item.label === "Scheme Incentive").textbox1
      );
      const esic = parseFloat(
        salaryView.find((item) => item.label === "ESIC").textbox1
      );
      const tds = parseFloat(
        salaryView.find((item) => item.label === "TDS").textbox1
      );
      const advanceLoan = parseFloat(
        salaryView.find((item) => item.label === "Advance Loan").textbox1
      );
      const basicYear =
        selectedOption === "fixed"
          ? basicYearPercentage
          : basicYearPercentage * balance;
      const pt = 2400;
      const medical = 0;
      const pfEmployee = pfPercentage * basicYear;
      const hra = hraPercentage * basicYear;
      const gratuity = gratuityPercentage * basicYear;
      const p_fEmployee = p_fPercentage * basicYear;
      const total = basicYear + medical + pt + pfEmployee + hra + gratuity;
      const specialAllowance = (balance - total) / 12;
      const vip = variablePayAmount / 12;
      const sip = variablePayAmount * sipPercentage;
      const basicMonth = basicYear / 12;
      const annualPackage = ctcAmount + pfEmployee + sip;
      console.log("second", salaryView);
      const updatedSalaryView = salaryView.map((item, index) => {
        switch (index) {
          case 0:
            return { ...item, textbox2: value }; //ctc
          case 1:
            return {
              ...item,
              textbox2: isNaN(variablePayAmount)
                ? ""
                : Math.round(variablePayAmount),
            }; // variable pay
          case 2:
            return {
              ...item,
              textbox2: isNaN(balance) ? "" : Math.round(balance),
            }; //balance
          case 3:
            return {
              ...item,
              textbox2: isNaN(basicYear) ? "" : Math.round(basicYear),
            }; //basic/year
          case 4:
            return {
              ...item,
              textbox2: isNaN(basicMonth) ? "" : Math.round(basicMonth),
            }; //basic/month
          case 5:
            return {
              ...item,
              textbox2: isNaN(medical) ? "" : Math.round(medical),
            }; // medical
          case 6:
            return { ...item, textbox2: isNaN(pt) ? "" : Math.round(pt) }; //pt
          case 7:
            return {
              ...item,
              textbox2: isNaN(pfEmployee) ? "" : Math.round(pfEmployee),
            }; //pf
          case 8:
            return { ...item, textbox2: isNaN(hra) ? "" : Math.round(hra) }; //hra
          case 9:
            return {
              ...item,
              textbox2: isNaN(gratuity) ? "" : Math.round(gratuity),
            }; // Set PT value
          case 10:
            return {
              ...item,
              textbox2: isNaN(p_fEmployee) ? "" : Math.round(p_fEmployee),
            }; // Set PT value
          case 11:
            return { ...item, textbox2: isNaN(total) ? "" : Math.round(total) }; // total
          case 12:
            return {
              ...item,
              textbox2: isNaN(specialAllowance)
                ? ""
                : Math.round(specialAllowance),
            }; // specialAllowance
          case 13:
            return {
              ...item,
              textbox2: isNaN(actualVariableAmount)
                ? ""
                : Math.round(actualVariableAmount),
            }; // Actual Variable Amount
          case 14:
            return {
              ...item,
              textbox2: isNaN(schemeIncentive)
                ? ""
                : Math.round(schemeIncentive),
            }; // Scheme Incentive
          case 15:
            return { ...item, textbox2: isNaN(esic) ? "" : Math.round(esic) }; // ESIC
          case 16:
            return { ...item, textbox2: isNaN(tds) ? "" : Math.round(tds) }; // TDS
          case 17:
            return {
              ...item,
              textbox2: isNaN(advanceLoan) ? "" : Math.round(advanceLoan),
            }; // Advance Loan
          case 18:
            return {
              ...item,
              textbox2: isNaN(annualPackage) ? "" : Math.round(annualPackage),
            }; //annualPackage
          case 19:
            return { ...item, textbox2: isNaN(vip) ? "" : Math.round(vip) }; //VIP
          case 20:
            return { ...item, textbox2: isNaN(sip) ? "" : Math.round(sip) }; //sip

          default:
            return item;
        }
      });

      console.log("first", updatedSalaryView);
      setSalaryView(updatedSalaryView);
    }
  };

  const handleInputChange = (e, index, type, item) => {
    const { name, value } = e.target;
    const newData = { ...formData };

    newData[name] = value;

    setFormData(newData);
  };

  const handleDropdownChange = (e, index) => {
    const { value } = e.target;
    setSelectedOption(value);
    const updatedSalaryView = [...salaryView];
    if (value === "percentage") {
      updatedSalaryView[index].textbox1 = "0";
      updatedSalaryView[index].txt1Disable = false;
      updatedSalaryView[index].textbox2 = "";
      updatedSalaryView[index].txt2Disable = true;
    } else if (value === "fixed") {
      updatedSalaryView[index].textbox1 = "0";
      updatedSalaryView[index].txt1Disable = true;
      updatedSalaryView[index].textbox2 = "";
      updatedSalaryView[index].txt2Disable = false;
    }
    setSalaryView(updatedSalaryView);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      const updatedAllowance = [];
      const updateDeduction = [];

      salaryView.forEach((item) => {
        // Process allowances
        const allowanceItem = allowance.find(
          (allowanceItem) => allowanceItem.allowance === item.label
        );
        if (allowanceItem) {
          const allowanceId = allowanceItem.id;
          let allowanceValue;
          const allowanceAmt = item.textbox2;

          // Check if the allowance is one of the specified allowances to set type to "fix"
          const fixedAllowances = [
            "Actual Variable Amount",
            "Scheme Incentive",
            "Special Allowances",
          ];
          let type;
          if (item.label === "Basic Salary") {
            // Take type from selectedOption for Basic Salary
            type = selectedOption === "fixed" ? "fix" : "per";
            // Assign allowanceValue based on the selectedOption
            allowanceValue =
              selectedOption === "fixed" ? item.textbox2 : item.textbox1;
          } else {
            type = fixedAllowances.includes(item.label) ? "fix" : "per";
            allowanceValue = item.textbox1;
          }

          updatedAllowance.push({
            aid: allowanceId,
            amt:
              item.label === "Special Allowances"
                ? allowanceAmt
                : allowanceValue,
            total: allowanceAmt,
            type: type,
          });

          setFormData((prevState) => ({
            ...prevState,
            allowance: updatedAllowance,
          }));
        }

        // Process deductions
        const deductionItem = deduction.find(
          (deductionItem) => deductionItem.deduction === item.label
        );
        if (deductionItem) {
          const deductionId = deductionItem.id;
          const deductionValue = item.textbox1;
          const deductionAmt = item.textbox2;

          // Check if the deduction is one of the specified deductions to set type to "fix"
          const fixedDeductions = ["ESIC", "TDS", "Advance Loan", "Prof Tax"];
          const type = fixedDeductions.includes(item.label) ? "fix" : "per";

          updateDeduction.push({
            did: deductionId,
            damt: item.label === "Prof Tax" ? deductionAmt : deductionValue,
            total: deductionAmt,
            type: type,
          });

          setFormData((prevState) => ({
            ...prevState,
            deduction: updateDeduction,
          }));
        }
      });

      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (props.rowData && Object.keys(props.rowData).length > 0) {
        bodyFormData.append("id", props.rowData[0].id);
      }

      bodyFormData.append("employeeId", empSalary?.id);
      // bodyFormData.append("basic_salary", formData.basic_salary);
      bodyFormData.append(
        "ctc",
        salaryView.find((item) => item.label == "CTC to Offer")?.textbox2
      );
      bodyFormData.append(
        "variable_per",
        salaryView.find((item) => item.label == "Variable Pay")?.textbox1
      );
      bodyFormData.append(
        "variable_pay",
        salaryView.find((item) => item.label == "Variable Pay")?.textbox2
      );
      bodyFormData.append(
        "balance",
        salaryView.find((item) => item.label == "Balance")?.textbox2
      );
      bodyFormData.append(
        "basic_per",
        salaryView.find((item) => item.label == "Basic Salary")?.textbox1
      );
      bodyFormData.append(
        "basic_amt",
        salaryView.find((item) => item.label == "Basic Salary")?.textbox2
      );
      bodyFormData.append(
        "special_allowance",
        salaryView.find((item) => item.label == "Special Allowances")?.textbox2
      );
      bodyFormData.append(
        "annual_package",
        salaryView.find((item) => item.label == "Annual Package")?.textbox2
      );
      bodyFormData.append(
        "vip",
        salaryView.find((item) => item.label == "VIP (Monthly Amount)")
          ?.textbox2
      );
      bodyFormData.append(
        "sip_per",
        salaryView.find((item) => item.label == "SIP Amount")?.textbox1
      );
      bodyFormData.append(
        "sip",
        salaryView.find((item) => item.label == "SIP Amount")?.textbox2
      );
      bodyFormData.append(
        "total",
        salaryView.find((item) => item.label == "Total")?.textbox2
      );
      bodyFormData.append("allowance", JSON.stringify(formData.allowance));
      bodyFormData.append("deduction", JSON.stringify(formData.deduction));
      bodyFormData.append("payment_frequency", formData.payment_frequency);
      bodyFormData.append("payment_mode", formData.payment_mode);
      bodyFormData.append(
        "effective_date",
        moment(formData.effective_date).format("YYYY-MM-DD")
      );
      bodyFormData.append("salary_increment", props.isSalaryIncrement ? '1':'0');
      // bodyFormData.append("deduction_reason", formData.deduction_reason);
      bodyFormData.append("isActive", 1);
      utils
        .callAPI("post", `/salary/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            // const updatedEmpSalary = {
            //   ...empSalary,
            //   employee_salary: [
            //     {
            //       month_year: res.month_year,
            //       // basic_salary: res.basic_salary,
            //       ctc: res.ctc,
            //       variable_per: res.variable_per,
            //       variable_pay: res.variable_pay,
            //       allowance: res.allowance,
            //       deduction: res.deduction,
            //       gross_salary: res.gross_salary,
            //       net_salary: res.net_salary,
            //       payment_frequency: res.payment_frequency,
            //       payment_mode: res.payment_mode,
            //       payment_date: res.payment_date,
            //       // bonus: res.bonus,
            //       deduction_reason: res.deduction_reason,
            //       isActive: res.isActive,
            //     },
            //   ],
            // };

            const updatedEmpSalary = {
              ...empSalary,
              employee_salary: [...(empSalary.employee_salary || []), res],
            };
            dispatch(setEmpSalary(updatedEmpSalary));
            setIsSubmit(false);
            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  const tableData = [
    { label: "Basic Salary", secondLabel: "Basic Salary" },
    { label: "House Rent Allowance", secondLabel: "HRA" },
    { label: "Special Allowances", secondLabel: "Special Allowances" },
    { label: "Total (Take Home Salary)", secondLabel: "Total" },
    { label: "Benefits", secondLabel: "" },
    { label: "Provident Fund Contribution", secondLabel: "PF" },
    { label: "Professional Tax", secondLabel: "Prof Tax" },
    { label: "Gratuity", secondLabel: "Gratuity" },
    { label: "Medical Reimbursement", secondLabel: "Mediclaim" },
    { label: "Monthly Gross", secondLabel: "MonthlyGross" },
    { label: "Provident Fund Contribution (Employer)", secondLabel: "P.F" },
    { label: "Total Gross Salary", secondLabel: "TotalGorss" },
    { label: "Bonus", secondLabel: "" },
    { label: "Variable Incentive Plan", secondLabel: "Variable Pay" },
    { label: "Special Incentive Plan", secondLabel: "SIP Amount" },
    { label: "Cost to Company (PM)", secondLabel: "costToCompany" },
  ];

  let takeHomeTotal = 0;
  let monthlyGross = 0;
  let costToCompany = 0;
  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
        <RB.Card.Header>
          <h4>{isEdit ? "Edit" : props.isSalaryIncrement ? 'Salary Increment':'Add'}</h4>
          <div className="card-header-action">
            <Link
              className="btn btn-underline"
              onClick={handleBackClick}
            >
              Back
            </Link>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
          
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Employee ID"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Employee ID"
                      name="employeeId"
                      value={formData.employeeId}
                      onChange={(e) => handleInputChange(e, null, null)}
                      required
                      disabled
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Payment Frequency"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Select
                      name="payment_frequency"
                      value={formData.payment_frequency}
                      onChange={(e) => handleInputChange(e, null, null)}
                      required
                    >
                      <option value="">Select Payment Frequency</option>
                      <option value="0">Add Monthly</option>
                      <option value="1">Quarterly</option>
                      <option value="2">Half Yearly</option>
                      <option value="3">Yearly</option>
                    </RB.Form.Select>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Payment Mode"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Select
                      name="payment_mode"
                      value={formData.payment_mode}
                      onChange={(e) => handleInputChange(e, null, null)}
                      required
                    >
                      <option value="">Select Payment Mode</option>
                      <option value="0">Bank Transfer</option>
                      <option value="1">Cheque</option>
                      <option value="2">Cash</option>
                    </RB.Form.Select>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3 mt-3">
                  <RB.FloatingLabel
                    label="Select Effective Date"
                    className={`floating-label-fancy ${
                      formData.effective_date ? "dateSelected" : "dateempty"
                    }`}
                  >
                    <DatePicker
                      selected={formData.effective_date}
                      onChange={(date) =>
                        setFormData({ ...formData, effective_date: date })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      // placeholderText="Select Payment Date"
                      minDate={new Date()}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>
            <RB.Row>
              {/* <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Deduction Reason"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="textarea"
                      placeholder="Enter Deduction Reason"
                      name="deduction_reason"
                      value={formData.deduction_reason}
                      onChange={(e) => handleInputChange(e, null, null)}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col> */}
            </RB.Row>
            <RB.Row className="g-2 row row-cols-sm-2 row-cols-md-2 row-cols-lg-3">
              {salaryView.map((item, index) => (
                <>
                  <RB.Col className="cols">
                    <RB.Form.Control
                      type="text"
                      placeholder={item.label}
                      disabled
                    />
                  </RB.Col>
                  <RB.Col className="cols">
                    <RB.InputGroup>
                      <RB.Form.Control
                        type="text"
                        placeholder={item.label}
                        name={`textbox1-${index}`}
                        disabled={item.txt1Disable}
                        value={item.textbox1}
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(/[^0-9.]/g, "");
                          if (
                            ![
                              "Actual Variable Amount",
                              "Scheme Incentive",
                              "ESIC",
                              "TDS",
                              "Advance Loan",
                            ].includes(item.label)
                          ) {
                            if (value > 100) {
                              value = 100;
                            }
                          }
                          const updatedSalaryView = [...salaryView];
                          updatedSalaryView[index].textbox1 = value;
                          setSalaryView(updatedSalaryView);
                          handleCTCChange({ target: { value: ctcAmt } });
                        }}
                        required
                      />
                      {item.label === "Basic Salary" ? (
                        <RB.InputGroup.Text
                          id={`addon-${index}`}
                          style={{ padding: "0px" }}
                        >
                          <RB.Form.Select
                            aria-label="Select percentage or fixed"
                            value={selectedOption}
                            onChange={(e) => handleDropdownChange(e, index)}
                          >
                            <option value="percentage">%</option>
                            <option value="fixed">Fixed</option>
                          </RB.Form.Select>
                        </RB.InputGroup.Text>
                      ) : item.txt1Disable ||
                        [
                          "Actual Variable Amount",
                          "Scheme Incentive",
                          "ESIC",
                          "TDS",
                          "Advance Loan",
                        ].includes(item.label) ? null : (
                        <RB.InputGroup.Text id={`addon-${index}`}>
                          %
                        </RB.InputGroup.Text>
                      )}
                    </RB.InputGroup>
                  </RB.Col>
                  <RB.Col className="cols">
                    <RB.Form.Control
                      type="text"
                      placeholder={item.label}
                      min={0}
                      value={item.textbox2}
                      disabled={item.txt2Disable}
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replace(/[^0-9.]/g, "");
                        if (item.label === "Basic Salary") {
                          handleBasicSalaryChange({
                            target: { value, name: item.label },
                          });
                        } else {
                          handleCTCChange({
                            target: { value, name: item.label },
                          });
                        }
                      }}
                    />
                  </RB.Col>
                </>
              ))}
            </RB.Row>
            {salaryView.find((item) => item.label === "CTC to Offer")
              .textbox2 ? (
              <>
                <RB.Row className="g-2 mt-2">
                  <RB.Table striped bordered hover variant="light">
                    <thead>
                      <tr>
                        <th scope="col">Compensation Details</th>
                        <th scope="col">Monthly Salary (INR)</th>
                        <th scope="col">Yearly Salary (INR)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((items, index) => {
                        let yearlySalary = 0;
                        let monthlySalary = 0;
                        if (items.secondLabel === "Special Allowances") {
                          const specialAllowanceItem = salaryView.find(
                            (item) => item.label === items.secondLabel
                          );
                          yearlySalary =
                            parseFloat(specialAllowanceItem?.textbox2) * 12 ||
                            0;
                          monthlySalary =
                            parseFloat(specialAllowanceItem?.textbox2) || 0;
                        } else if (items.secondLabel === "Total") {
                          const basicSalaryItem = salaryView.find(
                            (item) => item.label === "Basic Salary"
                          );
                          const hraItem = salaryView.find(
                            (item) => item.label === "HRA"
                          );
                          const specialAllowanceItem = salaryView.find(
                            (item) => item.label === "Special Allowances"
                          );
                          const basicSalaryYearly =
                            parseFloat(basicSalaryItem?.textbox2) || 0;
                          const hraYearly = parseFloat(hraItem?.textbox2) || 0;
                          const specialAllowanceYearly =
                            parseFloat(specialAllowanceItem?.textbox2) * 12 ||
                            0;

                          const total =
                            specialAllowanceYearly +
                            basicSalaryYearly +
                            hraYearly;
                          takeHomeTotal = Math.round(total);
                          yearlySalary = total;
                          monthlySalary = total / 12;
                        } else if (items.secondLabel === "MonthlyGross") {
                          const pf = salaryView.find(
                            (item) => item.label === "PF"
                          );
                          const pt = salaryView.find(
                            (item) => item.label === "Prof Tax"
                          );
                          const gratuity = salaryView.find(
                            (item) => item.label === "Gratuity"
                          );
                          const medical = salaryView.find(
                            (item) => item.label === "Mediclaim"
                          );
                          const total =
                            parseFloat(takeHomeTotal) +
                            parseFloat(medical.textbox2) +
                            parseFloat(pf.textbox2) +
                            parseFloat(pt.textbox2) +
                            parseFloat(gratuity.textbox2);

                          console.log(
                            "Monthly Gross",
                            takeHomeTotal,
                            medical,
                            pf,
                            pt,
                            gratuity
                          );
                          monthlyGross = total;
                          yearlySalary = total;
                          monthlySalary = total / 12;
                        } else if (items.secondLabel === "TotalGorss") {
                          const pf = salaryView.find(
                            (item) => item.label === "P.F"
                          );
                          const total =
                            parseFloat(pf.textbox2) + parseFloat(monthlyGross);

                          costToCompany = total;
                          yearlySalary = total;
                          monthlySalary = total / 12;
                        } else if (items.secondLabel === "costToCompany") {
                          const vip = salaryView.find(
                            (item) => item.label === "VIP (Monthly Amount)"
                          );
                          const sip = salaryView.find(
                            (item) => item.label === "SIP Amount"
                          );
                          const total =
                            parseFloat(vip.textbox2) * 12 +
                            parseFloat(sip.textbox2) +
                            parseFloat(costToCompany);
                          yearlySalary = total;
                          monthlySalary = total / 12;
                        } else {
                          const salaryItem = salaryView.find(
                            (item) => item.label === items.secondLabel
                          );
                          yearlySalary = parseFloat(salaryItem?.textbox2) || 0;
                          monthlySalary = yearlySalary / 12;
                        }
                        const isBold = [
                          "Benefits",
                          "Monthly Gross",
                          "Total (Take Home Salary)",
                          "Total Gross Salary",
                          "Cost to Company (PM)",
                        ].includes(items.label);
                        return (
                          <tr key={index}>
                            <td className={isBold ? "fw-bold fs-6" : ""}>
                              {items.label}
                            </td>
                            <td className={isBold ? "fw-bold fs-6" : ""}>
                              {monthlySalary == 0
                                ? "-"
                                : Math.round(monthlySalary)}
                            </td>
                            <td className={isBold ? "fw-bold fs-6" : ""}>
                              {yearlySalary == 0
                                ? "-"
                                : Math.round(yearlySalary)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </RB.Table>
                </RB.Row>
              </>
            ) : (
              ""
            )}

            <RB.Row className="justify-content-end mt-3">
              <RB.Col className="col-auto">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
