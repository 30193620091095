import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import Layout from "../../Layout";
import * as utils from "../../Utils/comman";
import * as RB from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import "./pdftable.css";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import logo from "./../../assets/images/logo.png";
import { fetchUserProfile } from "../../reducers/userProfileSlice";
import * as XLSX from "xlsx";

function SalaryView() {
  const [csvData, setCsvData] = useState([]);
  const [active, setActive] = useState(false);
  const dispatch = useDispatch();
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const csvLink = createRef();
  const tableRef = useRef();

  useEffect(() => {
    if (active) {
      setActive(false);
      csvLink.current.link.click();
    }
  }, [csvData]);

  const getFormattedMonthYear = (date) => {
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${month}-${year}`;
  };

  const [selectedMonthYear, setSelectedMonthYear] = useState(() => {
    const currentDate = new Date();
    return getFormattedMonthYear(currentDate);
  });
  const [salaryDetail, setSalary] = useState([]);
  const [filterReset, setFilterReset] = useState(false);
  const userProfile = useSelector((state) => state.userProfile.userProfile);

  useEffect(() => {
    if (!userProfile) {
      dispatch(fetchUserProfile());
    }
  }, [userProfile]);

  useEffect(() => {
    getSalary();
  }, [userProfile?.employeeId]);
  const getSalary = () => {
    utils
      .callAPI(
        "get",
        `/salary/payslip/list?month_year=${selectedMonthYear}&employeeId=${userProfile?.employeeId}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          console.log(res.result);
          setSalary(res.result);
          setFilterReset(false);
        } else {
          setSalary([]);
          setFilterReset(false);
        }
      })
      .catch((err) => {
        setSalary([]);
        setFilterReset(false);
      });
  };
  const handleReset = () => {
    const currentDate = new Date();
    setSelectedMonthYear(getFormattedMonthYear(currentDate));
    setFilterReset(true);
  };

  const handleFilter = () => {
    getSalary();
  };

  useEffect(() => {
    if (filterReset) {
      getSalary();
    }
  }, [filterReset]);

  const months = useMemo(() => {
    const options = [];
    const currentDate = new Date();
    for (let i = 0; i < 12; i++) {
      const date = new Date(currentDate);
      date.setMonth(date.getMonth() - i);
      const monthYear = getFormattedMonthYear(date);
      options.push(
        <option key={i} value={monthYear}>
          {monthYear}
        </option>
      );
    }
    return options;
  }, []);

  const isEmpSalary = salaryDetail.every(
    (salary) =>
      !salary.employee_salary ||
      Object.keys(salary.employee_salary).length === 0
  );

  const calculateAllowanceTotal = (itemsString) => {
    if (!itemsString) return 0;
    const items = JSON.parse(itemsString);
    return items
      .reduce((total, item) => total + parseFloat(item.amt), 0)
      .toFixed(2);
  };

  const calculateDeductionTotal = (itemsString) => {
    if (!itemsString) return 0;
    const items = JSON.parse(itemsString);
    return items
      .reduce((total, item) => total + parseFloat(item.damt), 0)
      .toFixed(2);
  };

  function handleDownload() {
    utils
      .callAPI(
        "post",
        `/salary/payslip/export-employee?month_year=${selectedMonthYear}&employeeId=${userProfile?.employeeId}`
      )
      .then((res) => {
        if (res && Object.keys(res).length > 0) {
          // setCsvData(res);
          // setActive(true);
          generateCSV(res); 

        } else {
          setCsvData([]);
          // done(false);
        }
      })
      .catch((err) => {
        setCsvData([]);
        // done(false);
      });
  }

  
  function generateCSV(filteredData) {
    const ws = XLSX.utils.json_to_sheet(filteredData, {
      header: Object.keys(filteredData[0]),
      skipHeader: true
    });
    
    const wb = XLSX.utils.book_new();
    
    XLSX.utils.book_append_sheet(wb, ws, "Employees");
    
    const csvOutput = XLSX.utils.sheet_to_csv(ws);
    
    const blob = new Blob([csvOutput], { type: "text/csv;charset=utf-8;" });
    
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Salaryhistory.csv";
    
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const renderCompensationTable = (salary) => {
    const allowances = JSON.parse(salary.allowance);
    const deductions = JSON.parse(salary.deduction);

    const findAmount = (items, name, type = "amt") => {
      const item = items.find((item) => item.name === name);
      return item ? parseFloat(item[type]) : 0;
    };

    const data = [
      { label: "Basic", value: findAmount(allowances, "Basic Salary") },
      { label: "HRA", value: findAmount(allowances, "HRA") },
      {
        label: "Other Allow.",
        value: findAmount(allowances, "Special Allowances"),
      },
      {
        label: "Actual Variable Amount",
        value: findAmount(allowances, "Actual Variable Amount"),
      },
      {
        label: "Scheme Incentive",
        value: findAmount(allowances, "Scheme Incentive"),
      },
      { label: "Gross Salary", value: parseFloat(salary.gross_salary) },
      { label: "P.F.", value: findAmount(deductions, "PF", "damt") },
      { label: "ESIC", value: findAmount(deductions, "ESIC", "damt") },
      { label: "Prof Tax", value: findAmount(deductions, "Prof Tax", "damt") },
      { label: "Gratuity", value: findAmount(deductions, "Gratuity", "damt") },
      {
        label: "Advance/Loan",
        value: findAmount(deductions, "Advance Loan", "damt"),
      },
      {
        label: "Mediclaim",
        value: findAmount(deductions, "Mediclaim", "damt"),
      },
      { label: "TDS", value: findAmount(deductions, "TDS", "damt") },
      { label: "Net Ded.", value: parseFloat(salary.net_deduction) },
      { label: "Net Salary (Employees)", value: parseFloat(salary.net_salary) },
      { label: "PF (Employer)", value: parseFloat(salary.employee_pf) },
      {
        label: "Total Net Salary (Including Employer PF)",
        value: parseFloat(salary.total_net_salary),
      },
    ];

    return data.map((row, index) => (
      <tr key={index}>
        <td>{row.label}</td>
        <td>{Math.round(row.value)}</td>
        {/* <td>
          {row.label == "TDS"
            ? salary?.tds_month
              ? Math.round(salary?.tds_month)
              : 0
            : Math.round(row.value * 12)}
        </td> */}
      </tr>
    ));
  };

  const handlePdfGenarete = () => {
    const tableRef = document.querySelector(".payslip-container");
    html2canvas(tableRef).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: [canvas.width * 0.1, canvas.height * 0.1],
      });

      pdf.addImage(
        imgData,
        "PNG",
        0,
        0,
        canvas.width * 0.1,
        canvas.height * 0.1
      );
      pdf.save(`${selectedMonthYear}_salarySlip.pdf`);
    });
  };

  const renderTable = (tdsArray, salary) => {
    const tds = tdsArray[0] || {};
    const otherIncome = tds?.other_income ? JSON.parse(tds.other_income) : [];
    const totalOtherIncome = otherIncome.reduce(
      (total, income) => total + parseFloat(income.amount || 0),
      0
    );

    const tdsResponse = tds?.tds_response ? JSON.parse(tds.tds_response) : null;
    const grossTaxableIncome = tdsResponse?.data?.gross_taxable_income ?? 0;
    const grossTotalIncome = tdsResponse?.data?.gross_total_income ?? 0;
    const totalDeductionsUnderChapterVIA =
      tdsResponse?.data?.total_deductions_under_chapter_vi_a ?? 0;
    const totalTaxPayable = tdsResponse?.data?.total_tax_payable ?? 0;
    const healthAndEducationCess =
      tdsResponse?.data?.health_and_education_cess ?? 0;
    const totalTaxesPaid = tdsResponse?.data?.total_taxes_paid ?? 0;
    const taxOnTotalIncome = tdsResponse?.data?.tax_on_total_income ?? 0;
    const surcharge = tdsResponse?.data?.surcharge ?? 0;

    const getDeduction = (name) => {
      const deduction = salary?.employee_salary?.deduction
        ? JSON.parse(salary.employee_salary.deduction).find(
            (item) => item.name === name
          )
        : { damt: 0 };
      return parseFloat(deduction?.damt ?? 0).toFixed(2);
    };

    const getTdsForMonth = (monthName) => {
      return parseFloat(
        salary?.tds_month?.find((a) => a.month_nm === monthName)?.tds ?? 0
      ).toFixed(2);
    };

    const deduction_80c_items = [
      "lic",
      "pf",
      "ppf",
      "vpf",
      "nsc",
      "re_invested",
      "ulip",
      "elss",
      "tution_fees",
      "housing_loan_repayment",
      "stamp_duty_registration_charge",
      "infrastructure_bond",
      "bank_fd",
      "post_term_deposit",
      "senior_citizen_saving",
      "sukanya_samriddhi_account",
      "other_deduction",
    ];

    const totalDeduction_80c = deduction_80c_items.reduce((total, item) => {
      return total + parseFloat(tds[item] ?? 0);
    }, 0);

    const pfTotal = parseFloat(tds?.pf ?? 0);
    const vpfTotal = parseFloat(tds?.vpf ?? 0);
    const totalPFandVPF = pfTotal + vpfTotal;

    const deductionData = [
      { name: "lic", label: "1", particulars: "Life Insurance Premium (LIC)" },
      { name: "pf", label: "2", particulars: "Provident Fund (PF)" },
      { name: "ppf", label: "3", particulars: "Public Provident Fund (PPF)" },
      {
        name: "vpf",
        label: "4",
        particulars: "Voluntary Provident Fund (VPF)",
      },
      {
        name: "nsc",
        label: "5",
        particulars: "National Savings Certificate (NSC)",
      },
      {
        name: "re_invested",
        label: "6",
        particulars: "Interest accrued on NSC (Re-invested)",
      },
      {
        name: "ulip",
        label: "7",
        particulars: "Unit Linked Insurance Policy (ULIP)",
      },
      {
        name: "elss",
        label: "8",
        particulars: "Equity Linked Savings Scheme (ELSS) Mutual Fund",
      },
      {
        name: "tution_fees",
        label: "9",
        particulars: "Payment for Tuition Fees for Children (Max. 2 Children)",
      },
      {
        name: "housing_loan_repayment",
        label: "10",
        particulars: "Principal Repayment of Housing Loan",
      },
      {
        name: "stamp_duty_registration_charge",
        label: "11",
        particulars:
          "Stamp Duty Registration charges for Buying House (1st Year Only)",
      },
      {
        name: "infrastructure_bond",
        label: "12",
        particulars: "Infrastructure Bonds",
      },
      {
        name: "bank_fd",
        label: "13",
        particulars: "Bank Fixed Deposit for 5 Years & Above",
      },
      {
        name: "post_term_deposit",
        label: "14",
        particulars: "Post Office Term Deposit For 5 Years & Above",
      },
      {
        name: "senior_citizen_saving",
        label: "15",
        particulars: "Senior Citizen Saving Scheme",
      },
      {
        name: "sukanya_samriddhi_account",
        label: "16",
        particulars: "Sukanya Samriddhi Account Scheme",
      },
      {
        name: "other_deduction",
        label: "17",
        particulars: "Others (Please Specify)",
      },
    ];

    return (
      <div className="side-by-side-tables">
        <table className="custom-table">
          <thead>
            <tr>
              <th colSpan="1" className="text-center">
                Exemption Under Section 10 17
              </th>
              <th colSpan="1" className="total">
                PART [A]
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Standard deduction</td>
              <td className="text-end">50000.00</td>
            </tr>
            <tr>
              <td colSpan="1" className="total text-end">
                TOTAL
              </td>
              <td className="text-end">50000.00</td>
            </tr>
            <tr>
              <td className="text-center font-weight-bold">Other Income</td>
              <td className="total">PART [B]</td>
            </tr>
            {otherIncome.map((income, i) => (
              <tr key={i}>
                <td>{income.title}</td>
                <td className="text-end">
                  {parseFloat(income.amount || 0).toFixed(2)}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="1" className="total text-end">
                TOTAL
              </td>
              <td className="text-end">{totalOtherIncome.toFixed(2)}</td>
            </tr>
            <tr>
              <th colSpan="1" className="text-center">
                Deduction Under Chapter VI-A
              </th>
              <td className="total">PART [C]</td>
            </tr>
            {deductionData.map((deduction, index) => (
              <tr key={index} style={{ height: "20px" }}>
                <td>{deduction.particulars}</td>
                <td className="text-end">
                  {parseFloat(tds[deduction.name] || 0).toFixed(2)}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="1" className="total text-end">
                TOTAL
              </td>
              <td className="text-end">{totalDeduction_80c.toFixed(2)}</td>
            </tr>

            <tr>
              <th colSpan="1" className="text-center">
                Deduction Under Chapter VI(Sec. 80c)
              </th>
              <td className="total">PART [D]</td>
            </tr>
            <tr>
              <td>Employees Provident Fund & Voluntary PF (sec 80C)</td>
              <td className="text-end">{totalPFandVPF.toFixed(2)}</td>
            </tr>
            <tr>
              <td colSpan="1" className="total text-end">
                TOTAL
              </td>
              <td className="text-end">{totalPFandVPF.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>

        <table className="custom-table">
          <thead>
            <tr>
              <th colSpan="2" className="text-center">
                Income Tax Deduction
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Gross Salary</td>
              <td className="text-end">
                {salary?.employee_salary?.gross_salary ?? "0.00"}
              </td>
            </tr>
            <tr>
              <td>Professional Tax</td>
              <td className="text-end">{getDeduction("Prof Tax")}</td>
            </tr>
            <tr>
              <td>House Rent</td>
              <td className="text-end">
                {parseFloat(tds?.house_rent_allowance ?? 0).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>Exemption Under Section 10 17</td>
              <td className="text-end">0.00</td>
            </tr>
            <tr>
              <td>Other Income</td>
              <td className="text-end">{totalOtherIncome.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Deduction Under Chapter VI-A</td>
              <td className="text-end">
                {totalDeductionsUnderChapterVIA.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>Deduction Under Chapter VI(Sec. 80c)</td>
              <td className="text-end">{totalDeduction_80c.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Total Taxable Income</td>
              <td className="text-end">{grossTaxableIncome.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Total Tax</td>
              <td className="text-end">{taxOnTotalIncome.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Surcharge + Education Cess</td>
              <td className="text-end">
                {(surcharge + healthAndEducationCess).toFixed(2)}
              </td>
            </tr>
            <tr>
              <td>Total Tax Deducted</td>
              <td className="text-end">{totalTaxesPaid.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Tax to be Deducted</td>
              <td className="text-end">{totalTaxPayable.toFixed(2)}</td>
            </tr>
            <tr>
              <td>Monthly Projected Tax</td>
              <td className="text-end">0.00</td>
            </tr>
            <tr colSpan="1">
              <th className="text-center" style={{ borderRight: "none" }}>
                Tax Paid Details
              </th>
              <td style={{ borderLeft: "none" }}></td>
            </tr>
            <tr>
              <td colSpan="2" style={{ padding: "0", margin: "0" }}>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    height: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ padding: "8px", border: "1px solid #000" }}>
                        APR
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #000" }}>
                        MAY
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #000" }}>
                        JUN
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #000" }}>
                        JUL
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #000" }}>
                        AUG
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #000" }}>
                        SEP
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ padding: "8px", border: "1px solid #000" }}>
                        {getTdsForMonth("April")}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #000" }}>
                        {getTdsForMonth("May")}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #000" }}>
                        {getTdsForMonth("June")}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #000" }}>
                        {getTdsForMonth("July")}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #000" }}>
                        {getTdsForMonth("August")}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #000" }}>
                        {getTdsForMonth("September")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{ padding: "0", margin: "0" }}>
                <table
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    height: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th style={{ padding: "8px", border: "1px solid #000" }}>
                        OCT
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #000" }}>
                        NOV
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #000" }}>
                        DEC
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #000" }}>
                        JAN
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #000" }}>
                        FEB
                      </th>
                      <th style={{ padding: "8px", border: "1px solid #000" }}>
                        MAR
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ padding: "8px", border: "1px solid #000" }}>
                        {getTdsForMonth("October")}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #000" }}>
                        {getTdsForMonth("November")}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #000" }}>
                        {getTdsForMonth("December")}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #000" }}>
                        {getTdsForMonth("January")}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #000" }}>
                        {getTdsForMonth("February")}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #000" }}>
                        {getTdsForMonth("March")}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Emplyoee Salary</h1>
          </div>

          <div className="section-body">
            <RB.Card className="card shadow">
              {!isEmpSalary ? (
                <>
                  {salaryDetail.map((empsalary) => (
                    <RB.Card.Body>
                      <RB.Row className="justify-content-between">
                        <RB.Col md="auto">
                          <div className="row" text-center>
                            <RB.Col md={6} className="mb-3 mb-md-0">
                              <RB.Form.Select
                                aria-label="Default select example"
                                className="me-2"
                                value={selectedMonthYear}
                                onChange={(e) =>
                                  setSelectedMonthYear(e.target.value)
                                }
                              >
                                <option value="">Select a month-year...</option>
                                {months}
                              </RB.Form.Select>
                            </RB.Col>
                            <RB.Col md={6} className="col-md-auto">
                              <RB.Button
                                className=""
                                variant="primary"
                                onClick={handleFilter}
                              >
                                Find
                              </RB.Button>
                              <RB.Button
                                className="ms-2"
                                variant="secondary"
                                onClick={handleReset}
                              >
                                Reset
                              </RB.Button>
                            </RB.Col>
                          </div>
                        </RB.Col>
                        {utils.hasPermission(
                          primaryMenuRes,
                          "Salary",
                          "salary.download"
                        ) && (
                          <RB.Col md="auto">
                            {salaryDetail.length > 0 && (
                              <div
                                className="d-flex justify-content-md-end mt-md-0 mt-3 me-md-0 me-3"
                                text-center
                              >
                                <RB.Button
                                  className="ms-md-2"
                                  variant="primary"
                                  onClick={handlePdfGenarete}
                                >
                                  Salary Slip
                                </RB.Button>
                              </div>
                            )}
                          </RB.Col>
                        )}
                      </RB.Row>

                      {utils.hasPermission(
                        primaryMenuRes,
                        "Salary",
                        "salary.download"
                      ) && (
                        <>
                          <h6 className="mt-3">Personal Information</h6>
                          <hr className="hr-lg mt-1" />
                          <RB.Row>
                            {empsalary?.firstName && (
                              <RB.Col md={4}>
                                <span className="text-uppercase fw-bold small text-body-secondary">
                                  First Name
                                </span>
                                <p className="fw-medium text-sm fw-semibold">
                                  {empsalary?.firstName}
                                </p>
                              </RB.Col>
                            )}
                            {empsalary?.middleName && (
                              <RB.Col md={4}>
                                <span className="text-uppercase fw-bold small text-body-secondary">
                                  Middle Name
                                </span>
                                <p className="fw-medium text-sm fw-semibold">
                                  {empsalary?.middleName}
                                </p>
                              </RB.Col>
                            )}
                            {empsalary?.lastName && (
                              <RB.Col md={4}>
                                <span className="text-uppercase fw-bold small text-body-secondary">
                                  Last Name
                                </span>
                                <p className="fw-medium text-sm fw-semibold">
                                  {empsalary?.lastName}
                                </p>
                              </RB.Col>
                            )}
                          </RB.Row>
                          <RB.Row className="mb-3">
                            {empsalary?.employeeId && (
                              <RB.Col md={4}>
                                <span className="text-uppercase fw-bold small text-body-secondary">
                                  Employee id
                                </span>
                                <p className="fw-medium text-sm fw-semibold">
                                  {empsalary?.employeeId}
                                </p>
                              </RB.Col>
                            )}
                            {empsalary?.email && (
                              <RB.Col md={4}>
                                <span className="text-uppercase fw-bold small text-body-secondary">
                                  Email
                                </span>
                                <p className="fw-medium text-sm fw-semibold">
                                  {empsalary?.email}
                                </p>
                              </RB.Col>
                            )}
                            {/* Add more personal information fields with conditions as needed */}
                          </RB.Row>
                          {empsalary?.employee_salary && (
                            // Object.keys(empsalary?.employee_salary)?.map((salary) => (
                            <>
                              <h6>Salary Information</h6>
                              <hr className="hr-lg mt-1" />
                              <RB.Row>
                                <RB.Col md={4}>
                                  <span className="text-uppercase fw-bold small text-body-secondary">
                                    CTC
                                  </span>
                                  <p className="fw-medium text-sm fw-semibold">
                                    {empsalary?.employee_salary.ctc}
                                  </p>
                                </RB.Col>

                                {empsalary?.employee_salary.variable_pay && (
                                  <RB.Col md={4}>
                                    <span className="text-uppercase fw-bold small text-body-secondary">
                                      variable Pay
                                    </span>
                                    <p className="fw-medium text-sm fw-semibold">
                                      {empsalary?.employee_salary.variable_pay}
                                    </p>
                                  </RB.Col>
                                )}
                                {empsalary?.employee_salary.total && (
                                  <RB.Col md={4}>
                                    <span className="text-uppercase fw-bold small text-body-secondary">
                                      Total
                                    </span>
                                    <p className="fw-medium text-sm fw-semibold">
                                      {empsalary?.employee_salary.total}
                                    </p>
                                  </RB.Col>
                                )}

                                <RB.Col md={4}>
                                  <span className="text-uppercase fw-bold small text-body-secondary">
                                    ACTUAL DAYS AMOUNT
                                  </span>
                                  <p className="fw-medium text-sm fw-semibold">
                                    {empsalary?.employee_salary?.actual_days}
                                  </p>
                                </RB.Col>
                              </RB.Row>

                              <RB.Row>
                                {empsalary?.employee_salary?.present_days && (
                                  <RB.Col md={4}>
                                    <span className="text-uppercase fw-bold small text-body-secondary">
                                      Days
                                    </span>
                                    <p className="fw-medium text-sm fw-semibold">
                                      {empsalary?.employee_salary?.present_days}
                                    </p>
                                  </RB.Col>
                                )}
                              </RB.Row>
                              <h6>Payment Information</h6>
                              <hr className="hr-lg mt-1" />
                              <RB.Row className="mb-3">
                                {empsalary?.employee_salary
                                  ?.payment_frequency !== undefined && (
                                  <RB.Col md={4}>
                                    <span className="text-uppercase fw-bold small text-body-secondary">
                                      Payment Frequency
                                    </span>
                                    <p className="fw-medium text-sm fw-semibold">
                                      {" "}
                                      {(() => {
                                        switch (
                                          empsalary?.employee_salary
                                            ?.payment_frequency
                                        ) {
                                          case "0":
                                            return "Add Monthly";
                                          case "1":
                                            return "Quarterly";
                                          case "2":
                                            return "Half Yearly";
                                          case "3":
                                            return "Yearly";
                                          default:
                                            return "";
                                        }
                                      })()}
                                    </p>
                                  </RB.Col>
                                )}
                                {empsalary?.employee_salary.payment_mode !==
                                  undefined && (
                                  <RB.Col md={4}>
                                    <span className="text-uppercase fw-bold small text-body-secondary">
                                      Payment Mode
                                    </span>
                                    <p className="fw-medium text-sm fw-semibold">
                                      {(() => {
                                        switch (
                                          empsalary?.employee_salary
                                            ?.payment_mode
                                        ) {
                                          case "0":
                                            return "Bank Transfer";
                                          case "1":
                                            return "Cheque";
                                          case "2":
                                            return "Cash";
                                          default:
                                            return "";
                                        }
                                      })()}
                                    </p>
                                  </RB.Col>
                                )}
                                {/* {empsalary?.employee_salary?.payment_date && (
                              <RB.Col md={4}>
                                <span className="text-uppercase fw-bold small text-body-secondary">
                                  Payment Date
                                </span>
                                <p className="fw-medium text-sm fw-semibold">
                                  {empsalary?.employee_salary.payment_date}
                                </p>
                              </RB.Col>
                            )} */}
                              </RB.Row>
                              <RB.Row>
                                {empsalary?.employee_salary
                                  ?.deduction_reason && (
                                  <RB.Col md={4}>
                                    <span className="text-uppercase fw-bold small text-body-secondary">
                                      Deduction Reason
                                    </span>
                                    <p className="fw-medium text-sm fw-semibold">
                                      {
                                        empsalary?.employee_salary
                                          ?.deduction_reason
                                      }
                                    </p>
                                  </RB.Col>
                                )}
                              </RB.Row>
                              <RB.Row>
                                <div className="table-responsive">
                                  <RB.Table
                                    striped
                                    bordered
                                    hover
                                    variant="light"
                                  >
                                    <thead>
                                      <tr>
                                        <th scope="col">
                                          Compensation Details
                                        </th>
                                        <th scope="col">
                                          Monthly Salary (INR)
                                        </th>
                                        {/* <th scope="col">Yearly Salary (INR)</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {renderCompensationTable(
                                        empsalary?.employee_salary
                                      )}
                                    </tbody>
                                  </RB.Table>
                                </div>
                              </RB.Row>
                              <RB.Row className="my-3">
                                {" "}
                                {empsalary?.employee_salary &&
                                  empsalary?.employee_salary?.gross_salary && (
                                    <RB.Col md={6}>
                                      <div className="d-flex border rounded-2">
                                        <div className="flex-shrink-0 me-3 bg-light d-flex p-3 justify-content-center align-items-center">
                                          <i className="material-icons-outlined md-36">
                                            account_balance_wallet
                                          </i>
                                        </div>
                                        <div className="flex-grow-1 p-2">
                                          <p className="mb-0">
                                            Total Net Salary
                                          </p>
                                          {/* <p className="mb-0">
                                        +{" "}
                                        {
                                          empsalary?.employee_salary
                                            ?.employee_pf
                                        }{" "}
                                        PF (Employer)
                                      </p> */}
                                          <h4 className="fw-bold">
                                            {" "}
                                            {
                                              empsalary?.employee_salary
                                                ?.total_net_salary
                                            }
                                          </h4>
                                        </div>
                                      </div>
                                    </RB.Col>
                                  )}
                                {empsalary?.employee_salary &&
                                  empsalary?.employee_salary?.net_salary && (
                                    <RB.Col md={6}>
                                      <div className="d-flex border border-primary rounded-2">
                                        <div className="flex-shrink-0 me-3 bg-primary d-flex p-3 justify-content-center align-items-center text-xl">
                                          <i className="material-icons-outlined md-36">
                                            payment
                                          </i>
                                        </div>
                                        <div className="flex-grow-1 p-2">
                                          <p className="mb-0">Net Salary</p>
                                          <h4 className="fw-bold">
                                            {" "}
                                            {
                                              empsalary?.employee_salary
                                                ?.net_salary
                                            }
                                          </h4>
                                        </div>
                                      </div>
                                    </RB.Col>
                                  )}
                              </RB.Row>
                            </>
                          )}
                        </>
                      )}
                    </RB.Card.Body>
                  ))}
                </>
              ) : (
                <>
                  <RB.Card.Body>
                    <RB.Row className="text-center">
                      <div className="d-flex justify-content-center align-items-center text-center">
                        <div className="me-2 fs-3">
                          <i className="material-icons-outlined">search_off</i>
                        </div>
                        <div className="fs-5">
                          <p className="mb-0">No salary details found.</p>
                        </div>
                      </div>
                    </RB.Row>
                  </RB.Card.Body>
                </>
              )}
            </RB.Card>
          </div>
        </section>
      </div>
      <div class="payslip-container" ref={tableRef}>
        <div class="row mb-2">
          <div class="col-sm-2">
            <img src={logo} className="imgContaner"></img>
          </div>
          <div class="col-sm-10">
            <div class="d-flex justify-content-between">
              <div>
                <h6>VURA BAU-CHEMIE LLP</h6>
                <p>
                  13TH FLOOR ELITE BUSINESS HUB,NEAR PRAJAPATI BHAVAN, OPP.
                  <br />
                  SAPATH HEXA, SOLA, AHMEDABAD
                </p>
              </div>
              <div class="text-end">
                <h6>For the Month of {selectedMonthYear}</h6>
                <h6>Salary Slip</h6>
                <p>Form IV B [ Rule 26(2) (b) ]</p>
              </div>
            </div>
          </div>
        </div>
        {salaryDetail.map((salary, index) => (
          <>
            <div
              className="first-main-div"
              style={{ border: "1px solid #000" }}
            >
              <table class="payslip-header">
                <tr>
                  <td className="w-50">Emp. Id :- {`${salary.employeeId}`}</td>
                  <td className="w-50">P.F. No. :- -</td>
                </tr>
                <tr>
                  <td>
                    Emp. Name :-{" "}
                    {`${salary.firstName} ${salary.middleName} ${salary.lastName}`}
                  </td>
                  <td>UAN. No. :- -</td>
                </tr>
                <tr>
                  <td>Designation :- {salary?.roleName}</td>
                  <td>ESI No. :-</td>
                </tr>
                <tr>
                  <td>Department :- {salary?.department_name}</td>
                  <td>Bank :- {salary.bank_name}</td>
                </tr>
                <tr>
                  <td>Location :- {salary.location}</td>
                  <td>A/c No :- {salary.bank_account_no}</td>
                </tr>
                <tr>
                  <td>PAN No :- {salary?.panNo}</td>
                  <td>D.O.J. :- {salary?.join_date}</td>
                </tr>
              </table>

              <table class="payslip-table">
                <tr className="main-border">
                  <th colspan="2" className="text-center">
                    WORKING DETAILS
                  </th>
                  <th
                    colspan="4"
                    class=" text-center"
                    style={{ borderLeft: "1px solid #000" }}
                  >
                    EARNING DETAILS
                  </th>
                  <th colspan="3" class="text-center table-border-left">
                    DEDUCTION DETAILS
                  </th>
                </tr>
                <tr className="main-border">
                  <th></th>
                  <th></th>
                  <th style={{ borderLeft: "1px solid #000" }}>Earnings</th>
                  <th className="table-border-left">Actual</th>
                  <th className="table-border-left">Payable</th>
                  <th className="table-border-left">YTD</th>
                  <th className="table-border-left">Deduction</th>
                  <th className="table-border-left">Amount</th>
                  <th className="table-border-left">YTD</th>
                </tr>
                <tr class="no-border">
                  <td>WD</td>
                  <td className="text-end">
                    {salary?.employee_salary?.month_days
                      ? salary?.employee_salary?.month_days
                      : "0.00"}
                  </td>
                  <td style={{ borderLeft: "1px solid #000" }}>
                    Consol. Basic
                  </td>
                  <td class="amount table-border-left text-end">
                    {salary?.employee_salary?.basic_salary
                      ? Math.round(
                          salary?.employee_salary?.basic_salary
                        ).toFixed(2)
                      : 0.0}
                  </td>
                  <td class="amount table-border-left text-end">
                    {" "}
                    {salary?.employee_salary?.basic_salary
                      ? Math.round(
                          salary?.employee_salary?.basic_salary
                        ).toFixed(2)
                      : 0.0}
                  </td>
                  <td class="amount table-border-left text-end">
                    {salary?.allowance_ytd?.["Basic Salary"]
                      ? salary?.allowance_ytd?.["Basic Salary"].toFixed(2)
                      : 0.0}
                  </td>
                  <td className="table-border-left">P.F.</td>
                  <td className="amount table-border-left text-end">
                    {(
                      (salary?.employee_salary?.deduction &&
                        JSON.parse(salary?.employee_salary?.deduction)?.find(
                          (item) => item.name === "PF"
                        )) || { damt: 0 }
                    ).damt.toFixed(2)}
                  </td>

                  <td className="amount table-border-left text-end">
                    {salary?.deduction_ytd?.["PF"]
                      ? (salary?.deduction_ytd?.["PF"]).toFixed(2)
                      : 0.0}
                  </td>
                </tr>
                <tr class="no-border">
                  <td>WO</td>
                  <td className="text-end">0.00</td>
                  <td style={{ borderLeft: "1px solid #000" }}>DA</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td className="table-border-left  ">ESI</td>
                  <td class="amount table-border-left text-end">
                    {(
                      (salary?.employee_salary?.deduction &&
                        JSON.parse(salary?.employee_salary?.deduction)?.find(
                          (item) => item.name === "ESIC"
                        )) || { damt: 0 }
                    ).damt.toFixed(2)}
                  </td>
                  <td class="amount table-border-left text-end">
                    {salary?.deduction_ytd?.["ESIC"]
                      ? (salary?.deduction_ytd?.["ESIC"]).toFixed(2)
                      : 0.0}
                  </td>
                </tr>
                <tr class="no-border">
                  <td>PH</td>
                  <td className="text-end">0.00</td>
                  <td style={{ borderLeft: "1px solid #000" }}>HRA</td>
                  <td className="amount table-border-left text-end">
                    {(
                      (salary?.employee_salary?.allowance &&
                        JSON.parse(salary?.employee_salary?.allowance)?.find(
                          (item) => item.name === "HRA"
                        )) || { amt: 0 }
                    ).amt.toFixed(2)}
                  </td>

                  <td class="amount table-border-left text-end">
                    {" "}
                    {(
                      (salary?.employee_salary?.allowance &&
                        JSON.parse(salary?.employee_salary?.allowance)?.find(
                          (item) => item.name === "HRA"
                        )) || { amt: 0 }
                    ).amt.toFixed(2)}
                  </td>
                  <td class="amount table-border-left text-end">
                    {salary?.allowance_ytd?.["HRA"]
                      ? (salary?.allowance_ytd?.["HRA"]).toFixed(2)
                      : 0.0}
                  </td>
                  <td className="table-border-left">P.T.</td>
                  <td className="amount table-border-left text-end">
                    {(
                      (salary?.employee_salary?.deduction &&
                        JSON.parse(salary?.employee_salary?.deduction)?.find(
                          (item) => item.name === "Prof Tax"
                        )) || {
                        damt: 0,
                      }
                    ).damt.toFixed(2)}
                  </td>
                  <td class="amount table-border-left text-end">
                    {salary?.deduction_ytd?.["Prof Tax"]
                      ? (salary?.deduction_ytd?.["Prof Tax"]).toFixed(2)
                      : 0.0}
                  </td>
                </tr>
                <tr class="no-border">
                  <td>PD</td>
                  <td className="text-end">
                    {" "}
                    {salary?.employee_salary?.present_days
                      ? salary?.employee_salary?.present_days
                      : "0.00"}
                  </td>
                  <td style={{ borderLeft: "1px solid #000" }}>Local All.</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td className="table-border-left">I.T.</td>
                  <td className="table-border-left text-end">
                    {(
                      (salary?.employee_salary?.deduction &&
                        JSON.parse(salary?.employee_salary?.deduction)?.find(
                          (item) => item.name === "TDS"
                        )) || { damt: 0 }
                    ).damt.toFixed(2)}
                  </td>
                  <td className="table-border-left text-end">
                    {salary?.deduction_ytd?.["TDS"]
                      ? (salary?.deduction_ytd?.["TDS"]).toFixed(2)
                      : 0.0}
                  </td>
                </tr>
                <tr class="no-border">
                  <td>C.O.</td>
                  <td className="text-end">0.00</td>
                  <td style={{ borderLeft: "1px solid #000" }}>Oth. All</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td className="table-border-left">L.W.F.</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td className="table-border-left text-end">0.00</td>
                </tr>
                <tr class="no-border">
                  <td>OD</td>
                  <td className="text-end">0.00</td>
                  <td style={{ borderLeft: "1px solid #000" }}>
                    Variable All.
                  </td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td className="table-border-left">Adv. Loan</td>
                  <td className="table-border-left text-end">
                    {(
                      (salary?.employee_salary?.deduction &&
                        JSON.parse(salary?.employee_salary?.deduction)?.find(
                          (item) => item.name === "Advance Loan"
                        )) || { damt: 0.0 }
                    ).damt.toFixed(2)}
                  </td>
                  <td className="table-border-left text-end">
                    {salary?.deduction_ytd?.["Advance Loan"]
                      ? (salary?.deduction_ytd?.["Advance Loan"]).toFixed(2)
                      : 0.0}
                  </td>
                </tr>
                <tr class="no-border">
                  <td>PL</td>
                  <td className="text-end">
                    {(salary?.employee_salary?.leave_count &&
                      JSON.parse(salary.employee_salary.leave_count).find(
                        (leave) => leave.name === "PL"
                      )?.days) ||
                      "0.00"}
                  </td>
                  <td style={{ borderLeft: "1px solid #000" }}>
                    Special Allow.
                  </td>
                  <td className="amount table-border-left text-end">
                    {Math.ceil(
                      (
                        (salary?.employee_salary?.allowance &&
                          JSON.parse(salary?.employee_salary?.allowance)?.find(
                            (item) => item.name === "Special Allowances"
                          )) || { amt: 0 }
                      ).amt
                    ).toFixed(2)}
                  </td>
                  <td className="amount table-border-left text-end">
                    {Math.ceil(
                      (
                        (salary?.employee_salary?.allowance &&
                          JSON.parse(salary?.employee_salary?.allowance)?.find(
                            (item) => item.name === "Special Allowances"
                          )) || { amt: 0 }
                      ).amt
                    ).toFixed(2)}
                  </td>
                  <td className="amount table-border-left text-end">
                    {salary?.allowance_ytd?.["Special Allowances"]
                      ? (salary?.allowance_ytd?.["Special Allowances"]).toFixed(
                          2
                        )
                      : 0.0}
                  </td>
                  <td className="table-border-left"></td>
                  <td className="table-border-left text-end"></td>

                  <td className="table-border-left"></td>
                </tr>
                <tr class="no-border">
                  <td>CL</td>
                  <td className="text-end">
                    {(salary?.employee_salary?.leave_count &&
                      JSON.parse(salary.employee_salary.leave_count).find(
                        (leave) => leave.name === "CL"
                      )?.days) ||
                      "0.00"}
                  </td>
                  <td style={{ borderLeft: "1px solid #000" }}></td>
                  <td class="amount table-border-left text-end"></td>
                  <td class="amount table-border-left text-end"></td>
                  <td class="amount table-border-left text-end"></td>
                  <td className="table-border-left">Oth. Ded.</td>
                  <td className="table-border-left text-end">
                    {(
                      ((salary?.employee_salary?.deduction &&
                        JSON.parse(salary?.employee_salary?.deduction)?.find(
                          (item) => item.name === "Mediclaim"
                        )?.damt) ||
                        0) +
                      ((salary?.employee_salary?.deduction &&
                        JSON.parse(salary?.employee_salary?.deduction)?.find(
                          (item) => item.name === "Gratuity"
                        )?.damt) ||
                        0)
                    ).toFixed(2)}
                  </td>

                  <td className="table-border-left"></td>
                </tr>
                <tr class="no-border">
                  <td>SL</td>
                  <td className="text-end">
                    {(salary?.employee_salary?.leave_count &&
                      JSON.parse(salary.employee_salary.leave_count).find(
                        (leave) => leave.name === "SL"
                      )?.days) ||
                      "0.00"}
                  </td>
                  <td style={{ borderLeft: "1px solid #000" }}></td>
                  <td class="amount table-border-left text-end"></td>
                  <td class="amount table-border-left text-end"></td>
                  <td class="amount table-border-left text-end"></td>
                  <td className="table-border-left">Canteen/Food</td>
                  <td className="table-border-left"></td>
                  <td className="table-border-left"></td>
                </tr>
                <tr class="no-border">
                  <td>BL</td>
                  <td className="text-end">0.00</td>
                  <td style={{ borderLeft: "1px solid #000" }}></td>
                  <td class="amount table-border-left text-end"></td>
                  <td class="amount table-border-left text-end"></td>
                  <td class="amount table-border-left text-end"></td>
                  <td className="table-border-left"></td>
                  <td className="table-border-left"></td>
                  <td className="table-border-left"></td>
                </tr>
                <tr class="no-border">
                  <td>LWP</td>
                  <td className="text-end">0.00</td>
                  <td style={{ borderLeft: "1px solid #000" }}></td>
                  <td class="amount table-border-left text-end"></td>
                  <td class="amount table-border-left text-end"></td>
                  <td class="amount table-border-left text-end"></td>
                  <td className="table-border-left"></td>
                  <td className="table-border-left"></td>
                  <td className="table-border-left"></td>
                </tr>
                <tr class="no-border">
                  <td>OT Hrs.</td>
                  <td className="text-end">0.00</td>
                  <td style={{ borderLeft: "1px solid #000" }}>Diff. Basic</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td className="table-border-left"></td>
                  <td className="table-border-left"></td>
                  <td className="table-border-left"></td>
                </tr>
                <tr class="no-border">
                  <td></td>
                  <td></td>
                  <td style={{ borderLeft: "1px solid #000" }}>Diff. Arrear</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td className="table-border-left"></td>
                  <td className="table-border-left"></td>
                  <td className="table-border-left"></td>
                </tr>
                <tr class="no-border">
                  <td></td>
                  <td></td>
                  <td style={{ borderLeft: "1px solid #000" }}>OverTime</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td className="table-border-left"></td>
                  <td className="table-border-left"></td>
                  <td className="table-border-left"></td>
                </tr>
                <tr class="total-row" style={{ border: "1px solid #000" }}>
                  <td>TOTAL</td>
                  <td className="text-end">
                    {salary?.employee_salary?.present_days}
                  </td>
                  <td style={{ borderLeft: "1px solid #000" }}>Gross Income</td>
                  <td class="amount table-border-left text-end">
                    {Math.round(salary?.employee_salary?.gross_salary).toFixed(
                      2
                    )}
                  </td>
                  <td class="amount table-border-left text-end">
                    {Math.round(salary?.employee_salary?.gross_salary).toFixed(
                      2
                    )}
                  </td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td className="table-border-left">Gross Ded.</td>
                  <td class="amount table-border-left text-end">
                    {salary?.employee_salary?.gross_deduction
                      ? salary?.employee_salary?.gross_deduction
                      : 0.0}
                  </td>
                  <td class="amount table-border-left text-end">0.00</td>
                </tr>
                <tr class="no-border">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="table-border-left">Net Amount</td>
                  <td class="amount table-border-left text-end">
                    {Math.round(salary?.employee_salary?.net_salary).toFixed(2)}
                  </td>
                  <td class="amount table-border-left text-end">0.00</td>
                  <td></td>
                </tr>
              </table>
            </div>
            <div className="text-center mt-2 mb-0">
              <p>TDS Details</p>
            </div>
            {salary.tds_data
              ? renderTable(salary.tds_data, salary)
              : renderTable(null)}
            <table class="tds-details-table full-width">
              <tr>
                <th>Leave Type</th>
                <th>Opening Balance</th>
                <th>Leave Credit</th>
                <th>Leave Availed</th>
                <th>Leave Encash</th>
                <th>Balance Available</th>
              </tr>
              {Object.keys(salary?.employee_leave).map((leaveType) => (
                <tr key={leaveType}>
                  <td>{leaveType}</td>
                  <td>{salary?.employee_leave[leaveType].days}</td>
                  <td>{salary?.employee_leave[leaveType].days}</td>
                  <td>0</td>
                  <td>0</td>
                  <td>{0}</td>
                </tr>
              ))}
            </table>
          </>
        ))}
      </div>
    </Layout>
  );
}

export default SalaryView;
