import { createSlice } from '@reduxjs/toolkit';

const actionSlice = createSlice({
  name: 'action',
  initialState: {
    currentAction: '',
  },
  reducers: {
    setAction: (state, action) => {
      state.currentAction = action.payload;
    },
    resetAction: (state) => {
      state.currentAction = '';
    },
  },
});

export const { setAction, resetAction } = actionSlice.actions;

export default actionSlice.reducer;
