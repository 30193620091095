import moment from "moment";
import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as utils from "../../Utils/comman";
// import Select from "react-select";
import CustomDropdown from "./CustomDropdown";
import { Link } from "react-router-dom";

function Add(props) {
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    leaveType: "",
    leaveName: "",
    reason: "",
    applied_date: new Date(),
    year: new Date().getFullYear(),
    leave_day: "",
    leave_type_id: "",
    isActive: "",
    leave_half_day_1: "first_half",
    leave_half_day_2: "first_half",
  });
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState();
  const statusList = [
    { id: "", name: "Select an option..." },
    { id: "1", name: "Active" },
    { id: "2", name: "Inactive" },
  ];
  const [leaveDayType, setLeaveDayType] = useState("full"); // "full" or "custom"
  const [showHalfDayDropdowns, setShowHalfDayDropdowns] = useState(0); // 0, 1, or 2
  const [calculatedDays, setCalculatedDays] = useState(0);

  const calculateLeaveDays = () => {
    let days =
      moment(formData.endDate).diff(moment(formData.startDate), "days") + 1;

    if (leaveDayType === "custom") {
      // Handling single-day leave
      if (days === 1) {
        if (formData.leave_half_day_1 !== formData.leave_half_day_2) {
          days = 1; // If different halves, count as full day
        } else {
          days = 0.5; // If same halves, count as half day
        }
      } else {
        // Handling the first day
        if (formData.leave_half_day_1 === "second_half") {
          days -= 0.5; // Second half only counts as half day
        }

        // Handling the last day
        if (formData.leave_half_day_2 === "first_half") {
          days -= 0.5; // First half only counts as half day
        }

        // If start day is first half and end day is second half, count both as full days
        if (
          formData.leave_half_day_1 === "first_half" &&
          formData.leave_half_day_2 === "second_half"
        ) {
          days = days; // Both count as full days, no adjustment needed
        }

        // Adjust for cases where the first day is a second half and last day is a first half
        if (
          formData.leave_half_day_1 === "second_half" &&
          formData.leave_half_day_2 === "first_half"
        ) {
          days += 1; // Add back a full day since these two combine to make a full day
        }
      }
    }

    setCalculatedDays(days);
  };

  useEffect(() => {
    if (formData.startDate && formData.endDate) {
      calculateLeaveDays();
    }
  }, [
    formData.startDate,
    formData.endDate,
    formData.leave_half_day_1,
    formData.leave_half_day_2,
    leaveDayType,
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name, "valeu", value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDropDownChange = (id, name) => {
    setFormData({
      ...formData,
      leaveType: id,
      leaveName: name,
    });
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (editId) {
        bodyFormData.append("id", editId);
      }
      bodyFormData.append("leave_no", "leave/2024/0003");
      bodyFormData.append(
        "applied_date",
        moment(formData.applied_date).format("YYYY-MM-DD")
      );
      bodyFormData.append("year", formData.year);

      if (leaveDayType === "full") {
        bodyFormData.append("leave_day", "full");
      } else if (leaveDayType === "custom") {
        if (moment(formData.startDate).isSame(formData.endDate, "day")) {
          bodyFormData.append("leave_day", "custom");
          bodyFormData.append("sdate_half", formData.leave_half_day_1);
        } else {
          bodyFormData.append("leave_day", "custom");
          bodyFormData.append("sdate_half", formData.leave_half_day_1);
          bodyFormData.append("edate_half", formData.leave_half_day_2);
        }
      }

      bodyFormData.append("leave_type_id", formData.leaveType);
      bodyFormData.append(
        "start_date",
        moment(formData.startDate).format("YYYY-MM-DD")
      );
      bodyFormData.append(
        "end_date",
        moment(formData.endDate).format("YYYY-MM-DD")
      );
      bodyFormData.append("reason", formData.reason);
      bodyFormData.append("isActive", "1");

      utils
        .callAPI("post", `/leave/leave-request/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setEditId("");
            setFormData({
              startDate: null,
              endDate: null,
              leaveType: "",
              reason: "",
              applied_date: new Date(),
              year: new Date().getFullYear(),
              leave_day: "",
              leave_type_id: "",
              isActive: "",
            });
            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
          }
          setIsSubmit(false);
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  useEffect(() => {
    getLeaveTypeList();
  }, []);

  const getLeaveTypeList = () => {
    utils
      .callAPI("get", `/leave/leave-type/list `)
      .then((res) => {
        if (typeof res !== "undefined") {
          setLeaveTypeList(res);
          console.log(res);
        } else {
          setLeaveTypeList([]);
        }
      })
      .catch((err) => {
        setLeaveTypeList([]);
      });
  };

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      setEditId(props.rowData.id);
      setIsEdit(true);
      const leaveDays = props.rowData.leave_day.split(",");
      console.log(leaveDays);
      setLeaveDayType(props.rowData.leave_day === "full" ? "full" : "custom");
      setFormData({
        startDate: new Date(props.rowData.start_date),
        endDate: new Date(props.rowData.end_date),
        leaveType: props.rowData.leave_type_id,
        leaveName:props.rowData.leave_name,
        reason: props.rowData.reason,
        applied_date: new Date(props.rowData.applied_date),
        year: props.rowData.year,
        leave_day: props.rowData.leave_day,
        leave_half_day_1: leaveDays[0] !== "full" ? leaveDays[0] : "",
        leave_half_day_2:
          leaveDays.length > 1 && leaveDays[leaveDays.length - 1] !== "full"
            ? leaveDays[leaveDays.length - 1]
            : "",
        isActive: props.rowData.isActive,
      });
    } else {
      setIsEdit(false);
    }
  }, [props.rowData]);

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0;
  };

  const handleLeaveDayChange = (e) => {
    const selectedType = e.target.value;
    setLeaveDayType(selectedType);

    if (selectedType === "custom") {
      if (formData.startDate && formData.endDate) {
        if (moment(formData.startDate).isSame(formData.endDate, "day")) {
          setShowHalfDayDropdowns(1);
        } else {
          setShowHalfDayDropdowns(2);
        }
      }
    } else {
      setShowHalfDayDropdowns(0);
    }
  };

  useEffect(() => {
    if (leaveDayType === "custom" && formData.startDate && formData.endDate) {
      if (moment(formData.startDate).isSame(formData.endDate, "day")) {
        setShowHalfDayDropdowns(1);
      } else {
        setShowHalfDayDropdowns(2);
      }
    }
  }, [formData.startDate, formData.endDate, leaveDayType]);

  return (
    <div className="section-body ">
      <RB.Card className="">
        <RB.Card.Header>
          <h4>{isEdit ? "Edit" : "Leave Request Form"}</h4>
          <div className="card-header-action">
            <Link className="btn btn-underline me-2" onClick={handleBackClick}>
              Back
            </Link>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3 mt-4">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <DatePicker
                      selected={formData.applied_date}
                      onChange={(date) =>
                        setFormData({ ...formData, applied_date: date })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Leave Applied Date"
                      minDate={new Date()}
                      required
                      disabled
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <RB.Form.Control
                      type="text"
                      placeholder="Year"
                      name="year"
                      value={formData.year}
                      onChange={handleInputChange}
                      required
                    />
                    <RB.Form.Label>Year</RB.Form.Label>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <DatePicker
                      selected={formData.startDate}
                      onChange={(date) =>
                        setFormData({ ...formData, startDate: date })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Select Start Date"
                      minDate={new Date()}
                      filterDate={isWeekday}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel className="floating-label-fancy">
                    <DatePicker
                      selected={formData.endDate}
                      onChange={(date) =>
                        setFormData({ ...formData, endDate: date })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Select End Date"
                      minDate={formData.startDate}
                      filterDate={isWeekday}
                      required
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please provide end date.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <RB.Form.Group className="mb-3">
                  <RB.Form.Label>Select Leave Day</RB.Form.Label>
                  <div className="d-flex align-items-center">
                    <RB.Form.Check
                      type="radio"
                      label="Full day"
                      name="leaveDay"
                      value="full"
                      checked={leaveDayType === "full"}
                      onChange={handleLeaveDayChange}
                      className="me-3"
                      id="leaveDayFull"
                    />
                    <RB.Form.Check
                      type="radio"
                      label="Custom"
                      name="leaveDay"
                      value="custom"
                      checked={leaveDayType === "custom"}
                      onChange={handleLeaveDayChange}
                      id="leaveDayCustom"
                    />
                  </div>
                </RB.Form.Group>
              </RB.Col>

              {leaveDayType === "custom" && showHalfDayDropdowns > 0 && (
                <RB.Col md={6}>
                  <RB.Row className={`${showHalfDayDropdowns === 2 ? 'justify-content-center align-items-center':''}`}>
                    <>
                      <RB.Col md={5}>
                        <div className="mb-3">
                          <RB.FloatingLabel
                            className="floating-label-fancy"
                            label={`${
                              showHalfDayDropdowns === 1 ? "On" : "From"
                            } ${moment(formData.startDate).format(
                              "DD-MM-YYYY"
                            )}`}
                          >
                            <RB.Form.Control
                              as="select"
                              name="leave_half_day_1"
                              value={formData.leave_half_day_1}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="first_half">First Half</option>
                              <option value="second_half">Second Half</option>
                            </RB.Form.Control>
                          </RB.FloatingLabel>
                        </div>
                      </RB.Col>
                      {showHalfDayDropdowns === 2 ? (
                        <RB.Col md={1}>-</RB.Col>
                      ) : null}
                      {showHalfDayDropdowns === 2 && (
                        <RB.Col md={5}>
                          <div className="mb-3">
                            <RB.FloatingLabel
                              className="floating-label-fancy"
                              label={`To ${moment(formData.endDate).format(
                                "DD-MM-YYYY"
                              )}`}
                            >
                              <RB.Form.Control
                                as="select"
                                name="leave_half_day_2"
                                value={formData.leave_half_day_2}
                                onChange={handleInputChange}
                                required
                              >
                                <option value="first_half">First Half</option>
                                <option value="second_half">Second Half</option>
                              </RB.Form.Control>
                            </RB.FloatingLabel>
                          </div>
                        </RB.Col>
                      )}
                    </>
                  </RB.Row>
                  {/* {calculatedDays > 0 && (
                    <RB.Row className="justify-content-center align-items-center">
                      <RB.Col md={12}>
                        <div className="mb-3 mt-2">
                          <small className="text-muted">
                            <i className="fa fa-info-circle me-1" />
                            You are requesting for{" "}
                            <strong>{calculatedDays} days</strong> of leave
                          </small>
                        </div>
                      </RB.Col>
                    </RB.Row>
                  )} */}
                </RB.Col>
              )}

              <CustomDropdown
                formData={formData}
                leaveTypeList={leaveTypeList}
                handleInputChange={handleDropDownChange}
              />
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Reason"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="textarea"
                      placeholder="Enter Reason"
                      name="reason"
                      value={formData.reason}
                      onChange={handleInputChange}
                      required
                      maxLength={200}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row className="justify-content-start mt-3">
              <RB.Col className="col-auto">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
