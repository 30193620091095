import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import List from "../Components/Travel/List";
import Add from "../Components/Travel/Add";
import { useLocation } from "react-router-dom";
import TravelDocument from "../Components/Travel/TravelDocument";
import View from "../Components/Travel/View";

function Travel() {
  const [comp, setComp] = useState("list");
  const [rowData, setRowData] = useState();
  const location = useLocation();

  useEffect(() => {
    setComp("list");
  }, [location]);

  function handleChange(newValue) {
    setComp(newValue.mode);
    setRowData(newValue.rowData);
  }

  // Render the appropriate component based on the comp state
  function renderComponent() {
    switch (comp) {
      case "list":
        return <List comp={comp} onChange={handleChange} rowData={rowData} />;
      case "travel":
        return <TravelDocument comp={comp} rowData={rowData} onChange={handleChange} />;
      case "view":
        return <View comp={comp} rowData={rowData} onChange={handleChange} />;
      default:
        return <Add comp={comp} rowData={rowData} onChange={handleChange} />;
    }
  }

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>{comp === "travel" ? "Travel Documents" : "Travel Plan"}</h1>
          </div>
          {renderComponent()}
        </section>
      </div>
    </Layout>
  );
}

export default Travel;
