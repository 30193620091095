import React, { useState } from "react";
import Layout from "../Layout";
import * as RB from "react-bootstrap";
import SalaryGenerate from "../Components/SalaryGenerate/SalaryGenerate";

function Salary() {
  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Employee Salary</h1>
          </div>
          <div className="section-body">
            <SalaryGenerate />
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Salary;
