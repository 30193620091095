import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useLocation } from "react-router-dom";
import List from "../Components/TeamLeaveRequest/List";
import { Tab, Tabs } from "react-bootstrap";
import OtherTeamLeave from "../Components/TeamLeaveRequest/OtherTeamLeave";
import { useSelector } from "react-redux";
import Leaves from "../Components/Leaves/Leaves";

function TeamLeaveRequest() {
  const [comp, setComp] = useState("list");
  const [rowData, setRowData] = useState();
  const location = useLocation();

  useEffect(() => {
    setComp("list");
  }, [location]);

  function handleChange(newValue) {
    setComp(newValue.mode);
    setRowData(newValue.rowData);
  }
  const [activeTab, setActiveTab] = useState("yourTeam");

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };
  const userProfile = useSelector((state) => state.userProfile.userProfile);

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>
              {activeTab === "yourTeam"
                ? "Team Leave Request"
                : "Other Team Leave Request"}
            </h1>
          </div>
          {/* {userProfile.user_role[0].roleName == "HR Manager" ? (
            <>
              <Leaves />
            </>
          ) : (
            <> */}
              <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id="leave-request-tabs"
              >
                <Tab eventKey="yourTeam" title="Your Team Request">
                  {activeTab === "yourTeam" && <List />}
                </Tab>
                <Tab eventKey="otherTeam" title="Other Team Request">
                  {activeTab === "otherTeam" && <OtherTeamLeave />}
                </Tab>
              </Tabs>
            {/* </>
          )} */}
        </section>
      </div>
    </Layout>
  );
}

export default TeamLeaveRequest;
