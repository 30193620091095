import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../Utils/comman";

export const fetchTravelHeadList = createAsyncThunk(
  "travelHead/fetchTravelHeadList",
  async (_, thunkAPI) => {
    try {
      const response = await utils.callAPI("get", "/travel-head/list");
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  travelHeadList: [],
  isLoading: false,
  isError: false,
};

const travelHeadSlice = createSlice({
  name: "travelHead",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTravelHeadList.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchTravelHeadList.fulfilled, (state, action) => {
        state.travelHeadList = action.payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchTravelHeadList.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default travelHeadSlice.reducer;
