import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import {
  Delete,
  Description,
  Download,
  NoteAlt,
  Visibility,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { setEmpTDS } from "./../../reducers/employeeTDSSlice";
import { Link } from "react-router-dom";

function View(props) {
  const dispatch = useDispatch();
  const [empDetails, setEmpDetails] = useState([]);
  const userProfile = useSelector((state) => state?.userProfile?.userProfile);
  const employeeTds = useSelector((state) => state?.empTDS?.employeeTDS);
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [newFilesUploaded, setNewFilesUploaded] = useState(false);

  const [showNote, setShowNote] = useState(false);
  const [note, setNote] = useState({});

  const [docLength, setDocLength] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [docs, setDocs] = useState();
  const [fieldNotes, setFieldNotes] = useState({});
  const [uploadAll, setUploadAll] = useState(false);

  const fieldData = [
    {
      section: "Exemption u/s 10",
      fields: [
        {
          name: "houseRentAllowance",
          label: "House Rent Allowance",
          particulars:
            "I am staying in a Rented House and I agree to submit Rent receipts or Rent Agreement with the Landlord when required. Rent Paid is ___x____ Months.",
          eligibility: "Metro/Non-Metro",
          value: employeeTds?.house_rent_allowance,
          docHeadName: "House Rent Allowance",
        },
      ],
    },
    {
      section: "Deduction u/s 24",
      fields: [
        {
          name: "interestHousingLoanBefore1999",
          label: "1",
          docHeadName: "House Loan Before 1999",
          particulars:
            "Interest on Housing Loan u/s 24 (if the house is Self-Occupied and the loan was taken before 1st April 1999) Bankers Certificate to be Submitted",
          eligibility: (
            <>
              Upto Rs. 30,000/-
              <br />
              (If Self-Occupied)
            </>
          ),
          value: employeeTds?.deduction_24_a,
        },
        {
          name: "interestHousingLoanAfter1999",
          docHeadName: "House Loan After 1999",
          label: "2",
          particulars:
            "Interest on Housing Loan u/s 24 (if the house is Self-Occupied and the loan was taken after 1st April 1999) Bankers Certificate to be Submitted",
          eligibility: (
            <>
              Upto Rs. 200,000/-
              <br />
              (If Self-Occupied)
            </>
          ),
          value: employeeTds?.deduction_24_b,
        },
        {
          name: "interestHousingLoanLetOut",
          docHeadName: "Housing Loan u/s 24",
          label: "3",
          particulars: (
            <>
              Interest on Housing Loan u/s 24 (Let-out/Deemed to be Let-out)
              <br />
              (if the property is LET-OUT Rental income need to be specified)
            </>
          ),

          eligibility: "",
          value: employeeTds?.deduction_24_c,
        },
      ],
    },
    {
      section: "Deduction u/s 80C (Maximum Amount Upto Rs. 150000)",
      fields: [
        {
          name: "lifeInsurancePremium",
          docHeadName: "Life Insurance Premium (LIC)",
          label: "1",
          particulars: "Life Insurance Premium (LIC)",
          eligibility: "",
          placeholder: "Enter amount",
          value: employeeTds?.lic,
        },
        {
          name: "providentFund",
          label: "2",
          docHeadName: "Provident Fund (PF)",
          particulars: "Provident Fund (PF)",
          eligibility: "",
          value: employeeTds?.pf,
        },
        {
          name: "publicProvidentFund",
          label: "3",
          docHeadName: "Public Provident Fund (PPF)",
          particulars: "Public Provident Fund (PPF)",
          eligibility: "",
          value: employeeTds?.ppf,
        },
        {
          name: "voluntaryProvidentFund",
          docHeadName: "Voluntary Provident Fund (VPF)",
          label: "4",
          particulars: "Voluntary Provident Fund (VPF)",
          eligibility: "",
          value: employeeTds?.vpf,
        },
        {
          name: "nationalSavingsCertificate",
          docHeadName: "National Savings Certificate (NSC)",
          label: "5",
          particulars: "National Savings Certificate (NSC)",
          eligibility: "",
          value: employeeTds?.nsc,
        },
        {
          name: "interestNSC",
          label: "6",
          docHeadName: "Interest accrued on NSC",
          particulars: "Interest accrued on NSC (Re-invested)",
          eligibility: "",
          value: employeeTds?.re_invested,
        },
        {
          name: "unitLinkedInsurancePolicy",
          label: "7",
          docHeadName: "Unit Linked Insurance Policy",
          particulars: "Unit Linked Insurance Policy (ULIP)",
          eligibility: "",
          value: employeeTds?.ulip,
        },
        {
          name: "equityLinkedSavingsScheme",
          label: "8",
          docHeadName: "ELSS",
          particulars: "Equity Linked Savings Scheme (ELSS) Mutual Fund",
          eligibility: "",
          value: employeeTds?.elss,
        },
        {
          name: "tuitionFees",
          label: "9",
          docHeadName: "Payment for Tuition Fees",
          particulars:
            "Payment for Tuition Fees for Children (Max. 2 Children)",
          eligibility: "",
          value: employeeTds?.tution_fees,
        },
        {
          name: "principalRepayment",
          docHeadName: "Repayment of Housing Loan",
          label: "10",
          particulars: "Principal Repayment of Housing Loan",
          eligibility: "",
          value: employeeTds?.housing_loan_repayment,
        },
        {
          name: "stampDuty",
          label: "11",
          docHeadName: "Stamp Duty",
          particulars:
            "Stamp Duty Registration charges for Buying House (1st Year Only)",
          eligibility: "",
          value: employeeTds?.stamp_duty_registration_charge,
        },
        {
          name: "infrastructureBonds",
          docHeadName: "Infrastructure Bonds",
          label: "12",
          particulars: "Infrastructure Bonds",
          eligibility: "",
          value: employeeTds?.infrastructure_bond,
        },
        {
          name: "bankFixedDeposit",
          label: "13",
          docHeadName: "Bank Fixed Deposit",
          particulars: "Bank Fixed Deposit for 5 Years & Above",
          eligibility: "",
          value: employeeTds?.bank_fd,
        },
        {
          name: "postOfficeTermDeposit",
          label: "14",
          docHeadName: "Post Office",
          particulars: "Post Office Term Deposit For 5 Years & Above",
          eligibility: "",
          value: employeeTds?.post_term_deposit,
        },
        {
          name: "seniorCitizenSavingScheme",
          label: "15",
          docHeadName: "Senior Citizen Scheme",
          particulars: "Senior Citizen Saving Scheme",
          eligibility: "",
          value: employeeTds?.senior_citizen_saving,
        },
        {
          name: "sukanyaSamriddhi",
          label: "16",
          docHeadName: "Sukanya Samriddhi",
          particulars: "Sukanya Samriddhi Account Scheme",
          eligibility: "",
          value: employeeTds?.sukanya_samriddhi_account,
        },
        {
          name: "others80C",
          label: "17",
          docHeadName: "Other",
          particulars: "Others (Please Specify)",
          eligibility: "",
          value: employeeTds?.other_deduction,
        },
      ],
    },
    {
      section: "Deduction u/s 80CCC",
      fields: [
        {
          name: "contributionPensionFunds",
          docHeadName: "Pension Funds",
          label: "Contribution to Certain Pension Funds",
          particulars: "",
          eligibility: "Upto Rs. 150,000/-",
          value: employeeTds?.deduction_80ccc,
        },
      ],
    },
    {
      section: "Deduction u/s 80CCD(1)",
      fields: [
        {
          name: "contributionPensionScheme",
          docHeadName: "Pension Scheme",
          label: "Contribution to Pension Scheme of Central Government",
          particulars: "",
          eligibility: "Upto Rs. 150,000/-",
          value: employeeTds?.deduction_80ccd_A,
        },
      ],
    },
    {
      section: "Deduction u/s 80CCD(1B)",
      fields: [
        {
          name: "contributionNPS",
          docHeadName: "Contribution NPS",
          label: "Contribution to National Pension Scheme",
          particulars: "",
          eligibility: "Upto Rs. 50,000/-",
          value: employeeTds?.deduction_80ccd_B,
        },
      ],
    },
    {
      section: "Deduction u/s 80",
      fields: [
        {
          name: "medicalInsuranceIndividual",
          docHeadName: "Medical Insurance",
          label: "80 D",
          particulars:
            "Medical Insurance Premium for Individual, Spouse & Children",
          eligibility:
            "(Max Limit – Rs. 25,000/- Additional Rs. 25,000, if any person insured is a Senior Citizen)",
          value: employeeTds?.deduction_80_d_a,
        },
        {
          name: "medicalInsuranceParents",
          label: "80 D",
          docHeadName: "Medical Insurance(Parents)",
          particulars: "Medical Insurance Premium for Parents",
          eligibility:
            "(Max Limit – Rs. 25,000/- Additional Rs. 25,000, if any person insured is a Senior Citizen)",
          value: employeeTds?.deduction_80_d_b,
        },
        {
          name: "preventiveHealthCheckup",
          label: "80 D",
          docHeadName: "HealthCheckup",
          particulars: "Preventive Health Check-up",
          eligibility:
            "Max. Rs. 5,000/- (Within overall limit of Rs. 25,000/50,000)",
          value: employeeTds?.deduction_80_d_c,
        },
        {
          name: "interestEducationLoan",
          label: "80 E",
          docHeadName: "Education Loan",
          particulars: "Interest for Loan taken for Higher Education",
          eligibility: "No Limit (Only Interest Portion)",
          value: employeeTds?.deduction_80_d_e,
        },
        {
          name: "interestSavingAccount",
          label: "80 TTA",
          docHeadName: "Interest Saving Account",
          particulars:
            "Deduction in respect of interest on deposits in saving account",
          eligibility: "Upto Rs. 10,000/-",
          value: employeeTds?.deduction_80_d_tta,
        },
        {
          name: "others80",
          docHeadName: "Other",
          label: "Others (Please Specify)",
          particulars: "",
          eligibility: "",
          value: employeeTds?.deduction_80_d_other,
        },
      ],
    },
  ];

  const [userRole, setUserRole] = useState(true); // Initialize with true, assuming it's a normal user by default

  // Inside your component, where you receive the userProfile
  useEffect(() => {
    // Assuming userProfile is set somewhere in your component
    if (
      userProfile &&
      userProfile.user_role &&
      userProfile.user_role.length > 0
    ) {
      const roleName = userProfile?.user_role[0]?.roleName;
      // If user is not HR or Super Admin, set userRole to true, otherwise set it to false
      setUserRole(
        roleName !== "HR Manager" && roleName !== "Super Admin"
      );
    }
  }, [userProfile]);

  const getEmployeeDetails = () => {
    var bodyFormData = new FormData();

    bodyFormData.append("id", employeeTds?.employeeId);

    utils
      .callAPI("post", `/employee/getEmployeeDetails`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          setEmpDetails(res);
        } else {
          setEmpDetails([]);
        }
      })
      .catch((err) => {
        setEmpDetails([]);
      });
  };

  const getDocDetails = (name) => {
    var bodyFormData = new FormData();
    bodyFormData.append("employeeId", employeeTds?.employeeId);
    bodyFormData.append("tdsId", employeeTds?.id);
    bodyFormData.append("document_type", name);

    utils
      .callAPI("post", `/employee/getEmployeeTDSDocuments`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          setDocLength(res?.length);
          setDocs(res);
        } else {
          setDocs([]);
        }
      })
      .catch((err) => {});
  };

  const getNoteDetails = (name) => {
    var bodyFormData = new FormData();

    bodyFormData.append("employeeId", employeeTds?.employeeId);
    bodyFormData.append("tdsId", employeeTds?.id);
    bodyFormData.append("document_type", name);

    utils
      .callAPI("post", `/employee/getEmployeeTDSDocumentsNotes`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          if (res && res.length > 0) {
            const noteData = res[0].notes;
            setNote((prevNotes) => ({
              ...prevNotes,
              [name]: noteData,
            }));
          } else {
            setNote((prevNotes) => ({
              ...prevNotes,
              [name]: "",
            }));
          }
        } else {
          setNote("");
        }
      })
      .catch((err) => {});
  };

  const getAllNoteDetails = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("document_type", "");
    bodyFormData.append("employeeId", employeeTds?.employeeId);
    bodyFormData.append("tdsId", employeeTds?.id);

    utils
      .callAPI("post", `/employee/getEmployeeTDSDocumentsNotes`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          res.forEach((note) => {
            // Store the note with the document_type as the key

            setFieldNotes((prevState) => ({
              ...prevState,
              [note.document_type]: note.notes,
            }));
          });
        } else {
          setDocs([]);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getEmployeeDetails();
    getAllNoteDetails();
    getEmployeeList();
  }, [dispatch]);

  const getEmployeeList = () => {
    var bodyFormData = new FormData();

    bodyFormData.append("employeeId", employeeTds?.employeeId);
    bodyFormData.append("tdsId", employeeTds?.id);

    utils
      .callAPI("post", `/employee/getAllTDSList`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          dispatch(setEmpTDS(res[0]));
        } else {
          dispatch(setEmpTDS([]));
        }
      })
      .catch((err) => {
        dispatch(setEmpTDS([]));
      });
  };

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const handleEdit = () => {
    const propdata = {
      mode: "edit",
      // rowData: rowData,
    };
    props.onChange(propdata);
  };

  const handleDeleteDocServer = async (doc) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI(
            "post",
            `employee/DeleteEmployeeTDSDocuments?tdsId=${doc?.id}`,
            ""
          )
          .then((res) => {
            if (typeof res !== "undefined") {
              getDocDetails(doc?.document_type);
            }
          })
          .catch((err) => {});
      }
    });
  };

  const handleShowModal = (row) => {
    setSelectedRow(row);
    getDocDetails(row.name);

    setShowModal(true);
  };

  const handleShowNote = (row) => {
    setSelectedRow(row);

    getNoteDetails(row.name);

    setShowNote(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedRow(null);
    setSelectedFiles([]);
  };

  const handleNoteChange = (e) => {
    const { value } = e.target;

    const name = selectedRow?.name;

    if (name) {
      setNote((prevNotes) => ({
        ...prevNotes,
        [name]: value,
      }));
    }
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleDeleteDoc = (index) => {
    setSelectedFiles((prevDocs) => prevDocs.filter((_, i) => i !== index));
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
      //   "application/vnd.ms-excel",
      //  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/msword",
      //  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    const validFiles = files.filter((file) => allowedTypes.includes(file.type));

    if (validFiles?.length < files?.length) {
      toast.error(
        "Invalid file type. Please upload JPEG, JPG, PNG, PDF files.",
        {
          position: "top-right",
          autoClose: 5000,
        }
      );
    }

    const newDocs = validFiles.map((file, index) => ({
      name: `${selectedRow.name} - Document ${index + 1}`,
      file: file,
      document: URL.createObjectURL(file),
    }));

    setSelectedFiles((prevDocs) => [...prevDocs, ...newDocs]);
    setNewFilesUploaded(true);
  };

  const handleSaveNote = () => {
    const formData = new FormData();
    formData.append("employeeId", employeeTds?.employeeId);
    formData.append("tdsId", employeeTds?.id);
    formData.append("document_type", selectedRow?.name);
    formData.append("notes", note[selectedRow?.name] || "");

    utils
      .callAPI("post", "/employee/addEmployeeTDSDocumentNotes", formData)
      .then((response) => {
        getEmployeeList();
        getAllNoteDetails();
        setShowNote(false);
        setNote("");
      })
      .catch((error) => {
        //  console.error("Error uploading documents", error);
      });
  };

  const handleUploadClick = () => {
    // Handle the actual document upload logic here
    const newFilesToUpload = selectedFiles.filter((file) => !file.id);

    // Handle the actual document upload logic here
    const formData = new FormData();
    newFilesToUpload.forEach((file) => {
      formData.append("document[]", file.file);
    });
    formData.append("employeeId", employeeTds?.employeeId);
    formData.append("tdsId", employeeTds?.id);
    formData.append("document_type", selectedRow?.name);

    // Example API call to upload documents
    utils
      .callAPI("post", "/employee/addEmployeeTDSDocument", formData)
      .then((response) => {
        // Assuming the response contains uploaded documents data
        // setUploadedDocs((prevDocs) => [...prevDocs, ...response]);
        setNewFilesUploaded(false);
        getDocDetails(selectedRow?.name);
        setSelectedFiles([]);
        setShowModal(false);
      })
      .catch((error) => {
        //  console.error("Error uploading documents", error);
      });
  };

  const handleAllUploadClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once uploaded, HR could verify your documents!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, upload all",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI(
            "post",
            `employee/updateStatusTDS?tdsId=${employeeTds?.id}`,
            ""
          )
          .then((res) => {
            if (typeof res !== "undefined") {
              // getDocDetails(doc?.document_type);
              getEmployeeList();
            }
          })
          .catch((err) => {});
      }
    });
  };

  const handleVerify = () => {
    Swal.fire({
      title: "Confirm Verification?",
      html: `
       <div style="text-align: left; line-height: 1.5;">
        <p style="margin-bottom: 10px; font-size: 1.1em;">Please review the following before verifying:</p>
        <ul style="margin-bottom: 10px; padding-left: 20px;">
        
          <li style="margin-bottom: 5px;">Ensure all amounts are accurate.</li>
          <li style="margin-bottom: 5px;">Verify that all documents are correct.</li>
              <li style="margin-bottom: 5px;">Tax recalculation will occur if you click on verify.</li>
        </ul>
        <p style="margin-bottom: 0; font-size: 1.1em;">If everything is correct, click "Yes, Verify".</p>
      </div>
    `,
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, Verify",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI(
            "post",
            `/employee/VerifyDocumentsTDS?tdsId=${employeeTds?.id}`,
            ""
          )
          .then((res) => {
            if (typeof res !== "undefined") {
              // getDocDetails(doc?.document_type);
              getEmployeeList();
            }
          })
          .catch((err) => {});
      }
    });
  };

  const isSaveEnabled = selectedFiles?.length > 0 && newFilesUploaded;

  return (
    <div className="section-body">
      <RB.Card className="card shadow">
        <RB.Card.Header className="border-0">
          <h4></h4>
          <div className="card-header-action ">
            <RB.Button className="btn btn-light me-2" onClick={handleBackClick}>
              Back
            </RB.Button>
            <RB.Button className="btn btn-light" onClick={handleEdit}>
              Edit TDS
            </RB.Button>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <h5>Personal Information</h5>
          <hr className="hr-lg mt-1" />
          <RB.Row>
            <RB.Col md={4}>
              <span className="text-uppercase fw-bold small text-body-secondary">
                Name
              </span>
              <p className="fw-medium text-sm fw-semibold">
                {empDetails?.firstName ? empDetails.firstName : ""}{" "}
                {empDetails?.lastName ? empDetails.lastName : ""}
              </p>
            </RB.Col>
            <RB.Col md={4}>
              <span className="text-uppercase fw-bold small text-body-secondary">
                Designation
              </span>
              <p className="fw-medium text-sm fw-semibold">
                {empDetails?.roleName}
              </p>
            </RB.Col>

            <RB.Col md={4}>
              <span className="text-uppercase fw-bold small text-body-secondary">
                Department
              </span>
              <p className="fw-medium text-sm fw-semibold">
                {empDetails?.department_name}
              </p>
            </RB.Col>

            <RB.Col md={4}>
              <span className="text-uppercase fw-bold small text-body-secondary">
                PAN No
              </span>
              <p className="fw-medium text-sm fw-semibold">
                {empDetails?.panNo}
              </p>
            </RB.Col>

            <RB.Col md={4}>
              <span className="text-uppercase fw-bold small text-body-secondary">
                Tax Scheme
              </span>
              <p className="fw-medium text-sm fw-semibold">
                {employeeTds?.tax_scheme === "old"
                  ? "Old Tax Scheme"
                  : "New Tax Scheme"}
              </p>
            </RB.Col>
          </RB.Row>
          <div className="table-responsive">
            <RB.Table striped bordered hover>
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Particulars</th>
                  <th>Eligibility</th>
                  <th>Amount (INR)</th>
                  <th>Documents</th>

                  {Object.keys(fieldNotes).length !== 0 &&
                    employeeTds?.status === "0" &&
                    userProfile?.user_role[0]?.roleName !==
                      "HR Manager" &&
                    userProfile?.user_role[0]?.roleName !== "Super Admin" && (
                      <>
                        <th>Note</th>
                      </>
                    )}
                  {(userProfile?.user_role[0]?.roleName ===
                    "HR Manager" ||
                    userProfile?.user_role[0]?.roleName === "Super Admin") && (
                    <>
                      <th>Note</th>
                    </>
                  )}

                  {/* {userProfile?.user_role[0]?.roleName !== "HR Manager" &&
                  userProfile?.user_role[0]?.roleName !== "Super Admin" && (
                    <>
                      <th>Documents</th>
                      <th>Note</th>
                    </>
                  )} */}
                </tr>
              </thead>

              <tbody>
                {fieldData.map((section) => (
                  <React.Fragment key={section.section}>
                    <tr>
                      <td colSpan={12}>{section.section}</td>
                    </tr>
                    {section.fields.map((field) => (
                      <tr
                        key={field.name}
                        className={
                          fieldNotes[field.name] && employeeTds?.status === "0"
                            ? "highlight-tr"
                            : ""
                        }
                      >
                        <td className="col-2 ">{field.label}</td>
                        <td className="col-4">{field.particulars}</td>
                        <td className="col-2">{field.eligibility}</td>
                        <td className="col-2 ">
                          {field?.value ? field.value : ""}
                        </td>
                        <td className="col-2">
                          <RB.Button onClick={() => handleShowModal(field)}>
                            <Description />
                          </RB.Button>
                        </td>
                        {Object.keys(fieldNotes).length !== 0 &&
                          employeeTds?.status === "0" &&
                          userProfile?.user_role[0]?.roleName !==
                            "HR Manager" &&
                          userProfile?.user_role[0]?.roleName !==
                            "Super Admin" && (
                            <td className="col-2">
                              {fieldNotes[field.name] &&
                                userProfile?.user_role[0]?.roleName !==
                                  "HR Manager" &&
                                userProfile?.user_role[0]?.roleName !==
                                  "Super Admin" && (
                                  <RB.Button
                                    variant="outline-secondary"
                                    className=""
                                    onClick={() => handleShowNote(field)}
                                  >
                                    <NoteAlt />
                                  </RB.Button>
                                )}
                            </td>
                          )}

                        {(userProfile?.user_role[0]?.roleName ===
                          "HR Manager" ||
                          userProfile?.user_role[0]?.roleName ===
                            "Super Admin") && (
                          <td className="col-2">
                            <RB.Button
                              variant="outline-secondary"
                              className=""
                              onClick={() => handleShowNote(field)}
                            >
                              <NoteAlt />
                            </RB.Button>
                          </td>
                        )}

                        {/* {(userProfile?.user_role[0]?.roleName ===
                        "HR Manager" ||
                        userProfile?.user_role[0]?.roleName ===
                          "Super Admin") && (
                         rowData.status === "1"&&
                        <td className="col-2">
                          <RB.Button onClick={() => handleShowModal(field)}>
                            <Description />
                          </RB.Button>
                        </td>
                      )} */}
                        {/* {userProfile?.user_role[0]?.roleName !==
                        "HR Manager" &&
                        userProfile?.user_role[0]?.roleName !==
                          "Super Admin" && (
                          <td className="col-2">
                            <RB.Button onClick={() => handleShowModal(field)}>
                              <Description />
                            </RB.Button>
                          </td>
                        )} */}

                        {/* {userProfile?.user_role[0]?.roleName !==
                        "HR Manager" &&
                        userProfile?.user_role[0]?.roleName !==
                          "Super Admin" && (
                          <td className="col-2">
                            <RB.Button
                              variant="outline-secondary"
                              className=""
                              onClick={() => handleShowNote(field)}
                            >
                              <NoteAlt />
                            </RB.Button>
                          </td>
                        )} */}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </RB.Table>
          </div>
          {employeeTds?.other_income?.length > 0 && (
            <RB.Row className="my-4">
              <h6>Other Income</h6>
              {employeeTds.other_income.map((incomeItem, index) => (
                <RB.Col md={4} key={index}>
                  <div className="border p-3 rounded h-100">
                    <span className="text-uppercase fw-bold small text-body-secondary mr-2">
                      {incomeItem.title}
                    </span>
                    <p className="fw-medium text-sm fw-semibold mb-0">
                      {incomeItem.amount}
                    </p>
                  </div>
                </RB.Col>
              ))}
            </RB.Row>
          )}
          <RB.Form.Group
            as={RB.Row}
            className="mb-3 align-items-end"
            controlId="formHorizontalEmail"
          >
            {/* <RB.Form.Label column sm={4} className="mb-3 pb-0">
                Date
              </RB.Form.Label> */}
            <RB.Col className="mb-3" sm={2}>
              <RB.Form.Label className="floating-label-fancy">
                Date
              </RB.Form.Label>
              <span className="d-block lh-1">
                {" "}
                {moment(employeeTds?.createdAt).format("DD-MM-YYYY")}
              </span>
            </RB.Col>

            <RB.Col className="mb-3" md={5}>
              <RB.Form.Label className="floating-label-fancy">
                Place
              </RB.Form.Label>
              <span className="d-block lh-1">{employeeTds?.place}</span>
            </RB.Col>
          </RB.Form.Group>

          {userProfile?.user_role[0]?.roleName !== "HR Manager" &&
            userProfile?.user_role[0]?.roleName !== "Super Admin" &&
            employeeTds?.status === "0" && (
              <RB.Button
                variant="primary"
                onClick={handleAllUploadClick}
                //disabled={!isSaveEnabled}
                className=""
              >
                Upload All
              </RB.Button>
            )}
          {!userRole && (
            <RB.Button
              variant="primary"
              onClick={handleVerify}
              //disabled={!isSaveEnabled}
              className=""
            >
              Verify Documents
            </RB.Button>
          )}
        </RB.Card.Body>
      </RB.Card>

      <RB.Modal show={showModal} onHide={handleCloseModal}>
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>{selectedRow?.docHeadName}</RB.Modal.Title>
        </RB.Modal.Header>

        <RB.Modal.Body>
          {selectedFiles?.length < 1 &&
            docs?.length < 1 &&
            userProfile?.user_role[0]?.roleName !== "HR Manager" &&
            userProfile?.user_role[0]?.roleName !== "Super Admin" && (
              <p className="text-center">No Documents Uploaded...</p>
            )}

          {selectedFiles?.length > 0 && (
            <>
              <h6 className="text-body-secondary mb-3">New Documents</h6>
              {selectedFiles.map((doc, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-2"
                >
                  <span
                    // href={doc.document}
                    // target="_blank"
                    // rel="noopener noreferrer"
                    className="text-dark"
                  >
                    {doc?.file?.name}
                  </span>
                  {/* <button
                    // variant="secondary"
                    size="sm"
                    className="ms-2 btn-link"
                    href={doc.document}
                    download={doc.name}
                  >
                    <Download />
                  </button> */}
                  <div>
                    <a
                      //  variant="danger"
                      //size="sm"
                      className="btn-link text-dark me-2"
                      target="_blank"
                      //rel="noopener noreferrer"
                      href={doc.document}
                    >
                      <Visibility />
                    </a>

                    <Link
                      //  variant="danger"
                      //size="sm"
                      className="btn-link text-danger"
                      onClick={() => handleDeleteDoc(index)}
                    >
                      <Delete />
                    </Link>
                  </div>
                </div>
              ))}
            </>
          )}

          {docs &&
            (userProfile?.user_role[0]?.roleName === "HR Manager" ||
              userProfile?.user_role[0]?.roleName === "Super Admin") &&
            employeeTds?.status === "1" && (
              <h6 className="text-body-secondary mb-3 mt-3">
                Uploaded Documents
              </h6>
            )}

          {docs &&
            userProfile?.user_role[0]?.roleName !== "HR Manager" &&
            userProfile?.user_role[0]?.roleName !== "Super Admin" && (
              <h6 className="text-body-secondary mb-3 mt-3">
                Uploaded Documents
              </h6>
            )}

          {docs &&
          (userProfile?.user_role[0]?.roleName === "HR Manager" ||
            userProfile?.user_role[0]?.roleName === "Super Admin") &&
          //rowData.status === "1"
          employeeTds?.status === "1"
            ? docs.map((doc, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-2"
                >
                  <span className="text-dark">{doc?.document_name}</span>

                  <div>
                    <a
                      className="btn-link text-dark me-2"
                      target="_blank"
                      href={doc.document}
                    >
                      <Visibility />
                    </a>

                    {userProfile?.user_role[0]?.roleName !==
                      "HR Manager" &&
                      userProfile?.user_role[0]?.roleName !== "Super Admin" && (
                        <Link
                          className="btn-link text-danger"
                          onClick={() => handleDeleteDocServer(doc)}
                        >
                          <Delete />
                        </Link>
                      )}
                  </div>
                </div>
              ))
            : (userProfile?.user_role[0]?.roleName === "HR Manager" ||
                userProfile?.user_role[0]?.roleName === "Super Admin") && (
                <p className="text-center">No Documents Uploaded...</p>
              )}

          {docs &&
            userProfile?.user_role[0]?.roleName !== "HR Manager" &&
            userProfile?.user_role[0]?.roleName !== "Super Admin" &&
            docs.map((doc, index) => (
              <div
                key={index}
                className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-2"
              >
                <span
                  // href={doc.document}
                  //target="_blank"
                  //rel="noopener noreferrer"
                  className="text-dark"
                >
                  {/* {" Document " + (index + 1)} */}
                  {doc?.document_name}
                </span>
                {/* <RB.Button
                  variant="secondary"
                  size="sm"
                  className="ms-2"
                  href={doc.document}
                  download={"Document " + index}
                >
                  <Download />
                </RB.Button> */}
                <div>
                  {/* <Link
                    //  variant="danger"
                    //size="sm"
                    className="btn-link text-dark me-2"
                    //target="_blank"
                    //rel="noopener noreferrer"
                    href={
                      "http://65.1.27.14/Vura_API_Server/src/uploads/employee_documents/MicrosoftTeams-image%20%2823%29.png"
                    }
                  >
                   
                    <Visibility />
                  </Link> */}
                  <a
                    //  variant="danger"
                    //size="sm"
                    className="btn-link text-dark me-2"
                    target="_blank"
                    //rel="noopener noreferrer"
                    href={doc.document}
                  >
                    <Visibility />
                  </a>

                  {userProfile?.user_role[0]?.roleName !== "HR Manager" &&
                    userProfile?.user_role[0]?.roleName !== "Super Admin" && (
                      <Link
                        //  variant="danger"
                        //size="sm"
                        className="btn-link text-danger"
                        onClick={() => handleDeleteDocServer(doc)}
                      >
                        <Delete />
                      </Link>
                    )}
                </div>
              </div>
            ))}
        </RB.Modal.Body>
        <RB.Modal.Footer>
          <RB.Form.Control
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="file-input"
            accept=".jpg,.jpeg,.png,.pdf,.xls,.xlsx,.doc,.docx"
          />

          {userProfile?.user_role[0]?.roleName !== "HR Manager" &&
            userProfile?.user_role[0]?.roleName !== "Super Admin" &&
            employeeTds?.status === "0" && (
              <>
                <RB.Button
                  variant="primary"
                  onClick={() => document.getElementById("file-input").click()}
                >
                  Select Documents
                </RB.Button>

                <RB.Button
                  variant="primary"
                  onClick={handleUploadClick}
                  disabled={!isSaveEnabled}
                  className="ms-2"
                >
                  Save
                </RB.Button>
              </>
            )}

          <RB.Button variant="light" onClick={handleCloseModal}>
            Close
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>

      <RB.Modal show={showNote} onHide={() => setShowNote(false)}>
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>{selectedRow?.docHeadName}</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          {userRole ? (
            <>
              <h6>Note</h6>
              <p>{note[selectedRow?.name] || ""}</p>
            </>
          ) : (
            <div className="mb-3">
              <RB.FloatingLabel
                controlId="description"
                label="Note"
                className="floating-label-fancy"
              >
                <RB.Form.Control
                  as="textarea"
                  type="text"
                  placeholder="Enter Note"
                  name="description"
                  value={note[selectedRow?.name] || ""}
                  onChange={handleNoteChange}
                  maxLength={500}
                  // disabled={userRole}
                />
              </RB.FloatingLabel>
            </div>
          )}
          {/* <div className="mb-3">
            <RB.FloatingLabel
              controlId="description"
              label="Note"
              className="floating-label-fancy"
            >
              <RB.Form.Control
                as="textarea"
                type="text"
                placeholder="Enter Note"
                name="description"
                value={note[selectedRow?.name] || ""}
                onChange={handleNoteChange}
                maxLength={500}
                disabled={userRole}
              />
            </RB.FloatingLabel>
          </div> */}
        </RB.Modal.Body>
        <RB.Modal.Footer>
          <RB.Button variant="secondary" onClick={() => setShowNote(false)}>
            Close
          </RB.Button>
          {!userRole && (
            <RB.Button variant="primary" onClick={handleSaveNote}>
              Save Note
            </RB.Button>
          )}
        </RB.Modal.Footer>
      </RB.Modal>
    </div>
  );
}

export default View;
