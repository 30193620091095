import React, { useState } from "react";
import Layout from "../Layout";
import List from "../Components/Attendances/List";

function Attendances() {
  const [comp, setComp] = useState("list");
  const [rowData, setRowData] = useState();

  function handleChange(newValue) {
    setComp(newValue.mode);
    setRowData(newValue.rowData);
  }
  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Attendance</h1>
          </div>

          <List comp={comp} onChange={handleChange} rowData={rowData} />
        </section>
      </div>
    </Layout>
  );
}

export default Attendances;
