import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import {
  Car,
  CloseCircle,
  DocumentText,
  TickCircle,
  Ticket,
} from "iconsax-react";
import moment from "moment";
import * as utils from "../../Utils/comman";
import CustomPagination from "../../Utils/CustomPagination";

function List(props) {
  const [TravelList, setTravelList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [fileUploads, setFileUploads] = useState([]);
  const [errors, setErrors] = useState({});
  const [showticket, setShowTicket] = useState(false);
  const [selectedTravelRequest, setSelectedTravelRequest] = useState(null);
  const [rejectionReason, setRejectionReason] = useState("");
  const [actionType, setActionType] = useState("");

  const handleCloseTicket = () => setShowTicket(false);
  const handleShowTicket = () => setShowTicket(true);
  const handleClose = () => setShow(false);
  const handleShow = (row, type) => {
    setCurrentRow(row);
    setActionType(type);
    setShow(true);
  };
  const [travelPlans, settravelPlans] = useState([]);
  const [showT, setShowT] = useState(false);
  const [reason, setReason] = useState("");
  const handleCloseT = () => setShowT(false);
  const handleShowT = () => setShowT(true);
  const handleAddClick = () => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };

  const columns = useMemo(() => [
    {
      name: (
        <div>
          Emp Code <br />
        </div>
      ),
      selector: (row) => row.empCode,
      width: "90px",
    },
    {
      name: (
        <div>
          Employee <br /> Name
        </div>
      ),
      selector: (row) => row.full_name,
    },
    {
      name: (
        <div>
          Departure <br /> Date
        </div>
      ),
      selector: (row) => moment(row.departureDate).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          Return <br /> Date
        </div>
      ),
      selector: (row) => moment(row.returnDate).format("DD-MM-YYYY"),
    },
    {
      name: (
        <div>
          Purpose <br /> Of
        </div>
      ),
      selector: (row) => row.purpose_of,
    },
    // {
    //   name: (
    //     <div>
    //       Type <br />
    //     </div>
    //   ),
    //   selector: (row) => (row.type === "area_visit" ? "Area Visit" : ""),
    // },
    // {
    //   name: (
    //     <div>
    //       State <br />
    //     </div>
    //   ),
    //   selector: (row) => row?.stateName?.map((state) => state?.state_name).join(", "),
    // },
    // {
    //   name: (
    //     <div>
    //       City <br />
    //     </div>
    //   ),
    //   selector: (row) => row?.cityName?.map((city) => city?.city_name).join(", "),
    // },
    {
      name: (
        <div>
          Approval <br /> Status
        </div>
      ),
      selector: (row) => row.approvalStatus,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          {row?.approvalStatus === "Approved" &&
            row.travelTicket.length == 0 && (
              <>
                <RB.Button
                  className="btn-flat me-1"
                  onClick={() => handleShow(row, "approve")}
                >
                  <TickCircle />
                </RB.Button>
                <RB.Button
                  className="btn-flat"
                  onClick={() => handleShow(row, "reject")}
                >
                  <CloseCircle />
                </RB.Button>
              </>
            )}
          {row.travelTicket.length !== 0 && (
            <RB.Button className="btn btn-flat ms-1" onClick={showTicket(row)}>
              <Ticket />
            </RB.Button>
          )}
          {row.travelPlan.length !== 0 && (
            <>
              <RB.Button
                className="btn btn-flat ms-1"
                onClick={showTravelPlan(row)}
              >
                <Car />
              </RB.Button>
            </>
          )}
        </>
      ),
    },
  ]);

  const showTravelPlan = useCallback(
    (row) => () => {
      handleShowT();
      settravelPlans(row?.travelPlan);
      // setshowTravelPlanDetail(true)
    },
    []
  );

  const showTicket = useCallback(
    (row) => () => {
      setSelectedTravelRequest(row);
      setShowTicket(true);
    },
    []
  );

  const getApprovalStatus = (status) => {
    switch (status) {
      case "0":
        return "Pending";
      case "1":
        return "Approved";
      case "2":
        return "Rejected";
      default:
        return "Unknown";
    }
  };

  const handleApprove = useCallback(
    (row) => async () => {
      handleShow(row, "approve");
    },
    []
  );

  const handleReject = useCallback(
    (row) => async () => {
      handleShow(row, "reject");
    },
    []
  );

  useEffect(() => {
    getTravelList();
  }, [page, filterText]);

  const getTravelList = () => {
    utils
      .callAPI(
        "get",
        `/travel/travel-desk-list?page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        if (res) {
          setTravelList(res);
        } else {
          setTravelList([]);
        }
      })
      .catch(() => {
        setTravelList([]);
      });
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileUpload = async (e, subHeadId) => {
    const files = Array.from(e.target.files);
    const base64Files = await Promise.all(
      files.map((file) => convertToBase64(file))
    );

    setFileUploads((prevUploads) => {
      const existingUpload = prevUploads.find(
        (upload) => upload.travel_mode_id === subHeadId
      );
      if (existingUpload) {
        existingUpload.ticket.push(
          ...base64Files.map((base64) => ({ file: base64 }))
        );
      } else {
        prevUploads.push({
          travel_mode_id: subHeadId,
          ticket: base64Files.map((base64) => ({ file: base64 })),
        });
      }
      return [...prevUploads];
    });
    setErrors((prevErrors) => {
      const { [subHeadId]: _, ...rest } = prevErrors;
      return rest;
    });
  };

  const validateUploads = () => {
    const errors = {};
    currentRow.subHeadName.forEach((subHead) => {
      const upload = fileUploads.find(
        (upload) => upload.travel_mode_id === subHead.id
      );
      if (!upload || upload.ticket.length === 0) {
        errors[subHead.id] = "Please upload at least one ticket.";
      }
    });
    return errors;
  };

  const handleSave = () => {
    if (actionType === "approve") {
      const validationErrors = validateUploads();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
      } else {
        saveApproval();
      }
    } else {
      saveRejection();
    }
  };

  const saveApproval = () => {
    console.log("File Uploads: ", fileUploads);
    handleClose();

    var bodyFormData = new FormData();
    bodyFormData.append("travel_request_id", currentRow?.id);
    bodyFormData.append("desk_approval", "Desk Approve");
    bodyFormData.append("desk_remark", "");
    bodyFormData.append("ticket_image", JSON.stringify(fileUploads));

    utils
      .callAPI("post", `/travel/travel-desk-approval`, bodyFormData)
      .then((res) => {
        getTravelList();
      })
      .catch(() => {
        setTravelList([]);
      });
  };

  const saveRejection = () => {
    if (!rejectionReason) {
      setErrors({ rejectionReason: "Please provide a reason for rejection." });
      return;
    }

    handleClose();

    const bodyFormData = new FormData();
    bodyFormData.append("travel_request_id", currentRow?.id);
    bodyFormData.append("desk_approval", "Desk Reject");
    bodyFormData.append("desk_remark", rejectionReason);
    bodyFormData.append("ticket_image", "");

    utils
      .callAPI("post", `/travel/travel-desk-approval`, bodyFormData)
      .then((res) => {
        getTravelList();
      })
      .catch(() => {
        setTravelList([]);
      });
  };

  const handleDownloadTicket = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(new Blob([blob], { type: "image/jpeg" }));
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "Ticket";
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }, 10000);
    } catch (error) {
      console.error("Failed to download image:", error);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <div className="section-body">
        <RB.Card className="card shadow">
          <RB.Card.Body>
            <DataTable
              columns={columns}
              data={TravelList.result}
              highlightOnHover
              responsive
              pagination
              paginationServer
              paginationTotalRows={TravelList.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
                page: 1,
              }}
              paginationComponent={() => (
                <CustomPagination
                  currentPage={page}
                  totalRows={TravelList.total}
                  rowsPerPage={countPerPage}
                  onPageChange={handlePageChange}
                />
              )}
              striped
              onChangePage={(page) => setPage(page)}
              persistTableHead
              customStyles={{
                head: {
                  style: {
                    fontSize: "0.875rem",
                    fontWeight: "bold",
                  },
                },
              }}
              // subHeader
              // subHeaderComponent={subHeaderComponent}
            />
          </RB.Card.Body>
        </RB.Card>
      </div>
      {currentRow && (
        <RB.Modal show={show} onHide={handleClose}>
          <RB.Modal.Header closeButton>
            <RB.Modal.Title>Upload Ticket</RB.Modal.Title>
          </RB.Modal.Header>
          <RB.Modal.Body>
            {actionType === "approve" ? (
              <div>
                {currentRow?.subHeadName.map((subHead) => (
                  <RB.Form.Group
                    key={subHead.id}
                    controlId={`formFile_${subHead.id}`}
                    className="mt-2"
                  >
                    <RB.Form.Label>{subHead.headName}</RB.Form.Label>
                    <RB.Form.Control
                      type="file"
                      multiple
                      onChange={(e) => handleFileUpload(e, subHead.id)}
                      isInvalid={!!errors[subHead.id]}
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      {errors[subHead.id]}
                    </RB.Form.Control.Feedback>
                  </RB.Form.Group>
                ))}
              </div>
            ) : (
              <RB.Form.Group controlId="rejectionReason">
                <RB.Form.Label>Reason for Rejection</RB.Form.Label>
                <RB.Form.Control
                  as="textarea"
                  rows={3}
                  value={rejectionReason}
                  onChange={(e) => setRejectionReason(e.target.value)}
                  isInvalid={!!errors.rejectionReason}
                />
                <RB.Form.Control.Feedback type="invalid">
                  {errors.rejectionReason}
                </RB.Form.Control.Feedback>
              </RB.Form.Group>
            )}
          </RB.Modal.Body>
          <RB.Modal.Footer>
            <RB.Button variant="secondary" onClick={handleClose}>
              Close
            </RB.Button>
            <RB.Button variant="primary" onClick={handleSave}>
              Save
            </RB.Button>
          </RB.Modal.Footer>
        </RB.Modal>
      )}

      <RB.Modal show={showticket} onHide={handleCloseTicket} size="lg">
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Travel Tickets</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          <RB.Row className="justify-content-center">
            {selectedTravelRequest?.travelTicket?.map((ticket) => {
              const fileExtension = ticket.ticket_image
                .split(".")
                .pop()
                .toLowerCase();
              const isImage = ["jpg", "jpeg", "png", "gif"].includes(
                fileExtension
              );
              const isPDF = fileExtension === "pdf";
              const isDoc = ["doc", "docx", "document"].includes(fileExtension);

              return (
                <RB.Col
                  key={ticket.id}
                  md={4}
                  className="d-flex justify-content-center ticket-col"
                >
                  <div className="position-relative ticket-wrapper">
                    <p>{ticket.headName}</p>

                    {isImage && (
                      <>
                        <a
                          href={ticket.ticketPath}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={ticket.ticketPath}
                            alt="ticket"
                            className="ticket-image"
                          />
                        </a>

                        <RB.Button
                          variant="primary"
                          className="btn btn-sm btn-circle download-btn"
                          onClick={() =>
                            handleDownloadTicket(ticket.ticketPath)
                          }
                        >
                          <i className="material-icons-outlined">
                            file_download
                          </i>
                        </RB.Button>
                      </>
                    )}

                    {isPDF && (
                      <a
                        href={ticket.ticketPath}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="material-icons-outlined md-128">
                          picture_as_pdf
                        </i>
                      </a>
                    )}

                    {isDoc && (
                      <a
                        href={ticket.ticketPath}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DocumentText size={70} />
                      </a>
                    )}
                  </div>
                </RB.Col>
              );
            })}
          </RB.Row>
        </RB.Modal.Body>
        <RB.Modal.Footer>
          <RB.Button variant="secondary" onClick={handleCloseTicket}>
            Close
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>

      <RB.Modal show={showT} onHide={handleCloseT} size={"xl"}>
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Travel Plan Details</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          <RB.Table striped bordered hover>
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Destination</th>
                <th>Name of Destination</th>
                <th>State</th>
                <th>District</th>
                <th>City</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              {travelPlans.map((plan, index) => (
                <tr key={plan.id}>
                  <td>{index + 1}</td>
                  <td>{moment(plan.start_date).format("DD-MM-YYYY")}</td>
                  <td>{moment(plan.end_date).format("DD-MM-YYYY")}</td>
                  <td>
                    {plan.destination == "area_visit"
                      ? "Area Wise"
                      : "Distributor Wise"}
                  </td>
                  <td>{plan.name_of_destination}</td>
                  <td>{plan.state_name}</td>
                  <td>{plan.district_name}</td>
                  <td>{plan.city_name}</td>
                  <td>{plan.remark}</td>
                </tr>
              ))}
            </tbody>
          </RB.Table>
        </RB.Modal.Body>
        <RB.Modal.Footer>
          <RB.Button variant="secondary" onClick={handleCloseT}>
            Close
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>
    </>
  );
}

export default List;
