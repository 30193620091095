import { configureStore } from "@reduxjs/toolkit";
import userProfile from "./reducers/userProfileSlice";
import empSalaryDataSlice from "./reducers/employeeSalarySlice";
import primaryMenuItems from "./reducers/primaryMenuSlice";
import clockStatus from "./reducers/userAttendanceSlice";
import empTDSDataSlice from "./reducers/employeeTDSSlice";
import travelHeadReducer from "./reducers/travelHeadSlice";
import travelPermissionReducer from "./reducers/travelPermissionSlice";
import expIdReducer from './reducers/expIdSlice';
import onboardedEmployeeSlice from "./reducers/onboardedEmployeeSlice";
import stateReducer from "./reducers/stateSlice";
import districtReducer from "./reducers/districtSlice";
import cityReducer from "./reducers/citySlice";
import allDisReducer from "./reducers/allDistrictSlice";
import allcity from "./reducers/allCitySlice";
import travelExpensesReducer from "./reducers/travelExpensesSlice";
import expenseReducer from "./reducers/expenseSlice";
import actionReducer from './reducers/actionSlice';
import expenseListReducer from './reducers/expenseData';
import dateReducer from './reducers/dateSlice';
import updateExpenseSlice from "./reducers/updateExpenseSlice";

export const store = configureStore({
  reducer: {
    userProfile: userProfile,
    empSalary: empSalaryDataSlice,
    primaryMenu: primaryMenuItems,
    clockStatus: clockStatus,
    empTDS: empTDSDataSlice,
    travelHead: travelHeadReducer,
    travelPermission: travelPermissionReducer,
    expId: expIdReducer,
    onboardedEmployee: onboardedEmployeeSlice,
    state: stateReducer,
    district: districtReducer,
    city: cityReducer,
    allDis:allDisReducer,
    allcity:allcity,
    expenses: travelExpensesReducer,
    expense: expenseReducer,
    action: actionReducer,
    expense: expenseListReducer,
    dates: dateReducer,
    updateExp: updateExpenseSlice
  },
  //  middleware: getDefaultMiddleware =>
  // getDefaultMiddleware({
  //   serializableCheck: false,
  // }),
});
