import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../Utils/comman";

export const fetchTravelExpenses = createAsyncThunk(
  "expenses/fetchTravelExpenses",
  async ({ page, countPerPage }, thunkAPI) => {
    try {
      const response = await utils.callAPI("get", `expense/list?page=${page}&per_page=${countPerPage}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const initialState = {
  expenses: [],
  isLoading: false,
  isError: false,
  errorMessage: "",
};

const travelExpensesSlice = createSlice({
  name: "expenses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTravelExpenses.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchTravelExpenses.fulfilled, (state, action) => {
        state.expenses = action.payload;
        state.isLoading = false;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(fetchTravelExpenses.rejected, (state, action) => {
        state.expenses = [];
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.payload;
      });
  },
});

export default travelExpensesSlice.reducer;
