import React, { useState } from "react";
import Layout from "../Layout";
import { useLocation } from "react-router-dom";
import List from "../Components/Regularize Attendance/LIst";
import { Tab, Tabs } from "react-bootstrap";
import OtherTeamRegularize from "../Components/Regularize Attendance/OtherTeamRegularize";
import { useSelector } from "react-redux";
import HrList from "../Components/Regularize Attendance/HrList";

function RegularizeAttendance() {
  const [comp, setComp] = useState("list");
  const [rowData, setRowData] = useState();
  const location = useLocation();
  const userProfile = useSelector((state) => state.userProfile.userProfile);

  // useEffect(() => {
  //   if (comp !== "list") {
  //     setComp("list");
  //   }
  // }, [location]);

  function handleChange(newValue) {
    setComp(newValue.mode);
    setRowData(newValue.rowData);
  }

  const [activeTab, setActiveTab] = useState("yourTeam");

  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Regularization Request</h1>
          </div>

          {userProfile?.user_role[0]?.roleName == "HR Manager" ? (
            <>
              <HrList />
            </>
          ) : (
            <>
              <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id="leave-request-tabs"
              >
                <Tab eventKey="yourTeam" title="Your Team Request">
                  {activeTab === "yourTeam" && (
                    <List
                      comp={comp}
                      onChange={handleChange}
                      rowData={rowData}
                    />
                  )}
                </Tab>
                <Tab eventKey="otherTeam" title="Other Team Request">
                  {activeTab === "otherTeam" && <OtherTeamRegularize />}
                </Tab>
              </Tabs>
            </>
          )}
        </section>
      </div>
    </Layout>
  );
}

export default RegularizeAttendance;
