import React, { createContext, useContext, useState } from "react";

const LoaderContext = createContext();

export function useLoader() {
  return useContext(LoaderContext);
}

export function LoaderProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const startLoader = () => {
    setLoading(true);
  };

  const stopLoader = () => {
    setLoading(false);
  };

  return (
    <LoaderContext.Provider value={{ loading, startLoader, stopLoader }}>
      {children}
    </LoaderContext.Provider>
  );
}
