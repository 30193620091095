import React, { useCallback, useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import ReasonModal from "../../ReasonModal";
import { Eye } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchExpensePlans } from "../../../reducers/expenseSlice";
import LocalConveyance from "../AddExpense/LocalConveyance";
import * as utils from "../../../Utils/comman";
import ExpensesList from "../ExpensesList";
import { Tune } from "@mui/icons-material";
import List from "../List";
import { clearExp } from "../../../reducers/updateExpenseSlice";

function LocalExpenseList({activeTab}) {
  const travelHeadList = useSelector(
    (state) => state.travelHead.travelHeadList
  );
  const travelPermissionList = useSelector(
    (state) => state.travelPermission.travelPermissionList
  );
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const { expensePlans } = useSelector((state) => state.expense);
  const expId = useSelector((state) => state.expId);

  const [userPermissions, setUserPermissions] = useState([]);
  const [filteredHeads, setFilteredHeads] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedHeadId, setSelectedHeadId] = useState(null);
  const [showAdd, setShowAdd] = useState(false);
  const [compo, setcompo] = useState("add");
  const [id, setID] = useState("");
  const localExType = true;
  const dispatch = useDispatch();

  useEffect(() => {
    if (userProfile && travelPermissionList?.length > 0) {
      const employeeId = userProfile.employeeId;
      const userPermissionData = travelPermissionList.find(
        (permission) => permission.employeeId === employeeId
      );
      if (userPermissionData) {
        setUserPermissions(userPermissionData.permissions);
      } else {
        setUserPermissions([]);
      }
    }
  }, [userProfile, travelPermissionList]);

  useEffect(() => {
    if (userPermissions?.length > 0) {
      const heads = travelHeadList.filter((head) =>
        userPermissions.some(
          (permission) =>
            permission.head_id === head.head_id && head.head_id === "2"
        )
      );
      setFilteredHeads(heads);

      if (heads.length > 0) {
        setSelectedHeadId(heads[0].head_id);
        setSelectedType(heads[0].head_name);
      }
    } else {
      setFilteredHeads([]);
    }
  }, [userPermissions, travelHeadList]);

  const handleTypeChange = (event) => {
    const headId = event.target.value;
    setSelectedType(
      travelHeadList.find((head) => head.head_id === headId).head_name
    );
    setSelectedHeadId(headId);
  };

  const renderSelectedComponent = () => {
    const selectedHead = filteredHeads.find(
      (head) => head.head_id === selectedHeadId
    );

    switch (selectedType) {
      case "Local Conveyance":
        return (
          <LocalConveyance
            head={selectedHead}
            userPermissions={userPermissions}
            setShowAdd={setShowAdd}
          />
        );

      default:
        return null;
    }
  };

  const handleShow = () => {
    setShowAdd(!showAdd);
    setcompo("add");
    dispatch(clearExp());

  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <div></div>
        {showAdd ? (
          <RB.Button className="btn btn-underline mb-2" onClick={handleShow}>
            Back
          </RB.Button>
        ) : (
          <RB.Button variant="primary" className="mb-2" onClick={handleShow}>
            Add Expense
          </RB.Button>
        )}
      </div>
      {compo == "allView" ? (
        <>
          <ExpensesList openAccordionId={0} localID={id} localExType={localExType} setShowAdd={setShowAdd} setcompo={setcompo} activeTab={activeTab}/>
        </>
      ) : (
        <>
          {!showAdd ? (
            <List setcompo={setcompo} setID={setID} setShowAdd={setShowAdd} />
          ) : (
            <>
              {filteredHeads?.length > 0 ? (
                <>{renderSelectedComponent()}</>
              ) : (
                <RB.Row className="justify-content-center">
                  <RB.Col className="text-center">
                    <h5>No permissions available to add expenses.</h5>
                  </RB.Col>
                </RB.Row>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default LocalExpenseList;
