import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Link } from "react-router-dom";

function Add(props) {
  const [isEdit, setisEdit] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    announcementContent: "",
    expireDateTime: "",
    status: "",
  });
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [editId, setEditId] = useState();

  useEffect(() => {
    // Any initialization logic can go here
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (isEdit) {
        bodyFormData.append("id", editId);
      }
      bodyFormData.append("title", formData.title);
      bodyFormData.append("description", formData.announcementContent);
      bodyFormData.append(
        "expiry_date",
        moment(formData.expireDateTime).format("YYYY-MM-DD")
      );
      bodyFormData.append("isActive", formData.status);

      utils
        .callAPI("post", `/Announcement/add`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setEditId("");
            setFormData({
              announcementContent: "",
              expireDateTime: "",
              status: "",
              title: "",
            });
            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
      setIsSubmit(false);
    }
  }, [isSubmit]);

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      setEditId(props.rowData.id);
      setisEdit(true);
      setFormData({
        announcementContent: props.rowData.description,
        status: props.rowData.isActive,
        expireDateTime: props.rowData.expiry_date,
        title: props.rowData.title,
      });
    } else {
      setisEdit(false);
    }
  }, [props.rowData]);

  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
        <RB.Card.Header>
          <h4>{isEdit ? "Edit" : "Add"}</h4>

          <div className="card-header-action">
            <Link
              className="btn btn-underline"
              onClick={handleBackClick}
            >
              Back
            </Link>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Row>
            <RB.Col md={6}>
              <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="mb-1">
                  <div className="mb-3">
                    <RB.FloatingLabel
                      controlId="formTitle"
                      label="Title"
                      className="floating-label-fancy"
                    >
                      <RB.Form.Control
                        type="text"
                        placeholder="Enter Title"
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                        required
                        maxLength={32}
                      />
                    </RB.FloatingLabel>
                  </div>
                </div>

                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formAnnouncementContent"
                    label="Description"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter Description"
                      name="announcementContent"
                      value={formData.announcementContent}
                      onChange={handleInputChange}
                      required
                    />
                  </RB.FloatingLabel>
                </div>

                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formStartDate"
                    // label="Start Date"
                    className="floating-label-fancy"
                    style={{ width: "500px" }}
                  >
                    <DatePicker
                      selected={formData.expireDateTime}
                      onChange={(date) =>
                        setFormData({ ...formData, expireDateTime: date })
                      }
                      dateFormat="dd-MM-yyyy"
                      className="form-control"
                      placeholderText="Select expire date"
                      minDate={new Date()}
                      required
                    />
                  </RB.FloatingLabel>
                </div>

                {/* <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formExpireDateTime"
                    label="Expire Date Time"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="datetime-local"
                      name="expireDateTime"
                      value={formData.expireDateTime}
                      onChange={handleInputChange}
                      required
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please provide an expiration date and time.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div> */}

                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formStatus"
                    label="Status"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Select
                      name="status"
                      value={formData.status}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Status...</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </RB.Form.Select>
                  </RB.FloatingLabel>
                </div>

                <RB.Row className="align-items-center mt-3">
                  <RB.Col className="col-auto">
                    <RB.Button variant="primary" type="submit">
                      Submit
                    </RB.Button>
                  </RB.Col>
                </RB.Row>
              </RB.Form>
            </RB.Col>
          </RB.Row>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
