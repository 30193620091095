import React, { useCallback, useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import ReasonModal from "../../ReasonModal";
import { Eye } from "iconsax-react";
import { useDispatch, useSelector } from "react-redux";
import { fetchExpensePlans } from "../../../reducers/expenseSlice";
import LocalConveyance from "../AddExpense/LocalConveyance";
import TravelEntitlement from "../AddExpense//TravelEntitlement ";
import MiscMonthlyLimit from "../AddExpense/MiscMonthlyLimit";
import HotelExpense from "../AddExpense/HotelExpense";
import FoodExpense from "../AddExpense/FoodExpense";
import SceduleExpenseList from "./SceduleExpenseList";
import ExpensesList from "../ExpensesList";
import { useLocation } from "react-router-dom";
import { clearExp } from "../../../reducers/updateExpenseSlice";
import * as utils from "../../../Utils/comman";
import moment from "moment";

function TravelPlanSceduleList({
  travelPlan,
  setShowScedule,
  activeTab,
  setshowApproveExpense,
}) {
  const compo = useSelector((state) => state.action.currentAction);
  const travelHeadList = useSelector(
    (state) => state.travelHead.travelHeadList
  );
  const travelPermissionList = useSelector(
    (state) => state.travelPermission.travelPermissionList
  );
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const { expensePlans } = useSelector((state) => state.expense);
  const expId = useSelector((state) => state.expId);

  const [userPermissions, setUserPermissions] = useState([]);
  const [filteredHeads, setFilteredHeads] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedHeadId, setSelectedHeadId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [openAccordionId, setOpenAccordionId] = useState(null);
  const [currentTravelScedule, setCurrenttravelScedule] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const userRole = userProfile?.user_role[0]?.roleName;
  const updateExp = useSelector((state) => state.updateExp.updateExp);
  const [showExpense, setShowExpense] = useState(false);
  const [remark, setRemark] = useState("");
  const [isCheckBoxDisable, setisCheckBoxDisable] = useState(false);

  useEffect(() => {
    if (
      location.pathname === "/expenses/team-expense" ||
      location.pathname === "/expenses/all-expense" ||
      location.pathname === "/expenses/accountant-expense"
    ) {
      setShowExpense(true);
    } else {
      setShowExpense(false);
    }
  }, [location.pathname]);

  const handleToggle = useCallback(
    (item) => () => {
      setOpenAccordionId(openAccordionId === item.id ? null : item.id);
      setCurrenttravelScedule(item);
    },
    []
  );

  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModal(true);
    },
    []
  );

  const showTravelPLan = () => {
    setShowScedule(false);
  };

  useEffect(() => {
    if (userProfile && travelPermissionList?.length > 0) {
      const employeeId = userProfile.employeeId;
      const userPermissionData = travelPermissionList.find(
        (permission) => permission.employeeId === employeeId
      );
      if (userPermissionData) {
        setUserPermissions(userPermissionData.permissions);
      } else {
        setUserPermissions([]);
      }
    }
  }, [userProfile, travelPermissionList]);

  useEffect(() => {
    if (userPermissions?.length > 0) {
      const heads = travelHeadList.filter((head) =>
        userPermissions.some(
          (permission) =>
            permission.head_id === head.head_id && permission.head_id !== "2"
        )
      );
      setFilteredHeads(heads);
      if (heads?.length > 0) {
        setSelectedHeadId(heads[0].head_id);
        setSelectedType(heads[0].head_name);
      }
    } else {
      setFilteredHeads([]);
    }
  }, [userPermissions, travelHeadList]);

  useEffect(() => {
    if (openAccordionId && expId) {
      dispatch(
        fetchExpensePlans({ expenseId: expId, planId: openAccordionId })
      );
    }
  }, [openAccordionId]);

  const handleTypeChange = (event) => {
    const headId = event.target.value;
    setSelectedType(
      travelHeadList.find((head) => head.head_id === headId).head_name
    );
    setSelectedHeadId(headId);
  };

  //   useEffect(() => {
  //     if (activeTab === "local") {
  //       const localHead = filteredHeads.find(
  //         (head) => head.head_name === "Local Conveyance"
  //       );
  //       if (localHead) {
  //         setSelectedHeadId(localHead.head_id);
  //         setSelectedType(localHead.head_name);
  //       }
  //     } else if (filteredHeads.length > 0) {
  //       setSelectedHeadId(filteredHeads[0].head_id);
  //       setSelectedType(filteredHeads[0].head_name);
  //     }
  //   }, [activeTab, filteredHeads]);

  useEffect(() => {
    if (Object.keys(updateExp).length > 0) {
      setSelectedHeadId(updateExp.headId);
      setSelectedType(updateExp.headName);
      setisCheckBoxDisable(true)
    }
  }, [updateExp]);

  const renderCheckBoxes = () => {
    return (
      filteredHeads
        //   .filter((head) =>
        //     activeTab === "local"
        //       ? head.head_name === "Local Conveyance"
        //       : head.head_name !== "Local Conveyance"
        //   )
        .map((head) => (
          <div
            key={head.head_id}
            className="d-flex align-items-center me-3 mb-3"
          >
            <RB.Form.Check
              type="radio"
              name="expenseType"
              id={`head-${head.head_id}`}
              label={head.head_name}
              value={head.head_id}
              onChange={handleTypeChange}
              checked={selectedHeadId === head.head_id}
              disabled={isCheckBoxDisable}
            />
          </div>
        ))
    );
  };

  const renderSelectedComponent = () => {
    const selectedHead = filteredHeads.find(
      (head) => head.head_id === selectedHeadId
    );

    switch (selectedType) {
      case "Travel Entitlement":
        return (
          <TravelEntitlement
            head={selectedHead}
            userPermissions={userPermissions}
            openAccordionId={openAccordionId}
          />
        );
      case "Local Conveyance":
        return (
          <LocalConveyance
            head={selectedHead}
            userPermissions={userPermissions}
            openAccordionId={openAccordionId}
          />
        );
      case "Misc. Monthly Limit":
        return (
          <MiscMonthlyLimit
            head={selectedHead}
            userPermissions={userPermissions}
            openAccordionId={openAccordionId}
          />
        );
      case "Hotel Expense":
        return (
          <HotelExpense
            head={selectedHead}
            userPermissions={userPermissions}
            openAccordionId={openAccordionId}
            currentTravelScedule={currentTravelScedule}
          />
        );
      case "Food Expense":
        return (
          <FoodExpense
            head={selectedHead}
            userPermissions={userPermissions}
            openAccordionId={openAccordionId}
          />
        );
      default:
        return null;
    }
  };
  console.log(travelPlan);
  const renderAccordionItem = (item) => {
    const wordCount = item.remark?.split(" ").length;
    return (
      <RB.Accordion.Item eventKey={item.id} key={item.id}>
        <RB.Accordion.Header onClick={handleToggle(item)} className="p-0">
          <RB.Table className="exp-tableFix table-sm m-0 p-0">
            <thead>
              <tr>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Destination</th>
                <th>Destination Name</th>
                <th>State</th>
                <th>District</th>
                <th>City</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{moment(item.start_date).format("DD-MM-YYYY")}</td>
                <td>{moment(item.end_date).format("DD-MM-YYYY")}</td>
                <td>
                  {item.destination == "area_visit"
                    ? "Area Wise"
                    : "Distributor Wise"}
                </td>
                <td>{item.name_of_destination}</td>
                <td>{item.state_name}</td>
                <td>{item.district_name}</td>
                <td>{item.city_name}</td>
                <td>
                  {wordCount > 2 ? (
                    <RB.Button
                      className="btn btn-flat p-0"
                      onClick={handleShowModal("Remark", item.remark)}
                    >
                      <Eye />
                    </RB.Button>
                  ) : (
                    item.remark
                  )}
                </td>
              </tr>
            </tbody>
          </RB.Table>
        </RB.Accordion.Header>
        <RB.Accordion.Body>
          {location.pathname === "/expenses/team-expense" ||
          location.pathname === "/expenses/accountant-expense" ||
          userRole == "HR Manager" ? (
            <>
              {openAccordionId && (
                <ExpensesList
                  openAccordionId={openAccordionId}
                  travelPlan={travelPlan}
                />
              )}
            </>
          ) : (
            <>
              {filteredHeads?.length > 0 ? (
                <>
                  {compo == "add" ? (
                    <>
                      <h6>Expense Type</h6>
                      <RB.Form className="d-flex justify-content-between">
                        <div className="d-flex flex-wrap">
                          {renderCheckBoxes()}
                        </div>
                      </RB.Form>
                      {openAccordionId === item.id && renderSelectedComponent()}
                    </>
                  ) : (
                    <>
                      {openAccordionId && (
                        <ExpensesList
                          openAccordionId={openAccordionId}
                          travelPlan={travelPlan}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <RB.Row className="justify-content-center">
                  <RB.Col className="text-center">
                    <h5>No permissions available to add expenses.</h5>
                  </RB.Col>
                </RB.Row>
              )}
            </>
          )}
        </RB.Accordion.Body>
      </RB.Accordion.Item>
    );
  };

  const handleSubmit = () => {
    var bodyFormData = new FormData();
    bodyFormData.append("type", "travel");
    bodyFormData.append("id", travelPlan?.id);
    bodyFormData.append("submit", "1");
    bodyFormData.append("remark", remark);
    utils
      .callAPI("post", `/expense/submit-hr`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          setRemark();
          setshowApproveExpense(false);
        } else {
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      {location.pathname == "/expenses/your-expense" && (
        <div className="d-flex justify-content-between">
          <h5 className="d-inline">
            {compo !== "add" && (
              <>
                {travelPlan.total_expense !== undefined &&
                  `Total Travel Expense: ₹ ${travelPlan.total_expense.toFixed(
                    2
                  )}`}
              </>
            )}
          </h5>
          <RB.Button
            className="btn btn-underline mb-2"
            onClick={showTravelPLan}
          >
            Back
          </RB.Button>
        </div>
      )}
      {showExpense && (
        <div className="d-flex justify-content-between">
          <h5 className="d-inline">
            {travelPlan.total_expense !== undefined &&
              `Total Travel Expense: ₹ ${travelPlan.total_expense.toFixed(2)}`}
          </h5>
        </div>
      )}

      <RB.Accordion defaultActiveKey="0">
        {travelPlan?.travelPlan
          ?.sort((a, b) => new Date(a.start_date) - new Date(b.start_date))
          .map((item) => renderAccordionItem(item))}
      </RB.Accordion>
      {location.pathname == "/expenses/all-expense" && (
        <>
          {travelPlan?.hr_submit !== "1" && (
            <>
              <RB.Form.Group controlId="remark" className="mt-3">
                <RB.Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Remark"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </RB.Form.Group>
              <RB.Button className="mt-2" onClick={handleSubmit}>
                Submit
              </RB.Button>
            </>
          )}
        </>
      )}
      <ReasonModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={modalTitle}
        text={modalText}
      />
    </>
  );
}

export default TravelPlanSceduleList;
