import React, { useState } from "react";
import List from "../Components/Artisan/List";
import View from "../Components/Artisan/View";
import Layout from "../Layout";
import ScanView from "../Components/Artisan/ScanView";
import Add from "../Components/Artisan/Add";
import TransactionDetail from "../Components/Artisan/TransactionDetail";

function Artisan() {
  const [comp, setComp] = useState("list");
  const [rowData, setRowData] = useState();

  function handleChange(newValue) {
    setComp(newValue.mode);
    setRowData(newValue.rowData);
  }
  return (
    <Layout>
      <div className="main-content">
        <section className="section mt-2">
          <div className="section-header">
            <h1>Artisan</h1>

          </div>
          {comp === "list" ? (
            <List comp={comp} onChange={handleChange} rowData={rowData} />
          ) : comp === "scan" ? ( // Check if comp is "scan"
            <ScanView comp={comp} onChange={handleChange} rowData={rowData} /> // Render ScanView component
          ) : comp === "view" ? ( // Check if comp is "scan"
            <View comp={comp} rowData={rowData} onChange={handleChange} />
          ) : comp === "transaction" ? ( // Check if comp is "scan"
            <TransactionDetail
              comp={comp}
              rowData={rowData}
              onChange={handleChange}
            />
          ) : (
            // Render ScanView component
            <Add comp={comp} onChange={handleChange} rowData={rowData} />
          )}
        </section>
      </div>
    </Layout>
  );
}

export default Artisan;
