import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import List from "../Components/Employee/List";
import Add from "../Components/Employee/Add";
import { useLocation, useParams } from "react-router-dom";

function Employee() {
	const [comp, setComp] = useState("list");
	const [rowData, setRowData] = useState();
	const location = useLocation();
	const param = useParams();

	useEffect(() => {
		if (location.search == "?offer-accepted") {
			setComp("edit");
		}
	}, [location]);

	function handleChange(newValue) {
		setComp(newValue.mode);
		setRowData(newValue.rowData);
	}

	return (
		<Layout>
			<div className="main-content">
				<section className="section">
					<div className="section-header">
						<h1>{comp == "add" ? "Register Employee" : "Employee"}</h1>
					</div>

					{comp === "list" ? (
						<List comp={comp} onChange={handleChange} rowData={rowData} />
					) : (
						<Add comp={comp} rowData={rowData} onChange={handleChange} />
					)}
				</section>
			</div>
		</Layout>
	);
}

export default Employee;
