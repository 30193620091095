import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import Layout from '../Layout';
import List from '../Components/Transactions/List';

function Transactions() {
    const [comp, setComp] = useState("list");
    const [rowData, setRowData] = useState();
    const location = useLocation();
  
    useEffect(() => {
      setComp("list");
    }, [location]);
  
    function handleChange(newValue) {
      setComp(newValue.mode);
      setRowData(newValue.rowData);
    }

    return (
      <Layout>
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>Transactions</h1>
            </div>
  
            {/* {comp === "list" ? ( */}
              <List comp={comp} onChange={handleChange} rowData={rowData} />
            {/* ) : comp === "transaction" ? (
              <TransactionView
                comp={comp}
                onChange={handleChange}
                rowData={rowData}
              />
            ) : (
              <View comp={comp} rowData={rowData} onChange={handleChange} />
            )} */}
          </section>
        </div>
      </Layout>
    );
  }
  

export default Transactions