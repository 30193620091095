import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../Utils/comman";
import { fetchCities } from "./citySlice";

export const fetchAllCities = createAsyncThunk(
  "location/fetchallCities",
  async (districtId, thunkAPI) => {
    try {
      const response = await utils.callAPI("get", `/city/by-district-list?districtId=${''}`);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  Allcities: [],
  isLoading: false,
  isError: false,
};

const AllcitySlice = createSlice({
  name: "Allcity",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCities.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchAllCities.fulfilled, (state, action) => {
        state.Allcities = Array.isArray(action.payload) ? action.payload : [];
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchAllCities.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default AllcitySlice.reducer;
