// reducers/onboardedEmployeeSlice.js

import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  onboardedEmployees: {},
};

const onboardedEmployeeSlice = createSlice({
  name: "onboardedEmployee",
  initialState,
  reducers: {
    addOnboardedEmployee: (state, action) => {
      state.onboardedEmployees = action.payload;
    },
  },
});

export const { addOnboardedEmployee } = onboardedEmployeeSlice.actions;
export default onboardedEmployeeSlice.reducer;
