import React, { useState } from "react";
import Layout from "../Layout";
import { useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import HrList from "../Components/Resignation/HrList";
import TeamResignationRequest from "../Components/Resignation/TeamResignationRequest";
import Add from "../Components/Resignation/Add";
import OtherTeamRegularize from "../Components/Resignation/OtherTeamRegularize";

function TeamResignationRequests() {
  const [comp, setComp] = useState("list");
  const [rowData, setRowData] = useState();
  const location = useLocation();
  const userProfile = useSelector((state) => state.userProfile.userProfile);

  // useEffect(() => {
  //   if (comp !== "list") {
  //     setComp("list");
  //   }
  // }, [location]);

  function handleChange(newValue) {
    setComp(newValue.mode);
    setRowData(newValue.rowData);
  }

  const [activeTab, setActiveTab] = useState("yourTeam");

  const handleTabSelect = (key) => {
    setRowData()
    setComp('list')
    setActiveTab(key);
  };

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Resignation Requests</h1>
          </div>

          {userProfile?.user_role[0]?.roleName == "HR Manager" ? (
            <>
              <HrList />
            </>
          ) : (
            <>
              <Tabs
                activeKey={activeTab}
                onSelect={handleTabSelect}
                id="leave-request-tabs"
              >
                <Tab eventKey="yourTeam" title="Your Team Request">
                  {activeTab === "yourTeam" && (
                    <>
                      {comp == "list" ? (
                        <TeamResignationRequest
                          comp={comp}
                          onChange={handleChange}
                          rowData={rowData}
                        />
                      ) : (
                        <Add
                          comp={comp}
                          onChange={handleChange}
                          rowData={rowData}
                        />
                      )}
                    </>
                  )}
                </Tab>
                <Tab eventKey="otherTeam" title="Other Team Request">
                  {activeTab === "otherTeam" &&   <>
                      {comp == "list" ? (
                        <OtherTeamRegularize
                          comp={comp}
                          onChange={handleChange}
                          rowData={rowData}
                        />
                        // <OtherTeamRegularize />
                      ) : (
                        <Add
                          comp={comp}
                          onChange={handleChange}
                          rowData={rowData}
                        />
                      )}
                    </>}
                </Tab>
              </Tabs>
            </>
          )}
        </section>
      </div>
    </Layout>
  );
}

export default TeamResignationRequests;
