import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import List from "../Components/Announcement/List";
import Add from "../Components/Announcement/Add";
import { useLocation } from "react-router-dom";

function Announcement() {
    const [comp, setComp] = useState("list");
	const [rowData, setRowData] = useState();
	const location = useLocation();

	useEffect(() => {
	  setComp("list")
	}, [location]);
	
	function handleChange(newValue) {
		setComp(newValue.mode);
		setRowData(newValue.rowData);
	}
	return (
		<Layout>
			<div className="main-content">
				<section className="section">
					<div className="section-header">
						<h1>Announcement</h1>
					</div>
					{comp === "list" ? (
						<List comp={comp} onChange={handleChange} rowData={rowData} />
					) : (
						<Add comp={comp} rowData={rowData} onChange={handleChange} />
					)}
				</section>
			</div>
		</Layout>
	);
}

export default Announcement