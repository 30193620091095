import React from "react";
import Layout from "../Layout";
import ExpensesTypes from "../Components/TravelExpense/ExpensesTypes";

function TravelExpense() {
  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Expenses</h1>
          </div>
          <ExpensesTypes />
        </section>
      </div>
    </Layout>
  );
}

export default TravelExpense;
