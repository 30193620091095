import React, { useCallback } from "react";
import * as RB from "react-bootstrap";
import { useSelector } from "react-redux";

function List(props) {
  const empSalary = useSelector((state) => state.empSalary.employeeSalary);
  const isEmployeeSalaryEmpty = empSalary?.employee_salary?.length === 0;

  const handleAddClick = () => {
    const propdata = { mode: "add" };
    props.onChange(propdata);
  };

  const handleIncrementClick = () => {
    const propdata = { mode: "increment" };
    props.onChange(propdata);
    props.setIsSalaryIncrement(true);
  };

  const handleEditClick = () => {
    const latestSalary =
      empSalary.employee_salary[empSalary.employee_salary.length - 1];
    const propdata = {
      mode: "edit",
      rowData: [latestSalary],
    };
    props.onChange(propdata);
  };

  const handleEdit = useCallback(
    (row) => () => {
      const propdata = {
        mode: "edit",
        rowData: row,
      };
      props.onChange(propdata);
    },
    [props]
  );

  const renderCompensationTable = (salary) => {
    const allowances = JSON.parse(salary.allowance);
    const deductions = JSON.parse(salary.deduction);
    const specialAllowanceYearly =
      parseFloat(
        allowances.find((a) => a.name === "Special Allowances")?.total
      ) * 12;
    const totalTakeHomeYearly =
      parseFloat(salary.basic_amt) +
      parseFloat(allowances.find((a) => a.name === "HRA")?.total) +
      specialAllowanceYearly;

    const PFEmployee = parseFloat(
      deductions.find((a) => a.name === "PF")?.total
    );
    const PFEmployeeMonth = PFEmployee / 12;

    const monthlyBalance = salary.balance / 12;
    const rows = [
      {
        label: "Basic Salary",
        name: "Basic Salary",
        monthly: salary.basic_amt / 12,
        yearly: salary.basic_amt,
      },
      {
        label: "HRA",
        name: "House Rent Allowance (HRA)",
        monthly: "",
        yearly: "",
      },
      {
        label: "",
        name: "Special Allowances",
        monthly: specialAllowanceYearly / 12,
        yearly: specialAllowanceYearly,
      },
      {
        label: "total",
        name: "Total (Take Home Salary)",
        monthly: totalTakeHomeYearly / 12,
        yearly: totalTakeHomeYearly,
      },
      { label: "", name: "Benefits", monthly: "", yearly: "" },
      {
        label: "PF",
        name: "Provident Fund Contribution",
        monthly: "",
        yearly: "",
      },
      { label: "Prof Tax", name: "Professional Tax", monthly: "", yearly: "" },
      { label: "Gratuity", name: "Gratuity", monthly: "", yearly: "" },
      {
        label: "Mediclaim",
        name: "Medical Reimbursement",
        monthly: "",
        yearly: "",
      },
      {
        label: "balance",
        name: "Monthly Gross",
        monthly: salary.balance / 12,
        yearly: salary.balance,
      },
      {
        label: "PF",
        name: "Provident Fund Contribution (Employer)",
        monthly: PFEmployeeMonth,
        yearly: PFEmployee,
      },
      {
        label: "",
        name: "Total Gross Salary",
        monthly: PFEmployeeMonth + monthlyBalance,
        yearly: PFEmployee + parseFloat(salary.balance),
      },
      { label: "", name: "Bonus", monthly: "", yearly: "" },
      {
        label: "vip",
        name: "Variable Incentive Plan",
        monthly: salary.vip,
        yearly: salary.variable_pay,
      },
      {
        label: "sip",
        name: "Special Incentive Plan",
        monthly: salary.sip / 12,
        yearly: salary.sip,
      },
      {
        label: "annual_package",
        name: "Cost to Company (PM)",
        monthly: salary.annual_package / 12,
        yearly: salary.annual_package,
      },
    ];

    allowances.forEach((allowance) => {
      const index = rows.findIndex((row) => row.label === allowance.name);
      if (index !== -1) {
        rows[index].monthly = allowance.total / 12;
        rows[index].yearly = allowance.total;
      }
    });

    deductions.forEach((deduction) => {
      const index = rows.findIndex((row) => row.label === deduction.name);
      if (index !== -1) {
        rows[index].monthly = deduction.total / 12;
        rows[index].yearly = deduction.total;
      }
    });

    return rows.map((row, index) => (
      <tr key={index}>
        <td>
          {[
            "Total (Take Home Salary)",
            "Monthly Gross",
            "Total Gross Salary",
            "Cost to Company (PM)",
          ].includes(row.name) ? (
            <strong>{row.name}</strong>
          ) : (
            row.name
          )}
        </td>
        <td>
          {[
            "Total (Take Home Salary)",
            "Monthly Gross",
            "Total Gross Salary",
            "Cost to Company (PM)",
          ].includes(row.name) ? (
            <strong>{Math.round(row.monthly)}</strong>
          ) : (
            Math.round(row.monthly)
          )}
        </td>
        <td>
          {[
            "Total (Take Home Salary)",
            "Monthly Gross",
            "Total Gross Salary",
            "Cost to Company (PM)",
          ].includes(row.name) ? (
            <strong>{Math.round(row.yearly)}</strong>
          ) : (
            Math.round(row.yearly)
          )}
        </td>
      </tr>
    ));
  };

  const reversedSalaries = [...(empSalary?.employee_salary || [])].reverse();

  return (
    <div className="section-body">
      <RB.Card className="card">
        <RB.Card.Header>
          <h4>{`${empSalary?.firstName} ${empSalary?.lastName}`}</h4>
          <div className="card-header-action">
            {isEmployeeSalaryEmpty ? (
              <RB.Button
                className="btn btn-primary me-2"
                onClick={handleAddClick}
              >
                Add Employee Salary
              </RB.Button>
            ) : (
              <>
                <RB.Button
                  className="btn btn-primary me-2"
                  onClick={handleEditClick}
                >
                  Edit Employee Salary
                </RB.Button>
                <RB.Button
                  className="btn btn-primary me-2"
                  onClick={handleIncrementClick}
                >
                  Increment Salary
                </RB.Button>
              </>
            )}
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          {reversedSalaries.length > 0 && (
            <>
              <h6>Salary Information</h6>
              <hr className="hr-lg mt-1" />
              <RB.Row>
                <RB.Col md={4}>
                  <span className="text-uppercase fw-bold small text-body-secondary">
                    CTC
                  </span>
                  <p className="fw-medium text-sm fw-semibold">
                    {reversedSalaries[0].ctc}
                  </p>
                </RB.Col>
                <RB.Col md={4}>
                  <span className="text-uppercase fw-bold small text-body-secondary">
                    Variable Per
                  </span>
                  <p className="fw-medium text-sm fw-semibold">
                    {reversedSalaries[0].variable_per}%
                  </p>
                </RB.Col>
                {reversedSalaries[0].variable_pay && (
                  <RB.Col md={4}>
                    <span className="text-uppercase fw-bold small text-body-secondary">
                      Variable Pay
                    </span>
                    <p className="fw-medium text-sm fw-semibold">
                      {reversedSalaries[0].variable_pay}
                    </p>
                  </RB.Col>
                )}
              </RB.Row>
              <h6>Payment Information</h6>
              <hr className="hr-lg mt-1" />
              <RB.Row>
                <RB.Col md={4}>
                  <span className="text-uppercase fw-bold small text-body-secondary">
                    Payment Frequency
                  </span>
                  <p className="fw-medium text-sm fw-semibold">
                    {(() => {
                      switch (reversedSalaries[0].payment_frequency) {
                        case "0":
                          return "Add Monthly";
                        case "1":
                          return "Quarterly";
                        case "2":
                          return "Half Yearly";
                        case "3":
                          return "Yearly";
                        default:
                          return "";
                      }
                    })()}
                  </p>
                </RB.Col>
                <RB.Col md={4}>
                  <span className="text-uppercase fw-bold small text-body-secondary">
                    Payment Mode
                  </span>
                  <p className="fw-medium text-sm fw-semibold">
                    {(() => {
                      switch (reversedSalaries[0].payment_mode) {
                        case "0":
                          return "Bank Transfer";
                        case "1":
                          return "Cheque";
                        case "2":
                          return "Cash";
                        default:
                          return "";
                      }
                    })()}
                  </p>
                </RB.Col>
                <RB.Col md={4}>
                  <span className="text-uppercase fw-bold small text-body-secondary">
                    Effective Date
                  </span>
                  <p className="fw-medium text-sm fw-semibold">
                    {reversedSalaries[0].effective_date}
                  </p>
                </RB.Col>
              </RB.Row>
              <h6>Compensation</h6>
              <hr className="hr-lg mt-1" />
              <RB.Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Component</th>
                    <th>Monthly</th>
                    <th>Annual</th>
                  </tr>
                </thead>
                <tbody>{renderCompensationTable(reversedSalaries[0])}</tbody>
              </RB.Table>
            </>
          )}
          <RB.Accordion>
            {reversedSalaries.slice(1).map((salary, index) => (
              <RB.Accordion.Item eventKey={index.toString()} key={index}>
                <RB.Accordion.Header>
                  Effective Date: {salary.effective_date}
                </RB.Accordion.Header>
                <RB.Accordion.Body>
                  <h6>Salary Information</h6>
                  <hr className="hr-lg mt-1" />
                  <RB.Row>
                    <RB.Col md={4}>
                      <span className="text-uppercase fw-bold small text-body-secondary">
                        CTC
                      </span>
                      <p className="fw-medium text-sm fw-semibold">
                        {salary.ctc}
                      </p>
                    </RB.Col>
                    <RB.Col md={4}>
                      <span className="text-uppercase fw-bold small text-body-secondary">
                        Variable Per
                      </span>
                      <p className="fw-medium text-sm fw-semibold">
                        {salary.variable_per}%
                      </p>
                    </RB.Col>
                    {salary.variable_pay && (
                      <RB.Col md={4}>
                        <span className="text-uppercase fw-bold small text-body-secondary">
                          Variable Pay
                        </span>
                        <p className="fw-medium text-sm fw-semibold">
                          {salary.variable_pay}
                        </p>
                      </RB.Col>
                    )}
                  </RB.Row>
                  <h6>Payment Information</h6>
                  <hr className="hr-lg mt-1" />
                  <RB.Row>
                    <RB.Col md={4}>
                      <span className="text-uppercase fw-bold small text-body-secondary">
                        Payment Frequency
                      </span>
                      <p className="fw-medium text-sm fw-semibold">
                        {salary.payment_frequency === 0
                          ? "Monthly"
                          : "Bi-Monthly"}
                      </p>
                    </RB.Col>
                    <RB.Col md={4}>
                      <span className="text-uppercase fw-bold small text-body-secondary">
                        Payment Mode
                      </span>
                      <p className="fw-medium text-sm fw-semibold">
                        {salary.payment_mode === 0 ? "Cash" : "Cheque"}
                      </p>
                    </RB.Col>
                    <RB.Col md={4}>
                      <span className="text-uppercase fw-bold small text-body-secondary">
                        Effective Date
                      </span>
                      <p className="fw-medium text-sm fw-semibold">
                        {salary.effective_date}
                      </p>
                    </RB.Col>
                  </RB.Row>

                  <h6>Compensation</h6>
                  <hr className="hr-lg mt-1" />
                  <RB.Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Component</th>
                        <th>Monthly</th>
                        <th>Annual</th>
                      </tr>
                    </thead>
                    <tbody>{renderCompensationTable(salary)}</tbody>
                  </RB.Table>
                </RB.Accordion.Body>
              </RB.Accordion.Item>
            ))}
          </RB.Accordion>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
