import React, { useState } from "react";
import * as RB from "react-bootstrap";

const CustomDropdown = ({ formData, leaveTypeList, handleInputChange }) => {
  const handleSelect = (evt, evtKey) => {
    // what am I suppose to write in there to get the value?
    console.log(evt);
  };

  return (
    <RB.Col md={6}>
      <div className="mb-3">
        <RB.Dropdown className="leaveTypeDropdown">
          <RB.Dropdown.Toggle
            variant="outline-primary"
            id="dropdown-leave-type"
            className="w-100"
          >
            <span className="d-block">Leave Type</span>
            {formData.leaveName || "Select Leave Type..."}
          </RB.Dropdown.Toggle>

          <RB.Dropdown.Menu className="w-100">
            {leaveTypeList.map((leaveType, index) => (
              <RB.Dropdown.Item
                eventkey={leaveType.leave_name}
                key={index}
                value={leaveType.id}
                onClick={() =>
                  handleInputChange(leaveType.id, leaveType.leave_name)
                }
                disabled={leaveType.remain_leave === 0}
              >
                <div className="d-flex justify-content-between align-items-center ">
                  <span>{leaveType.leave_name}</span>
                  {leaveType.remain_leave === 0 ? (
                    <span className="text-danger">Not Available</span>
                  ) : (
                    <span>{leaveType.remain_leave} Leaves Available</span>
                  )}
                </div>
              </RB.Dropdown.Item>
            ))}
          </RB.Dropdown.Menu>
        </RB.Dropdown>
      </div>
    </RB.Col>
  );
};

export default CustomDropdown;
