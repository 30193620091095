import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import List from "../Components/User/List";
import Add from "../Components/User/Add";
import { useLocation } from "react-router-dom";
import UserAssignPermission from "../Components/User/UserAssignPermission";
import RolePermission from "../Components/User/RolePermission";
import * as RB from "react-bootstrap";
import * as utils from "../Utils/comman";

function User() {
  const [comp, setComp] = useState("list");
  const [rowData, setRowData] = useState();
  const location = useLocation();
  const [permission, setPermission] = useState([]);
  const [userApiCall, setUserApiCall] = useState(false);
  const [roleApi, setRoleApi] = useState(false);

  useEffect(() => {
    setComp("list");
  }, [location]);

  useEffect(() => {
    if (comp == "permission") {
      getPermission();
    }
  }, [comp]);

  function handleChange(newValue) {
    setComp(newValue.mode);
    setRowData(newValue.rowData);
  }

  const handleBack = () => {
    setComp("list");
  };

  const getPermission = () => {
    utils
      .callAPI("get", `/permission/module-permission`)
      .then((res) => {
        if (typeof res !== "undefined") {
          const formattedData = transformPermissions(res);
          setPermission(formattedData);
          setUserApiCall(true);
          setRoleApi(true);
        } else {
          setPermission([]);
          setRoleApi(false);
          setUserApiCall(false);
        }
      })
      .catch((err) => {
        setPermission([]);
        setRoleApi(false);
        setUserApiCall(false);
      });
  };

  const transformPermissions = (permissions) => {
    return permissions.map((module) => ({
      key: module.module,
      label: module.module,
      children: module.permissions.map((perm) => ({
        key: perm.id.toString(),
        label: perm.name,
      })),
    }));
  };

  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>User</h1>
          </div>
          {comp === "list" ? (
            <List comp={comp} onChange={handleChange} rowData={rowData} />
          ) : comp === "add" ? (
            <Add comp={comp} rowData={rowData} onChange={handleChange} />
          ) : comp === "permission" ? (
            <>
              <RB.Card className="p-4">
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h5 className="d-inline-block">{`${rowData?.firstName} ${rowData?.middleName} ${rowData?.lastName} (${rowData.user_role[0].roleName})`}</h5>
                  </div>
                  <RB.Button
                    className="btn btn-underline me-2"
                    onClick={handleBack}
                  >
                    Back
                  </RB.Button>
                </div>
                <RB.Card.Body>
                  <div className="row">
                    <div className="col-md-6">
                      <h5>Designation Permission</h5>
                      <RolePermission
                        rowData={rowData}
                        permission={permission}
                        roleApi={roleApi}
                        setRoleApi={setRoleApi}
                      />
                    </div>
                    <div className="col-md-6">
                      <h5>User Permission</h5>
                      <UserAssignPermission
                        comp={comp}
                        userApiCall={userApiCall}
                        rowData={rowData}
                        onChange={handleChange}
                        permission={permission}
                        setUserApiCall={setUserApiCall}
                      />
                    </div>
                  </div>
                </RB.Card.Body>
              </RB.Card>
            </>
          ) : null}
        </section>
      </div>
    </Layout>
  );
}

export default User;
