import React, { useEffect, useState, useRef } from "react";

const CustomPagination = ({
  currentPage,
  totalRows,
  rowsPerPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const [inputPage, setInputPage] = useState(currentPage);
  const typingTimeoutRef = useRef(null);
  const maxVisiblePages = 5;

  useEffect(() => {
    setInputPage(currentPage);
  }, [currentPage]);

  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    onPageChange(page);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      setInputPage(value);
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }

      typingTimeoutRef.current = setTimeout(() => {
        if (value === "") {
          return;
        }
        const page = Number(value);
        if (page >= 1 && page <= totalPages) {
          handlePageChange(page);
        } else {
          setInputPage(currentPage);
        }
      }, 500);
    }
  };
console.log(totalRows)
  return (
    <div className="d-flex flex-wrap align-items-center justify-content-lg-end justify-content-center mt-3">
      <div className="d-flex align-items-center mb-3">
        <strong>Page</strong>
        <input
          type="text"
          className="form-control mx-2 text-center"
          value={inputPage}
          onChange={handleInputChange}
          tabIndex={0}
          style={{ width: "70px" }}
        />
        <strong>of {totalPages}</strong>
      </div>
      <nav aria-label="Page navigation" className="mb-3">
        <ul className="pagination justify-content-center flex-wrap">
          <li
            className={`page-item p-1 ${currentPage === 1 ? "disabled" : ""}`}
          >
            <button
              className="page-link btn"
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            >
              {"<<"}
            </button>
          </li>
          <li
            className={`page-item p-1 ${currentPage === 1 ? "disabled" : ""}`}
          >
            <button
              className="page-link btn"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {"<"}
            </button>
          </li>
          {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
            const page = startPage + index;
            return (
              <li
                key={page}
                className={`page-item p-1 ${
                  currentPage === page ? "active" : ""
                }`}
              >
                <button
                  className="page-link btn"
                  onClick={() => handlePageChange(page)}
                >
                  {page}
                </button>
              </li>
            );
          })}
          <li
            className={`page-item p-1 ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link btn"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {">"}
            </button>
          </li>
          <li
            className={`page-item p-1 ${
              currentPage === totalPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link btn"
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            >
              {">>"}
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default CustomPagination;
