// src/reducers/dateSlice.js
import { createSlice } from "@reduxjs/toolkit";

const dateSlice = createSlice({
  name: "dates",
  initialState: {
    dates: []
  },
  reducers: {
    setDates: (state, action) => {
      state.dates = action.payload;
    }
  }
});

export const { setDates } = dateSlice.actions;
export default dateSlice.reducer;
