import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { useLocation } from "react-router-dom";
import List from "../Components/TravelDesk/List";

function TravelDesk() {
  const [comp, setComp] = useState("list");
  const [rowData, setRowData] = useState();
  const location = useLocation();

  useEffect(() => {
    setComp("list");
  }, [location]);

  function handleChange(newValue) {
    setComp(newValue.mode);
    setRowData(newValue.rowData);
  }
  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>Travel Desk</h1>
          </div>
            <List/>
        </section>
      </div>
    </Layout>
  );
}

export default TravelDesk;
