import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { AddCircle, Edit2, Trash } from "iconsax-react";
import { useSelector } from "react-redux";
import CustomPagination from "../../Utils/CustomPagination";

function List(props) {
  const [product, setProduct] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };
  const columns = [
    {
      name: (
        <div>
          Product Name <br />
        </div>
      ),
      selector: (row) => row.product_name,
      minWidth: "180px",
    },
    {
      name: (
        <div>
          Product Code <br />
        </div>
      ),
      selector: (row) => row.productCode,
    },
    {
      name: (
        <div>
          Product Category <br />
        </div>
      ),
      selector: (row) => row.productCategory,
    },
    {
      name: (
        <div>
          Regular Price <br />
        </div>
      ),
      selector: (row) => row.regularPrice,
    },
    {
      name: (
        <div>
          Sales Price <br />
        </div>
      ),
      selector: (row) => row.salesPrice,
    },
    {
      name: (
        <div>
          Artisan Coupon Value <br />
        </div>
      ),
      selector: (row) => row.couponValue,
    },
    {
      name: (
        <div>
          Non Scannable Coupon Value <br />
        </div>
      ),
      selector: (row) => row.nonScannableCouponValue,
      style: {
        //backgroundColor: "#f7f7f7",
      },
    },
    {
      name: (
        <div>
          Size <br />
        </div>
      ),
      selector: (row) => row.size + " " + (row?.unit || ""),
    },
    {
      name: "Action",
      center: true,
      style: {
        //  backgroundColor: "#f7f7f7",
      },
      cell: (row) => (
        <>
          {utils.hasPermission(primaryMenuRes, "Product", "product.edit") && (
            <RB.Button className="btn-flat" onClick={handelEdit(row)}>
              <Edit2 />
            </RB.Button>
          )}
          {utils.hasPermission(primaryMenuRes, "Product", "product.delete") && (
            <RB.Button className="btn-flat" btn-flat onClick={showDelete(row)}>
              <Trash />
            </RB.Button>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    getproduct();
  }, [page]);

  const getproduct = () => {
    utils
      .callAPI("get", `/Product/list?page=${page}&per_page=${countPerPage}`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setProduct(res);
        } else {
          setProduct([]);
        }
      })
      .catch((err) => {
        setProduct([]);
      });
  };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `Product/delete?productId=${row.id}`)
          .then((res) => {
            if (typeof res !== "undefined") {
              getproduct();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  
  return (
    <div className="section-body">
      <RB.Card className="card shadow card-primary">
        <div className="floating-btns-holder-artisan">
          {utils.hasPermission(primaryMenuRes, "Product", "product.create") && (
            <a
              href
              className="btn btn-dark btn-circle"
              variant="dark"
              onClick={handleAddClick}
            >
              <AddCircle />
            </a>
          )}
        </div>
        <RB.Card.Header>
          <h4></h4>
          <div className="card-header-action"></div>
        </RB.Card.Header>
        <RB.Card.Body>
          {utils.hasPermission(primaryMenuRes, "Product", "product.view") && (
            <DataTable
              columns={columns}
              data={product.result}
              highlightOnHover
              responsive
              pagination
              striped
              paginationServer
              paginationTotalRows={product.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              paginationComponent={() => (
                <CustomPagination
                  currentPage={page}
                  totalRows={[product].total}
                  rowsPerPage={countPerPage}
                  onPageChange={handlePageChange}
                />
              )}
              onChangePage={(page) => setPage(page)}
              persistTableHead
              customStyles={{
                head: {
                  style: {
                    fontSize: "0.875rem", // Adjust the font size as needed
                    fontWeight: "bold", // Adjust the font weight as needed
                  },
                },
              }}
              //overflowX="auto"
              // subHeader
              // subHeaderComponent={subHeaderComponent}
            />
          )}
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
