import React, { useEffect, useState } from 'react'
import Layout from '../Layout';
import View from '../Components/Holiday/View';
import Add from '../Components/Holiday/Add';
import { useLocation } from 'react-router-dom';

function Holiday() {
    const [comp, setComp] = useState("list");
    const [rowData, setRowData] = useState();
    const location = useLocation();

    useEffect(() => {
      setComp("list")
    }, [location]);
    
    function handleChange(newValue) {
      setComp(newValue.mode);
      setRowData(newValue.rowData);
    }
    return (
      <Layout>
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>Holiday</h1>
            </div>
            {comp === "list" ? (
              <View comp={comp} onChange={handleChange} rowData={rowData} />
            ) : (
              <Add comp={comp} rowData={rowData} onChange={handleChange} />
            )}
          </section>
        </div>
      </Layout>
    );
}

export default Holiday