import React, { useEffect, useState } from 'react'
import List from '../Components/Proposed ManPower/List';
import Add from '../Components/Proposed ManPower/Add';
import { useLocation } from 'react-router-dom';
import Layout from '../Layout';

function ProposedManpower() {
    const [comp, setComp] = useState("list");
    const [rowData, setRowData] = useState();
    const location = useLocation();
  
    useEffect(() => {
      setComp("list");
    }, [location]);
  
    function handleChange(newValue) {
      setComp(newValue.mode);
      setRowData(newValue.rowData);
    }
    return (
      <Layout>
        <div className="main-content">
          <section className="section">
            <div className="section-header">
              <h1>Proposed ManPower</h1>
            </div>
            {comp === "list" ? (
              <List comp={comp} onChange={handleChange} rowData={rowData} />
            ) : (
              <Add comp={comp} rowData={rowData} onChange={handleChange} />
            )}
          </section>
        </div>
      </Layout>
    );
}

export default ProposedManpower