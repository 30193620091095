import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../Layout";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { Edit2, Trash } from "iconsax-react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomPagination from "../../Utils/CustomPagination";

function ItSetup() {
  const [name, setName] = useState("");
  const [isActive, setIsActive] = useState("");
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [itSetupList, setItSetupList] = useState([]);
  const [isEdit, setIsEdit] = useState([]);
  const [editId, setEditId] = useState("");
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const statusList = [
    { id: "", name: "Select an option..." },
    { id: "1", name: "Active" },
    { id: "0", name: "Inactive" },
  ];
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Status",
      selector: (row) => (row.isActive == "1" ? "Active" : "InActive"),
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          {utils.hasPermission(primaryMenuRes, "It Setup", "it_setup.edit") && (
            <RB.Button className="btn-flat" onClick={() => handleEdit(row)}>
              <Edit2 />
            </RB.Button>
          )}
          &nbsp;
          {utils.hasPermission(
            primaryMenuRes,
            "It Setup",
            "it_setup.delete"
          ) && (
            <RB.Button className="btn-flat" onClick={() => showDelete(row)}>
              <Trash />
            </RB.Button>
          )}
        </>
      ),
    },
  ];
  useEffect(() => {
    getitList();
  }, [page]);

  const getitList = () => {
    utils
      .callAPI("get", `/employee/itsetup/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setItSetupList(res);
        } else {
          setItSetupList([]);
        }
      })
      .catch((err) => {
        setItSetupList([]);
      });
  };

  const handleEdit = useCallback((row) => {
    console.log(row);
    setIsEdit(true);
    setName(row.name);
    setIsActive(row.isActive);
    setEditId(row.id);
  }, []);

  const showDelete = useCallback((row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("post", `/employee/itsetup/delete?id=${row.id}`)
          .then((res) => {
            if (typeof res !== "undefined") {
              getitList();
            }
          })
          .catch((err) => {});
      }
    });
  }, []);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleStatusChange = (event) => {
    setIsActive(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (isEdit) {
        bodyFormData.append("id", editId);
      }
      bodyFormData.append("name", name);
      bodyFormData.append("isActive", isActive);

      utils
        .callAPI("post", `/employee/itsetup/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            getitList();
            setName("");
            setIsActive("");
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  const handleClear = () => {
    setName("");
    setIsActive("");
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  
  return (
    <Layout>
      <div className="main-content">
        <section className="section">
          <div className="section-header">
            <h1>IT Setup</h1>
          </div>
          <div className="section-body">
            <RB.Card className="">
              <RB.Card.Body>
                <RB.Row>
                  {utils.hasPermission(
                    primaryMenuRes,
                    "It Setup",
                    "it_setup.create"
                  ) && (
                    <RB.Col md={4}>
                      <RB.Card className="shadow-1">
                        <RB.Card.Body>
                          <RB.Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                          >
                            <div className="mb-3">
                              <RB.FloatingLabel
                                controlId="name"
                                label="Name"
                                className="floating-label-fancy"
                              >
                                <RB.Form.Control
                                  type="text"
                                  placeholder="Enter Name"
                                  value={name}
                                  onChange={handleNameChange}
                                  required
                                />
                                <RB.Form.Control.Feedback type="invalid">
                                  Please enter a name.
                                </RB.Form.Control.Feedback>
                              </RB.FloatingLabel>
                            </div>

                            <div className="mb-3">
                              <RB.FloatingLabel
                                controlId="isActive"
                                label="Active"
                                className="floating-label-fancy"
                              >
                                <RB.Form.Select
                                  value={isActive}
                                  onChange={handleStatusChange}
                                  required
                                >
                                  {statusList.map((status) => (
                                    <option key={status.id} value={status.id}>
                                      {status.name}
                                    </option>
                                  ))}
                                </RB.Form.Select>
                                <RB.Form.Control.Feedback type="invalid">
                                  Please select active status.
                                </RB.Form.Control.Feedback>
                              </RB.FloatingLabel>
                            </div>
                            <div className="d-flex align-items-center gx-3">
                              <div className="text-start">
                                <RB.Button type="submit" variant="primary">
                                  Submit
                                </RB.Button>
                              </div>
                              <div>
                                <Link
                                  className="text-underline ms-3"
                                  //	type="reset"
                                  //variant="primary"
                                  onClick={handleClear}
                                >
                                  {"Clear"}
                                </Link>
                              </div>
                            </div>
                          </RB.Form>
                        </RB.Card.Body>
                      </RB.Card>
                    </RB.Col>
                  )}
                  {utils.hasPermission(
                    primaryMenuRes,
                    "It Setup",
                    "it_setup.view"
                  ) && (
                    <RB.Col md={8}>
                      <RB.Card className="shadow-1">
                        <RB.Card.Body>
                          <DataTable
                            columns={columns}
                            data={itSetupList.result}
                            highlightOnHover
                            responsive
                            pagination
                            paginationServer
                            paginationTotalRows={itSetupList.total}
                            paginationPerPage={countPerPage}
                            paginationComponentOptions={{
                              noRowsPerPage: true,
                            }}
                            paginationComponent={() => (
                              <CustomPagination
                                currentPage={page}
                                totalRows={itSetupList.total}
                                rowsPerPage={countPerPage}
                                onPageChange={handlePageChange}
                              />
                            )}
                            striped
                            onChangePage={(page) => setPage(page)}
                            persistTableHead
                            customStyles={{
                              head: {
                                style: {
                                  fontSize: "0.875rem", // Adjust the font size as needed
                                  fontWeight: "bold", // Adjust the font weight as needed
                                },
                              },
                            }}
                          />
                        </RB.Card.Body>
                      </RB.Card>
                    </RB.Col>
                  )}
                </RB.Row>
              </RB.Card.Body>
            </RB.Card>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default ItSetup;
