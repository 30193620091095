import React, { useState } from "react";
import PrimaryMenu from "./PrimaryMenu";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Outlet } from "react-router-dom";
import $ from "jquery";
import "nicescroll/dist/jquery.nicescroll.min.js";
import { useEffect } from "react";
import { Collapse } from "react-bootstrap";
import { HomeRepairServiceOutlined } from "@mui/icons-material";
import * as icons from "@mui/icons-material";
import avatarImg from "../assets/images/avatar-4.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../reducers/userProfileSlice";
import * as utils from "../Utils/comman";
import { fetchPrimaryMenu } from "../reducers/primaryMenuSlice";

function PrimaryNavebar() {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const primaryMenuRes = useSelector((state) => state.primaryMenu.primaryMenu);

  // const hasPermission = (permissions, moduleName) => {
  //   const modulePermissions = permissions?.find(
  //     (permission) => permission.module === moduleName
  //   );
  //   return modulePermissions !== undefined;
  // };

  const [menuItems, setMenuItems] = useState([]);

  // const [menuItems, setMenuItems] = useState(() => {
  //   const prepareMenuItems = () => {
  //     return PrimaryMenu.map((menuItem) => {
  //       if (hasPermission(primaryMenuRes?.permissions, menuItem.title)) {
  //         if (menuItem.submenu) {
  //           const filteredSubmenu = menuItem.submenu.filter((subitem) =>
  //             hasPermission(primaryMenuRes?.permissions, subitem.title)
  //           );
  //           return { ...menuItem, submenu: filteredSubmenu };
  //         } else {
  //           return menuItem;
  //         }
  //       } else if (menuItem.submenu) {
  //         const filteredSubmenu = menuItem.submenu.filter((subitem) =>
  //           hasPermission(primaryMenuRes?.permissions, subitem.title)
  //         );
  //         return filteredSubmenu.length > 0
  //           ? { ...menuItem, submenu: filteredSubmenu }
  //           : null;
  //       } else {
  //         return null;
  //       }
  //     }).filter(Boolean);
  //   };

  //   return prepareMenuItems();
  // });

  useEffect(() => {
    // Ensure the body allows scrolling when the component mounts
    document.body.style.overflow = "auto";

    return () => {
      // Reset to default or handle it accordingly when the component unmounts
      document.body.style.overflow = "";
    };
  }, []);

  useEffect(() => {
    $(function () {
      $("#main-sidebar").niceScroll();
    });
  }, []);

  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);

  const toggleSubmenu = (index) => {
    $(function () {
      $("#main-sidebar").niceScroll();
    });
    $(function () {
      $("#main-sidebar").niceScroll().resize();
    });
    setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
  };

  useEffect(() => {
    const filteredMenuItems = PrimaryMenu.map((menuItem) => {
      if (hasPermission(primaryMenuRes?.permissions, menuItem.title)) {
        if (menuItem.submenu) {
          const filteredSubmenu = menuItem.submenu.filter((subitem) =>
            hasPermission(primaryMenuRes?.permissions, subitem.title)
          );
          return { ...menuItem, submenu: filteredSubmenu };
        } else {
          return menuItem;
        }
      } else if (menuItem.submenu) {
        const filteredSubmenu = menuItem.submenu.filter((subitem) =>
          hasPermission(primaryMenuRes?.permissions, subitem.title)
        );
        return filteredSubmenu.length > 0
          ? { ...menuItem, submenu: filteredSubmenu }
          : null;
      } else {
        return null;
      }
    }).filter(Boolean);
    setMenuItems(filteredMenuItems);
  }, [primaryMenuRes]);
  const hasPermission = (permissions, moduleName) => {
    const modulePermissions = permissions?.find(
      (permission) => permission.module === moduleName
    );
    return modulePermissions !== undefined;
  };
  return (
    <>
      <div className="sidebar-profile-info mt-2 mb-3  d-none d-flex flex-column justify-content-center align-items-center">
        <div className="flex-shrink-0 mt-1">
          <div className="avatar avatar-md mb-2">
            <img
              src={
                userProfile?.profilePic_path
                  ? userProfile?.profilePic_path
                  : avatarImg
              }
              alt="avatar"
            />
          </div>
        </div>
        <div className="flex-grow-1 pb-2">
          <h6 className="text-dark">{`${userProfile?.firstName} ${userProfile?.lastName}`}</h6>
        </div>
      </div>
      <ul className="sidebar-menu" id="sidebar-ul-menu">
        {/* <li><a className="nav-link" href="#"><i className="far fa-square"></i> <span>Dashboard</span></a></li> */}
        {menuItems.map((item, index) => {
          if (!("comp" in item)) {
            return (
              <li key={index} className="main-sidebar-li">
                <a href={item.externalurl} className={item.cName}>
                  <i className="material-icons-outlined">{item.icon}</i>
                  {item.title}
                </a>
              </li>
            );
          } else {
            if (!("submenu" in item)) {
              if (item.showInMenu) {
                return (
                  <li
                    key={index}
                    className={({ isActive }) =>
                      isActive
                        ? item.cName + "main-sidebar-li active"
                        : "main-sidebar-li inactive"
                    }
                  >
                    <NavLink
                      key={index}
                      to={`${item.path}`}
                      // onClick={(e) => {
                      //   var body = $("body");
                      //   var mediaQuery = window.matchMedia("(max-width: 768px)");
                      //   if (mediaQuery.matches) {
                      //     if (body.hasClass("sidebar-gone")) {
                      //       // body.removeClass("sidebar-gone");
                      //       body.addClass("sidebar-show");
                      //     } else {
                      //       body.addClass("sidebar-gone");
                      //       body.removeClass("sidebar-show");
                      //     }
                      //   }
                      // }}
                      className=""
                    >
                      {/* <i className="material-icons-outlined 01">{item.icon}</i> */}
                      {/* <item.icon size="32" color="#FF8A65" /> */}
                      {item.icon}
                      {item.iconHover}
                      {item.title}
                    </NavLink>
                    {item.title == "Team Resignation" && <hr className="m-0" />}
                    {/* {index === 7 && <hr  className="m-0"/>} */}
                  </li>
                );
              }
            } else {
              if (item.showInMenu) {
                return (
                  <>
                    <li
                      key={index}
                      className={`main-sidebar-li ${
                        index === openSubmenuIndex ? "active" : ""
                      }`}
                    >
                      <a
                        href={`#submenu${index}`}
                        className="nav-link has-dropdown"
                        onClick={(e) => {
                          e.preventDefault();
                          toggleSubmenu(index);
                        }}
                      >
                        {/* <i className="material-icons">{item.icon}</i> */}
                        {item.icon}
                        {item.iconHover}
                        <span>{item.title}</span>
                      </a>
                      <ul
                        id={`submenu${index}`}
                        className={`dropdown-menu${
                          openSubmenuIndex === index ? " show" : ""
                        }`}
                      >
                        {item.submenu.map((subitem, subindex) => (
                          <li key={subindex}>
                            <NavLink
                              className={subitem.cName}
                              to={`${subitem.path}`}
                              onClick={() => {}}
                            >
                              <span>{subitem.title}</span>
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                      {/*
                      <a
                        href={`#submenu${index}`}
                        className="nav-link has-dropdown"
                        onClick={(e) => {
                          e.preventDefault(); // Prevent default behavior
                          toggleSubmenu(index);
                        }}
                      >
                        <i className="material-icons">{item.icon}</i>
                        <span>{item.title}</span>
                      </a>
                      <Collapse in={openSubmenuIndex === index}>
                        <ul
                          id={`submenu${index}`}
                          className="collapse dropdown-menu"
                        >
                          {item.submenu.map((subitem, subindex) => (
                            <li key={subindex}>
                              <NavLink
                                className={subitem.cName}
                                to={`${subitem.path}`}
                                onClick={() => {
                                  // Optionally handle submenu item click
                                }}
                              >
                                <span>{subitem.title}</span>
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </Collapse> */}
                    </li>
                  </>
                );
              }
            }
          }
        })}
      </ul>
    </>
  );
}

export default PrimaryNavebar;
