import moment from "moment";
import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import DatePicker from "react-datepicker";
import * as utils from "../../Utils/comman";
import { Link } from "react-router-dom";

function Add(props) {
  const [isEdit, setisEdit] = useState(false);
  const couponForSelect = [
    { id: "", name: "Select an option..." },
    { id: "1", name: "Artisan" },
    //{ id: "2", name: "Distributer" },
    // { id: "3", name: "Architecht" },
    // { id: "4", name: "Contracter" },
    // { id: "5", name: "Retailer" },
    // { id: "6", name: "Dealer" },
    // { id: "7", name: "Market Operator" },
  ];

  const couponScannable = [
    { id: "", name: "Select an option..." },
    { id: "1", name: "Scannable" },
    { id: "0", name: "Non Scannable" },
  ];

  const [formData, setFormData] = useState({
    couponName: "",
    numberOfCoupons: "",
    price: "",
    productId: "",
    remark: "",
    status: "",
    couponScannable: "",
    couponStatus: false,
  });
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  // const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [priceList, setPriceList] = useState([]);
  const [productList, setProductList] = useState([
    // { id: 1, product_name: "Product 1" },
    // { id: 2, product_name: "Product 2" },
    // { id: 3, product_name: "Product 3" },
  ]);

  useEffect(() => {
    getProductList();
  }, []);

  const getProductList = () => {
    utils
      .callAPI("get", `/Product/product-price`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setProductList(res);
        } else {
          setProductList([]);
        }
      })
      .catch((err) => {
        setProductList([]);
      });
  };
  useEffect(() => {
    // Call getPriceList whenever the couponScannable state changes
    if (formData.productId !== "") getPriceList(formData.productId);
  }, [formData.couponScannable]);

  const getPriceList = (value) => {
    utils
      .callAPI("get", `/Product/coupon-value?product_id=${value}`)
      .then((res) => {
        if (typeof res !== "undefined") {
          let price = "";
          if (formData.couponScannable === "Non Scannable") {
            price = res.nonScannableCouponValue;
          } else if (formData.couponScannable === "Scannable") {
            price = res.couponValue;
          }

          setFormData((prevFormData) => ({
            ...prevFormData,
            price: price,
          }));

          setPriceList(res);
        } else {
          setPriceList([]);
        }
      })
      .catch((err) => {
        setPriceList([]);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "numberOfCoupons" && parseInt(value) <= 0) {
      return;
    }
    if (name === "price" && parseInt(value) <= 0) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      const currentYear = new Date().getFullYear();

      var bodyFormData = new FormData();
      if (editId) {
        bodyFormData.append("id", editId);
      }
      bodyFormData.append("coupon_name", formData.couponName);
      // bodyFormData.append(
      //   "start_date",
      //   moment(formData.startDate).format("YYYY-MM-DD")
      // );
      // bodyFormData.append(
      //   "end_date",
      //   moment(formData.endDate).format("YYYY-MM-DD")
      // );
      bodyFormData.append("status", "1");
      bodyFormData.append("quantity", formData.numberOfCoupons);
      bodyFormData.append("price", formData.price);
      bodyFormData.append("remark", formData.remark);
      bodyFormData.append("product_id", formData.productId);
      bodyFormData.append("couponType", formData.couponScannable);

      utils
        .callAPI(
          "post",
          `/Coupon/add
        `,
          bodyFormData
        )
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setEditId("");
            setFormData({
              couponName: "",
              numberOfCoupons: "",
              productId: "",
              remark: "",
              status: "",
              couponScannable: "",
            });
            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      setisEdit(true);
      const roleId = props.rowData.user_role[0]?.id;
      setFormData({
        couponName: props.rowData.coupon_name || "",
        numberOfCoupons: props.rowData.numberOfCoupons || "",
        startDate: props.rowData.startDate || "",
        endDate: props.rowData.endDate || "",
        isActive: props.rowData.status || "",
        remark: props.rowData.remark || "",
        price: props.rowData.price || "",
        couponScannable: props.rowData?.couponScannable || "",
      });
    } else {
      setisEdit(false);
    }
  }, [props.rowData]);

  return (
    <div className="section-body ">
      <RB.Card className="">
        <RB.Card.Header>
          <h4>{isEdit ? "Edit Coupon" : "Add Coupon"}</h4>
          <div className="card-header-action">
            <Link
              className="btn btn-underline me-2"
              onClick={handleBackClick}
            >
              Back
            </Link>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Coupon For"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="couponName"
                      value={formData.couponName}
                      onChange={handleInputChange}
                      required
                    >
                      {couponForSelect.map((role) => (
                        <option key={role.id} value={role.name}>
                          {role.name}
                        </option>
                      ))}
                    </RB.Form.Control>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Number of Coupons"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="number"
                      placeholder="Enter Number of Coupons"
                      name="numberOfCoupons"
                      value={formData.numberOfCoupons}
                      onChange={handleInputChange}
                      required
                    />
                    {/* <RB.Form.Control.Feedback type="invalid">
                      Please provide number of coupons.
                    </RB.Form.Control.Feedback> */}
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Product"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="productId"
                      value={formData.productId}
                      // onChange={(e) => handleInputChange(e)}
                      onChange={(e) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          productId: e.target.value,
                        }));
                        getPriceList(e.target.value); // Call getPriceList when a product is selected
                      }}
                      required
                    >
                      <option key="" value="">
                        Select Product...
                      </option>

                      {productList.map((product) => (
                        <option key={product.id} value={product.id}>
                          {product.product_name}
                        </option>
                      ))}
                    </RB.Form.Control>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Coupon Type"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="couponScannable"
                      value={formData.couponScannable}
                      onChange={handleInputChange}
                      required
                      disabled={!formData.productId}
                    >
                      <option key="" value="">
                        Select an option...
                      </option>
                      {/* Conditional rendering of dropdown options */}

                      {priceList?.couponValue !== "0.00" && (
                        <option key="scannable" value="Scannable">
                          Scannable
                        </option>
                      )}
                      {priceList?.nonScannableCouponValue !== "0.00" && (
                        <option key="non-scannable" value="Non Scannable">
                          Non Scannable
                        </option>
                      )}
                    </RB.Form.Control>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Coupon Price"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="number"
                      placeholder="Enter Number of Coupons"
                      name="price"
                      value={formData.price}
                      onChange={handleInputChange}
                      readOnly
                    />
                    {/* <RB.Form.Control.Feedback type="invalid">
                      Please provide number of coupons.
                    </RB.Form.Control.Feedback> */}
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="remark"
                    label="Remark"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Enter remark"
                      name="remark"
                      value={formData.remark}
                      onChange={handleInputChange}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>
            {/* <div className="mb-2">
                  <RB.FloatingLabel
                    label="Status"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="status"
                      value={formData.status}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Status...</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </RB.Form.Control>
                    <RB.Form.Control.Feedback type="invalid">
                      Please select a status.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div> */}

            <RB.Row className="align-items-center mt-3">
              <RB.Col className="col-auto">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
