import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import moment from "moment";
import { useSelector } from "react-redux";
import { AddCircle, Edit2, Trash } from "iconsax-react";
import CustomPagination from "../../Utils/CustomPagination";

function List(props) {
  const [announcement, setAnnouncement] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };
  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
    },
    {
      name: "Announcement",
      selector: (row) => {
        const words = row.description.split(" ");
        if (words.length > 8) {
          return words.slice(0, 8).join(" ") + "...";
        } else {
          return row.description;
        }
      },
    },
    {
      name: "Expire Date",
      selector: (row) => moment(row.expiry_date).format("DD-MM-YYYY"),
    },
    {
      name: "Status",
      selector: (row) => (row.isActive == "1" ? "Active" : "inActive"),
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          {utils.hasPermission(
            primaryMenuRes,
            "Announcement",
            "announcement.edit"
          ) && (
            <RB.Button className="btn-flat" onClick={handelEdit(row)}>
              <Edit2 />
            </RB.Button>
          )}
          &nbsp;
          {utils.hasPermission(
            primaryMenuRes,
            "Announcement",
            "announcement.delete"
          ) && (
            <RB.Button className="btn-flat ms-1" onClick={showDelete(row)}>
              <Trash />
            </RB.Button>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    getannouncement();
  }, [page]);

  const getannouncement = () => {
    utils
      .callAPI("get", `/Announcement/list?page=${page}&per_page=${countPerPage}`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setAnnouncement(res);
        } else {
          setAnnouncement([]);
        }
      })
      .catch((err) => {
        setAnnouncement([]);
      });
  };

  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.onChange(propdata);
  });

  const showDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/Announcement/delete?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              getannouncement();
            }
          })
          .catch((err) => {});
      }
    });
  });

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  

  return (
    <div className="section-body">
      <RB.Card className="">
        <div className="floating-btns-holder-artisan">
          {utils.hasPermission(
            primaryMenuRes,
            "Announcement",
            "announcement.create"
          ) && (
            <a
              href
              className="btn btn-dark btn-circle"
              variant="dark"
              onClick={handleAddClick}
            >
              <AddCircle />
            </a>
          )}
        </div>
        <RB.Card.Header>
          <h4></h4>
          <div className="card-header-action"></div>
        </RB.Card.Header>
        <RB.Card.Body>
          {utils.hasPermission(
            primaryMenuRes,
            "Announcement",
            "announcement.view"
          ) && (
            <DataTable
              columns={columns}
              data={announcement.result}
              highlightOnHover
              responsive
              pagination
              striped
              paginationServer
              paginationTotalRows={announcement.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              paginationComponent={() => (
                <CustomPagination
                  currentPage={page}
                  totalRows={announcement.total}
                  rowsPerPage={countPerPage}
                  onPageChange={handlePageChange}
                />
              )}
              onChangePage={(page) => setPage(page)}
              persistTableHead
              // subHeader
              customStyles={{
                head: {
                  style: {
                    fontSize: "0.875rem", // Adjust the font size as needed
                    fontWeight: "bold", // Adjust the font weight as needed
                  },
                },
              }}
              // subHeaderComponent={subHeaderComponent}
            />
          )}
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default List;
