import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useSelector } from "react-redux";
import { Edit, Edit2, ProfileAdd, Trash } from "iconsax-react";
import { Tree } from "primereact/tree";

function UserAssignPermission(props) {
  const [selectedKeys, setSelectedKeys] = useState(null);
  const [currentUserPermission, setCurrentUserPermission] = useState([]);
  const [rolewisePermission, setRolewisePermission] = useState([]);
  useEffect(() => {
    if (props?.userApiCall) {
      getCurrentUserPermission();
      getRoleWisePermission();
    }
  }, [props?.userApiCall]);

  const getCurrentUserPermission = () => {
    utils
      .callAPI("get", `/user-permission/list?user_id=${props?.rowData?.id}`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setCurrentUserPermission(res);
          props.setUserApiCall(false);
        } else {
          props.setUserApiCall(false);
        }
      })
      .catch((err) => {
        props.setUserApiCall(false);
      });
  };

  const getRoleWisePermission = () => {
    utils
      .callAPI(
        "get",
        `role/permissions?role_id=${props?.rowData?.user_role[0].id}&user_id=${props?.rowData?.id}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setRolewisePermission(res?.permissions);
        } else {
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (currentUserPermission.length > 0 && props?.permission.length > 0) {
      const selectedKeyData = {};
      props.permission.forEach((module) => {
        const selectedChildren = module.children.filter((child) =>
          currentUserPermission.some(
            (permission) => permission.permission_id === child.key
          )
        );

        if (selectedChildren.length > 0) {
          if (selectedChildren.length === module.children.length) {
            selectedKeyData[module.key] = { checked: true };
          } else {
            selectedKeyData[module.key] = { partialChecked: true };
          }

          selectedChildren.forEach((child) => {
            selectedKeyData[child.key] = { checked: true };
          });
        }
      });

      setSelectedKeys(selectedKeyData);
    }
  }, [currentUserPermission, props?.permission]);

  const handleBack = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handlePermission = () => {
    const selectedPermissionIds = Object.keys(selectedKeys)
      .filter((key) => !isNaN(parseInt(key, 10)))
      .map((key) => parseInt(key, 10));

    var bodyFormData = new FormData();

    bodyFormData.append("user_id", props?.rowData?.id);
    bodyFormData.append("permission_id", JSON.stringify(selectedPermissionIds));
    bodyFormData.append("description", "");

    utils
      .callAPI("post", `/user-permission/assign`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          // const propdata = {
          //   mode: "list",
          // };
          // props.onChange(propdata);
        }
      })
      .catch((err) => {});
  };

  const modifyPermissions = (permissions) => {
    const disabledPermissionIds = rolewisePermission.map(
      (item) => item.permission_id
    );
    const currentUserPermissionIds = currentUserPermission.map(
      (item) => item.permission_id
    );

    return permissions.map((module) => {
      let anyChildDisabled = false;

      const updatedChildren = module.children.map((child) => {
        const isChildDisabled = disabledPermissionIds.includes(child.key);
        const isInCurrentUser = currentUserPermissionIds.includes(child.key);

        if (isChildDisabled) {
          anyChildDisabled = true;
        }
        return {
          ...child,
          disabled: isChildDisabled && !isInCurrentUser,
          style:
            isChildDisabled && !isInCurrentUser
              ? { pointerEvents: "none", opacity: 0.7, cursor: "not-allowed" }
              : {},
        };
      });

      return {
        ...module,
        children: updatedChildren,
        style:
          anyChildDisabled &&
          updatedChildren.every((child) => child.style.pointerEvents)
            ? { pointerEvents: "none", opacity: 0.7, cursor: "not-allowed" }
            : {},
      };
    });
  };

  const modifiedPermissions = useMemo(
    () => modifyPermissions(props.permission),
    [props.permission, rolewisePermission]
  );

  const onSelectionChange = (e) => {
    const { value } = e;
    const filterDisabledChildren = (node, selectedKeys) => {
      let allChildrenSelected = true;
      let someChildrenSelected = false;

      // if (selectedKeys[node]?.style && selectedKeys[node]?.style?.pointerEvents) {
      //   return;
      // }

      if (node.children) {
        node.children.forEach((child) => {
          if (child.style.pointerEvents) {
            delete selectedKeys[child.key];
          } else if (selectedKeys[child.key]) {
            someChildrenSelected = true;
          } else {
            allChildrenSelected = false;
          }
        });

        // if (selectedKeys[node].style.pointerEvents) {
        //   return false;
        // }
        console.log(node.key, )
        if(node.style.pointerEvents){
          return
        }
        if (allChildrenSelected) {
          selectedKeys[node.key] = { checked: true };
        } else if (someChildrenSelected) {
          selectedKeys[node.key] = { partialChecked: true };
        } else {
          delete selectedKeys[node.key];
        }
      }
    };

    const newSelectedKeys = { ...value };

    modifiedPermissions.forEach((module) => {
      filterDisabledChildren(module, newSelectedKeys);

      if (module.children) {
        module.children.forEach((child) => {
          filterDisabledChildren(child, newSelectedKeys);
        });
      }
    });

    setSelectedKeys(newSelectedKeys);
  };

  return (
    <>
      <Tree
        className="w-full md:w-30rem"
        value={modifiedPermissions}
        selectionMode="checkbox"
        selectionKeys={selectedKeys}
        onSelectionChange={onSelectionChange}
      />
      <div class="d-flex justify-content-center mt-3">
        <div></div>
        <RB.Button variant="primary" onClick={handlePermission}>
          Update
        </RB.Button>
      </div>
    </>
  );
}

export default UserAssignPermission;
