import React, { useState, useEffect } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../Utils/comman";
import { Link, useNavigate } from "react-router-dom";
import brandLogo from "../assets/images/logo.png";
import Logo from "../Components/Logo";
import loginImg from "../assets/images/login-img.png";
import flowerImg from "../assets/images/flower.svg";

function ForgotPassword() {
	const [validated, setValidated] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const navigate = useNavigate();
	let year = new Date().getFullYear();
	const [values, setValues] = useState({
		email: "",
	});

	const handleTextChange = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.currentTarget;

		if (form.checkValidity() === false) {
			e.stopPropagation();
			setValidated(true);
		} else {
			setIsSubmit(true);
		}
	};

	useEffect(() => {
		if (isSubmit) {
			const bodyFormData = new FormData();
			bodyFormData.append("email", values.email);
			utils
				.callAPI("post", "/forgot/forgot-password", bodyFormData)
				.then((res) => {
					if (res !== undefined) {
						setIsSubmit(false);
						setValues({
							email: "",
						});
					} else {
						setIsSubmit(false);
					}
				});
		}
	}, [isSubmit]);

	return (
		<section className="login-wrap bg-black min-vh-100 d-flex justify-content-center align-items-center1">
			<RB.Container className="pt-5 d-flex flex-column">
				<RB.Row className="justify-content-center justify-content-lg-between align-items-center1 h-100 ">
					<RB.Col
						md={10}
						lg={6}
						xl={7}
						className="order-2 order-lg-1 align-self-end text-center">
						{/* <div className="logo-block d-none d-lg-flex align-items-center mb-4">
								<Logo />
							</div> */}
						<h4 className="text-white mb-5">
							Welcome to Vura Family!
							{/* 	A Futuristic Brand Conceived And Nurtured With The Experience Of 120
								Years.{" "}
								A FUTURISTIC BRAND CONCEIVED AND NURTURED WITH THE EXPERIENCE OF
                120 YEARS. */}
						</h4>
						<p className="text-md mt-3">
							{/*	Vura Bau-Chemie LLP is a global brand that manufactures adhesives,
								sealants, waterproofing, repairs, maintenance & building products which
								are formulated with world-class German polymers & technology. It has
								developed a comprehensive product range based on international standards
								to facilitate the global construction chemical manufacturer and building
								materials market.
								 The brains behind the product development have a
                combined experience of more than 120 years in the same industry.
                With this abundance of experience, the company is ready to build
                better! */}
						</p>
						<div className="login-img">
							<RB.Image src={flowerImg} alt="Vura login" className="img-fluid" />
						</div>
					</RB.Col>
					<RB.Col
						md={10}
						lg={6}
						xl={5}
						className="order-1 order-lg-2 mb-3 mb-md-0 d-flex justify-content-center align-items-center">
						{/* <div className="logo-block d-flex d-lg-none my-4 justify-content-center">
								<Logo />
							</div> */}
						<RB.Card className="login-card bg-black rounded-4 shadow-lg border-0 w-100">
							<RB.CardHeader className="border-0">
								<div className="logo-block">
									<Logo />
								</div>
								{/* <h2 className="text-dark mb-3 text-2xl ">
										Welcome,
										<br />
										Please Login Here
									</h2> */}
							</RB.CardHeader>
							<RB.CardBody>
								<RB.Form
									onSubmit={handleSubmit}
									noValidate
									validated={validated}
									className="form-dark">
									<div className="mb-3">
										<RB.FloatingLabel label="Email" className="floating-label-fancy">
											<RB.Form.Control
												type="email"
												id="email"
												name="email"
												placeholder="Email"
												value={values.email}
												onChange={handleTextChange}
												isInvalid={validated && !values.email}
												required
											/>
											<RB.Form.Control.Feedback type="invalid">
												Please enter valid email address.
											</RB.Form.Control.Feedback>
										</RB.FloatingLabel>
									</div>
									<div className="d-grid gap-2">
										<RB.Button type="submit" variant="primary">
											Submit
										</RB.Button>
									</div>
								</RB.Form>
							</RB.CardBody>
							<div className="mt-2 text-center">
								<Link to="/" className="text-decoration-none text-white">
									Back to Login
								</Link>
							</div>
						</RB.Card>
					</RB.Col>
				</RB.Row>
				<RB.Row className="row justify-content-between align-items-center d-none">
					<RB.Col md={12} lg={6} xl={7}></RB.Col>
					<RB.Col md={12} lg={6} xl={5}>
						<div className="simple-footer">
							Copyright &copy; {process.env.REACT_APP_TITLE} {year}
						</div>
					</RB.Col>
				</RB.Row>
			</RB.Container>
		</section>
	);
}

export default ForgotPassword;
