import React from "react";
import { Link } from "react-router-dom";
//import brandLogo from "../assets/images/logo.png";
import brandLogo from "../assets/images/logo-new.png";
import brandLogoDark from "../assets/images/logo.png";
function Logo() {
  return (
    <Link className="navbar-brand pl-md-3" to="/">
      <img src={brandLogo} className="img-fluid" alt="logo" />
    </Link>
  );
}

export default Logo;
