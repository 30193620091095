import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import {
  AccountBalanceWalletOutlined,
  Close,
  Delete,
  Done,
  EventAvailable,
  EventNote,
  ExpandMore,
  MoreHoriz,
  NorthEast,
  PendingActions,
  SouthWest,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setEmpSalary } from "../../reducers/employeeSalarySlice";
import { useSelector } from "react-redux";
import useCommandRRedirect from "../../hooks/useCommandRRedirect";
import { Eye, PercentageCircle, Timer1 } from "iconsax-react";
import { data } from "jquery";
import ReasonModal from "../ReasonModal";
import CustomPagination from "../../Utils/CustomPagination";

function HrList(props) {
  const dispatch = useDispatch();
  const navigte = useNavigate();
  const [List, setList] = useState([]);
  const [page, setPage] = useState(1);
  const countPerPage = 10;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );
  const [isSubmit, setIsSubmit] = useState(false);
  const [chnageStatus, setChnageStatus] = useState("");
  const [Data, setData] = useState({});
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModal(true);
    },
    []
  );

  const handleAddClick = (e) => {
    const propdata = {
      mode: "add",
    };
    props.onChange(propdata);
  };
  const getStatus = (row) => {
    switch (row) {
      case "0":
        return "Pending";
      case "1":
        return "Approved";
      case "2":
        return "Rejected";
      default:
        return "Unknown";
    }
  };


  const columns = [
    {
      name: "Employee Id",
      selector: (row) => row.employeeId,
    },
    {
      name: "Employee Name",
      selector: (row)=> `${row.firstName} ${row.lastName}`,
    },
    {
      name: "Resign Date",
      selector: (row) => row.resign_date,
    },
   {
      name: "Reason",
      selector: (row) => row.reason_for_resign,
      cell: (row) => {
        const wordCount = row?.reason_for_resign?.split(" ").length;
        console.log(wordCount)
        return wordCount > 5 ? (
          <RB.Button
            className="btn btn-flat"
            onClick={handleShowModal("Reason", row.reason_for_resign)}
          >
            <Eye />
          </RB.Button>
        ) : (
          <span>{row.reason_for_resign}</span>
        );
      },
    },
    {
      name: "Status",
      selector: (row) => getStatus(row.resign_status),

    },
    {
      name: (
        <div>
          Action By <br />
        </div>
      ),
      selector: (row) => row.auth_name,
    },
    {
        name: "Team Log",
        center: true,
        selector: (row) => (
          <>
          {
            row.logData.length !== 0 && (
            <RB.OverlayTrigger
              trigger="click"
              rootClose
              key="bottom"
              placement="bottom"
              overlay={
                <RB.Popover id={`popover-positioned-bottom`}>
                  <RB.Popover.Body className="p-0 overflow-auto popover-scroll ">
                    <div className="list-group ">
                      {row.logData.length !== 0 ? (
                        <>
                          {row.logData.map((log, index) => (
                            <div className="list-group-item ">
                              {log.reporting_manager_name}
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          <div className="list-group-item">...</div>
                        </>
                      )}
                    </div>
                  </RB.Popover.Body>
                </RB.Popover>
              }
            >
               <Link
                  className="btn btn-flat"
                >
                  <Timer1 />
                </Link>
            </RB.OverlayTrigger>
            )
        }
          </>
        ),
      },
    
  ];

  useEffect(() => {
    getList();
  }, [page]);

  const getList = () => {
    utils
      .callAPI(
        "get",
        `/resignation/list-all?page=${page}&per_page=${countPerPage}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setList(res);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        setList([]);
      });
  };

  const handleApprove = useCallback(
    (row) => async () => {
      setData({ ...row, status: "Approved" });
      setChnageStatus("Approved");
      setIsSubmit(true);
    },
    []
  );

  const handleReject = useCallback(
    (row) => async () => {
      setData({ ...row, status: "Rejected" });
      setChnageStatus("Rejected");
      setIsSubmit(true);
    },
    []
  );

  useEffect(() => {
    if (isSubmit) {
      const updatedData = {
        ...Data,
        status: chnageStatus,
        id: Data.attendance_update_id,
        attendance_history_id: Data.id,
      };

      utils
        .callAPI("post", "/Attendance/approval", updatedData)
        .then((res) => {
          if (res) {
            getList();
            setIsSubmit(false);
          }
        })
        .catch(() => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  return (
    <>
    <div className="section-body">
      <RB.Card className="card shadow">
        <RB.Card.Header>
          <h4></h4>
          <div className="card-header-action"></div>
        </RB.Card.Header>
        <RB.Card.Body>
        
            <DataTable
              columns={columns}
              data={List.result}
              highlightOnHover
              responsive
              pagination
              paginationServer
              paginationTotalRows={List.total}
              paginationPerPage={countPerPage}
              paginationComponentOptions={{
                noRowsPerPage: true,
              }}
              paginationComponent={() => (
                <CustomPagination
                  currentPage={page}
                  totalRows={List.total}
                  rowsPerPage={countPerPage}
                  onPageChange={handlePageChange}
                />
              )}
              striped
              onChangePage={(page) => setPage(page)}
              persistTableHead
              customStyles={{
                head: {
                  style: {
                    fontSize: "0.875rem", // Adjust the font size as needed
                    fontWeight: "bold", // Adjust the font weight as needed
                  },
                },
              }}
              //subHeader
              // subHeaderComponent={subHeaderComponent}
            />
        </RB.Card.Body>
      </RB.Card>
    </div>
     <ReasonModal
     show={showModal}
     onHide={() => setShowModal(false)}
     title={modalTitle}
     text={modalText}
   />
   </>
  );
}

export default HrList;
