import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import DatePicker from "react-datepicker";
import EmpTravelList from "./EmpTravelList";
import EmpLocalList from "./EmpLocalList";
import { useLocation } from "react-router-dom";
import { ArrowDown } from "iconsax-react";

function TabList(props) {
  const [activeTab, setActiveTab] = useState("Travel");
  const [isAccountant, setIsAccountant] = useState(false);
  const location = useLocation();
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  useEffect(() => {
    if (
      location.pathname === "/expenses/all-expense" ||
      location.pathname === "/expenses/accountant-expense"
    ) {
      setIsAccountant(true);
    } else {
      setIsAccountant(false);
    }
  }, [location.pathname]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    props.setCurrentTab(tab)
  };
  const handleMonthChange = (date) => {
    setSelectedMonth(date);
  };
  return (
    <>
      {isAccountant ? (
        <div className="d-flex justify-content-between align-items-center mb-3">
          <DatePicker
            selected={selectedMonth}
            onChange={handleMonthChange}
            dateFormat="MMMM-yyyy"
            showMonthYearPicker
            className="form-control"
            showMonthDropdown
            showYearDropdown
          />
        </div>
      ) : (
        ""
      )}
      <RB.Tabs
        activeKey={activeTab}
        onSelect={(tab) => handleTabChange(tab)}
        id="travel-local-tabs"
        className="mb-3"
      >
        <RB.Tab eventKey="Travel" title="Travel">
          {activeTab == "Travel" && (
            <EmpTravelList selectedMonth={selectedMonth} props={props} />
          )}
        </RB.Tab>
        <RB.Tab eventKey="Local" title="Local">
          {activeTab == "Local" && (
            <EmpLocalList selectedMonth={selectedMonth} props={props} />
          )}
        </RB.Tab>
      </RB.Tabs>
    </>
  );
}

export default TabList;
