import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as utils from "../Utils/comman";

function ArtisanDelete() {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("artisan_token"));

  const handleDelete = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this account?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: "Cancel",
    });

    if (result.isConfirmed) {
      utils
        .callAPI("post", "/Client/client-delete", {})
        .then((res) => {
          if (res !== undefined) {
            localStorage.removeItem("artisan_token");
            navigate("/artisan");
          } else {
          }
        })
        .catch((err) => {});
    } else if (result.isDenied) {
      localStorage.removeItem("artisan_token");
      navigate("/artisan");
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/artisan");
    } else {
      handleDelete();
    }
  }, [token, navigate]);

  return <div></div>;
}

export default ArtisanDelete;
