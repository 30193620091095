import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import Layout from "../../Layout";
import * as utils from "../../Utils/comman";
import { Link } from "react-router-dom";

function Add(props) {
  const [formData, setFormData] = useState({
    product_name: "",
    productCode: "",
    productCategory: "",
    regularPrice: "",
    salesPrice: "",
    size: "",
    unit: "",
    description: "",
    characteristics: "",
    usageGuide: "",
    couponValue: "",
    nonScannableCouponValue: "",
    image: [],
    document: [],
  });
  const [editId, setEditId] = useState();
  const [category, setcategory] = useState([]);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isDocDragging, setIsDocDragging] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isValidImage, setisValidImage] = useState(false);
  const [isValidDoc, setisValidDoc] = useState(false);
  const [isEdit, setisEdit] = useState(false);
  const [editImage, setEditImage] = useState([]);
  const [editDoc, setEditDoc] = useState([]);

  const units = [
    //{ id: "", value: "" },
    { id: "1", value: "ml" },
    { id: "2", value: "L" },
    { id: "3", value: "Kg" },
  ];

  useEffect(() => {
    getcategory();
  }, []);

  const getcategory = () => {
    utils
      .callAPI("get", `/Product/categoryList`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setcategory(res);
        } else {
          setcategory([]);
        }
      })
      .catch((err) => {
        setcategory([]);
      });
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Validation regex for numeric input with optional decimal values
    const numericRegex = /^\d*\.?\d*$/;
    // Check if the input field name is one of the numeric fields
    if (
      [
        "size",
        "regularPrice",
        "salesPrice",
        "couponValue",
        "nonScannableCouponValue",
      ].includes(name)
    ) {
      // If it's a numeric field, validate the input value
      if (numericRegex.test(value) || value === "") {
        // Update the state only if the value is valid or empty
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      // For other fields, simply update the state with the new value
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      // if (isEdit) {
      bodyFormData.append("id", props?.rowData?.id ? props?.rowData?.id : "");
      // }
      bodyFormData.append("product_name", formData.product_name);
      bodyFormData.append("productCode", formData.productCode);
      bodyFormData.append("productCategory", formData.productCategory);
      bodyFormData.append("regularPrice", formData.regularPrice);
      bodyFormData.append("salesPrice", formData.salesPrice);
      bodyFormData.append("size", formData.size);
      bodyFormData.append("unit", formData.unit);
      bodyFormData.append("description", formData.description);
      bodyFormData.append("characteristics", formData.characteristics);
      bodyFormData.append("usageGuide", formData.usageGuide);
      bodyFormData.append("couponValue", formData.couponValue);
      bodyFormData.append(
        "nonScannableCouponValue",
        formData.nonScannableCouponValue
      );
      const availableImages = [];

      formData.image.forEach((img, index) => {
        if (img.productImgId) {
          availableImages.push(img);
        } else {
          if (!img.isDeleted) {
            bodyFormData.append("image[]", img);
          }
        }
      });
      bodyFormData.append("availableImg", JSON.stringify(availableImages));

      const availableDoc = [];

      formData.document.forEach((doc, index) => {
        if (doc.productDocId) {
          availableDoc.push(doc);
        } else {
          if (!doc.isDeleted) {
            console.log("yeh hai image", doc);
            bodyFormData.append("document[]", doc);
          }
        }
      });
      bodyFormData.append("availableDoc", JSON.stringify(availableDoc));

      utils
        .callAPI("post", `/Product/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setFormData({
              product_name: "",
              productCode: "",
              productCategory: "",
              regularPrice: "",
              salesPrice: "",
              size: "",
              unit: "",
              description: "",
              characteristics: "",
              usageGuide: "",
              couponValue: "",
              nonScannableCouponValue: "",
              unit: "",
              image: [],
              document: [],
            });
            setisEdit(false);
            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      setisEdit(true);
      setFormData({
        id: props.rowData.id || "",
        product_name: props.rowData.product_name || "",
        productCode: props.rowData.productCode || "",
        productCategory: props.rowData.productCategory || "",
        description: props.rowData.description || "",
        characteristics: props.rowData.characteristics || "",
        usageGuide: props.rowData.usageGuide || "",
        regularPrice: props.rowData.regularPrice || "",
        salesPrice: props.rowData.salesPrice || "",
        couponValue: props.rowData.couponValue || "",
        nonScannableCouponValue: props.rowData.nonScannableCouponValue || "",
        unit: props.rowData.unit || "",
        size: props.rowData.size || "",
        image: props.rowData.productImages || [],
        document: props.rowData.productDocuments || [],
      });
    } else {
      setisEdit(false);
    }
  }, [props.rowData]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    // Validate file types (jpg, jpeg, png)
    const isValidFileType = files.every(
      (file) =>
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png"
    );
    if (!isValidFileType) {
      // Handle invalid file type
      return;
    }
    console.log("Product ka path", files);
    setFormData({ ...formData, image: [...formData.image, ...files] });
  };

  const handleDocumentChange = (e) => {
    const files = Array.from(e.target.files);
    const isValidFileType = files.every(
      (file) => file.type === "application/pdf"
    );
    if (!isValidFileType) {
      return;
    }
    setFormData({ ...formData, document: [...formData.document, ...files] });
  };
  const handleDragOver = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(true);
    } else {
      setIsDocDragging(true);
    }
  };

  const handleDragEnter = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(true);
    } else {
      setIsDocDragging(true);
    }
  };

  const handleDragLeave = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(false);
    } else {
      setIsDocDragging(false);
    }
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(false);

      const files = Array.from(e.dataTransfer.files);
      const isValidFileType = files.every(
        (file) =>
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/png"
      );
      if (!isValidFileType) {
        setisValidImage(true);
        return;
      } else {
        setisValidImage(false);
      }
      setFormData({ ...formData, image: [...formData.image, ...files] });
    } else {
      const files = Array.from(e.dataTransfer.files);
      const isValidFileType = files.every(
        (file) => file.type === "application/pdf"
      );
      if (!isValidFileType) {
        setisValidDoc(true);
        return;
      } else {
        setisValidDoc(false);
      }
      setFormData({ ...formData, document: [...formData.document, ...files] });
      setIsDocDragging(false);
    }
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...formData.image];
    updatedImages[index].isDeleted = true;
    setFormData({
      ...formData,
      image: updatedImages,
    });
  };

  const handleDeleteDocument = (index) => {
    const updatedDoc = [...formData.document];
    updatedDoc[index].isDeleted = true;
    setFormData({
      ...formData,
      document: updatedDoc,
    });
  };

  function validateInput(event) {
    const char = String.fromCharCode(event.charCode);
    const regex = /[0-9.]/;
    if (!regex.test(char)) {
      event.preventDefault(); // Prevent invalid character insertion
    }
  }

  return (
    <div className="section-body">
      <RB.Card className="card-primary">
        <RB.Card.Header>
          <h4>{isEdit ? "Edit" : "Add"}</h4>
          <div className="card-header-action">
            <Link
              className="btn btn-underline me-2"
              onClick={handleBackClick}
            >
              Back
            </Link>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="productName"
                    label="Product Name"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Product Name"
                      name="product_name"
                      value={formData.product_name}
                      onChange={handleInputChange}
                      maxLength={32}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="productCode"
                    label="Product Code"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Product Code"
                      name="productCode"
                      value={formData.productCode}
                      onChange={handleInputChange}
                      maxLength={10}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="productCategory"
                    label="Product Category"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      type="text"
                      placeholder="Enter Product Category"
                      name="productCategory"
                      value={formData.productCategory}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Product Category..</option>
                      {category.map((data) => (
                        <option value={data.id}>
                          {data.productCategoryName}
                        </option>
                      ))}
                    </RB.Form.Control>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="regularPrice"
                    label="Regular Price"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Regular Price"
                      name="regularPrice"
                      value={formData.regularPrice}
                      onChange={handleInputChange}
                      min={0}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="salesPrice"
                    label="Sales Price"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Sales Price"
                      name="salesPrice"
                      value={formData.salesPrice}
                      onChange={handleInputChange}
                      min={0}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={4}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="size"
                    label="Size"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      onKeyPress={validateInput}
                      placeholder="Enter Size"
                      name="size"
                      value={formData.size}
                      onChange={handleInputChange}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={2}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formRoleId"
                    label="Unit"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="unit"
                      className="form-select"
                      value={formData.unit}
                      onChange={handleInputChange}
                      required
                    >
                      {units.map((unit) => (
                        <option key={unit.id} value={unit.value}>
                          {unit.value}
                        </option>
                      ))}
                    </RB.Form.Control>
                    <RB.Form.Control.Feedback type="invalid">
                      Please select option for roleId.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="couponValue"
                    label="Scannable Coupon Value"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Scannable Coupon Value"
                      name="couponValue"
                      value={formData.couponValue}
                      onChange={handleInputChange}
                      // min={0}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="nonScannableCouponValue"
                    label="Non Scannable Coupon Value"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Non Scannable Coupon Value"
                      name="nonScannableCouponValue"
                      value={formData.nonScannableCouponValue}
                      onChange={handleInputChange}
                      // min={0}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="characteristics"
                    label="Characteristics"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Enter Characteristics"
                      name="characteristics"
                      value={formData.characteristics}
                      onChange={handleInputChange}
                      maxLength={500}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="usageGuide"
                    label="Usage Guide"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Enter Usage Guide"
                      name="usageGuide"
                      value={formData.usageGuide}
                      onChange={handleInputChange}
                      maxLength={500}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="description"
                    label="Description"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Enter Description"
                      name="description"
                      value={formData.description}
                      onChange={handleInputChange}
                      maxLength={500}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <hr />
            <RB.Row>
              <RB.Col md={6}>
                <RB.Card>
                  <RB.CardBody className="p-2">
                    <h6 className="mb-1">Images</h6>
                    <hr />
                    <div
                      className={`border p-4 text-center d-flex flex-column align-items-center ${
                        isDraggingOver ? "bg-info" : ""
                      }`}
                      onDragOver={(e) => handleDragOver(e, "image")}
                      onDragEnter={(e) => handleDragEnter(e, "image")}
                      onDragLeave={(e) => handleDragLeave(e, "image")}
                      onDrop={(e) => handleDrop(e, "image")}
                    >
                      <div className="avatar avatar-circle avatar-lg bg-light text-dark">
                        <i className="material-icons-outlined">cloud_upload</i>
                      </div>
                      <p className="mb-0">Drag & Drop your images here</p>
                      <small className="mb-2">or</small>
                      <div>
                        <input
                          type="file"
                          accept="image/jpeg, image/jpg, image/png"
                          multiple
                          style={{ display: "none" }}
                          onChange={handleImageChange}
                          id="fileInput"
                        />
                        <RB.Button
                          variant="primary"
                          className="me-2"
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                        >
                          <i className="las la-file-upload me-1"></i>Browse
                          Images
                        </RB.Button>
                      </div>
                    </div>
                    <span className={`${isValidImage ? "text-danger" : ""}`}>
                      <small>
                        *Note: Only JPG,JPEG and PNG Files are allowed
                      </small>
                    </span>

                    <RB.Row className="mt-3 g-3">
                      {formData.image
                        .filter((img) => !img.isDeleted) // Filter out deleted images
                        .map((img, index) => (
                          <RB.Col className="col-md-6 col-lg-4">
                            {img.productImgId ? ( // Existing image from API
                              <div
                                key={index}
                                className="position-relative mb-4"
                              >
                                <div className="product-image-upload">
                                  <img
                                    src={img.productImg}
                                    alt={`Image ${index}`}
                                  />
                                </div>
                                <RB.Button
                                  variant="danger"
                                  className="btn btn-sm btn-circle position-absolute top-100 start-50 translate-middle"
                                  onClick={() => handleDeleteImage(index)}
                                >
                                  <i className="material-icons-outlined">
                                    delete
                                  </i>
                                </RB.Button>
                              </div>
                            ) : (
                              // Newly added image
                              <div
                                key={index}
                                className="position-relative mb-4"
                              >
                                <div className="product-image-upload">
                                  <img
                                    src={URL.createObjectURL(img)}
                                    alt={`Image ${index}`}
                                  />
                                </div>
                                <RB.Button
                                  variant="danger"
                                  className="btn btn-sm btn-circle position-absolute top-100 start-50 translate-middle"
                                  onClick={() => handleDeleteImage(index)}
                                >
                                  <i className="material-icons-outlined">
                                    delete
                                  </i>
                                </RB.Button>
                              </div>
                            )}
                          </RB.Col>
                        ))}
                    </RB.Row>
                  </RB.CardBody>
                </RB.Card>
              </RB.Col>

              <RB.Col md={6}>
                <RB.Card>
                  <RB.CardBody className="p-2">
                    <h6 className="mb-2">Documents</h6>
                    <hr />
                    <div
                      className={`border p-4 text-center d-flex flex-column align-items-center ${
                        isDocDragging ? "bg-info" : ""
                      }`}
                      onDragOver={(e) => handleDragOver(e, "doc")}
                      onDragEnter={(e) => handleDragEnter(e, "doc")}
                      onDragLeave={(e) => handleDragLeave(e, "doc")}
                      onDrop={(e) => handleDrop(e, "doc")}
                    >
                      <div className="avatar avatar-circle avatar-lg bg-light text-dark">
                        <i className="material-icons-outlined">cloud_upload</i>
                      </div>
                      <p className="mb-0">Drag & Drop your documents here</p>
                      <small className="mb-2">or</small>
                      <div>
                        <input
                          type="file"
                          accept="application/pdf"
                          multiple
                          style={{ display: "none" }}
                          onChange={handleDocumentChange}
                          id="docInput"
                        />
                        <RB.Button
                          variant="primary"
                          className="me-2"
                          onClick={() =>
                            document.getElementById("docInput").click()
                          }
                        >
                          <i className="las la-file-upload me-1"></i>Browse
                          Documents
                        </RB.Button>
                      </div>
                    </div>
                    <span className={`${isValidDoc ? "text-danger" : ""}`}>
                      <small>*Note: Only PDF Files are allowed</small>
                    </span>
                    <RB.Row className="mt-3 g-3">
                      {formData.document
                        .filter((doc) => !doc.isDeleted)
                        .map((doc, index) => (
                          <RB.Col key={index} className="col-md-6 col-lg-4">
                            <div className="position-relative me-2 mb-4 rounded border p-3 text-center">
                              <div className="product-doc-upload">
                                <a
                                  href={
                                    isEdit
                                      ? doc.productDocument
                                      : URL.createObjectURL(doc)
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-decoration-none text-dark"
                                >
                                  <i className="material-icons-outlined md-64">
                                    picture_as_pdf
                                  </i>
                                  <div className="text-center">
                                    {isEdit
                                      ? `${
                                          doc.productDocName
                                            ? doc.productDocName
                                            : doc.name.slice(0, 10) + "... .pdf"
                                        }`
                                      : doc.name.slice(0, 10) + "... .pdf"}
                                  </div>
                                </a>
                              </div>
                              <RB.Button
                                variant="danger"
                                className="btn btn-sm btn-circle position-absolute top-100 start-50 translate-middle"
                                onClick={() => handleDeleteDocument(index)}
                              >
                                <i className="material-icons-outlined">
                                  delete
                                </i>
                              </RB.Button>
                            </div>
                          </RB.Col>
                        ))}
                    </RB.Row>
                  </RB.CardBody>
                </RB.Card>
              </RB.Col>
            </RB.Row>
            <RB.Row className="align-items-center mt-3">
              <RB.Col className="col-auto">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
