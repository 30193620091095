// requestCountSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as utils from "../Utils/comman";

export const fetchPrimaryMenu = createAsyncThunk(
  "/users/settings",
  async (_, thunkAPI) => {
    try {
      const response = await utils.callAPI("get", "/users/settings");
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  primaryMenu: null,
};

const menuSlice = createSlice({
  name: "Menu",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrimaryMenu.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchPrimaryMenu.fulfilled, (state, action) => {
        state.primaryMenu = action.payload;
        state.isLoading = false;
        state.isError = false;
      })
      .addCase(fetchPrimaryMenu.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default menuSlice.reducer;
