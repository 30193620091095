import {
  Circle,
  Delete,
  InsertDriveFile,
  PictureAsPdf,
  PlusOne,
  PlusOneSharp,
} from "@mui/icons-material";
import { Add, AddCircle, Image, Trash } from "iconsax-react";
import React, { useEffect, useState } from "react";
import * as utils from "../../Utils/comman";
import * as RB from "react-bootstrap";

function TravelDocument(props) {
  const [documents, setDocuments] = useState([{ file: null, amount: "" }]);
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isDocUploaded, setIsDocUploaded] = useState(false);

  const handleBackClick = () => {
    props.onChange({ mode: "list" });
  };

  useEffect(() => {
    if (
      props?.rowData?.travelDocuments &&
      props?.rowData?.travelDocuments?.length !== 0
    ) {
      setIsDocUploaded(true);
    } else {
      setIsDocUploaded(false);
    }
  }, [props.rowData]);

  const handleFileChange = (index, event) => {
    const file = event.target.files[0];

    if (
      file &&
      (file.type === "application/pdf" || file.type.startsWith("image/"))
    ) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        let cleanedBase64String = base64String;

        if (file.type === "application/pdf") {
          cleanedBase64String = base64String.replace(
            "data:application/pdf;base64,",
            ""
          );
        }

        const newDocuments = [...documents];
        newDocuments[index].file = cleanedBase64String;
        setDocuments(newDocuments);
      };
      reader.readAsDataURL(file);
    } else {
      const newDocuments = [...documents];
      newDocuments[index].file = null;
      setDocuments(newDocuments);

    }
  };

  const handleAmountChange = (index, event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      const newDocuments = [...documents];
      newDocuments[index].amount = value;
      setDocuments(newDocuments);
    }
  };

  const handleAddClick = () => {
    setDocuments([...documents, { file: null, amount: "" }]);
    setValidated(false);
  };

  const handleRemoveClick = (index) => {
    const newDocuments = documents.filter((_, i) => i !== index);
    setDocuments(newDocuments);
  };

  const isUploadEnabled = documents.every(
    (doc) => doc.file !== null && doc.amount !== ""
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      console.log(documents);
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      const bodyFormData = new FormData();

      bodyFormData.append("travelReqId", props?.rowData?.id);
      bodyFormData.append("notes", "");
      bodyFormData.append("travel_document", JSON.stringify(documents));
      utils
        .callAPI("post", `/travel/expense`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
          }
          setIsSubmit(false);
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  return (
    <div className="section-body">
      <RB.Card className="card shadow">
        <RB.Card.Header>
          <h4>Upload Documents</h4>
          <div className="card-header-action">
            <RB.Button className="btn btn-primary" onClick={handleBackClick}>
              Back
            </RB.Button>
          </div>
        </RB.Card.Header>

        <RB.Card.Body>
          <div className="mb-3">
            *Note: Only PDF and image files are allowed.
          </div>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            {documents.map((document, index) => (
              <RB.Row key={index} className="mb-3 align-items-end">
                <RB.Col md={5}>
                  <RB.Form.Group controlId={`documentUpload${index}`}>
                    <RB.Form.Control
                      type="file"
                      accept=".pdf, image/*"
                      onChange={(e) => handleFileChange(index, e)}
                      required
                    />
                  </RB.Form.Group>
                </RB.Col>
                <RB.Col md={5}>
                  <RB.Form.Group controlId={`documentAmount${index}`}>
                    {/* <RB.Form.Label>Amount</RB.Form.Label> */}
                    <RB.Form.Control
                      type="text"
                      value={document.amount}
                      onChange={(e) => handleAmountChange(index, e)}
                      placeholder="Enter amount"
                      required
                    />
                  </RB.Form.Group>
                </RB.Col>
                {index === documents.length - 1 ? (
                  <RB.Col md={2}>
                    <RB.Button
                      className="btn btn-primary "
                      onClick={handleAddClick}
                    >
                      <AddCircle />
                    </RB.Button>
                  </RB.Col>
                ) : (
                  <RB.Col md={2}>
                    <RB.Button
                      variant="outline-danger"
                      className="btn btn-danger"
                      onClick={() => handleRemoveClick(index)}
                    >
                      <Delete />
                    </RB.Button>
                  </RB.Col>
                )}
              </RB.Row>
            ))}
            <RB.Row className="d-flex justify-content-start">
              <RB.Col md={3}>
                <RB.Button className="btn btn-primary" type="submit">
                  Upload
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default TravelDocument;
