import { CloseCircle, Document, Edit, Eye, TickCircle } from "iconsax-react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import Swal from "sweetalert2";
import * as utils from "../../Utils/comman";
import { CheckCircle, Delete } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTravelLocalExpense } from "../../reducers/expenseData";
import ReasonModal from "../ReasonModal";
import { setAction } from "../../reducers/actionSlice";
import { setExp } from "../../reducers/updateExpenseSlice";

function ExpensesList({
  openAccordionId,
  localID,
  localExType,
  setShowAdd,
  setcompo,
  activeTab,
  travelPlan,
}) {
  const [show, setShow] = useState(false);
  const [doc, setdoc] = useState([]);
  const handleClose = () => setShow(false);
  const location = useLocation();
  const handleShow = useCallback((docs) => async () => {
    setShow(true);
    setdoc(docs);
  });
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const [updatedAmounts, setUpdatedAmounts] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const userRole = userProfile?.user_role[0]?.roleName;
  const dispatch = useDispatch();
  const { expenseData } = useSelector((state) => state.expense);
  const [List, setExpList] = useState([]);
  const [rejectReasonError, setRejectReasonError] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const [rejectReason, setRejectReason] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const [showModalR, setShowModalR] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");

  const handleRejectClose = () => {
    setShowRejectModal(false);
    setRejectReason("");
    setRejectReasonError("");
  };
  useEffect(() => {
    if (openAccordionId) {
      const masteexpId = 0;
      dispatch(fetchTravelLocalExpense({ openAccordionId, masteexpId }));
    } else if (localID) {
      dispatch(fetchTravelLocalExpense({ openAccordionId, localID }));
    }
  }, [dispatch, openAccordionId, localID]);

  useEffect(() => {
    setExpList(expenseData?.result);
  }, [expenseData]);
  // const getTravelList = () => {
  //   utils
  //     .callAPI(
  //       "get",
  //       `/expense/travel-local-expense?schedule_id=${
  //         openAccordionId ? openAccordionId : ""
  //       }&exp_master_id=${!openAccordionId ? 25 : ""}`
  //     )
  //     .then((res) => {
  //       if (typeof res !== "undefined") {
  //         setExpList(res.result);
  //       } else {
  //         setExpList([]);
  //       }
  //     })
  //     .catch((err) => {
  //       setExpList([]);
  //     });
  // };

  useEffect(() => {
    if (userRole === "HR Manager") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [userRole]);

  const renderExpensesByHead = () => {
    // const { expList } = List;
    const groupedExpenses = List?.reduce((acc, expense) => {
      const { headName } = expense;
      const existingGroup = acc.find((group) => group.headName === headName);

      if (!existingGroup) {
        acc.push({
          headName: headName,
          expenses: [expense],
        });
      } else {
        existingGroup.expenses.push(expense);
      }

      return acc;
    }, []);

    return groupedExpenses;
  };

  const calculateGrandTotal = () => {
    let grandTotal = 0;
    renderExpensesByHead()?.forEach((head) => {
      head.expenses.forEach((expense) => {
        grandTotal += parseFloat(
          expense.subheadname === "Car" || expense.subheadname === "Bike"
            ? expense.amount
            : expense.amount
        );
      });
    });
    return grandTotal.toFixed(2);
  };

  const handleDelete = useCallback((row) => async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        utils
          .callAPI("get", `/expense/delete-expense?id=${row.id}`, "")
          .then((res) => {
            if (typeof res !== "undefined") {
              if (localExType) {
                const id = 0;
                dispatch(fetchTravelLocalExpense({ id, localID }));
              } else {
                const masteexpId = 0;
                dispatch(
                  fetchTravelLocalExpense({ openAccordionId, masteexpId })
                );
              }
            }
          })
          .catch((err) => {});
      }
    });
  });

  const handleEdit = useCallback((expense) => async () => {
    dispatch(setExp(expense));
    if (activeTab == "local") {
      setShowAdd(true);
      setcompo("add");
    } else {
      dispatch(setAction("add"));
    }
  });

  const handleDownload = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(
        new Blob([blob], { type: "image/jpeg" })
      );

      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "Bills";
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }, 10000);
    } catch (error) {
      // console.error("Failed to download image:", error);
    }
  };
  useEffect(() => {
    const initialAmounts = {};
    List?.forEach((expense) => {
      initialAmounts[expense.id] = expense.amount;
    });
    setUpdatedAmounts(initialAmounts);
  }, [List]);

  const handleAmountChange = (id, newAmount, expId) => {
    setUpdatedAmounts((prev) => ({
      ...prev,
      [id]: newAmount,
    }));
  };

  // const handleSubmit = () => {
  //   const finalArray = List.filter(
  //     (expense) =>
  //       updatedAmounts[expense.id] !== undefined &&
  //       updatedAmounts[expense.id] !== expense.amount
  //   ).map((expense) => ({
  //     id: expense.id,
  //     amount: updatedAmounts[expense.id],
  //   }));

  //   var bodyFormData = new FormData();
  //   bodyFormData.append("expense_json", JSON.stringify(finalArray));
  //   utils
  //     .callAPI("post", `/expense/amt-edit-hr`, bodyFormData)
  //     .then((res) => {
  //       if (typeof res !== "undefined") {
  //         getAllTravelExpenses();
  //       } else {
  //       }
  //     })
  //     .catch((err) => {});
  // };

  const getAllTravelExpenses = () => {
    utils
      .callAPI("get", `/expense/all-list-hr?filters=Pending`)
      .then((res) => {
        if (typeof res !== "undefined") {
          const currentData = res.result.find((item) => item.id == List.id);
          setExpList(currentData);
        } else {
        }
      })
      .catch((err) => {});
  };

  const handleApprove = useCallback((row) => async () => {
    const formData = new FormData();
    formData.append("type", "travel");
    formData.append("expId", row.id);
    formData.append("status", "Approve");
    formData.append("reason", "");

    utils
      .callAPI("post", "/expense/senior-change-status", formData)
      .then((res) => {
        if (typeof res !== "undefined") {
          //   getTravelExpenses();
          const masteexpId = 0;
          dispatch(fetchTravelLocalExpense({ openAccordionId, masteexpId }));
        } else {
        }
      })
      .catch((err) => {});
  });

  const handleReject = useCallback((row) => async () => {
    setSelectedExpense(row);
    setShowRejectModal(true);
  });

  const handleRejectSubmit = async () => {
    if (!rejectReason.trim()) {
      setRejectReasonError("Reject reason cannot be blank");
      return;
    }
    const formData = new FormData();
    formData.append("type", "travel");
    formData.append("expId", selectedExpense.id);
    formData.append("status", "Reject");
    formData.append("reason", rejectReason);
    utils
      .callAPI("post", "/expense/senior-change-status", formData)
      .then((res) => {
        if (typeof res !== "undefined") {
          setRejectReason();
          setRejectReasonError("");
          //   getTravelExpenses();
          setShowRejectModal(false);
          const masteexpId = 0;
          dispatch(fetchTravelLocalExpense({ openAccordionId, masteexpId }));
        } else {
        }
      })
      .catch((err) => {});
  };

  const handleUpdateAmount = useCallback((expense) => async () => {
    const updatedAmount = updatedAmounts[expense.id];
    var bodyFormData = new FormData();
    bodyFormData.append("exp_id", expense.id);
    bodyFormData.append("amount", updatedAmount);
    utils
      .callAPI("post", `/expense/amt-edit-hr`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          // getAllTravelExpenses();
          const masteexpId = 0;
          dispatch(fetchTravelLocalExpense({ openAccordionId, masteexpId }));
        } else {
        }
      })
      .catch((err) => {});
  });

  const handleShowModal = useCallback(
    (title, text) => () => {
      setModalTitle(title);
      setModalText(text);
      setShowModalR(true);
    },
    []
  );

  return (
    <>
      {List?.length !== 0 ? (
        <>
          <div className="d-flex align-items-center justify-content-md-end">
            <h5 className="d-inline me-2">Total Amount:</h5>
            <h5 className="d-inline">₹ {calculateGrandTotal()}</h5>
          </div>
          {renderExpensesByHead()?.map((head, headIndex) => (
            <RB.Card key={headIndex}>
              <RB.Card.Header>
                <h4>{head.headName}</h4>
              </RB.Card.Header>
              <RB.Table
                striped
                bordered
                hover
                responsive
                className="table-sm text-center"
              >
                <thead>
                  <tr>
                    {head.headName === "Travel Entitlement" && (
                      <>
                        <th>SubHead</th>
                        <th>Class</th>
                        <th>Station From</th>
                        <th>Station To</th>
                        <th>Amount</th>
                        <th>Senior Status</th>
                        <th>Documents</th>
                        {(location.pathname === "/expenses/your-expense" ||
                          location.pathname === "/expenses/team-expense" ||
                          isVisible) && <th>Action</th>}
                      </>
                    )}
                    {head.headName === "Misc. Monthly Limit" && (
                      <>
                        <th>Nature of Expense</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Senior Status</th>
                        <th>Documents</th>
                        {(location.pathname === "/expenses/your-expense" ||
                          location.pathname === "/expenses/team-expense" ||
                          isVisible) && <th>Action</th>}
                      </>
                    )}
                    {head.headName === "Hotel Expense" && (
                      <>
                        <th>City</th>
                        <th>Check-in Date</th>
                        <th>Check-out Date</th>
                        <th>Hotel Name</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Senior Status</th>
                        <th>Documents</th>
                        {(location.pathname === "/expenses/your-expense" ||
                          location.pathname === "/expenses/team-expense" ||
                          isVisible) && <th>Action</th>}
                      </>
                    )}
                    {head.headName === "Food Expense" && (
                      <>
                        <th>City</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Senior Status</th>
                        <th>Documents</th>
                        {(location.pathname === "/expenses/your-expense" ||
                          location.pathname === "/expenses/team-expense" ||
                          isVisible) && <th>Action</th>}
                      </>
                    )}
                    {head.headName === "Local Conveyance" && (
                      <>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Senior Status</th>
                        <th>Documents</th>
                        {(location.pathname === "/expenses/your-expense" ||
                          location.pathname === "/expenses/team-expense" ||
                          isVisible) && <th>Action</th>}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {head.expenses.map((expense, expIndex) => (
                    <tr key={expIndex}>
                      {head.headName === "Travel Entitlement" && (
                        <>
                          <td>{expense.subheadname}</td>
                          <td>{expense.className}</td>
                          <td>{expense.stationFrom}</td>
                          <td>{expense.stationTo}</td>
                          <td className="text-center d-flex justify-content-center align-items-center">
                            {isVisible &&
                            location.pathname !== "/expenses/your-expense" ? (
                              <RB.Form.Control
                                type="text"
                                className="exp-amountInput"
                                value={
                                  updatedAmounts[expense.id] !== undefined
                                    ? updatedAmounts[expense.id]
                                    : expense.amount
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value)) {
                                    handleAmountChange(
                                      expense.id,
                                      value,
                                      expense.expId
                                    );
                                  }
                                }}
                                disabled={travelPlan?.hr_submit == "1"}
                              />
                            ) : (
                              <>
                                <div className="d-flex flex-column text-center">
                                  <span>{expense.amount}</span>
                                  <span className="small-italic">
                                    {expense.editAmtBy !== null
                                      ? "(Edited by HR)"
                                      : ""}
                                  </span>
                                </div>
                              </>
                            )}
                          </td>
                          <td>
                            {expense?.senior_status}
                            {expense.senior_status === "Reject" && (
                              <>
                                <RB.Button
                                  className="btn btn-flat ms-2"
                                  title="Reject Reason"
                                  onClick={handleShowModal(
                                    "Reject Reason",
                                    expense.senior_reson
                                  )}
                                >
                                  <Eye />
                                </RB.Button>
                              </>
                            )}
                          </td>
                          <td className="text-center">
                            {Array.isArray(expense?.expense_image) &&
                            expense?.expense_image?.length !== 0 ? (
                              <RB.Button
                                className="btn btn-flat ms-1"
                                onClick={handleShow(expense.expense_image)}
                              >
                                <Document />
                              </RB.Button>
                            ) : (
                              ""
                            )}
                          </td>
                          {location.pathname === "/expenses/your-expense" && (
                            <td>
                              {expense.senior_status !== "Reject" ? (
                                <>
                                  {expense.senior_status == "Pending" && (
                                    <RB.Button
                                      className="btn btn-flat ms-1"
                                      onClick={handleDelete(expense)}
                                    >
                                      <Delete />
                                    </RB.Button>
                                  )}
                                </>
                              ) : (
                                <>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    onClick={handleEdit(expense)}
                                  >
                                    <Edit />
                                  </RB.Button>
                                </>
                              )}
                            </td>
                          )}
                          {location.pathname === "/expenses/team-expense" && (
                            <>
                              {expense.senior_status == "Pending" && (
                                <td>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    onClick={handleApprove(expense)}
                                  >
                                    <TickCircle />
                                  </RB.Button>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    onClick={handleReject(expense)}
                                  >
                                    <CloseCircle />
                                  </RB.Button>
                                </td>
                              )}
                            </>
                          )}
                          {isVisible && (
                            <td>
                              <RB.Button
                                className="btn"
                                variant="primary"
                                onClick={handleUpdateAmount(expense)}
                                disabled={
                                  updatedAmounts[expense.id] === undefined ||
                                  updatedAmounts[expense.id] === expense.amount
                                }
                              >
                                Update
                              </RB.Button>
                            </td>
                          )}
                        </>
                      )}
                      {head.headName === "Misc. Monthly Limit" && (
                        <>
                          <td>{expense.nature_of_expense}</td>
                          <td>{expense.description}</td>
                          <td className="text-center d-flex justify-content-center align-items-center">
                            {isVisible &&
                            location.pathname !== "/expenses/your-expense" ? (
                              <RB.Form.Control
                                type="text"
                                value={
                                  updatedAmounts[expense.id] !== undefined
                                    ? updatedAmounts[expense.id]
                                    : expense.amount
                                }
                                className="exp-amountInput"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value)) {
                                    handleAmountChange(
                                      expense.id,
                                      value,
                                      expense.expId
                                    );
                                  }
                                }}
                                disabled={travelPlan?.hr_submit == "1"}
                              />
                            ) : (
                              <>
                                <div className="d-flex flex-column text-center">
                                  <span>{expense.amount}</span>
                                  <span className="small-italic">
                                    {expense.editAmtBy !== null
                                      ? `(Edited by HR)`
                                      : ""}
                                  </span>
                                </div>
                              </>
                            )}
                          </td>
                          <td>
                            {expense?.senior_status}
                            {expense.senior_status === "Reject" && (
                              <>
                                <RB.Button
                                  className="btn btn-flat ms-2"
                                  title="Reject Reason"
                                  onClick={handleShowModal(
                                    "Reject Reason",
                                    expense.senior_reson
                                  )}
                                >
                                  <Eye />
                                </RB.Button>
                              </>
                            )}
                          </td>
                          <td className="text-center">
                            {Array.isArray(expense?.expense_image) &&
                            expense?.expense_image?.length !== 0 ? (
                              <RB.Button
                                className="btn btn-flat ms-1"
                                onClick={handleShow(expense?.expense_image)}
                              >
                                <Document />
                              </RB.Button>
                            ) : (
                              ""
                            )}
                          </td>
                          {location.pathname === "/expenses/your-expense" && (
                            <td>
                              {expense.senior_status !== "Reject" ? (
                                <>
                                  {expense.senior_status == "Pending" && (
                                    <RB.Button
                                      className="btn btn-flat ms-1"
                                      onClick={handleDelete(expense)}
                                    >
                                      <Delete />
                                    </RB.Button>
                                  )}
                                </>
                              ) : (
                                <>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    onClick={handleEdit(expense)}
                                  >
                                    <Edit />
                                  </RB.Button>
                                </>
                              )}
                            </td>
                          )}
                          {location.pathname === "/expenses/team-expense" && (
                            <>
                              {expense.senior_status == "Pending" && (
                                <td>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    onClick={handleApprove(expense)}
                                  >
                                    <TickCircle />
                                  </RB.Button>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    onClick={handleReject(expense)}
                                  >
                                    <CloseCircle />
                                  </RB.Button>
                                </td>
                              )}
                            </>
                          )}
                          {isVisible && (
                            <td>
                              <RB.Button
                                className="btn"
                                variant="primary"
                                onClick={handleUpdateAmount(expense)}
                                disabled={
                                  updatedAmounts[expense.id] === undefined ||
                                  updatedAmounts[expense.id] === expense.amount
                                }
                              >
                                Update
                              </RB.Button>
                            </td>
                          )}
                        </>
                      )}
                      {head.headName === "Hotel Expense" && (
                        <>
                          <td>{expense.city}</td>
                          <td>
                            {moment(expense.dateFrom).format("DD-MM-YYYY")}
                          </td>
                          <td>{moment(expense.dateTo).format("DD-MM-YYYY")}</td>
                          <td>{expense.hotelName}</td>
                          <td>{expense.description}</td>
                          <td className="text-center d-flex justify-content-center align-items-center">
                            {isVisible &&
                            location.pathname !== "/expenses/your-expense" ? (
                              <RB.Form.Control
                                type="text"
                                className="exp-amountInput"
                                value={
                                  updatedAmounts[expense.id] !== undefined
                                    ? updatedAmounts[expense.id]
                                    : expense.amount
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value)) {
                                    handleAmountChange(
                                      expense.id,
                                      value,
                                      expense.expId
                                    );
                                  }
                                }}
                                disabled={travelPlan?.hr_submit == "1"}
                              />
                            ) : (
                              <>
                                <div className="d-flex flex-column text-center">
                                  <span>{expense.amount}</span>
                                  <span className="small-italic">
                                    {expense.editAmtBy !== null
                                      ? `(Edited by HR)`
                                      : ""}
                                  </span>
                                </div>
                              </>
                            )}
                          </td>
                          <td>
                            {expense?.senior_status}
                            {expense.senior_status === "Reject" && (
                              <>
                                <RB.Button
                                  className="btn btn-flat ms-2"
                                  title="Reject Reason"
                                  onClick={handleShowModal(
                                    "Reject Reason",
                                    expense.senior_reson
                                  )}
                                >
                                  <Eye />
                                </RB.Button>
                              </>
                            )}
                          </td>
                          <td className="text-center">
                            {Array.isArray(expense?.expense_image) &&
                            expense?.expense_image?.length !== 0 ? (
                              <RB.Button
                                className="btn btn-flat ms-1"
                                onClick={handleShow(expense.expense_image)}
                              >
                                <Document />
                              </RB.Button>
                            ) : (
                              ""
                            )}
                          </td>
                          {location.pathname === "/expenses/your-expense" && (
                            <td>
                              {expense.senior_status !== "Reject" ? (
                                <>
                                  {expense.senior_status == "Pending" && (
                                    <RB.Button
                                      className="btn btn-flat ms-1"
                                      onClick={handleDelete(expense)}
                                    >
                                      <Delete />
                                    </RB.Button>
                                  )}
                                </>
                              ) : (
                                <>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    title="Update Expense"
                                    onClick={handleEdit(expense)}
                                  >
                                    <Edit />
                                  </RB.Button>
                                </>
                              )}
                            </td>
                          )}
                          {location.pathname === "/expenses/team-expense" && (
                            <>
                              {expense.senior_status == "Pending" && (
                                <td>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    onClick={handleApprove(expense)}
                                  >
                                    <TickCircle />
                                  </RB.Button>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    onClick={handleReject(expense)}
                                  >
                                    <CloseCircle />
                                  </RB.Button>
                                </td>
                              )}
                            </>
                          )}
                          {isVisible && (
                            <td>
                              <RB.Button
                                className="btn"
                                variant="primary"
                                onClick={handleUpdateAmount(expense)}
                                disabled={
                                  updatedAmounts[expense.id] === undefined ||
                                  updatedAmounts[expense.id] === expense.amount
                                }
                              >
                                Update
                              </RB.Button>
                            </td>
                          )}
                        </>
                      )}
                      {head.headName === "Food Expense" && (
                        <>
                          <td>{expense.city}</td>
                          <td>{expense.description}</td>
                          <td className="text-center d-flex justify-content-center align-items-center">
                            {isVisible &&
                            location.pathname !== "/expenses/your-expense" ? (
                              <RB.Form.Control
                                type="text"
                                className="exp-amountInput"
                                value={
                                  updatedAmounts[expense.id] !== undefined
                                    ? updatedAmounts[expense.id]
                                    : expense.amount
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value)) {
                                    handleAmountChange(
                                      expense.id,
                                      value,
                                      expense.expId
                                    );
                                  }
                                }}
                                disabled={travelPlan?.hr_submit == "1"}
                              />
                            ) : (
                              <>
                                <div className="d-flex flex-column text-center">
                                  <span>{expense.amount}</span>
                                  <span className="small-italic">
                                    {expense.editAmtBy !== null
                                      ? `(Edited by HR)`
                                      : ""}
                                  </span>
                                </div>
                              </>
                            )}
                          </td>
                          <td>
                            {expense?.senior_status}
                            {expense.senior_status === "Reject" && (
                              <>
                                <RB.Button
                                  className="btn btn-flat ms-2"
                                  title="Reject Reason"
                                  onClick={handleShowModal(
                                    "Reject Reason",
                                    expense.senior_reson
                                  )}
                                >
                                  <Eye />
                                </RB.Button>
                              </>
                            )}
                          </td>
                          <td className="text-center">
                            {Array.isArray(expense?.expense_image) &&
                            expense?.expense_image?.length !== 0 ? (
                              <RB.Button
                                className="btn btn-flat ms-1"
                                onClick={handleShow(expense?.expense_image)}
                              >
                                <Document />
                              </RB.Button>
                            ) : (
                              ""
                            )}
                          </td>
                          {location.pathname === "/expenses/your-expense" && (
                            <td>
                              {expense.senior_status !== "Reject" ? (
                                <>
                                  {expense.senior_status == "Pending" && (
                                    <RB.Button
                                      className="btn btn-flat ms-1"
                                      onClick={handleDelete(expense)}
                                    >
                                      <Delete />
                                    </RB.Button>
                                  )}
                                </>
                              ) : (
                                <>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    title="Update Expense"
                                    onClick={handleEdit(expense)}
                                  >
                                    <Edit />
                                  </RB.Button>
                                </>
                              )}
                            </td>
                          )}
                          {location.pathname === "/expenses/team-expense" && (
                            <>
                              {expense.senior_status == "Pending" && (
                                <td>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    onClick={handleApprove(expense)}
                                  >
                                    <TickCircle />
                                  </RB.Button>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    onClick={handleReject(expense)}
                                  >
                                    <CloseCircle />
                                  </RB.Button>
                                </td>
                              )}
                            </>
                          )}
                          {isVisible && (
                            <td>
                              <RB.Button
                                className="btn"
                                variant="primary"
                                onClick={handleUpdateAmount(expense)}
                                disabled={
                                  updatedAmounts[expense.id] === undefined ||
                                  updatedAmounts[expense.id] === expense.amount
                                }
                              >
                                Update
                              </RB.Button>
                            </td>
                          )}
                        </>
                      )}
                      {head.headName === "Local Conveyance" && (
                        <>
                          <td>{expense.subheadname}</td>
                          <td>
                            {moment(expense.dateFrom).format("DD-MM-YYYY")}
                          </td>
                          <td>{expense.description}</td>
                          <td className="text-center d-flex justify-content-center align-items-center">
                            {isVisible &&
                            location.pathname !== "/expenses/your-expense" ? (
                              <RB.Form.Control
                                type="text"
                                value={
                                  updatedAmounts[expense.id] !== undefined
                                    ? updatedAmounts[expense.id]
                                    : expense.amount
                                }
                                className="exp-amountInput"
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value)) {
                                    handleAmountChange(
                                      expense.id,
                                      value,
                                      expense.expId
                                    );
                                  }
                                }}
                              />
                            ) : (
                              <>
                                <div className="d-flex flex-column text-center">
                                  <span>{expense.amount}</span>
                                  <span className="small-italic">
                                    {expense.editAmtBy !== null
                                      ? `(Edited by HR)`
                                      : ""}
                                  </span>
                                </div>
                              </>
                            )}
                          </td>
                          <td>
                            {expense?.senior_status}
                            {expense.senior_status === "Reject" && (
                              <>
                                <RB.Button
                                  className="btn btn-flat ms-2"
                                  title="Reject Reason"
                                  onClick={handleShowModal(
                                    "Reject Reason",
                                    expense.senior_reson
                                  )}
                                >
                                  <Eye />
                                </RB.Button>
                              </>
                            )}
                          </td>
                          <td className="text-center">
                            {Array.isArray(expense?.expense_image) &&
                            expense?.expense_image?.length !== 0 ? (
                              <RB.Button
                                className="btn btn-flat ms-1"
                                onClick={handleShow(expense?.expense_image)}
                              >
                                <Document />
                              </RB.Button>
                            ) : (
                              ""
                            )}
                          </td>
                          {location.pathname === "/expenses/your-expense" && (
                            <td>
                              {expense.senior_status !== "Reject" ? (
                                <>
                                  {expense.senior_status == "Pending" && (
                                    <RB.Button
                                      className="btn btn-flat ms-1"
                                      onClick={handleDelete(expense)}
                                    >
                                      <Delete />
                                    </RB.Button>
                                  )}
                                </>
                              ) : (
                                <>
                                  <RB.Button
                                    className="btn btn-flat ms-1"
                                    title="Update Expense"
                                    onClick={handleEdit(expense)}
                                  >
                                    <Edit />
                                  </RB.Button>
                                </>
                              )}
                            </td>
                          )}
                          {isVisible && (
                            <td>
                              <RB.Button
                                className="btn"
                                variant="primary"
                                onClick={handleUpdateAmount(expense)}
                                disabled={
                                  updatedAmounts[expense.id] === undefined ||
                                  updatedAmounts[expense.id] === expense.amount
                                }
                              >
                                Update
                              </RB.Button>
                            </td>
                          )}
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </RB.Table>
            </RB.Card>
          ))}
        </>
      ) : (
        <RB.Row className="justify-content-center mt-1">
          <RB.Col className="text-center">
            <h5>No expenses found.</h5>
          </RB.Col>
        </RB.Row>
      )}

      <RB.Modal show={show} onHide={handleClose} size="lg">
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Expense Documents</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          <RB.Row>
            {doc.map((image, index) => (
              <RB.Col md={4} key={index}>
                {image.expense_image.toLowerCase().endsWith(".pdf") ? (
                  <a
                    href={image.expensePath}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="product-image-upload-trael"
                  >
                    <div
                      className="position-relative me-2 mb-4 rounded border text-center"
                      style={{ maxWidth: "100%", height: "180px" }}
                    >
                      <div className="position-relative mt-4">
                        <i className="material-icons-outlined md-64">
                          picture_as_pdf
                        </i>
                      </div>
                    </div>
                  </a>
                ) : (
                  <div className="position-relative me-2 mb-4 rounded border text-center">
                    <div className="position-relative">
                      <a
                        // href={image.expensePath}
                        // target="_blank"
                        // rel="noopener noreferrer"
                        className="product-image-upload-trael"
                      >
                        <img
                          src={image.expensePath}
                          alt={image.expense_image}
                          className="img-fluid"
                          style={{ maxWidth: "100%", height: "180px" }}
                        />
                      </a>
                      <RB.Button
                        variant="primary"
                        className="btn btn-sm btn-circle position-absolute top-100 start-50 translate-middle"
                        onClick={() => handleDownload(image.expensePath)}
                      >
                        <i class="material-icons-outlined">file_download</i>
                      </RB.Button>
                    </div>
                  </div>
                )}
              </RB.Col>
            ))}
          </RB.Row>
        </RB.Modal.Body>
        <RB.Modal.Footer>
          <RB.Button variant="secondary" onClick={handleClose}>
            Close
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>

      <RB.Modal show={showRejectModal} onHide={handleRejectClose}>
        <RB.Modal.Header closeButton>
          <RB.Modal.Title>Enter Reject Reason</RB.Modal.Title>
        </RB.Modal.Header>
        <RB.Modal.Body>
          <RB.Form.Control
            as="textarea"
            placeholder="Enter reject reason..."
            value={rejectReason}
            onChange={(e) => {
              setRejectReason(e.target.value);
              setRejectReasonError("");
            }}
          />
          {rejectReasonError && (
            <div className="text-danger mt-2">{rejectReasonError}</div>
          )}
        </RB.Modal.Body>

        <RB.Modal.Footer>
          <RB.Button variant="secondary" onClick={handleRejectClose}>
            Close
          </RB.Button>
          <RB.Button variant="danger" onClick={handleRejectSubmit}>
            Reject
          </RB.Button>
        </RB.Modal.Footer>
      </RB.Modal>
      <ReasonModal
        show={showModalR}
        onHide={() => setShowModalR(false)}
        title={modalTitle}
        text={modalText}
      />
    </>
  );
}

export default ExpensesList;
