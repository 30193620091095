import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as RB from "react-bootstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "../FilterComponent";
import Swal from "sweetalert2";
import EditIcon from "@mui/icons-material/Edit";
import {
  Delete,
  Email,
  MoreHoriz,
  PersonAdd,
  RuleFolder,
} from "@mui/icons-material";
import * as utils from "../../Utils/comman";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { addOnboardedEmployee } from "../../reducers/onboardedEmployeeSlice";
import { Edit2, UserAdd } from "iconsax-react";
import CustomPagination from '../../Utils/CustomPagination'
function AcceptedList(props) {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const countPerPage = 10;
  const dispatch = useDispatch();
  useEffect(() => {
    getManPower();
  }, [page]);
  const primaryMenuRes = useSelector(
    (state) => state?.primaryMenu?.primaryMenu?.permissions
  );

  const getManPower = () => {
    utils
      .callAPI(
        "get",
        `/department/getManpowerList?per_page&page&id=&status=accept`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setList(res);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        setList([]);
      });
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Department",
      selector: (row) => row.department_name,
    },
    {
      name: "Expected Date of Joining",
      selector: (row) =>
        moment(row.expected_date_of_joining).format("YYYY-MM-DD"),
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      center: true,
      cell: (row) => (
        <>
          {utils.hasPermission(
            primaryMenuRes,
            "Proposed Manpower",
            "proposed_manpower.edit"
          ) && (
            <RB.Button
              className="btn-flat"
              title="Edit"
              onClick={handelEdit(row)}
            >
              <Edit2 />
            </RB.Button>
          )}
          {utils.hasPermission(
            primaryMenuRes,
            "Proposed Manpower",
            "proposed_manpower.on_board"
          ) && (
            <>
              {row.status == "Accept" && (
                <RB.Button
                  className="btn-flat ms-2"
                  title="On-board Employee"
                  onClick={handleOnboard(row)}
                >
                  <UserAdd />
                </RB.Button>
              )}
            </>
          )}
        </>
      ),
    },
  ];

  const handleOnboard = useCallback((row) => async () => {
    dispatch(addOnboardedEmployee(row));
    navigate("/employee?offer-accepted");
  });
  const handelEdit = useCallback((row) => async () => {
    const propdata = {
      mode: "edit",
      rowData: row,
    };
    props.props.onChange(propdata);
  });

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  
  return (
    <>
      <DataTable
        columns={columns}
        data={list.result}
        highlightOnHover
        responsive
        pagination
        paginationServer
        paginationTotalRows={list.total}
        paginationPerPage={countPerPage}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        paginationComponent={() => (
          <CustomPagination
            currentPage={page}
            totalRows={list.total}
            rowsPerPage={countPerPage}
            onPageChange={handlePageChange}
          />
        )}
        striped
        onChangePage={(page) => setPage(page)}
        persistTableHead
        customStyles={{
          head: {
            style: {
              fontSize: "0.875rem", // Adjust the font size as needed
              fontWeight: "bold", // Adjust the font weight as needed
            },
          },
        }}
        // subHeader
        // subHeaderComponent={subHeaderComponent}
      />
    </>
  );
}

export default AcceptedList;
