import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";
import { Link } from "react-router-dom";

function Add(props) {
  const [isEdit, setisEdit] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNo: "",
    email: "",
    isActive: "",
    roleId: "",
  });
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [role, setRole] = useState([]);
  const [validateMobile, setValidateMobile] = useState(false);

  const roles = [
    { id: "", name: "Select Designation..." },
    { id: "1", name: "Admin" },
    { id: "5", name: "User" },
    { id: "3", name: "Guest" },
  ];

  const status = [
    { id: "", name: "Select an option..." },
    { id: "1", name: "Active" },
    { id: "0", name: "Inactive" },
  ];

  useEffect(() => {
    getRoleList();
  }, []);

  const getRoleList = () => {
    utils
      .callAPI("get", `/role/list`)
      .then((res) => {
        if (typeof res !== "undefined") {
          setRole([{ id: "", roleName: "Select an option..." }, ...res.result]);
        } else {
          setRole([]);
        }
      })
      .catch((err) => {
        setRole([]);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let validatedValue = value;

    if (name === "mobileNo") {
      validatedValue = value.replace(/\D/g, "");

      setValidateMobile(validatedValue.length !== 10);

      // // Restrict input to 10 digits
      // if (validatedValue.length > 10) {
      //   validatedValue = validatedValue.slice(0, 10);
      // }
    }
    setFormData({
      ...formData,
      [name]: validatedValue,
    });
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      if (validateMobile) {
        return;
      }
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (props.rowData && Object.keys(props.rowData).length > 0) {
        bodyFormData.append("id", props.rowData.id);
      }
      bodyFormData.append("firstName", formData.firstName);
      bodyFormData.append("middleName", formData.middleName);
      bodyFormData.append("lastName", formData.lastName);
      bodyFormData.append("mobileNo", formData.mobileNo);
      bodyFormData.append("email", formData.email);
      bodyFormData.append("isActive", formData.isActive);
      // bodyFormData.append("roleId", formData.roleId);
      if (!isEdit) {
        bodyFormData.append("password", "Test@123");
      }

      if (props.rowData && Object.keys(props.rowData).length > 0) {
        utils
          .callAPI("post", `/users/users-edit`, bodyFormData)
          .then((res) => {
            if (typeof res !== "undefined") {
              const propdata = {
                mode: "list",
              };
              props.onChange(propdata);
              setIsSubmit(false);
            }
          })
          .catch((err) => {});
      } else {
        utils
          .callAPI("post", `/authentication/register`, bodyFormData)
          .then((res) => {
            if (typeof res !== "undefined") {
              const propdata = {
                mode: "list",
              };
              props.onChange(propdata);
              setIsSubmit(false);
            }
          })
          .catch((err) => {});
      }
      setIsSubmit(false);
    }
  }, [isSubmit]);

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      setisEdit(true);
      const roleId = props.rowData.user_role[0]?.id;
      setFormData({
        firstName: props.rowData.firstName || "",
        middleName: props.rowData.middleName || "",
        lastName: props.rowData.lastName || "",
        mobileNo: props.rowData.mobileNo || "",
        email: props.rowData.email || "",
        isActive: props.rowData.isActive || "",
        roleId: roleId || "",
      });
    } else {
      setisEdit(false);
    }
  }, [props.rowData]);

  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
        <RB.Card.Header>
          <h4>{isEdit ? "Edit" : "Add"}</h4>

          <div className="card-header-action">
          <Link
              className="btn btn-underline me-2"
              onClick={handleBackClick}
            >
              Back
            </Link>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="mb-1">
              <RB.Row>
                <RB.Col md={6}>
                  <div className="mb-3">
                    <RB.FloatingLabel
                      controlId="formFirstName"
                      label="First Name"
                      className="floating-label-fancy"
                    >
                      <RB.Form.Control
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </RB.FloatingLabel>
                  </div>
                </RB.Col>
                <RB.Col md={6}>
                  <div className="mb-3">
                    <RB.FloatingLabel
                      controlId="formMiddleName"
                      label="Middle Name"
                      className="floating-label-fancy"
                    >
                      <RB.Form.Control
                        type="text"
                        placeholder="Enter Middle Name"
                        name="middleName"
                        value={formData.middleName}
                        onChange={handleInputChange}
                        required
                      />
                    </RB.FloatingLabel>
                  </div>
                </RB.Col>
              </RB.Row>
            </div>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formLastName"
                    label="Last Name"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Last Name"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleInputChange}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formMobileNo"
                    label="Mobile No"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Mobile No"
                      name="mobileNo"
                      value={formData.mobileNo}
                      onChange={handleInputChange}
                      required
                    />
                    {validateMobile ? (
                      <span className="text-danger">
                        {" "}
                        Please provide a valid mobileNo.
                      </span>
                    ) : (
                      ""
                    )}
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formEmail"
                    label="Email"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formIsActive"
                    label="Status"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      as="select"
                      name="isActive"
                      value={formData.isActive}
                      onChange={handleInputChange}
                      required
                    >
                      {status.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </RB.Form.Control>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            {/* <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    controlId="formRoleId"
                    label="Role"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Select
                      as="select"
                      name="roleId"
                      value={formData.roleId}
                      onChange={handleInputChange}
                      required
                    >
                      {role.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.roleName}
                        </option>
                      ))}
                    </RB.Form.Select>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row> */}

            <RB.Row className="align-items-center mt-3">
              <RB.Col className="col-auto">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
