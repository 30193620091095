import React, { useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";
import DatePicker from "react-datepicker";
import Multiselect from "multiselect-react-dropdown";
import moment from "moment";
import noImage from "../../assets/images/no_image.jpg";
import { toast } from "react-toastify"; // Import toast from react-toastify
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

function Add(props) {
  const [isEdit, setisEdit] = useState(false);
  const [holidayGroups, setHolidayGroups] = useState([]);
  const [isValidImage, setisValidImage] = useState(false);
  const [pinCodeDetails, setPinCodeDetails] = useState(null);
  const [bankVerificationDone, setBankVerificationDone] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    aadharCardNo: "",
    email: "",
    panNo: "",
    dob: "",
    house: "",
    street: "",
    city: "",
    state: "",
    pinCode: "",
    country: "",
    gender: "",
    image: "",
    mobileNo: "",
    bank_name: "",
    account_holder_name: "",
    bank_account_no: "",
    bank_ifsc_code: "",
    isActive: "",
  });
  const [validated, setValidated] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [emergencyContactError, setEmergencyContactError] = useState(false);
  const [tempStudentProfilePic, setTempStudentProfile] = useState(
    props.rowData && props.rowData.image ? props.rowData.image : noImage
  );
  const [bankVerfiyId, setBankVerfiyId] = useState("");
  const [studentProfilePic, setStudentProfilePic] = useState("");
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const genderList = [
    { id: "", value: "Select an option..." },
    { id: "1", value: "Male" },
    { id: "2", value: "Female" },
    { id: "3", value: "Other" },
  ];

  // Function to handle bank verification
  const handleBankVerify = () => {
    var bodyFormData = new FormData();

    bodyFormData.append("ifsc_code", formData.bank_ifsc_code);
    bodyFormData.append("account_no", formData.bank_account_no);

    if (formData.bank_account_no && formData.bank_ifsc_code) {
      setIsLoading(true);
      // Trigger API call for bank verification
      utils
        .callAPI("post", `/Client/bank-verification-byAdmin`, bodyFormData)
        .then((res) => {
          // If verification is successful, set bank verification state to true
          if (typeof res !== "undefined") {
            if (res.bank_verify) {
              setBankVerfiyId(res.random_id);
              setBankVerificationDone(true);
            } else {
              setBankVerfiyId("");
              setBankVerificationDone(false);
            }
          }
        })
        .catch((err) => {
          // Handle error if verification fails

          console.error("Bank verification failed:", err);
        })
        .finally(() => {
          setIsLoading(false); // Stop loading regardless of success or failure
        });
    } else {
      // Show toast notification if both fields are not filled
      //toast.error("Please enter both bank account number and IFSC code.");
    }
  };

  const fetchPinCodeDetails = async (pinCode) => {
    try {
      const response = await fetch(
        `https://api.postalpincode.in/pincode/${pinCode}`
      );
      const data = await response.json();
      if (data && data.length > 0 && data[0].Status === "Success") {
        setPinCodeDetails(data[0].PostOffice[0]);
      } else {
        setPinCodeDetails(null);
      }
    } catch (error) {
      console.error("Error fetching pin code details:", error);
      setPinCodeDetails(null);
    }
  };

  // Update pin code details when pin code changes
  useEffect(() => {
    if (formData.pinCode.length === 6) {
      fetchPinCodeDetails(formData.pinCode);
    } else {
      setPinCodeDetails(null);
    }
  }, [formData.pinCode]);

  // Update form fields based on pin code details
  useEffect(() => {
    if (pinCodeDetails) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        city: pinCodeDetails.District,
        state: pinCodeDetails.State,
        country: pinCodeDetails.Country,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        city: "",
        state: "",
        country: "",
      }));
    }
  }, [pinCodeDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let validatedValue = value;

    if (name === "mobileNo") {
      validatedValue = value.replace(/\D/g, "");
      setEmergencyContactError(validatedValue.length !== 10);
    } else if (name === "pinCode") {
      validatedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    }

    setFormData({
      ...formData,
      [name]: validatedValue,
    });
  };

  const handleStudentUpload = (e) => {
    // const file = e.target.files[0];
    //   const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    var link = document.getElementById("studentProfilePic");
    link.click();
  };

  const handleStudentFileChange = (e) => {
    const file = e?.target?.files[0];

    if (!file) {
      return;
    }

    if (
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg" &&
      file.type !== "image/png"
    ) {
      // Handle invalid file type
      return;
    }

    setTempStudentProfile(URL.createObjectURL(file));
    setStudentProfilePic(e?.target?.files[0]);
  };

  const handleBackClick = () => {
    const propdata = {
      mode: "list",
    };
    props.onChange(propdata);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      if (emergencyContactError) {
        return;
      }
      setIsSubmit(true);
    }
  };

  useEffect(() => {
    if (isSubmit) {
      var bodyFormData = new FormData();
      if (isEdit) {
        bodyFormData.append("id", props.rowData.id);
        //     bodyFormData.append("role_edit_id", props.rowData.role_edit_id);
      }
      //   bodyFormData.append("employee_photo", "");

      bodyFormData.append("name", formData.name);

      // bodyFormData.append("email", formData.email);

      bodyFormData.append("address", formData.house);
      bodyFormData.append("street", formData.street);
      bodyFormData.append("state", formData.state);
      bodyFormData.append("city", formData.city);
      bodyFormData.append("pincode", formData.pinCode);
      bodyFormData.append("country", formData.country);
      bodyFormData.append("gender", formData.gender);

      bodyFormData.append("current_address", formData.house);
      bodyFormData.append("current_street", formData.street);
      bodyFormData.append("current_state", formData.state);
      bodyFormData.append("current_city", formData.city);
      bodyFormData.append("current_pincode", formData.pinCode);
      bodyFormData.append("current_country", formData.country);

      bodyFormData.append("aadharCardNo", formData.aadharCardNo);
      bodyFormData.append("panCardNo", formData.panNo);

      bodyFormData.append(
        "dateOfBirth",
        moment(formData.dob).format("YYYY-MM-DD")
      );

      bodyFormData.append("mobileNo", formData.mobileNo);
      bodyFormData.append("bankName", formData.bank_name);
      bodyFormData.append("accountNumber", formData.bank_account_no);
      bodyFormData.append("accountHolderName", formData.account_holder_name);
      bodyFormData.append("ifscCode", formData.bank_ifsc_code);
      bodyFormData.append("image", studentProfilePic);
      bodyFormData.append("document", formData.image);
      bodyFormData.append("bank_verify", bankVerificationDone ? "1" : "0");
      if (bankVerfiyId) bodyFormData.append("random_id", bankVerfiyId);
      if (isEdit) bodyFormData.append("client_id", props.rowData.id);
      console.log(props);

      utils
        .callAPI("post", `/Client/save`, bodyFormData)
        .then((res) => {
          if (typeof res !== "undefined") {
            setIsSubmit(false);
            setisEdit(false);
            const propdata = {
              mode: "list",
            };
            props.onChange(propdata);
          } else {
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          setIsSubmit(false);
        });
    }
  }, [isSubmit]);

  useEffect(() => {
    if (props.rowData && Object.keys(props.rowData).length > 0) {
      setisEdit(true);
      const rowData = props.rowData;
      console.log("yeh row data hai bhai artisan ka", rowData);
      setBankVerificationDone(rowData?.bank_verify === "1" ? true : false);

      setFormData({
        employeeId: rowData.employeeId || "",
        name: rowData.name || "",
        email: rowData.email || "",
        aadharCardNo: rowData.aadharCardNo || "",
        panNo: rowData.panCardNo || "",
        dob: rowData.dateOfBirth || "",
        house: rowData.address || "",
        street: rowData.street || "",
        city: rowData.city || "",
        state: rowData.state || "",
        pinCode: rowData.pincode || "",
        country: rowData.country || "",
        gender: rowData?.gender || "",
        mobileNo: rowData.mobileNo || "",
        bank_name: rowData.bankName || "",
        account_holder_name: rowData.accountHolderName || "",
        bank_account_no: rowData.accountNumber || "",
        bank_ifsc_code: rowData.ifscCode || "",

        image: rowData.document || "",
        //  isActive: rowData.isActive || "",
      });
      // setOnboardPlan( rowData.onboard_plan.split(",").map(id => ({ cat: id, key: `Plan ${id}` })))
    } else {
      setisEdit(false);
    }
  }, [props.rowData]);

  const handleImageChange = (e) => {
    const file = e?.target?.files[0];
    // Validate file types (jpg, jpeg, png)
    if (!file) {
      return;
    }
    const isValidFileType =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";
    if (!isValidFileType) {
      // Handle invalid file type
      return;
    }
    console.log("image ki file yeh ahi", file);
    setFormData({ ...formData, image: file });
  };

  const handleDeleteImage = (index) => {
    setFormData({
      ...formData,
      image: "",
    });
  };

  const handleDragOver = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(true);
    }
  };

  const handleDragEnter = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(true);
    }
  };

  const handleDragLeave = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(false);
    }
  };

  const handleDrop = (e, type) => {
    e.preventDefault();
    if (type == "image") {
      setIsDraggingOver(false);

      const file = e.dataTransfer.files[0];
      if (!file) {
        return;
      }
      const isValidFileType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";

      if (!isValidFileType) {
        setisValidImage(true);
        toast.error(
          "Invalid file type. Please upload JPEG, JPG, or PNG images.",
          {
            position: "top-right",
            autoClose: 5000,
          }
        );
        return;
      } else {
        setisValidImage(false);
        setFormData({ ...formData, image: file });
      }
    }
  };

  return (
    <div className="section-body">
      <RB.Card className="card shadow ">
        <RB.Card.Header>
          <h4>{isEdit ? "Edit Artisan" : "Add Artisan"}</h4>

          <div className="card-header-action">
          <Link
              className="btn btn-underline"
              onClick={handleBackClick}
            >
              Back
            </Link>
          </div>
        </RB.Card.Header>
        <RB.Card.Body>
          <RB.Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="d-flex align-items-center mb-2">
              <h6 className="mb-0">Personal Information</h6>
              {isLoading ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : bankVerificationDone ? (
                <i className="material-icons text-success ms-2">verified</i>
              ) : (
                <i className="material-icons text-danger ms-2">cancel</i>
              )}
            </div>
            <hr className="hr-lg mt-1" />

            <RB.Row>
              <RB.Col md={2}>
                <RB.Row>
                  <div className="col-md-10 text-center">
                    <div className="profile-photo rounded mb-3">
                      <img
                        src={tempStudentProfilePic}
                        className="img-fluid rounded"
                        id="img_profile4"
                        alt="profile"
                      />
                    </div>
                    <a
                      className="btn btn-block btn-outline-primary upload-button mb-3"
                      id="btn-f-upload"
                      onClick={handleStudentUpload}
                    >
                      Upload Photo
                    </a>
                    <input
                      id="studentProfilePic"
                      type="file"
                      className="form-control d-none"
                      name="studentProfilePic"
                      onChange={handleStudentFileChange}
                    />
                  </div>
                </RB.Row>
              </RB.Col>
              <RB.Col md={10}>
                <RB.Row>
                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        label="Full Name"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="text"
                          placeholder="Enter Full Name"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                          maxLength={32}
                        />
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>

                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        label="Mobile No"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          type="text"
                          placeholder="Enter Mobile No"
                          name="mobileNo"
                          value={formData.mobileNo}
                          onChange={handleInputChange}
                          required
                          maxLength={10}
                        />
                        {emergencyContactError ? (
                          <span className="text-danger">
                            {" "}
                            Please provide a valid emergency contact.
                          </span>
                        ) : (
                          ""
                        )}
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                </RB.Row>
                {/* <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Email"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col> */}
                <RB.Row>
                  <RB.Col md={6}>
                    <div className="mb-3 mt-3">
                      <RB.FloatingLabel
                        // label="Join Date"
                        className="floating-label-fancy"
                      >
                        <DatePicker
                          selected={formData.dob}
                          onChange={(date) => {
                            // Check if the input contains only numbers, hyphens, and slashes

                            setFormData({ ...formData, dob: date });
                          }}
                          dateFormat="dd-MM-yyyy"
                          className="form-control"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="Date of Birth"
                          required
                        />
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>

                  <RB.Col md={6}>
                    <div className="mb-3">
                      <RB.FloatingLabel
                        controlId="formRoleId"
                        label="Gender"
                        className="floating-label-fancy"
                      >
                        <RB.Form.Control
                          as="select"
                          name="gender"
                          className="form-select"
                          value={formData.gender}
                          onChange={handleInputChange}
                          required
                        >
                          {genderList.map((gen) => (
                            <option key={gen.id} value={gen.value}>
                              {gen.value}
                            </option>
                          ))}
                        </RB.Form.Control>
                        <RB.Form.Control.Feedback type="invalid">
                          Please select option for gender.
                        </RB.Form.Control.Feedback>
                      </RB.FloatingLabel>
                    </div>
                  </RB.Col>
                </RB.Row>
              </RB.Col>
            </RB.Row>
            <h6 className="mt-2">Address Information</h6>
            <hr className="hr-lg mt-1" />
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Flat/House"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Flat/House "
                      name="house"
                      value={formData.house}
                      onChange={handleInputChange}
                      required
                      maxLength={32}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Area/Street"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Area/Street"
                      name="street"
                      value={formData.street}
                      onChange={handleInputChange}
                      required
                      maxLength={32}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Pin Code"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Pin Code "
                      name="pinCode"
                      value={formData.pinCode}
                      onChange={handleInputChange}
                      required
                      maxLength={6}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="City/Town"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter City/Town "
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      readOnly
                      maxLength={32}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="State"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter State"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      required
                      maxLength={32}
                      readOnly
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Country"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Country"
                      name="country"
                      value={formData.country}
                      onChange={handleInputChange}
                      required
                      maxLength={32}
                      readOnly
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <h6 className="mt-2">Document Information</h6>
            <hr className="hr-lg mt-1" />
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Aadhar Card No"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Aadhar Card No"
                      name="aadharCardNo"
                      value={formData.aadharCardNo}
                      onChange={handleInputChange}
                      required
                      maxLength={12}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Pan Card No"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Pan Card"
                      name="panNo"
                      value={formData.panNo}
                      onChange={handleInputChange}
                      required
                      // maxLength={10}
                    />
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>
            <RB.Row>
              <RB.Col md={6}>
                <RB.Card>
                  <RB.CardBody className="p-2">
                    <h6 className="mb-1">Aadhar Card Image</h6>
                    <hr />
                    <div
                      className={`border p-4 text-center d-flex flex-column align-items-center ${
                        isDraggingOver ? "bg-info" : ""
                      }`}
                      onDragOver={(e) => handleDragOver(e, "image")}
                      onDragEnter={(e) => handleDragEnter(e, "image")}
                      onDragLeave={(e) => handleDragLeave(e, "image")}
                      onDrop={(e) => handleDrop(e, "image")}
                    >
                      <div className="avatar avatar-circle avatar-lg bg-light text-dark">
                        <i className="material-icons-outlined">cloud_upload</i>
                      </div>
                      <p className="mb-0">Drag & Drop your image here</p>
                      <small className="mb-2">or</small>
                      <div>
                        <input
                          type="file"
                          accept="image/jpeg, image/jpg, image/png"
                          style={{ display: "none" }}
                          onChange={handleImageChange}
                          id="fileInput"
                        />
                        <RB.Button
                          variant="primary"
                          className="me-2"
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                        >
                          <i className="las la-file-upload me-1"></i>Browse
                          Image
                        </RB.Button>
                      </div>
                    </div>
                    <span className={`${isValidImage ? "text-danger" : ""}`}>
                      <small>
                        *Note: Only JPG,JPEG and PNG Files are allowed
                      </small>
                    </span>

                    <RB.Row className="mt-3 g-3">
                      {formData.image ? (
                        <RB.Col className="col-md-6 col-lg-4">
                          <div className="position-relative mb-4">
                            <div className="product-image-upload">
                              {formData.image instanceof File ? (
                                <img
                                  src={URL.createObjectURL(formData.image)}
                                  alt={`Image`}
                                />
                              ) : (
                                // Use formData.image directly when it's already a URL
                                <img src={formData.image} alt={`Image`} />
                              )}
                            </div>
                            <RB.Button
                              variant="danger"
                              className="btn btn-sm btn-circle position-absolute top-100 start-50 translate-middle"
                              onClick={() => handleDeleteImage(0)} // Assuming index 0 for single image
                            >
                              <i className="material-icons-outlined">delete</i>
                            </RB.Button>
                          </div>
                        </RB.Col>
                      ) : null}
                    </RB.Row>
                  </RB.CardBody>
                </RB.Card>
              </RB.Col>
            </RB.Row>

            <div className="d-flex justify-content-between align-items-center">
              <h6 className="mt-2">Banking Information</h6>
            </div>
            <hr className="hr-lg mt-1" />
            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Account Holder's Name"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Account Holder's Name"
                      name="account_holder_name"
                      value={formData.account_holder_name}
                      onChange={handleInputChange}
                      required
                      maxLength={32}
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please Enter Account Holder's Name
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>

              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Bank Name"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Bank Name"
                      name="bank_name"
                      value={formData.bank_name}
                      onChange={handleInputChange}
                      required
                      maxLength={50}
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please Enter Bank Name.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>

            <RB.Row>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Bank Account No"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Bank Account No"
                      name="bank_account_no"
                      value={formData.bank_account_no}
                      onChange={handleInputChange}
                      required
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please Enter Bank Account No.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
              <RB.Col md={6}>
                <div className="mb-3">
                  <RB.FloatingLabel
                    label="Bank IFSC Code"
                    className="floating-label-fancy"
                  >
                    <RB.Form.Control
                      type="text"
                      placeholder="Enter Bank IFSC Code"
                      name="bank_ifsc_code"
                      value={formData.bank_ifsc_code}
                      onChange={handleInputChange}
                      required
                    />
                    <RB.Form.Control.Feedback type="invalid">
                      Please Enter Bank IFSC Code.
                    </RB.Form.Control.Feedback>
                  </RB.FloatingLabel>
                </div>
              </RB.Col>
            </RB.Row>
            <p className="text-body-secondary">
              Complete your account verification to facilitate secure banking
              transactions.
              <a
                className="text-warning link-underline-warning  fw-bold d-inline-block ms-1"
                onClick={handleBankVerify}
              >
                Verify Now
              </a>
            </p>
            <RB.Row className="align-items-center mt-3">
              <RB.Col className="col-auto">
                <RB.Button variant="primary" type="submit">
                  Submit
                </RB.Button>
              </RB.Col>
            </RB.Row>
          </RB.Form>
        </RB.Card.Body>
      </RB.Card>
    </div>
  );
}

export default Add;
