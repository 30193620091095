import { createSlice } from '@reduxjs/toolkit';

const expIdSlice = createSlice({
    name: 'expId',
    initialState: null,
    reducers: {
        setExpId: (state, action) => action.payload,
    },
});

export const { setExpId } = expIdSlice.actions;

export default expIdSlice.reducer;
