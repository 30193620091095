// reducers/employeeTDSSlice.js

import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  employeeTDS: [],
};

const empTDSDataSlice = createSlice({
  name: "empTDS",
  initialState,
  reducers: {
    setEmpTDS: (state, action) => {
      state.employeeTDS = action.payload;
    },
  },
});

export const { setEmpTDS } = empTDSDataSlice.actions;
export default empTDSDataSlice.reducer;
