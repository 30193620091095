export const CLOCK_IN = 'CLOCK_IN';
export const CLOCK_OUT = 'CLOCK_OUT';

export const clockIn = (checkType) => ({
  type: CLOCK_IN,
  checkType: checkType,
});

export const clockOut = (checkType) => ({
  type: CLOCK_OUT,
  checkType: checkType,
});

const initialState = {
  clockStatus: 'in', 
  checkType: 'local', // Set default checkType
};

const clockReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOCK_IN:
      return {
        ...state,
        clockStatus: 'in',
        checkType: action.checkType, // Update checkType
      };
    case CLOCK_OUT:
      return {
        ...state,
        clockStatus: 'out',
        checkType: action.checkType, // Update checkType
      };
    default:
      return state;
  }
};

export default clockReducer;
