import React, { useCallback, useEffect, useState } from "react";
import * as RB from "react-bootstrap";
import * as utils from "../../Utils/comman";
import { Tree } from "primereact/tree";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";

function AssignPermission({ rowData, permission, roleApi, setRoleApi }) {
  const [selectedKeys, setSelectedKeys] = useState({});
  const [currentUserPermission, setCurrentUserPermission] = useState([]);
  const [previouslySelectedKeys, setPreviouslySelectedKeys] = useState({});
  const [excludeID, setExcludeId] = useState([]);

  useEffect(() => {
    if (roleApi) {
      getCurrentUserPermission();
    }
  }, [roleApi]);

  const getCurrentUserPermission = () => {
    utils
      .callAPI(
        "get",
        `role/permissions?role_id=${rowData?.user_role[0].id}&user_id=${rowData?.id}`
      )
      .then((res) => {
        if (typeof res !== "undefined") {
          setCurrentUserPermission(res?.permissions);
          setExcludeId(res?.excludeIds);
          setRoleApi(false);
        } else {
          setRoleApi(false);
        }
      })
      .catch((err) => {
        setRoleApi(false);
      });
  };

  useEffect(() => {
    if (currentUserPermission.length > 0 && permission.length > 0) {
      const selectedKeyData = {};

      // Loop through each module in the user's current permissions
      currentUserPermission.forEach((module) => {
        // Find the corresponding module in the permissions list
        const moduleInPermission = permission.find((permModule) =>
          permModule.children.some(
            (child) => child.key === module.permission_id
          )
        );

        if (moduleInPermission) {
          let allChildrenChecked = true;
          let anyChildChecked = false;

          // Loop through each child in the found permission module
          moduleInPermission.children.forEach((child) => {
            const foundPermission = currentUserPermission.some(
              (userPerm) => userPerm.permission_id === child.key
            );
            const isExcluded = excludeID.some(
              (exclude) => exclude.permission_id === child.key
            );

            // If the permission is found and it's not excluded, mark it as checked
            if (foundPermission && !isExcluded) {
              selectedKeyData[child.key] = { checked: true };
              anyChildChecked = true;
            } else {
              allChildrenChecked = false;
            }
          });

          // Check the state of the parent based on its children's states
          if (anyChildChecked) {
            const isModuleExcluded = excludeID.some(
              (exclude) => exclude.permission_id === moduleInPermission.key
            );

            if (allChildrenChecked && !isModuleExcluded) {
              // If all children are checked and none are excluded, mark the parent as fully checked
              selectedKeyData[moduleInPermission.key] = { checked: true };
            } else if (!isModuleExcluded) {
              // If some children are checked but not all, mark the parent as partially checked
              selectedKeyData[moduleInPermission.key] = {
                partialChecked: true,
              };
            }
          }
        }
      });

      setSelectedKeys(selectedKeyData);
    }
  }, [currentUserPermission, permission, excludeID]);

  useEffect(() => {
    if (Object.keys(selectedKeys).length > 0) {
      const newPreviouslySelectedKeys = { ...previouslySelectedKeys };

      Object.keys(selectedKeys).forEach((key) => {
        if (selectedKeys[key]?.checked || selectedKeys[key]?.partialChecked) {
          newPreviouslySelectedKeys[key] = true;
        }
      });

      setPreviouslySelectedKeys(newPreviouslySelectedKeys);
    }
  }, [selectedKeys, previouslySelectedKeys]);

  const modifiedPermissions = permission.map((module) => {
    const isModuleExcluded = excludeID.some(
      (exclude) => exclude.permission_id === module.key
    );

    const isModulePreviouslySelected = previouslySelectedKeys[module.key];
    const isChildPreviouslySelected = module.children.some(
      (child) => previouslySelectedKeys[child.key]
    );

    const anyChildExcluded = module.children.some((child) => {
      return excludeID.some((exclude) => exclude.permission_id === child.key);
    });

    return {
      ...module,
      children: module.children.map((child) => {
        const isChildExcluded = excludeID.some(
          (exclude) => exclude.permission_id === child.key
        );

        return {
          ...child,
          disabled: isChildExcluded,
          style:
            !selectedKeys[child.key]?.checked &&
            !previouslySelectedKeys[child.key] &&
            !isChildExcluded
              ? { pointerEvents: "none", opacity: 0.7, cursor: "not-allowed" }
              : {},
        };
      }),
      style:
        !selectedKeys[module.key]?.checked &&
        !isChildPreviouslySelected &&
        !isModulePreviouslySelected &&
        !isModuleExcluded &&
        !anyChildExcluded
          ? { pointerEvents: "none", opacity: 0.7, cursor: "not-allowed" }
          : {},
    };
  });

  const handlePermission = () => {
    const selectedPermissionIds = Object.keys(selectedKeys)
      .filter((key) => !isNaN(parseInt(key, 10)))
      .map((key) => parseInt(key, 10));

    const previouslySelectedPermissionIds = Object.keys(previouslySelectedKeys)
      .filter((key) => !isNaN(parseInt(key, 10)))
      .map((key) => parseInt(key, 10));

    const removedPermissions = previouslySelectedPermissionIds.filter(
      (id) => !selectedPermissionIds.includes(id)
    );

    const addedPermissions = selectedPermissionIds.filter((id) =>
      excludeID.some((exclude) => parseInt(exclude.permission_id, 10) === id)
    );

    const finalObj = {
      add: addedPermissions.join(","),
      remove: removedPermissions.join(","),
    };
    console.log("a", finalObj);

    var bodyFormData = new FormData();

    bodyFormData.append("role_id", rowData?.user_role[0].id);
    bodyFormData.append("permission_id", JSON.stringify(finalObj));
    bodyFormData.append("user_id", rowData?.id);

    utils
      .callAPI("post", `/role/update/permission`, bodyFormData)
      .then((res) => {
        if (typeof res !== "undefined") {
          // setAssignPermission(false);
          getCurrentUserPermission();
        }
      })
      .catch((err) => {});
  };

  const onSelectionChange = (e) => {
    const { value } = e; // This contains the new selected keys
  
    const findNodeByKey = (key, nodes) => {
      for (let node of nodes) {
        if (node.key === key) {
          return node;
        }
        if (node.children) {
          const childNode = findNodeByKey(key, node.children);
          if (childNode) {
            return childNode;
          }
        }
      }
      return null;
    };
  
    const filterDisabledChildren = (node, selectedKeys) => {
      let allChildrenSelected = true;
      let someChildrenSelected = false;
  
      if (node.children) {
        node.children.forEach((child) => {
          if (child.style.pointerEvents) {
            delete selectedKeys[child.key];
          } else if (selectedKeys[child.key]) {
            someChildrenSelected = true;
          } else {
            allChildrenSelected = false;
          }
        });
  
        // Set parent state based on children selection
        if (allChildrenSelected) {
          selectedKeys[node.key] = { checked: true };
        } else if (someChildrenSelected) {
          selectedKeys[node.key] = { partialChecked: true };
        } else {
          delete selectedKeys[node.key];
        }
      }
    };
  
    const newSelectedKeys = { ...value }; // Clone the selected keys
  
    // Find the currently selected node from the permissions tree
    Object.keys(newSelectedKeys).forEach((selectedKey) => {
      const selectedNode = findNodeByKey(selectedKey, modifiedPermissions);
  
      if (selectedNode) {
        // Pass only the selected node and its children to filterDisabledChildren
        filterDisabledChildren(selectedNode, newSelectedKeys);
      }
    });
  
    setSelectedKeys(newSelectedKeys); // Update selected keys state
  };
  

  return (
    <>
      <Tree
        className="w-full md:w-30rem"
        value={modifiedPermissions}
        selectionMode="checkbox"
        selectionKeys={selectedKeys}
        onSelectionChange={onSelectionChange}
      />
      <div class="d-flex justify-content-center mt-3">
        <div></div>
        <RB.Button variant="primary" onClick={handlePermission}>
          Update
        </RB.Button>
      </div>
    </>
  );
}

export default AssignPermission;
